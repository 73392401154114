var MAX_LENGTH = 255;
function SettingsBudgetCategory(attach, item, parent, details) {
  var _i = this;
  if (!(this instanceof SettingsBudgetCategory)) {
    return new SettingsBudgetCategory(attach, item, parent, details);
  }
  _i.attach = attach;
  _i.item = item;
  _i.parent = parent;
  _i.details = details;
  _i.mode = 'read';

  _i.itemChanged = function () {
    _i.parent.refreshList();
  };

  _i.deleteEdit = function () {
    var confirmDelete = confirmationPopup(
      I18n.t('lbl_delete') + ' ' + _i.item.category + '?',
      I18n.t('lbl_delete'),
      function () {
        gService.deleteBudgetItem(_i.item, _i.itemChanged);
      },
      null,
      _i.parent.container
    );
    confirmDelete.show(_i.deleteButton.container);
  };

  _i.cancelEdit = function () {
    if (_i.mode == 'edit') {
      _i.listMode();
    }
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
  };

  _i.numDuplicates = function (proposedValue) {
    var arr = gData.tagsByNameSpace[_i.tag.namespace];

    if (!arr) {
      return 0;
    }

    var dupes = 0;

    for (var i = 0; i < arr.length; i++) {
      if (
        arr[i].value.toLowerCase().replace(/\s/g, '') ==
        proposedValue.toLowerCase().replace(/\s/g, '')
      ) {
        dupes++;
      }
    }

    return dupes++;
  };

  _i.saveEdit = function () {
    var proposedValue = singleSpace(trim(_i.category.getValue()));

    if (proposedValue == '') {
      return;
    }
    var data = {};
    data.id = _i.item.id;
    data.category = _i.category.getValue();
    if (_i.amount) {
      var amount = convertCurrencyToNumber(_i.amount.getValue());
      if (amount == '') amount = null;
      data.peritem_amount = amount;
    }
    if (_i.label) {
      data.peritem_label = _i.label.getValue();
      if (data.item == '') data.peritem_label = null;
    }
    gService.updateBudgetItem(data, _i.itemChanged);
  };

  _i.containerMouseover = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainerHover';
    }
  };

  _i.containerMouseout = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    }
  };

  _i.containerClick = function (evt) {
    if (_i.mode != 'edit') {
      _i.parent.editModeCallback();
      _i.editMode();
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainerEdit';
    }
  };

  _i.editMode = function () {
    removeAllChildren(_i.valuecontainer);
    _i.category = new TextInput(
      _i.valuecontainer.id,
      null,
      _i.item.category != '' ? _i.item.category : '- empty -',
      'blockFloat',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmaller',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      { maxLength: MAX_LENGTH }
    );
    _i.category.inputText.setAttribute(
      'data-client-id',
      _i.item.category + '-CategoryInput'
    );
    _i.category.startingValue = _i.category.getValue();
    _i.category.inputText.style.width = '164px';

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer3 settingsSpacerHidden';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.valuecontainer.appendChild(spacer);

    if (_i.details) {
      var option = {};
      option['precision'] = 2;

      _i.amount = new TextInput(
        _i.valuecontainer.id,
        null,
        _i.item.peritem_amount
          ? formatCurrency(_i.item.peritem_amount, option)
          : '',
        'blockFloat',
        'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
        'blockFloatNot fnt-r-15 settingsPageTextInputSmaller80Right'
      );
      _i.amount.inputText.setAttribute(
        'data-client-id',
        _i.item.category + '-AmountInput'
      );
      _i.amount.startingValue = _i.amount.getValue();
      _i.amount.inputText.style.width = '48px';

      spacer = document.createElement('DIV');
      spacer.className = 'blockFloat fnt-r-14 settingsSpacer7';
      spacer.innerHTML = I18n.t('lbl_per');
      _i.valuecontainer.appendChild(spacer);

      _i.label = new TextInput(
        _i.valuecontainer.id,
        null,
        _i.item.peritem_label ? _i.item.peritem_label : '',
        'blockFloat',
        'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
        'blockFloatNot fnt-r-15 settingsPageTextInputSmaller80'
      );
      _i.label.inputText.setAttribute(
        'data-client-id',
        _i.item.category + '-ItemInput'
      );
      _i.label.startingValue = _i.label.getValue();
      _i.label.inputText.style.width = '48px';
    }

    _i.deleteButton = new destructiveButton(
      'settingsSecondaryButtonCategoryNarrow'
    );
    _i.deleteButton.setButtonText(I18n.t('lbl_delete'));
    _i.deleteButton.setCallback(_i.deleteEdit);
    _i.valuecontainer.appendChild(_i.deleteButton.container);
    _i.deleteButton.container.tabIndex = 0;
    _i.deleteButton.container.setAttribute(
      'data-client-id',
      _i.item.category + '-DeleteBtn'
    );

    _i.saveButton = new primaryButton('settingsSecondaryButtonCategoryNarrow');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveEdit);
    _i.valuecontainer.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;
    _i.saveButton.container.setAttribute(
      'data-client-id',
      _i.item.category + '-SaveBtn'
    );

    _i.cancelButton = new spritesButton(
      'settingsCancelButtonCategory',
      null,
      compSpritesButtonsIcons.closeButtonRest,
      compSpritesButtonsIcons.closeButtonHover,
      compSpritesButtonsIcons.closeButtonDown
    );
    _i.cancelButton.setButtonText('');
    _i.cancelButton.setCallback(_i.cancelEdit);
    _i.valuecontainer.appendChild(_i.cancelButton.container);
    _i.cancelButton.container.setAttribute(
      'data-client-id',
      _i.item.category + '-CancelBtn'
    );

    _i.mode = 'edit';
  };

  _i.listMode = function () {
    removeAllChildren(_i.valuecontainer);
    var name = document.createElement('DIV');
    name.className = 'blockFloat fnt-r-14 ellipsis settingsBudgetCategory';
    _i.valuecontainer.appendChild(name);
    name.innerText = _i.item.category != '' ? _i.item.category : '- empty -';

    name = document.createElement('DIV');
    name.className = 'blockFloat fnt-r-14 ellipsis settingsBudgetCategoryPer';
    _i.valuecontainer.appendChild(name);
    var option = {};
    option['precision'] = 2;
    name.innerText = _i.item.peritem_amount
      ? formatCurrency(_i.item.peritem_amount, option)
      : '';
    if (
      name.innerText != '' &&
      _i.item.peritem_label != null &&
      _i.item.peritem_label != ''
    ) {
      name.innerText += ' ' + I18n.t('lbl_per') + ' ' + _i.item.peritem_label;
    }
    _i.mode = 'list';
  };

  _i.constructor = function () {
    _i.valuecontainer = document.createElement('DIV');
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    _i.valuecontainer.id = 'settingsUserContainer' + getGuid();
    _i.valuecontainer.setAttribute(
      'data-client-id',
      _i.item.category + '-ValueContainer'
    );
    _i.attach.appendChild(_i.valuecontainer);
    xAddEventListener(
      _i.valuecontainer,
      'mouseover',
      _i.containerMouseover,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      'mouseout',
      _i.containerMouseout,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      platform.click,
      _i.containerClick,
      true
    );
    _i.listMode();
  };
  _i.constructor();
}
