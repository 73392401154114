function SettingsBillRate(
  attachTo,
  parentSave,
  projectMode,
  project,
  parentSettingsBillRate
) {
  var _i = this;

  if (!(this instanceof SettingsBillRate)) {
    return new SettingsBillRate(
      attachTo,
      parentSave,
      projectMode,
      project,
      parentSettingsBillRate
    );
  }

  _i.projectMode = projectMode;
  _i.project = project;
  _i.projectId = project ? project.id : null;

  _i.max_shown = 8;
  _i.attachTo = xGetElementById(attachTo);
  _i.parentSave = parentSave;
  _i.billrates = [];
  _i.billratesinput = [];
  _i.saveall = false;
  _i.billratesChanged = false;

  // ids
  _i.addDisciplineDropdownID = 'add-discipline' + Awe.getGuid();
  _i.addRoleDropdownID = 'add-role' + Awe.getGuid();

  _i.arrayContains = function (a, obj) {
    var i = a.length;
    while (i--) {
      if (a[i].value === obj) {
        return true;
      }
    }
    return false;
  };

  _i.resetWidths = function () {
    var parent = xGetElementById('pageMainInfoContentContainer');
    if (parent) parent.style.width = _i.width1 + 'px';

    parent = xGetElementById('pageContentContainer');
    if (parent) parent.style.width = _i.width2 + 'px';

    parent = xGetElementById('pagePageFloat');
    if (parent) parent.style.width = _i.width3 + 'px';
  };

  _i.reset = function () {
    removeAllChildren(_i.billratecontainer);
    _i.resetWidths();
    _i.buildData();
    _i.buildMatrix();
    _i.buildHeader();
    _i.buildBody();
    _i.buildFooter();
  };

  _i.cancelButtonClicked = function () {
    _i.reset();
  };

  _i.saveCallback = function () {
    __A(!_i.projectMode, 'saveCallback called while in project mode');
    new notificationPopup(
      I18n.t('lbl_bill_rates'),
      I18n.t('msg_bill_rates_saved_confirm'),
      5000
    );
    _i.reset();
  };

  _i.billRateIdFromInput = function (rowindex, colindex) {
    var val = _i.billratesinput[rowindex][colindex],
      id = null;
    if (val && val.dataset.billrateId) {
      if (_i.projectMode) {
        // do _not_ use ids of rates copied from the default matrix
        if (
          _i.projectId != null &&
          _i.projectId == Number(val.dataset.billrateAssignableId)
        ) {
          id = Number(val.dataset.billrateId);
        }
      } else {
        id = Number(val.dataset.billrateId);
      }
    }
    return id;
  };

  _i.valueFromInput = function (rowindex, colindex) {
    var val = _i.billratesinput[rowindex][colindex];
    if (val) val = val.value;
    if (val == '') return 'empty';
    if (!val) return 0;
    val = convertCurrencyToNumber(val);
    val = parseFloat(val);

    return isNumber(val) ? val : 0;
  };

  _i.checkSaveBillrate = function (data) {
    if (_i.saveall) return true;
    for (var i = 0; i < _i.billrates.length; i++) {
      if (
        _i.billrates[i].role_id == data.role_id &&
        _i.billrates[i].discipline_id == data.discipline_id &&
        _i.billrates[i].rate == data.rate &&
        _i.billrates[i].assignable_id == data.assignable_id
      )
        return false;
    }
    return true;
  };

  _i.getValues = function () {
    var values = [],
      i,
      j,
      data,
      discipline_id,
      role_id;

    for (i = 0; i < _i.rows.length; i++) {
      discipline_id = _i.rows[i].id;
      if (discipline_id == 0) {
        discipline_id = null;
      }
      for (j = 0; j < _i.cols.length; j++) {
        role_id = _i.cols[j].id;
        if (role_id == 0) role_id = null;
        data = {};
        data.rate = _i.valueFromInput(i, j);
        if (data.rate != 'empty') {
          data.id = _i.billRateIdFromInput(i, j);
          data.role_id = role_id;
          data.discipline_id = discipline_id;
          data.assignable_id = _i.projectId;
          values.push(data);
        }
      }
    }

    if (_i.projectMode && _i.userControllers != null) {
      for (i = 0; i < _i.userControllers.length; i++) {
        data = _i.userControllers[i].getValue();
        if (data.rate != 'empty') {
          values.push(data);
        }
      }
    }

    return values;
  };

  _i.saveButtonClicked = function () {
    var billRates = _i.getValues();
    if (_i.projectMode) {
      __A(false, 'saveButtonClicked should not be called in project mode');
    } else {
      if (billRates.length == 0) {
        new notificationPopup(
          I18n.t('lbl_bill_rates'),
          I18n.t('msg_empty_bill_rates'),
          5000
        );
      } else {
        gService.createOrUpdateAccountBillRates(billRates, _i.saveCallback);
      }
    }
  };

  _i.onDisciplineAddClick = function (obj) {
    var def = {},
      savedRates = _i.getValues();

    removeAllChildren(_i.billratecontainer);

    _i.updateData(savedRates);

    def.value = obj[0].value;
    def.id = obj[0].id;

    _i.rows.push(def);
    _i.buildHeader();
    _i.buildBody();
    _i.buildFooter();

    focusElementAfterDelay('#settingBillRateAddRow');
  };

  _i.onRoleAddClick = function (obj) {
    var def = {},
      savedRates = _i.getValues();

    removeAllChildren(_i.billratecontainer);

    _i.updateData(savedRates);

    def.value = obj[0].value;
    def.id = obj[0].id;

    _i.cols.push(def);
    _i.buildHeader();
    _i.buildBody();
    _i.buildFooter();

    focusElementAfterDelay('#settingBillRateAddCol');
  };

  _i.addColClick = function () {
    var elementParent;
    var elementChildren;
    _i.roleData = _i.parseTagsForPopup('resource type', true, _i.cols);

    var height =
      32 *
      (_i.roleData.length > _i.max_shown ? _i.max_shown : _i.roleData.length);
    if (height < 50) height = 50;
    _i.addRole = new selectionPopup(
      290,
      height,
      'mainCon',
      _i.roleData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onRoleAddClick,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      _i.addRoleDropdownID
    );
    // Must grab the parent element(ie. Phase or Project Settings) and then it's children because
    // many div's have the same ID on this page
    elementParent = xGetElementById(attachTo);
    elementChildren = elementParent.getElementsByClassName(
      'settingsBillRateTextButton2'
    );
    _i.addRole.show(elementChildren[0]);
    focusFirstChildElementAfterDelay('#add-role');
    return false;
  };

  _i.addRowClick = function () {
    var elementParent;
    var elementChildren;
    _i.disciplineData = _i.parseTagsForPopup('discipline', true, _i.rows);

    var height =
      32 *
      (_i.disciplineData.length > _i.max_shown
        ? _i.max_shown
        : _i.disciplineData.length);
    if (height < 50) height = 50;
    _i.addDiscipline = new selectionPopup(
      290,
      height,
      'mainCon',
      _i.disciplineData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onDisciplineAddClick,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      _i.addDisciplineDropdownID
    );
    // Must grab the parent element(ie. Phase or Project Settings) and then it's children because
    // many div's have the same ID on this page
    elementParent = xGetElementById(attachTo);
    elementChildren = elementParent.getElementsByClassName(
      'settingsBillRateTextButton'
    );
    _i.addDiscipline.show(elementChildren[0]);
    focusFirstChildElementAfterDelay('#add-discipline');
    return false;
  };

  _i.parseTagsForPopup = function (namespaceString, sort, arr, novaluestring) {
    var ret = [];
    var tags = gData.getTagsByNamespace(namespaceString);
    if (!tags) {
      var obj = {};
      obj.type = 'label';
      obj.id = 0;
      obj.value = novaluestring ? novaluestring : I18n.t('msg_no_values');
      obj.label = novaluestring ? novaluestring : I18n.t('msg_no_values');
      ret.push(obj);
      return ret;
    }

    if (tags && sort)
      tags.sort(function (a, b) {
        var sa = a.value.toLowerCase();
        var sb = b.value.toLowerCase();
        return sa < sb ? -1 : 1;
      });

    for (var i = 0; i < tags.length; i++) {
      if (!_i.arrayContains(arr, tags[i].value)) {
        var obj = {};
        obj.type = 'item';
        obj.id = tags[i].id;
        obj.value = tags[i].value;
        obj.label = tags[i].value;
        obj.tag = tags[i];
        ret.push(obj);
      }
    }
    return ret;
  };

  _i.findRateByIndexes = function (discipline_id, role_id) {
    if (discipline_id == 0) discipline_id = null;
    if (role_id == 0) role_id = null;
    for (var i = 0; i < _i.billrates.length; i++) {
      if (
        _i.billrates[i].discipline_id == discipline_id &&
        _i.billrates[i].role_id == role_id
      ) {
        return _i.billrates[i];
      }
    }
    return null;
  };

  _i.addTableRow = function (index) {
    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 settingsBillRateRowLabel';
    label.setAttribute('scope', 'row');
    label.title = _i.rows[index].value;

    label.innerText = _i.rows[index].value;
    _i.billratecontainer.appendChild(label);

    if (_i.billratesinput[index] == null) _i.billratesinput[index] = [];

    var inputText = document.createElement('input');
    inputText.setAttribute('type', 'text');
    inputText.className = 'blockFloat fnt-r-14 settingsBillRateTextInput';
    inputText.setAttribute(
      'aria-label',
      'column ' + _i.cols[0].value + ', row ' + _i.rows[index].value
    );

    var rate = _i.findRateByIndexes(_i.rows[index].id, _i.cols[0].id);
    var option = { precision: 2 };

    if (rate) {
      var inputValue = formatCurrency(rate.rate, option);
      inputText.value = inputValue;
      inputText.dataset || (inputText.dataset = {}); // IE guard
      inputText.dataset.billrateId = rate.id;
      inputText.dataset.billrateAssignableId = rate.assignable_id;
      inputText.dataset.billrateUserId = rate.user_id;
    } else if (_i.defaultrate)
      inputText.value = formatCurrency(_i.defaultrate, option);
    else inputText.value = '';

    if (rate && _i.projectMode) {
      var defaultrate = gData.getDefaultBillRateForDisciplineRole(
        rate.discipline_id,
        rate.role_id
      );
      if (defaultrate && defaultrate.rate != rate.rate) {
        inputText.style.color = '#CC2E3B';
      }
    } else if (_i.projectMode) {
      var defaultrate = gData.getDefaultBillRateForDisciplineRole(null, null);
      if (defaultrate && defaultrate.rate != _i.defaultrate) {
        inputText.style.color = '#CC2E3B';
      }
    }

    _i.billratecontainer.appendChild(inputText);
    _i.billratesinput[index][0] = inputText;

    for (var i = 1; i < _i.cols.length; i++) {
      inputText = document.createElement('input');
      inputText.setAttribute('type', 'text');
      inputText.className = 'blockFloat fnt-r-14 settingsBillRateTextInput';
      var rate = _i.findRateByIndexes(_i.rows[index].id, _i.cols[i].id);
      inputText.setAttribute(
        'aria-label',
        'column ' + _i.cols[i].value + ', row ' + _i.rows[index].value
      );

      if (rate) {
        var inputValue = formatCurrency(rate.rate, option);
        inputText.value = inputValue;
        inputText.dataset || (inputText.dataset = {}); // IE guard
        inputText.dataset.billrateId = rate.id;
        inputText.dataset.billrateAssignableId = rate.assignable_id;
        inputText.dataset.billrateUserId = rate.user_id;
      } else if (_i.defaultrate) {
        inputText.value = formatCurrency(_i.defaultrate, option);
      } else {
        inputText.value = '';
      }

      if (rate && _i.projectId) {
        var defaultrate = gData.getDefaultBillRateForDisciplineRole(
          rate.discipline_id,
          rate.role_id
        );
        if (defaultrate && defaultrate.rate != rate.rate) {
          inputText.style.color = '#CC2E3B';
        }
      } else if (_i.projectId) {
        var defaultrate = gData.getDefaultBillRateForDisciplineRole(null, null);
        if (defaultrate && defaultrate.rate != _i.defaultrate) {
          inputText.style.color = '#CC2E3B';
        }
      }
      _i.billratecontainer.appendChild(inputText);
      _i.billratesinput[index][i] = inputText;
    }

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.billratecontainer.appendChild(spacer);
  };

  _i.buildHeader = function () {
    var container = document.createElement('DIV');
    container.className = 'blockFloatNot';
    container.style.display = 'flex';
    _i.billratecontainer.appendChild(container);

    var label = document.createElement('DIV');
    label.className = 'blockFloat fnt-r-14 settingsBillRateColumnStart';
    label.setAttribute('scope', 'col');
    label.innerText = _i.cols[0].value;
    label.title = _i.cols[0].value;
    container.appendChild(label);

    for (var i = 1; i < _i.cols.length; i++) {
      label = document.createElement('DIV');
      label.className = 'blockFloat fnt-r-14 settingsBillRateColumn';
      label.setAttribute('scope', 'col');
      label.innerText = _i.cols[i].value;
      label.title = _i.cols[i].value;
      container.appendChild(label);
    }
    if (_i.parseTagsForPopup('resource type', false, _i.cols).length > 0) {
      _i.addCol = new textBlock(
        I18n.t('lbl_plus_add_role'),
        'blockFloat fnt-r-14 settingsBillRateTextButton2',
        _i.timeTrackerFooterContainer
      );
      _i.addCol.setCallback(_i.addColClick);
      _i.addCol.container.id = 'settingBillRateAddCol';
      _i.addCol.container.title = I18n.t('lbl_plus_add_role');
      _i.addCol.container.setAttribute('role', 'button');
      _i.addCol.container.tabIndex = '0';
      container.appendChild(_i.addCol.container);
    }

    if (_i.cols.length > 4 && _i.colsprev != _i.cols.length) {
      var increase =
        90 * (_i.cols.length - (_i.colsprev > 4 ? _i.colsprev : 4));
      _i.colsprev = _i.cols.length;
      var parent = xGetElementById('pageMainInfoContentContainer');
      var width = xWidth('pageMainInfoContentContainer') + increase;
      if (parent) parent.style.width = width + 'px';

      parent = xGetElementById('pageContentContainer');
      width = xWidth('pageContentContainer') + increase;
      if (parent) parent.style.width = width + 'px';

      parent = xGetElementById('pagePageFloat');
      width = xWidth('pagePageFloat') + increase;
      if (parent) parent.style.width = width + 'px';
    }
  };

  _i.spacerLineUser = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.usersCollection.appendChild(spacer);
  };

  _i.displayUsers = function () {
    if (_i.billrateusers.length == 0) return;
    _i.usersCollection = document.createElement('DIV');
    _i.usersCollection.className = 'blockFloatNot settingsUserContainer';
    _i.container.appendChild(_i.usersCollection);

    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 settingsUserContainer';
    label.innerHTML = I18n.t('msg_bill_rates_current_html');
    _i.usersCollection.appendChild(label);

    _i.userControllers = [];

    _i.billrateusers.sort(function (a, b) {
      var sa = a.user.displayName.toLowerCase();
      var sb = b.user.displayName.toLowerCase();
      return sa < sb ? -1 : 1;
    });
    _i.spacerLineUser();
    for (var i = 0; i < _i.billrateusers.length; i++) {
      _i.userControllers.push(
        BillRateUser(_i.usersCollection, _i.billrateusers[i], _i)
      );
      _i.spacerLineUser();
    }
  };

  _i.buildBody = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer5';
    _i.billratecontainer.appendChild(spacer);

    for (var i = 0; i < _i.rows.length; i++) {
      _i.addTableRow(i);
    }
  };

  _i.checkArrayForId = function (arr, id) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].id == id) return i;
    }
    return false;
  };

  _i.buildData = function () {
    var rates;

    if (_i.projectMode && _i.projectId) {
      rates = gData.getBillRates(_i.projectId);

      // if we didn't get any rates for the current project, first attempt to copy from
      // parent bill rates. Failing that, fallback on account defaults.

      if (rates == null) {
        if (parentSettingsBillRate) {
          rates = parentSettingsBillRate.getValues();
        } else {
          rates = gData.getAccountBillRates();
        }

        // reset id and set assignable_id in the copied bill rates
        rates.forEach(function (r) {
          r.id = null;
          r.assignable_id = _i.projectId;
        });
      }
    } else {
      rates = gData.getAccountBillRates();
    }

    _i.updateData(rates);
  };

  _i.updateData = function (rates) {
    _i.defaultrate = 0.0;

    _i.billrates = [];
    for (var i = 0; i < rates.length; i++) {
      rate = rates[i];
      if (rate.user_id == null) {
        _i.billrates.push(rate);
        if (rate.discipline_id == null && rate.role_id == null) {
          _i.defaultrate = rate.rate;
        }
      }
    }

    _i.billrateusers = [];
    if (_i.projectMode) {
      // check for billrates for users for this project
      for (var i = 0; i < rates.length; i++) {
        rate = rates[i];
        if (rate.user_id != null && rate.assignable_id == _i.projectId) {
          var obj = {};
          let user = gData.getUser(rate.user_id);
          if (user) {
            obj.user = user;
            obj.rate = rate;
            _i.billrateusers.push(obj);
          }
        }
      }
    }
  };

  _i.buildMatrix = function () {
    var def = { id: 0, value: I18n.t('lbl_default') },
      roletags = gData.getTagsByNamespace('resource type'),
      disctags = gData.getTagsByNamespace('discipline');

    _i.rows = [];
    _i.rows.push(def);

    _i.cols = [];
    _i.cols.push(def);

    _i.colsprev = _i.cols.length;

    for (var i = 0; i < _i.billrates.length; i++) {
      if (
        _i.billrates[i].role_id &&
        _i.checkArrayForId(_i.cols, _i.billrates[i].role_id) == false
      ) {
        var def = {};
        def.id = _i.billrates[i].role_id;
        for (var k = 0; k < roletags.length; k++) {
          if (roletags[k].id == def.id) {
            // if there is a discipline_id, we need to verify it exists (not deleted)
            var addrow = true;
            if (_i.billrates[i].discipline_id) {
              addrow = false;
              for (var j = 0; j < disctags.length; j++) {
                if (disctags[j].id == _i.billrates[i].discipline_id) {
                  addrow = true;
                  break;
                }
              }
            }
            if (addrow) {
              def.value = roletags[k].value;
              _i.cols.push(def);
            }
            break;
          }
        }
      }
    }

    _i.cols.sort(function (a, b) {
      var sa = a.value.toLowerCase();
      var sb = b.value.toLowerCase();
      if (sa == I18n.t('lbl_default').toLowerCase()) return -1;
      if (sb == I18n.t('lbl_default').toLowerCase()) return 1;
      return sa < sb ? -1 : 1;
    });

    for (var i = 0; i < _i.billrates.length; i++) {
      if (
        _i.billrates[i].discipline_id &&
        _i.checkArrayForId(_i.rows, _i.billrates[i].discipline_id) == false
      ) {
        var def = {};
        def.id = _i.billrates[i].discipline_id;
        for (var k = 0; k < disctags.length; k++) {
          if (disctags[k].id == def.id) {
            var addcol = true;
            if (_i.billrates[i].role_id) {
              addcol = false;
              for (var j = 0; j < roletags.length; j++) {
                if (roletags[j].id == _i.billrates[i].role_id) {
                  addcol = true;
                  break;
                }
              }
            }
            if (addcol) {
              def.value = disctags[k].value;
              _i.rows.push(def);
            }
            break;
          }
        }
      }
    }

    _i.rows.sort(function (a, b) {
      var sa = a.value.toLowerCase();
      var sb = b.value.toLowerCase();
      if (sa == I18n.t('lbl_default').toLowerCase()) return -1;
      if (sb == I18n.t('lbl_default').toLowerCase()) return 1;
      return sa < sb ? -1 : 1;
    });
  };

  _i.buildFooter = function () {
    if (_i.parseTagsForPopup('discipline', false, _i.rows).length > 0) {
      _i.addRow = new textBlock(
        I18n.t('lbl_plus_add_discipline'),
        'blockFloatNot fnt-r-14 settingsBillRateTextButton'
      );
      _i.addRow.setCallback(_i.addRowClick);
      _i.addRow.container.id = 'settingBillRateAddRow';
      _i.addRow.container.title = I18n.t('lbl_plus_add_discipline');
      _i.addRow.container.setAttribute('role', 'button');
      _i.addRow.container.tabIndex = '0';
      _i.billratecontainer.appendChild(_i.addRow.container);
    }

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.billratecontainer.appendChild(spacer);

    var buttondiv = document.createElement('DIV');
    buttondiv.className =
      'blockFloatNot settingsBillRateButtonContentContainer';
    buttondiv.id = 'settingsBillRateButtonContentContainer';
    _i.billratecontainer.appendChild(buttondiv);

    if (_i.parentSave) return;

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    buttondiv.appendChild(_i.nestedDiv);

    _i.cancelButton = new secondaryButton('settingsSecondaryButtonRight');
    _i.cancelButton.setButtonText(I18n.t('lbl_revert'));
    _i.cancelButton.setCallback(_i.cancelButtonClicked);
    _i.nestedDiv.appendChild(_i.cancelButton.container);
    _i.cancelButton.container.tabIndex = 0;

    _i.saveButton = new primaryButton('settingsSecondaryButtonRight');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveButtonClicked);
    _i.nestedDiv.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;
  };

  _i.render = function () {
    _i.width1 = xWidth('pageMainInfoContentContainer');
    _i.width2 = xWidth('pageContentContainer');
    _i.width3 = xWidth('pagePageFloat');

    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsBillRatePageContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer4';
    _i.container.appendChild(spacer);

    _i.buildData();
    _i.buildMatrix();

    _i.projectMode && _i.displayUsers();

    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 settingsUserContainer';
    label.innerHTML = _i.projectId
      ? I18n.t('msg_bill_rates_future_html')
      : I18n.t('msg_bill_rate_for_role_discipline');
    _i.container.appendChild(label);

    _i.billratecontainer = document.createElement('DIV');
    _i.billratecontainer.className =
      'blockFloatNot settingsBillRateContentContainer';
    _i.billratecontainer.id = 'settingsBillRateContentContainer';
    _i.container.appendChild(_i.billratecontainer);

    if (_i.pspinner) {
      _i.pspinner.remove();
      _i.pspinner = null;
    }

    _i.buildHeader();
    _i.buildBody();
    _i.buildFooter();
  };

  _i.getRoleDiscipline = function () {
    function sort(tag) {
      tag.sort(function (a, b) {
        var sa = a.value.toLowerCase();
        var sb = b.value.toLowerCase();
        if (sa == sb) return 0;
        return sa > sb ? 1 : -1;
      });
    }
    function getDiscipline() {
      var discipline = gData.tagsByNameSpace['discipline'];
      if (discipline) {
        sort(discipline);
        _i.render();
      } else {
        gService.getTagsWithNamespace('discipline', _i.render);
      }
    }
    var role = gData.tagsByNameSpace['resource type'];
    if (role) {
      sort(role);
      getDiscipline();
    } else {
      gService.getTagsWithNamespace('resource type', getDiscipline);
    }
  };

  _i.constructor = function () {
    if (_i.projectMode) {
      _i.render();
      var observer = new MutationObserver(callback);
      var targetNode = document.getElementById(
        'settingsMyPreferencesContentContainer'
      );
      function callback(mutations) {
        _i.billratesChanged = true;
      }
      var options = {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true,
      };
      observer.observe(targetNode, options);
    } else {
      _i.pspinner = new pageSpinner();
      gService.getAccountBillRates(_i.getRoleDiscipline);
    }
  };

  _i.constructor();
}
