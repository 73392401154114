/*
    CLASS: button

    CLASS: buttonWithStates
    CLASS: autoWidthButtonWithStates


    CLASS: toggleButton() : no params
      .setCallback( callbackFunction ) : returns an object { index : int, label : string } when an item is selected
      .setMenuItems( arrayOfStrings ) : can be called at any time to replace existing menu items if any with a new list
      .setSelection( integerIndex ) : set the selected item by index
      .getSelection() : returns the current selection as an object { index : int, label : string }


    CLASS: genericButton( clsName ) : takes a CSS class name that governs the appearance of the button
      .setCallback( callbackFunction ) : returns an object { label : string } when button is clicked
      .getSelection() : returns : returns an object { label : string }



    CLASS: textBlock( buttonText, clsName, attachTo ) : label or button text, CSS class, string or DOM element
      .setCallback( callbackFunction ) : returns an object { label : string } when text is clicked
      --------
      Can be used for either a label or a button.  If a callback is set, the cursor is a pointer, if not,
      the cursor is a default arrow.



    CLASS: checkBox( textValue ) : label to assign to checkbox
      .setText( textValue ) : assign or change the label
      .setSelection( boolean) : set checked / unchecked state
      .getSelection() : returns object { selected : boolean, label : string }
      .setCallback( callback ) : returns getSelection to the callback on click
      .setDisabled( boolean ) : true to disable checkbox, false to enable
      .setFloat( clsName ) : assign a different float class ( default is blockFloat )

*/

function button(parentObj, attachTo, text, ind, callback) {
  var _i = this;

  // params
  _i.parentObj = parentObj;
  _i.attachTo = xGetElementById(attachTo);
  _i.ind = ind;

  _i.contClick = function (e) {
    cancelEvent(e);
    if (callback) {
      callback(_i.ind);
    } else {
      _i.parentObj.reportSelectedIndex(_i.ind);
    }
  };

  _i.constructor = function () {
    _i.cont = document.createElement('DIV');
    _i.cont.className = 'blockFloat fnt-sb-15';
    _i.cont.style.width = 'auto';
    _i.cont.style.height = 'auto';
    _i.cont.style.textAlign = 'center';
    _i.cont.style.backgroundColor = '#ffffff';
    _i.cont.style.padding = '10px 20px';
    _i.cont.style.marginRight = '20px';
    _i.cont.style.cursor = 'pointer';
    _i.cont.innerText = text;

    _i.attachTo.appendChild(_i.cont);

    xAddEventListener(_i.cont, platform.mouseDown, _i.contClick, false);
  };

  _i.constructor();
}

function buttonWithStates(
  width,
  textColor,
  buttonText,
  restSprite,
  hoverSprite,
  depressSprite,
  className,
  callback
) {
  var _i = this;

  // params
  _i.width = width;
  _i.textColor = textColor;
  _i.buttonText = buttonText;
  _i.restSprite = restSprite;
  _i.hoverSprite = hoverSprite;
  _i.depressSprite = depressSprite;
  _i.className = className;
  _i.callback = callback;

  // locals
  _i.container = null;
  _i.label = null;

  _i.containerMouseover = function (evt) {
    cancelEvent(evt);
    _i.hover.show();
  };

  _i.containerMouseout = function (evt) {
    cancelEvent(evt);
    _i.hover.hide();
    _i.depress.hide();
  };

  _i.containerMousedown = function (evt) {
    cancelEvent(evt);
    _i.hover.hide();
    _i.depress.show();
  };

  _i.containerMouseup = function (evt) {
    cancelEvent(evt);

    _i.depress.hide();
    _i.hover.show();

    if (_i.callback) {
      _i.callback();
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');

    if (_i.className) {
      _i.container.className = _i.className;
    }

    _i.container.style.height = restSprite.height + 'px';

    if (typeof _i.width == 'number') {
      _i.container.style.width = _i.width + 'px';
    } else {
      _i.container.style.width = _i.width;
    }

    _i.container.style.cursor = 'pointer';

    _i.rest = new backgroundBar(_i.restSprite);
    _i.hover = new backgroundBar(_i.hoverSprite);
    _i.depress = new backgroundBar(_i.depressSprite);

    _i.hover.hide();
    _i.depress.hide();

    _i.container.appendChild(_i.rest.container);
    _i.container.appendChild(_i.hover.container);
    _i.container.appendChild(_i.depress.container);

    _i.label = document.createElement('DIV');
    _i.label.style.position = 'absolute';
    _i.label.style.width = '100%';
    _i.label.style.height = '100%';
    _i.label.style.fontFamily = 'GibsonRegular';
    _i.label.style.lineHeight = _i.restSprite.height + 'px';
    _i.label.style.fontSize = '14px';
    _i.label.style.textAlign = 'center';
    _i.label.innerText = _i.buttonText;
    _i.label.style.color = _i.textColor;
    _i.label.style.textShadow = '0px -1px 0px rgba( 0, 0, 0, .5 )';

    _i.container.appendChild(_i.label);

    xAddEventListener(_i.container, 'mouseover', _i.containerMouseover, true);
    xAddEventListener(_i.container, 'mouseout', _i.containerMouseout, true);
    xAddEventListener(
      _i.container,
      platform.mouseDown,
      _i.containerMousedown,
      true
    );
    xAddEventListener(
      _i.container,
      platform.mouseUp,
      _i.containerMouseup,
      true
    );
  };

  _i.constructor();
}

function autoWidthButtonWithStates(
  buttonText,
  buttonTextColor,
  restSprite,
  hoverSprite,
  depressSprite,
  callback
) {
  var _i = this;
  // params
  _i.buttonText = buttonText;
  _i.buttonTextColor = buttonTextColor;
  _i.restSprite = restSprite;
  _i.hoverSprite = hoverSprite;
  _i.depressSprite = depressSprite;
  _i.callback = callback;

  // locals
  _i.container = null;
  _i.textContainer = null;

  _i.containerMouseover = function (evt) {
    cancelEvent(window.event || evt);
    _i.container.backgroundHover.container.style.display = 'block';
    _i.container.backgroundHover.container.setAttribute('aria-hidden', false);
  };

  _i.containerMouseout = function (evt) {
    cancelEvent(window.event || evt);
    _i.container.backgroundHover.container.style.display = 'none';
    _i.container.backgroundHover.container.setAttribute('aria-hidden', true);
  };

  _i.containerMousedown = function (evt) {
    cancelEvent(window.event || evt);
    _i.container.backgroundDepress.container.style.display = 'block';
    _i.container.backgroundDepress.container.setAttribute('aria-hidden', false);
    xAddEventListener(document, platform.mouseUp, _i.containerMouseup, true);
  };

  _i.containerMouseup = function (evt) {
    cancelEvent(window.event || evt);

    _i.container.backgroundDepress.container.style.display = 'none';
    _i.container.backgroundDepress.container.setAttribute('aria-hidden', true);
    xRemoveEventListener(document, platform.mouseUp, _i.containerMouseup, true);

    var xevt = new xEvent(evt);

    if (xHasPoint(_i.container, xevt.pageX, xevt.pageY) && _i.callback) {
      _i.callback();
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.style.display = 'block';
    _i.container.setAttribute('aria-hidden', false);
    _i.container.style.height = restSprite.height + 'px';
    _i.container.style.cursor = 'pointer';

    _i.container.backgroundRest = new backgroundBar(_i.restSprite);
    _i.container.appendChild(_i.container.backgroundRest.container);

    _i.container.backgroundHover = new backgroundBar(_i.hoverSprite);
    _i.container.appendChild(_i.container.backgroundHover.container);
    _i.container.backgroundHover.container.style.display = 'none';
    _i.container.backgroundHover.container.setAttribute('aria-hidden', true);

    _i.container.backgroundDepress = new backgroundBar(_i.depressSprite);
    _i.container.appendChild(_i.container.backgroundDepress.container);
    _i.container.backgroundDepress.container.style.display = 'none';
    _i.container.backgroundDepress.container.setAttribute('aria-hidden', true);

    _i.textContainer = document.createElement('DIV');
    _i.textContainer.style.position = 'relative';
    _i.textContainer.style.display = 'block';
    _i.textContainer.setAttribute('aria-hidden', false);
    _i.textContainer.style.left = '0px';
    _i.textContainer.style.top = '0px';
    _i.textContainer.style.width = '100%';
    _i.textContainer.style.height = '100%';
    _i.textContainer.style.whiteSpace = 'nowrap';
    _i.textContainer.style.fontFamily = 'GibsonRegular';
    _i.textContainer.style.fontSize = '14px';
    _i.textContainer.style.color = _i.buttonTextColor;
    _i.textContainer.style.lineHeight = _i.restSprite.height + 'px';
    _i.textContainer.style.marginRight = '10px';
    _i.textContainer.style.marginLeft = '10px';
    _i.textContainer.style.textShadow = '0px -1px 0px rgba( 0, 0, 0, .5 )';
    _i.textContainer.innerText = _i.buttonText;

    _i.container.appendChild(_i.textContainer);

    xAddEventListener(_i.container, 'mouseover', _i.containerMouseover, true);
    xAddEventListener(_i.container, 'mouseout', _i.containerMouseout, true);
    xAddEventListener(
      _i.container,
      platform.mouseDown,
      _i.containerMousedown,
      true
    );
  };

  _i.constructor();
}

function toggleButtonItem(ind, itemLabel) {
  var _i = this;

  // params
  _i.ind = ind;
  _i.itemLabel = itemLabel;

  // locals
  _i.container = null;
  _i.callback = null;
  _i.attachTo = null;

  _i.destroy = function () {
    _i.attachTo.removeChild(_i.container);
  };

  _i.isSelected = function () {
    return hasClass(_i.container, 'selected');
  };

  _i.select = function () {
    addClass(_i.container, 'selected');
  };

  _i.unselect = function () {
    removeClass(_i.container, 'selected');
  };

  _i.containerClick = function (evt) {
    cancelEvent(evt);

    // if already selected do nothing
    if (hasClass(_i.container, 'selected')) {
      return;
    }

    if (_i.callback) {
      _i.callback(_i.ind);
    }
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);
    _i.attachTo.appendChild(_i.container);
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'toggleButtonItem blockFloat unselectable';
    _i.container.innerText = _i.itemLabel;
    xAddEventListener(_i.container, platform.click, _i.containerClick, false);
  };

  _i.constructor();
}

function toggleButton() {
  var _i = this;

  // locals
  _i.container = null;
  _i.items = [];
  _i.attachTo = null;
  _i.callback = null;
  _i.itemLabels = null;

  _i.setSelection = function (ind) {
    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].unselect();
    }

    _i.items[ind].select();
  };

  _i.getSelection = function () {
    for (var z = 0; z < _i.items.length; z++) {
      if (_i.items[z].isSelected()) {
        var retVal = { index: z, label: _i.itemLabels[z] };
        return retVal;
      }
    }

    return null;
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.toggleButtonClick = function (ind) {
    _i.setSelection(ind);

    if (_i.callback) {
      _i.callback({ index: ind, label: _i.itemLabels[ind] });
    }
  };

  _i.destroy = function () {
    _i.destroyItems();
    _i.attachTo.removeChild(_i.container);
  };

  _i.destroyItems = function () {
    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].destroy();
      _i.items[z] = null;
    }

    _i.items = [];
  };

  _i.setMenuItems = function (itemLabels) {
    _i.itemLabels = itemLabels;

    // remove existing items if any
    _i.destroyItems();

    for (var z = 0; z < _i.itemLabels.length; z++) {
      _i.items[z] = new toggleButtonItem(z, _i.itemLabels[z]);
      _i.items[z].setCallback(_i.toggleButtonClick);
      _i.items[z].setAttachment(_i.container);
    }
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);
    _i.attachTo.appendChild(_i.container);
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'well blockFloat';
  };

  _i.constructor();
}

function spritesButton(
  clsName,
  textClsName,
  buttonsprite,
  hoversprite,
  downsprite,
  ariaLabel
) {
  var _i = this;

  _i.buttonsprite = buttonsprite;
  _i.spriteHover = hoversprite;
  _i.spriteDown = downsprite;
  _i.guid = Awe.getGuid();

  // params
  _i.clsName = clsName;
  _i.textClsName = textClsName;
  if (ariaLabel) {
    _i.ariaLabel = ariaLabel;
  }

  // locals
  _i.container = null;
  _i.attachTo = null;
  _i.callback = null;
  _i.btnText = null;

  // ids
  _i.btnLabelID = 'button' + _i.guid;

  // did the last mouse down event occur on this?
  _i.gotMouseDown = false;
  _i.isMouseOverContainer = false;

  _i.setSelection = function () {
    // no op
  };

  _i.getSelection = function () {
    return { label: _i.btnText };
  };

  _i.destroy = function () {
    _i.attachTo.removeChild(_i.container);
  };

  _i.setButtonText = function (txt) {
    _i.btnText = txt;
    if (_i.btnText) {
      _i.buttonLabel.innerText = _i.btnText;
      _i.buttonLabel.id = _i.btnLabelID;
      _i.container.setAttribute('aria-labelledby', _i.btnLabelID);
    } else {
      _i.buttonLabel.innerHTML = '';
    }
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);
    _i.attachTo.appendChild(_i.container);
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.mouseOver = function (evt) {
    if (_i.spriteHover) {
      cancelEvent(window.event || evt);
      _i.container.backgroundHover.container.style.display = 'block';
      _i.container.backgroundHover.container.setAttribute('aria-hidden', false);
    }
    _i.gotMouseDown = false;
    _i.isMouseOverContainer = true;
  };

  _i.handleKeypress = function (e) {
    if (typeof e == 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key == 'Enter' || e.key == ' ') {
      _i.containerClick(e);
    }
    return true;
  };

  _i.mouseOut = function (evt) {
    if (_i.spriteHover) {
      cancelEvent(window.event || evt);
      _i.container.backgroundHover.container.style.display = 'none';
      _i.container.backgroundHover.container.setAttribute('aria-hidden', true);
    }
    _i.gotMouseDown = false;
    _i.isMouseOverContainer = false;
  };

  _i.mouseDown = function (evt) {
    _i.gotMouseDown = true;
    if (_i.spriteDown) {
      cancelEvent(window.event || evt);
      _i.container.backgroundDepress.container.style.display = 'block';
      _i.container.backgroundDepress.container.setAttribute(
        'aria-hidden',
        false
      );
    }
    xAddEventListener(document, platform.mouseUp, _i.mouseUp, false);
  };

  _i.mouseUp = function (evt) {
    xRemoveEventListener(document, platform.mouseUp, _i.mouseUp, false);
    if (_i.spriteDown) {
      _i.container.backgroundDepress.container.style.display = 'none';
      _i.container.backgroundDepress.container.setAttribute(
        'aria-hidden',
        true
      );
    }
    var xevt = new xEvent(evt);

    // use setTimeout to allow other mouse events & their callbacks to
    // finish before checking the state of _i.isMouseOverContainer #IEfix
    setTimeout(function () {
      if (_i.isMouseOverContainer || _i.gotMouseDown) _i.containerClick(evt);
    }, 1);
  };

  _i.containerClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.callback) {
      _i.callback(_i);
    }
  };

  _i.setClassName = function (clsName) {
    _i.clsName = clsName;
    if (_i.container) {
      _i.container.className = _i.clsName + ' ' + 'blockFloat unselectable';
    }
  };

  _i.createBackground = function (sprite) {
    _i.bbar = new backgroundBar(sprite);
    _i.container.appendChild(_i.bbar.container);
    if (_i.spriteHover) {
      _i.container.backgroundHover = new backgroundBar(_i.spriteHover);
      _i.container.appendChild(_i.container.backgroundHover.container);
      _i.container.backgroundHover.container.style.display = 'none';
      _i.container.backgroundHover.container.setAttribute('aria-hidden', true);
    }
    if (_i.spriteDown) {
      _i.container.backgroundDepress = new backgroundBar(_i.spriteDown);
      _i.container.appendChild(_i.container.backgroundDepress.container);
      _i.container.backgroundDepress.container.style.display = 'none';
      _i.container.backgroundDepress.container.setAttribute(
        'aria-hidden',
        true
      );
    }
  };

  _i.createTextLabel = function () {
    _i.buttonLabel = document.createElement('DIV');
    _i.buttonLabel.className = _i.textClsName + ' blockFloat unselectable';
    _i.buttonLabel.style.whiteSpace = 'nowrap';
    _i.container.appendChild(_i.buttonLabel);
    _i.buttonLabel.innerText = _i.btnText;
  };

  _i.setSprite = function (sprite) {
    _i.container.innerHTML = '';
    _i.container.className = _i.clsName + ' ' + 'blockFloat unselectable';
    _i.createBackground(sprite);
    _i.createTextLabel();
  };

  _i.setDisabled = function () {
    _i.container.style.pointerEvents = 'none';
    _i.container.style.color = '#ffffff';
    _i.setSprite(compSpritesButtonsIcons.midButtonRest);
  };

  _i.setEnabled = function (sprite) {
    _i.container.style.pointerEvents = 'auto';
    _i.container.style.color = '#444444';
    if (sprite) {
      _i.setSprite(sprite);
    } else {
      _i.setSprite(compSpritesButtonsIcons.primaryButtonRest);
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    if (_i.ariaLabel != undefined) {
      _i.container.setAttribute('aria-label', _i.ariaLabel);
    }

    _i.container.className = _i.clsName + ' ' + 'blockFloat unselectable';
    _i.container.setAttribute('role', 'button');
    _i.container.tabIndex = 0;
    // the container will be the object that receives events
    // but we will need parent access during the events
    _i.container.parent = _i;

    _i.setSprite(_i.buttonsprite);
    xAddEventListener(_i.container, 'mouseover', _i.mouseOver, false);
    xAddEventListener(_i.container, 'mouseout', _i.mouseOut, false);
    xAddEventListener(_i.container, platform.mouseDown, _i.mouseDown, false);
    xAddEventListener(_i.container, 'keypress', _i.handleKeypress, false);
  };

  _i.constructor();
}

// Lodestar primary button (blue)
function primaryButton(className, ariaLabel) {
  return new spritesButton(
    'blockFloat fnt-r-14 spriteButtonBase' + (className ? ' ' + className : ''),
    'spritesButtonTextContainerWide',
    compSpritesButtonsIcons.primaryButtonRest,
    compSpritesButtonsIcons.primaryButtonHover,
    compSpritesButtonsIcons.primaryButtonDown,
    ariaLabel
  );
}

function primaryButtonNarrow(className, ariaLabel) {
  return new spritesButton(
    'blockFloat fnt-r-14 spriteButtonBase' + (className ? ' ' + className : ''),
    'spritesButtonTextContainer',
    compSpritesButtonsIcons.primaryButtonRest,
    compSpritesButtonsIcons.primaryButtonHover,
    compSpritesButtonsIcons.primaryButtonDown,
    ariaLabel
  );
}

function expandCollapseButton(className, ariaLabel) {
  return new toolbarButton(className, ariaLabel);
}

function toolbarButton(className, ariaLabel) {
  let _i = this;

  _i.guid = Awe.getGuid();
  _i.className = className;
  _i.ariaLabel = ariaLabel;
  _i.container = null;
  _i.btnText = null;
  _i.btnLabelID = 'button' + _i.guid;
  _i.setButtonText = function (txt) {
    _i.btnText = txt;
    if (_i.btnText) {
      if (!_i.text) {
        _i.text = document.createElement('DIV');
        _i.text.style.margin = '0';
        _i.text.style.padding = '0';
        _i.text.style.fontSize = '12px';
        _i.text.style.color = '#979797';
        _i.container.appendChild(_i.text);
      }
      _i.text.innerText = _i.btnText;
      _i.container.id = _i.btnLabelID;
      _i.container.setAttribute('aria-labelledby', _i.btnLabelID);
    } else {
      _i.container.innerHTML = '';
    }
  };
  _i.container = document.createElement('button');
  _i.container.className =
    'fnt-r-14' + (_i.className ? ' ' + _i.className : '');
  _i.container.setAttribute('aria-label', _i.ariaLabel);
  _i.container.addEventListener('mouseenter', function () {
    _i.container.style.cursor = 'pointer';
  });
  _i.container.addEventListener('mouseleave', function () {
    _i.container.style.cursor = 'default';
  });
  //initialize the button text
  _i.setButtonText(I18n.t('lbl_collapse_all'));
  _i.btnLabelID = _i.ariaLabel.replace(/\s+/g, '-').toLowerCase();
}

// Lodestar secondary button (Gray)
function secondaryButton(className, ariaLabel, icon, isTransparent) {
  var secondaryButton = new spritesButton(
    'blockFloat fnt-r-14 spriteButtonBase' + (className ? ' ' + className : ''),
    'spritesButtonCommonTextContainer',
    isTransparent
      ? compSpritesButtonsIcons.secondaryButtonRestTransparent
      : compSpritesButtonsIcons.secondaryButtonRest,
    isTransparent
      ? compSpritesButtonsIcons.secondaryButtonHoverTransparent
      : compSpritesButtonsIcons.secondaryButtonHover,
    isTransparent
      ? compSpritesButtonsIcons.secondaryButtonDownTransparent
      : compSpritesButtonsIcons.secondaryButtonDown,
    ariaLabel
  );

  secondaryButton.container.style.color = '#444444';

  if (icon && icon.className && icon.svg) {
    var iconEl = document.createElement('div');
    iconEl.className = icon.className;
    iconEl.innerHTML = icon.svg;
    secondaryButton.container.appendChild(iconEl);
  }

  return secondaryButton;
}

// Lodestar new feature button (Green)
function newFeatureButton(className, ariaLabel) {
  var newFeatureButton = new spritesButton(
    'blockFloat fnt-r-14 spriteButtonBase' + (className ? ' ' + className : ''),
    'spritesButtonCommonTextContainer',
    compSpritesButtonsIcons.newFeatureButtonRest,
    ariaLabel
  );

  newFeatureButton.container.style.color = '#FFFFFF';
  return newFeatureButton;
}

// Lodestar destructive button (Red)
function destructiveButton(className, ariaLabel) {
  return new spritesButton(
    'blockFloat fnt-r-14 spriteButtonBase' + (className ? ' ' + className : ''),
    'spritesButtonCommonTextContainer',
    compSpritesButtonsIcons.destructiveButtonRest,
    compSpritesButtonsIcons.destructiveButtonHover,
    compSpritesButtonsIcons.destructiveButtonDown,
    ariaLabel
  );
}

function genericButton(clsName) {
  var _i = this;

  // params
  _i.clsName = clsName;

  // locals
  _i.container = null;
  _i.attachTo = null;
  _i.callback = null;
  _i.btnText = null;

  _i.setSelection = function () {
    // no op
  };

  _i.getSelection = function () {
    return { label: _i.btnText };
  };

  _i.destroy = function () {
    _i.attachTo.removeChild(_i.container);
  };

  _i.setButtonText = function (txt) {
    _i.btnText = txt;
    if (_i.btnText) {
      _i.container.innerText = _i.btnText;
    } else {
      _i.container.innerHTML = '';
    }
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);
    _i.attachTo.appendChild(_i.container);
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.containerClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.callback) {
      _i.callback(_i);
    }
  };

  _i.setClassName = function (clsName) {
    _i.clsName = clsName;
    if (_i.container) {
      _i.container.className = _i.clsName + ' ' + 'blockFloat unselectable';
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = _i.clsName + ' ' + 'blockFloat unselectable';
    _i.container.style.whiteSpace = 'nowrap';
    _i.container.innerText = _i.btnText;
    // the container will be the object that receives events
    // but we will need parent access during the events
    _i.container.parent = _i;
    xAddEventListener(_i.container, platform.click, _i.containerClick, false);
  };

  _i.constructor();
}

function textBlock(buttonText, clsName, attachTo) {
  var _i = this;

  // params
  _i.buttonText = buttonText;
  _i.clsName = clsName;
  _i.attachTo = xGetElementById(attachTo);

  // locals
  _i.container = null;
  _i.callback = null;

  _i.destroy = function () {
    _i.attachTo.removeChild(_i.container);
  };

  _i.getSelection = function () {
    return { label: _i.buttonText };
  };

  _i.setSelection = function () {
    // no op
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);

    if (_i.container.parentNode) {
      _i.container.parentNode.removeChild(_i.container);
    }

    _i.attachTo.appendChild(_i.container);
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
    _i.container.style.cursor = 'pointer';
  };

  _i.containerClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.callback) {
      _i.callback(_i.getSelection());
    }
  };

  _i.containerKeyPress = function (evt) {
    if (typeof e === 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key === 'Enter' || e.key === ' ') {
      _i.containerClick();
    }
    return true;
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloat ' + _i.clsName + ' unselectable';
    _i.container.id = _i.clsName;
    _i.container.innerText = _i.buttonText;
    _i.container.style.cursor = 'default';

    if (_i.attachTo) {
      _i.setAttachment(_i.attachTo);
    }

    xAddEventListener(_i.container, platform.click, _i.containerClick, false);
    xAddEventListener(_i.container, 'keypress', _i.containerKeyPress, false);
  };

  _i.constructor();
}

function checkbox(textValue, textclass, boxclass) {
  var _i = this;

  // params
  _i.textValue = textValue;
  _i.textclass = textclass;
  _i.boxclass = boxclass;

  // locals
  _i.container = null;
  _i.box = null;
  _i.text = null;
  _i.selected = null;
  _i.disabled = null;
  _i.callback = null;
  _i.attachTo = null;

  _i.destroy = function () {
    _i.attachTo.removeChild(_i.container);
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.setDisabled = function (newState) {
    _i.disabled = newState;

    removeClass(_i.container, 'disabled');

    if (_i.disabled) {
      addClass(_i.container, 'disabled');
    }
  };

  _i.getSelection = function () {
    return { selected: _i.selected, label: _i.textValue };
  };

  _i.setSelection = function (newState) {
    _i.selected = newState;

    if (_i.selected) {
      getSprite(compSpritesButtonsIcons.selectedCheckbox, _i.box);
    } else {
      getSprite(compSpritesButtonsIcons.unselectedCheckbox, _i.box);
    }
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);
    _i.attachTo.appendChild(_i.container);
  };

  _i.setText = function (textValue) {
    _i.textValue = textValue;
    _i.text.innerText = _i.textValue;
  };

  _i.setFloat = function (clsName) {
    addClass(_i.container, clsName);
    addClass(_i.box, clsName);
    addClass(_i.text, clsName);
  };

  _i.containerClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.disabled) {
      return;
    }

    _i.setSelection(!_i.selected);

    if (_i.callback) {
      _i.callback(_i.getSelection());
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'checkboxContainer blockFloat';

    _i.box = document.createElement('DIV');

    if (_i.boxclass) {
      _i.box.className = _i.boxclass + ' blockFloat';
    } else {
      _i.box.className = 'checkboxBox blockFloat';
    }
    _i.text = document.createElement('DIV');
    if (_i.textclass) {
      _i.text.className = _i.textclass + ' blockFloat unselectable';
    } else {
      _i.text.className = 'checkboxText blockFloat unselectable';
    }
    _i.container.appendChild(_i.box);
    _i.container.appendChild(_i.text);

    _i.setSelection(false);
    _i.setDisabled(false);

    if (_i.textValue) {
      _i.setText(_i.textValue);
    }

    xAddEventListener(_i.container, platform.click, _i.containerClick, false);
  };

  _i.constructor();
}
