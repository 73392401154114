function CustomFieldFilterHelper() {
  var _i = this,
    loaded = false;
  (customFieldFilters = { users: null, assignables: null }),
    (customFieldFiltersByFilterId = {}),
    (customFieldFiltersById = {}),
    (customFieldFilterValues = { users: null, assignables: null }),
    (customFieldFilterValuesByFilterId = {}),
    (customFieldValuesByCustomFieldableId = { users: null, assignables: null });

  _i.getCustomFieldFilters = function (namespace) {
    customFieldFilters[namespace] ||
      console.warn('custom field filter data has not been loaded');
    return customFieldFilters[namespace] || [];
  };

  _i.getCustomFieldFilter = function (filterId) {
    return customFieldFiltersByFilterId[filterId];
  };

  _i.getCustomFieldFilterListData = function (filter) {
    if (!customFieldFilterValuesByFilterId[filter.customFieldFilterId]) {
      var filterValuesByNamespace = customFieldFilterValues[filter.namespace],
        filterValues = [];
      filterValuesByNamespace.forEach(function (cfv) {
        cfv.custom_field_id == filter.id && filterValues.push(cfv);
      });
      customFieldFilterValuesByFilterId[filter.customFieldFilterId] =
        filterValues;
    }
    return customFieldFilterValuesByFilterId[filter.customFieldFilterId];
  };

  _i.getCustomFilterLabel = function (filter) {
    var label = filter.name + ': <strong>',
      values = _i.getCustomFieldFilterListData(filter),
      selectedCount = 0;

    values.forEach(function (cfv) {
      if (cfv.selected) {
        label += (selectedCount > 0 ? ', ' : '') + cfv.value;
        selectedCount += 1;
      }
    });

    selectedCount == 0 && (label += 'All');
    label += '</strong>';
    return label;
  };

  _i.updateSelection = function (filter, selection) {
    var data = _i.getCustomFieldFilterListData(filter);
    // TODO (perf) O(n^2)
    for (var i = 0; i < data.length; i++) {
      data[i].selected = false;
      for (var j = 0; j < selection.length; j++) {
        if (data[i].value == selection[j].value) {
          data[i].selected = true;
        }
      }
    }
  };

  _i.clearSelections = function (namespace) {
    var values = customFieldFilterValues[namespace];
    for (var i in values) {
      values[i].selected = false;
    }
  };

  // Returns true if any custom field filters have actually been selected.
  _i.hasFilters = function (namespace) {
    var sf = _i.selectedFilters(namespace);
    for (var k in sf) {
      // if we found at least one filter
      if (sf.hasOwnProperty(k)) {
        return true;
      }
    }
    return false;
  };

  // Returns a dictionary of selected custom field filter ids where the values
  // are arrays of the selected values.
  _i.selectedFilters = function (namespace) {
    // TODO Perf — shouldn't do this per every row filter evaluation
    var values = customFieldFilterValues[namespace],
      selectedValues = {},
      v;
    if (values) {
      for (var k in values) {
        if (values.hasOwnProperty(k)) {
          v = values[k];
          if (v.selected) {
            selectedValues[v.custom_field_id] ||
              (selectedValues[v.custom_field_id] = []);
            selectedValues[v.custom_field_id].push(_.pick(v, 'value'));
          }
        }
      }
    }
    return selectedValues;
  };

  _i.restoreSelectedFilters = function (selectedFilters) {
    for (var k in selectedFilters) {
      if (selectedFilters.hasOwnProperty(k) && customFieldFiltersById[k]) {
        _i.updateSelection(customFieldFiltersById[k], selectedFilters[k]);
      }
    }
  };

  // If no custom field filters have been selected, return true. Otherwise, check if the current
  // custom fieldable id matches any selectd values in each filter. Returns true if there is
  // at least one match for every custom field filter that has selected filters (OR within a
  // given custom field filter; AND across multiple custom field filters).
  _i.doesCustomFieldablePassFilters = function (namespace, customFieldableId) {
    var selectedFilters = _i.selectedFilters(namespace),
      selectedFilterValues,
      valuesByNamespace,
      assignedValues,
      passesCurrentCff,
      passedStatusByCustomFilterId = {};

    if (_.keys(selectedFilters).length == 0) {
      // No custom field filters have been selected
      return true;
    } else {
      valuesByNamespace = customFieldValuesByCustomFieldableId[namespace];

      if (!valuesByNamespace) {
        // No values in the given namespace, so pass filter
        return true;
      }

      assignedValues = valuesByNamespace
        ? valuesByNamespace[customFieldableId]
        : null;

      if (!assignedValues) {
        // This custum fieldable object has no custom field assignments
        return false;
      }

      // For each custom field filter
      for (var customFieldId in selectedFilters) {
        if (selectedFilters.hasOwnProperty(customFieldId)) {
          selectedFilterValues = selectedFilters[customFieldId];
          // Passes current custom field filter if at least one assigned value
          // matches a selected value for the same custom field filter
          passesCurrentCff = _.some(assignedValues, function (av) {
            return (
              av.custom_field_id == customFieldId &&
              _.some(selectedFilterValues, function (sv) {
                return av.value == sv.value;
              })
            );
          });
          passedStatusByCustomFilterId[customFieldId] =
            passedStatusByCustomFilterId[customFieldId] || passesCurrentCff;
        }
      }
      return _.every(passedStatusByCustomFilterId, function (ps) {
        return ps;
      });
    }
  };

  _i.loadData = function (callback, onerror) {
    var remainingCallbacks = 4;

    if (loaded) {
      setTimeout(callback, 100);
      return;
    }

    customFieldFilters.users = [];
    customFieldFilters.assignables = [];

    function internalCb() {
      remainingCallbacks -= 1;
      if (remainingCallbacks == 0) {
        loaded = true;
        callback && callback();
      }
    }

    function onReceivedCustomFields(data) {
      if (data && data.length > 0) {
        data.forEach(function (f) {
          var cf, filterId;
          if (f.is_visible_as_filter) {
            cf = _.pick(f, 'id', 'name', 'namespace', 'description');
            cf.isCustomField = true;
            filterId = '__cf_' + f.namespace + '_' + f.id;
            cf.customFieldFilterId = filterId;
            customFieldFiltersByFilterId[filterId] = cf;
            customFieldFiltersById[f.id] = cf;
            customFieldFilters[f.namespace].push(cf);
          }
        });
      }
      internalCb();
    }

    // Parse custom field values data for a given namespace and initialize
    // a. all unique values for each custom field in the namespace (customFieldFilterValues)
    // b. all custom fieldable (users, assignables) associations with custom field values
    //    (valuesByCustomFieldableId)
    function updateCustomFieldValues(namespace, data) {
      var values = [],
        valuesByCustomFieldableId = {},
        uniqueValueKey,
        valueAlreadyAdded = {},
        valuesByFieldableType;

      if (data) {
        data.forEach(function (cfv) {
          var v,
            customFieldableId = cfv.custom_fieldable_id;

          if (cfv.value) {
            uniqueValueKey = cfv.custom_field_id + '_' + cfv.value;
            if (!valueAlreadyAdded[uniqueValueKey]) {
              valueAlreadyAdded[uniqueValueKey] = true;
              // make a selection list data item object
              v = _.clone(cfv);
              v.type = 'item';
              v.label = cfv.value;
              v.selected = false;
              values.push(v);
            }

            valuesByCustomFieldableId[customFieldableId] ||
              (valuesByCustomFieldableId[customFieldableId] = []);
            valuesByCustomFieldableId[customFieldableId].push(cfv);
          }
        });
      }

      customFieldFilterValues[namespace] = values;
      customFieldValuesByCustomFieldableId[namespace] =
        valuesByCustomFieldableId;
    }

    function onReceivedUserCustomFieldValues(data) {
      updateCustomFieldValues('users', data);
      internalCb();
    }

    function onReceivedAssignableCustomFieldValues(data) {
      updateCustomFieldValues('assignables', data);
      internalCb();
    }

    gService.getCustomFields('users', onReceivedCustomFields, onerror);

    gService.getCustomFields('assignables', onReceivedCustomFields, onerror);

    new injectJS(
      API_BASE_URL + '/reports/meta/custom_field_values/user',
      onReceivedUserCustomFieldValues,
      onerror,
      false, // noauth
      true // allowCache
    );

    new injectJS(
      // TODO Cleanup API consistency of namespace (assignables vs project)
      API_BASE_URL + '/reports/meta/custom_field_values/project',
      onReceivedAssignableCustomFieldValues,
      onerror,
      false, // noauth
      true // allowCache
    );
  };
}
