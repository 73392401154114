function dayCellButton(
  isSelectable,
  clsName,
  textClsName,
  buttonsprite,
  hoversprite,
  downsprite,
  date
) {
  var _i = this;
  _i.buttonsprite = buttonsprite;
  _i.spriteHover = hoversprite;
  _i.spriteDown = downsprite;
  // params
  _i.clsName = clsName;
  _i.textClsName = textClsName;
  _i.date = date;

  // locals
  _i.container = null;
  _i.attachTo = null;
  _i.callback = null;
  _i.btnText = null;
  _i.isSelectable = isSelectable;

  // ids
  _i.btnID = 'button' + Awe.getGuid();

  _i.setSelection = function () {
    // no op
  };

  _i.getSelection = function () {
    return { label: _i.btnText };
  };

  _i.destroy = function () {
    _i.attachTo.removeChild(_i.container);
  };

  _i.createAriaLabel = function () {
    var ariaLabel = '';
    if (_i.date !== null) {
      _i.dateArray = _i.date.toString().split(' ');
      ariaLabel =
        _i.dateArray[0] +
        ' ' +
        _i.dateArray[1] +
        ' ' +
        _i.dateArray[2] +
        ', ' +
        _i.dateArray[3];
    }

    return ariaLabel;
  };

  _i.setButtonText = function (txt) {
    _i.btnText = txt;
    if (_i.btnText) {
      _i.buttonLabel.innerText = _i.btnText;
    } else {
      _i.buttonLabel.innerHTML = '';
    }
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);
    _i.attachTo.appendChild(_i.container);
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.mouseOver = function (evt) {
    if (_i.spriteHover) {
      cancelEvent(window.event || evt);
      _i.container.backgroundHover.container.style.display = 'block';
      _i.container.backgroundHover.container.setAttribute('aria-hidden', false);
    }
  };

  _i.mouseOut = function (evt) {
    if (_i.spriteHover) {
      cancelEvent(window.event || evt);
      _i.container.backgroundHover.container.style.display = 'none';
      _i.container.backgroundHover.container.setAttribute('aria-hidden', true);
    }
  };

  _i.mouseDown = function (evt) {
    if (_i.spriteDown) {
      cancelEvent(window.event || evt);
      _i.container.backgroundDepress.container.style.display = 'block';
      _i.container.backgroundDepress.container.setAttribute(
        'aria-hidden',
        false
      );
    }
    xAddEventListener(document, platform.mouseUp, _i.mouseUp, false);
  };

  _i.mouseUp = function (evt) {
    xRemoveEventListener(document, 'mouseup', _i.mouseUp, false);
    if (_i.spriteDown) {
      _i.container.backgroundDepress.container.style.display = 'none';
      _i.container.backgroundDepress.container.setAttribute(
        'aria-hidden',
        true
      );
    }
    var xevt = new xEvent(evt);

    if (xHasPoint(_i.container, xevt.pageX, xevt.pageY) && _i.callback) {
      _i.containerClick(evt);
    }
  };

  _i.containerClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.callback) {
      _i.callback(_i);
    }
  };

  _i.handleKeypress = function (e) {
    if (typeof e == 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key == 'Enter' || e.key == ' ') {
      _i.containerClick(e);
    }
    return true;
  };

  _i.setClassName = function (clsName) {
    _i.clsName = clsName;
    if (_i.container) {
      _i.container.className = _i.clsName + ' ' + 'blockFloat unselectable';
    }
  };

  _i.createBackground = function (sprite) {
    _i.bbar = new backgroundBar(sprite);
    _i.container.appendChild(_i.bbar.container);
    if (_i.spriteHover) {
      _i.container.backgroundHover = new backgroundBar(_i.spriteHover);
      _i.container.appendChild(_i.container.backgroundHover.container);
      _i.container.backgroundHover.container.style.display = 'none';
      _i.container.backgroundHover.container.setAttribute('aria-hidden', true);
    }
    if (_i.spriteDown) {
      _i.container.backgroundDepress = new backgroundBar(_i.spriteDown);
      _i.container.appendChild(_i.container.backgroundDepress.container);
      _i.container.backgroundDepress.container.style.display = 'none';
      _i.container.backgroundDepress.container.setAttribute(
        'aria-hidden',
        true
      );
    }
  };

  _i.createTextLabel = function () {
    _i.buttonLabel = document.createElement('LABEL');
    _i.buttonLabel.className =
      _i.textClsName + ' blockFloat unselectable calendarButtonLabel';
    _i.buttonLabel.setAttribute('for', _i.btnID);
    _i.buttonLabel.style.whiteSpace = 'nowrap';
    _i.buttonLabel.style.float = 'none';
    _i.buttonLabel.style.textAlign = 'center';
    _i.container.appendChild(_i.buttonLabel);
    _i.buttonLabel.innerText = _i.btnText;
  };

  _i.setSprite = function (sprite) {
    _i.container.innerHTML = '';
    _i.container.className = _i.clsName + ' ' + 'blockFloat unselectable';
    _i.createBackground(sprite);
    _i.createTextLabel();
  };

  _i.constructor = function () {
    _i.container = document.createElement('TD');
    _i.container.className = _i.clsName + ' ' + 'blockFloat unselectable';
    _i.container.setAttribute('role', 'button');
    _i.container.tabIndex = '0';
    _i.container.id = _i.btnID;

    _i.container.parent = _i;

    _i.setSprite(_i.buttonsprite);

    if (_i.isSelectable) {
      _i.container.setAttribute('aria-label', _i.createAriaLabel());
      xAddEventListener(_i.container, 'mouseover', _i.mouseOver, false);
      xAddEventListener(_i.container, 'mouseout', _i.mouseOut, false);
      xAddEventListener(_i.container, platform.mouseDown, _i.mouseDown, false);
      xAddEventListener(_i.container, 'keypress', _i.handleKeypress, false);
    } else {
      _i.container.style.cursor = 'default';
      _i.container.setAttribute(
        'aria-label',
        _i.createAriaLabel() + ' Date not available for selection.'
      );
    }
  };

  _i.constructor();
}

function calendarDay(
  parentObj,
  attachTo,
  dayNum,
  selected,
  greyout,
  date,
  isSelectable
) {
  var _i = this;
  if (!(this instanceof calendarDay)) {
    return new calendarDay(
      parentObj,
      attachTo,
      dayNum,
      selected,
      greyout,
      date,
      isSelectable
    );
  }

  // params
  _i.parentObj = parentObj;
  _i.attachTo = attachTo;
  _i.dayNum = dayNum;
  _i.selected = selected;
  _i.greyout = greyout;
  _i.date = new Date(date);

  _i.contClick = function (evt) {
    _i.parentObj.reportDayClick(_i.date);
  };

  _i.constructor = function () {
    if (_i.selected) {
      _i.day = new dayCellButton(
        isSelectable,
        'blockFloat calendarDayCellSelected',
        'fnt-r-14',
        compSpritesButtonsIcons.calendarDaySelected,
        null,
        null,
        null,
        _i.date
      );
    } else if (greyout) {
      _i.day = new dayCellButton(
        isSelectable,
        'blockFloat calendarDayCellDisabled',
        'fnt-r-14',
        compSpritesButtonsIcons.calendarDayRest,
        compSpritesButtonsIcons.calendarDayHover,
        compSpritesButtonsIcons.calendarDayDown,
        _i.date
      );
    } else {
      _i.day = new dayCellButton(
        isSelectable,
        'blockFloat calendarDayCell',
        'fnt-r-14',
        compSpritesButtonsIcons.calendarDayRest,
        compSpritesButtonsIcons.calendarDayHover,
        compSpritesButtonsIcons.calendarDayDown,
        _i.date
      );
    }

    if (_i.selected == true) {
      _i.day.container.tabIndex = '0';
    }

    _i.day.setButtonText(_i.dayNum.toFixed());
    _i.day.setCallback(_i.contClick);
    _i.attachTo.appendChild(_i.day.container);
  };

  _i.constructor();
}

function Calendar(attachTo, callback, startdate, selectableDays) {
  var _i = this;

  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.callback = callback;

  // locals
  _i.cont = null;
  _i.selectedDate = new Date(startdate);
  _i.startDate = new Date(startdate);
  _i.items = [];
  _i.selectableDays = selectableDays;

  // TODO We should use the names in utility.js or better yet, those that come from rails i8n
  _i.dayNames = [
    I18n.t('lbl_sunday_abbr_uc'),
    I18n.t('lbl_monday_abbr_uc'),
    I18n.t('lbl_tuesday_abbr_uc'),
    I18n.t('lbl_wednesday_abbr_uc'),
    I18n.t('lbl_thursday_abbr_uc'),
    I18n.t('lbl_friday_abbr_uc'),
    I18n.t('lbl_saturday_abbr_uc'),
  ];
  _i.dayNamesLong = [
    I18n.t('lbl_day_sunday'),
    I18n.t('lbl_day_monday'),
    I18n.t('lbl_day_tuesday'),
    I18n.t('lbl_day_wednesday'),
    I18n.t('lbl_day_thursday'),
    I18n.t('lbl_day_friday'),
    I18n.t('lbl_day_saturday'),
  ];

  // TODO We should use the names in utility.js or better yet, those that come from rails i8n
  _i.monthNames = [
    I18n.t('lbl_month_january'),
    I18n.t('lbl_month_february'),
    I18n.t('lbl_month_march'),
    I18n.t('lbl_month_april'),
    I18n.t('lbl_month_may'),
    I18n.t('lbl_month_june'),
    I18n.t('lbl_month_july'),
    I18n.t('lbl_month_august'),
    I18n.t('lbl_month_september'),
    I18n.t('lbl_month_october'),
    I18n.t('lbl_month_november'),
    I18n.t('lbl_month_december'),
  ];

  _i.clear = function () {
    _i.selectedDate = null;
  };

  _i.reportDayClick = function (date) {
    if (_i.callback) {
      _i.callback(date);
    }
  };

  _i.incYear = function () {
    var yr = _i.selectedDate.getFullYear() + 1;
    _i.selectedDate.setYear(yr);
  };

  _i.decYear = function () {
    var yr = _i.selectedDate.getFullYear() - 1;
    _i.selectedDate.setYear(yr);
  };

  _i.incMonth = function () {
    _i.selectedDate.setDate(1);
    var mo = _i.selectedDate.getMonth() + 1;
    if (mo > 11) {
      mo = 0;
      _i.incYear();
    }

    _i.selectedDate.setMonth(mo);
    _i.setMonth();
  };

  _i.decMonth = function () {
    _i.selectedDate.setDate(1);
    var mo = _i.selectedDate.getMonth() - 1;

    if (mo < 0) {
      mo = 11;
      _i.decYear();
    }
    _i.selectedDate.setMonth(mo);
    _i.setMonth();
  };

  _i.setDate = function (date) {
    _i.selectedDate = new Date(date);
    _i.startDate = new Date(date);
    _i.setMonth();
  };

  _i.setMonth = function (dat) {
    if (dat == null) {
      dat = _i.selectedDate;
    }

    var mo = _i.monthNames[dat.getMonth()];
    var yr = dat.getFullYear();

    _i.header.innerText = mo;
    _i.year.innerHTML = "<div class='calendarYear'>" + yr + '</div>';
    _i.addDays();
    _i.createRows();
  };

  _i.leftMonthClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);
    _i.decMonth();
  };

  _i.rightMonthClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);
    _i.incMonth();
  };

  _i.removeDays = function () {
    if (_i.newDayHolder) {
      while (_i.newDayHolder.childNodes.length > 0) {
        _i.newDayHolder.removeChild(_i.newDayHolder.childNodes[0]);
      }
      _i.items = [];
    }
  };

  _i.addBlanks = function (dat) {
    var numBlank = dat.getDay();
    var yr = _i.selectedDate.getFullYear();
    var mo = _i.selectedDate.getMonth() - 1;

    if (mo < 0) {
      mo = 11;
      yr--;
    }
    var first = new Date(_i.selectedDate);
    first.setMonth(mo);
    first.setYear(yr);
    var daynum = first.daysInMonth() - numBlank + 1;
    first.setDate(daynum);
    for (var z = 0; z < numBlank; z++) {
      calendarDay(
        _i,
        _i.dayHolder,
        daynum,
        false,
        true,
        first,
        _i.isDateSelectable(first)
      );
      daynum++;
      first.setDate(daynum);
    }
    return numBlank;
  };

  _i.isSelected = function (date) {
    return (
      date.getDate() == _i.startDate.getDate() &&
      date.getMonth() == _i.startDate.getMonth() &&
      date.getYear() == _i.startDate.getYear()
    );
  };

  _i.isDateSelectable = function (date) {
    return _i.selectableDays ? _i.selectableDays[date.getDay()] : true;
  };

  _i.addDays = function () {
    _i.removeDays();

    var dy = 1;
    var tmpDate = new Date(_i.selectedDate);
    tmpDate.setDate(dy);
    var mo = tmpDate.getMonth();

    var numdays = _i.addBlanks(tmpDate);

    while (tmpDate.getMonth() == mo) {
      _i.items[_i.items.length] = new calendarDay(
        _i,
        _i.dayHolder,
        dy,
        _i.isSelected(tmpDate),
        false,
        tmpDate,
        _i.isDateSelectable(tmpDate)
      );
      dy++;
      tmpDate.setDate(dy);
      numdays++;
    }
    var additionalDayNum = 1;
    while (numdays % 7 > 0) {
      calendarDay(
        _i,
        _i.dayHolder,
        additionalDayNum++,
        false,
        true,
        tmpDate,
        _i.isDateSelectable(tmpDate)
      );
      tmpDate.addDays(1);
      numdays++;
    }
  };

  _i.makeDayNames = function () {
    _i.dayNameCont = document.createElement('THEAD');
    _i.dayNameCont.className =
      'blockFloatNot fnt-r-12 calendarDayNamesContainer';
    _i.dayTable.appendChild(_i.dayNameCont);

    _i.dayNameRow = document.createElement('TR');
    _i.dayNameRow.className = 'blockFloatNot';
    _i.dayNameCont.appendChild(_i.dayNameRow);

    for (var z = 0; z < _i.dayNames.length; z++) {
      var dv = document.createElement('TH');
      dv.className = 'blockFloat calendarDayName';
      dv.innerText = _i.dayNames[z];
      dv.title = _i.dayNamesLong[z];
      dv.setAttribute('scope', 'col');
      _i.dayNameRow.appendChild(dv);
    }
  };

  _i.createRows = function () {
    _i.dayHolderChildArray = [];

    if (_i.newDayHolder) {
      _i.newDayHolder.parentElement.removeChild(_i.newDayHolder);
    }

    _i.newDayHolder = document.createElement('TBODY');
    _i.newDayHolder.id = 'day-holder-wrows';
    _i.newDayHolder.className = 'blockFloat calendarDayHolder';
    _i.newDayHolder.style.width = '100%';

    while (_i.dayHolder.firstChild) {
      const curChild = _i.dayHolder.removeChild(_i.dayHolder.firstChild);
      _i.dayHolderChildArray.push(curChild);
    }

    while (_i.dayHolderChildArray.length) {
      _i.row = document.createElement('TR');
      _i.subArray = _i.dayHolderChildArray.splice(0, 7);
      for (var i = 0; i < _i.subArray.length; i++) {
        var curNode = _i.subArray[i];
        _i.row.appendChild(curNode);
      }
      _i.newDayHolder.appendChild(_i.row);
    }

    _i.dayTable.appendChild(_i.newDayHolder);
  };

  _i.constructor = function () {
    _i.cont = document.createElement('DIV');
    _i.cont.className = 'calendarContainer';
    _i.attachTo.appendChild(_i.cont);

    _i.year = document.createElement('DIV');
    _i.year.className = 'blockFloat fnt-b-12 calendarYearContainer';

    _i.header = document.createElement('DIV');
    _i.header.className = 'blockFloat fnt-b-14 calendarHeader';
    // LOC-120 note: setting font weight because otherwise in Russian the header is not bold
    _i.header.style.fontWeight = 'bold';

    _i.leftMonth = document.createElement('DIV');
    _i.leftMonth.className = 'blockFloat calendarLeftMonth';
    _i.leftMonth.setAttribute('aria-label', 'previous month');
    _i.leftMonth.tabIndex = 0;
    _i.leftMonth.setAttribute('role', 'button');
    var appendleft = document.createElement('DIV');
    _i.leftMonth.appendChild(appendleft);
    getSprite(compSpritesButtonsIcons.leftArrow, appendleft);
    xAddEventListener(
      _i.leftMonth,
      platform.mouseDown,
      _i.leftMonthClick,
      false
    );

    _i.rightMonth = document.createElement('DIV');
    _i.rightMonth.className = 'blockFloat calendarRightMonth';
    _i.rightMonth.setAttribute('aria-label', 'next month');
    _i.rightMonth.tabIndex = 0;
    _i.rightMonth.setAttribute('role', 'button');

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    _i.rightMonth.appendChild(_i.nestedDiv);

    var appendright = document.createElement('DIV');
    _i.nestedDiv.appendChild(appendright);
    getSprite(compSpritesButtonsIcons.rightArrow, appendright);
    xAddEventListener(
      _i.rightMonth,
      platform.mouseDown,
      _i.rightMonthClick,
      false
    );

    _i.dayTable = document.createElement('TABLE');
    _i.dayTable.className = 'blockFloat';

    _i.dayHolder = document.createElement('TBODY');

    _i.cont.appendChild(_i.year);
    _i.cont.appendChild(_i.leftMonth);
    _i.cont.appendChild(_i.header);
    _i.cont.appendChild(_i.rightMonth);
    _i.cont.appendChild(_i.dayTable);

    _i.makeDayNames();
    xAddEventListener(document, platform.mouseDown, _i.hide, false);

    if (_i.selectedDate == null) {
      _i.selectedDate = new Date();
    }

    _i.setMonth();
  };

  _i.constructor();
}

// shortcutsDefinition
// example object:
//
//  var exampleShortcuts = {
//     headerLabel : I18n.t('lbl_set_to'),
//     shortcuts : [
//       { spriteName : "splitIconS", label : "Today", dateObject : new Date("July 22, 2012") },
//       { spriteName : "", label : "Project", dateObject : new Date("July 27, 2012") }
//     ]
//  };
// Pass an object formatted as the example to add shortcuts to a calendar popup.
// The default shortcut is a button for "today".

var defaultCalendarShortcut = {
  headerLabel: '',
  shortcuts: [
    {
      spriteName: '',
      label: 'lbl_set_to_today',
      dateObject: new Date().toStartOfDay(),
    },
  ],
};

var defaultCalendarShortcutNone = {
  headerLabel: '',
  shortcuts: [
    {
      spriteName: '',
      label: 'lbl_set_to_today',
      dateObject: new Date().toStartOfDay(),
    },
    { spriteName: '', label: 'lbl_set_to_none', dateObject: null },
  ],
};

var nonProjectChangeFromDateShortcuts = {
  headerLabel: '',
  shortcuts: [
    {
      spriteName: '',
      label: 'lbl_set_to_today',
      dateObject: new Date().toStartOfDay(),
    },
  ],
};

var nonProjectChangeToDateShortcuts = {
  headerLabel: '',
  shortcuts: [
    {
      spriteName: '',
      label: 'lbl_set_to_today',
      dateObject: new Date().toStartOfDay(),
    },
  ],
};

var changeFromDateShortcuts = {
  headerLabel: '',
  shortcuts: [
    {
      spriteName: '',
      label: 'lbl_set_to_today',
      dateObject: new Date().toStartOfDay(),
    },
    {
      spriteName: '',
      label: 'lbl_set_to_project_start',
      dateObject: new Date().toStartOfDay(),
    },
  ],
};

var changeToDateShortcuts = {
  headerLabel: '',
  shortcuts: [
    {
      spriteName: '',
      label: 'lbl_set_to_project_end',
      dateObject: new Date().toStartOfDay(),
    },
  ],
};

function calendarPopup(
  width,
  height,
  dialogViewport,
  startDate,
  dialogSpecs,
  callback,
  multiselect,
  handleblur,
  direction,
  noautorotate,
  preferredDirections,
  parentPopupContainer,
  blurcallback,
  selectableDays,
  shortcutsDefinition,
  dropdownID
) {
  var _i = this;

  // params
  _i.width = width;
  _i.height = height;
  _i.dialogViewport = dialogViewport;
  _i.selectedDate = new Date(startDate);
  _i.dialogSpecs = dialogSpecs;
  _i.callback = callback;
  _i.multiselect = multiselect;
  _i.handleblur = handleblur;
  _i.direction = direction;
  _i.noautorotate = noautorotate;

  // locals
  _i.popup = null;
  _i.selectionList = null;
  _i.scrollCallback = null;
  _i.blurcallback = blurcallback;
  _i.shortcutsDefinition = shortcutsDefinition || defaultCalendarShortcut;

  // id
  _i.dropdownID = dropdownID;

  _i.isVisible = function () {
    return _i.popupContainer && _i.popupContainer.isVisible();
  };

  _i.escHandler = function (evt) {
    if (evt.keyCode == 27) {
      cancelEvent(window.event || evt);
      _i.hide();
    }
  };

  _i.setSelectionCallback = function (callback) {
    _i.callback = callback;
  };

  _i.remove = function () {
    _i.popupContainer.remove();
    _i.popupContainer = null;
  };

  _i.hide = function () {
    _i.popupContainer.hide();
  };

  _i.show = function (elementToPointAt) {
    if (_i.popupContainer == null) _i.buildPopup();
    _i.popupContainer.pointAtElement(elementToPointAt);
  };

  _i.toggle = function (elementToPointAt) {
    if (_i.isVisible()) {
      _i.hide();
    } else {
      _i.show(elementToPointAt);
    }
  };

  _i.showAtPoint = function (x, y, xoffset, yoffset) {
    if (_i.popupContainer == null) _i.buildPopup();
    _i.popupContainer.pointAt(x, y, xoffset, yoffset);
  };

  _i.itemClick = function (date) {
    _i.hide();
    if (_i.callback) _i.callback(date);
  };

  _i.blur = function () {
    _i.remove();
    if (_i.blurcallback) _i.blurcallback();
  };

  _i.setDate = function (date) {
    _i.calendar.setDate(date);
  };

  _i.buildPopup = function () {
    _i.popupContainer = new popupContainer(
      _i.dialogSpecs,
      _i.width,
      _i.height,
      3,
      _i.noautorotate ? false : true,
      _i.direction ? _i.direction : 'wc',
      _i.dialogViewport,
      preferredDirections,
      _i.handleblur ? _i.blur : _i.remove,
      parentPopupContainer,
      null,
      { dropdownID: _i.dropdownID }
    );
    _i.popupContainer.attachContent(_i.cont);
    _i.popupContainer.attachContent(_i.shortCuts.container);
    _i.popupContainer.contentHolder.style.backgroundColor = '#FFFFFF';
    _i.popupContainer.contentHolder.style.textAlign = 'center';
  };

  _i.constructor = function () {
    _i.height += 30 * _i.shortcutsDefinition.shortcuts.length + 30;
    _i.cont = document.createElement('DIV');
    _i.cont.className = 'calendarPopupContainer blockFloatNot';
    _i.calendar = new Calendar(
      _i.cont,
      _i.itemClick,
      _i.selectedDate ? _i.selectedDate : new Date(),
      selectableDays
    );
    _i.shortCuts = new calendarShortCuts(
      _i,
      _i.cont,
      _i.shortcutsDefinition,
      _i.itemClick
    );
  };

  _i.constructor();
}

function calendarShortcutItem(
  parentObject,
  parentNode,
  index,
  dataObject,
  callback,
  labelledby
) {
  var _i = this;

  // params
  _i.parentObject = parentObject;
  _i.parentNode = parentNode;
  _i.index = index;
  _i.dataObject = dataObject;
  _i.callback = callback;
  _i.headerText = labelledby;

  // locals
  _i.container = document.createElement('DIV');
  _i.label = document.createElement('DIV');
  _i.dateString = document.createElement('DIV');

  _i.containerClick = function () {
    _i.callback(_i.index);
  };

  _i.init = (function () {
    _i.container.className = 'blockFloatNot fnt-r-14';
    _i.container.setAttribute('role', 'button');
    _i.container.tabIndex = '0';
    _i.container.style.width = 'auto';
    _i.container.style.marginBottom =
      dataObject.label === 'lbl_set_to_none' ? '0px' : '15px';
    _i.container.style.marginLeft = '0px';
    _i.container.style.cursor = 'pointer';

    if (_i.dataObject.spriteName) {
      _i.sprite = getSprite(compSpritesButtonsIcons[_i.dataObject.spriteName]);
      _i.sprite.className = 'blockFloat';
      _i.sprite.style.marginRight = '11px';
      _i.sprite.style.marginTop =
        (30 - compSpritesButtonsIcons[_i.dataObject.spriteName].height) / 2 +
        'px';
    }

    _i.label.className = 'blockFloat';
    _i.label.style.color = '#0970BE';
    _i.label.style.lineHeight = '14px';
    _i.label.style.marginRight = '5px';
    _i.label.innerText = I18n.t(_i.dataObject.label);

    _i.dateString.className = 'blockFloat';
    _i.dateString.style.color = '#666666';
    _i.dateString.style.lineHeight = '14px';
    _i.dateString.innerHTML = dataObject.dateObject
      ? ' (' + dataObject.dateObject.toInputStyleStringShort() + ')'
      : '';

    _i.parentNode.appendChild(_i.container);
    if (_i.sprite) {
      _i.container.appendChild(_i.sprite);
    }
    _i.container.appendChild(_i.label);
    _i.container.appendChild(_i.dateString);

    _i.container.setAttribute(
      'aria-label',
      _i.headerText + ': ' + _i.label.innerText + ' ' + _i.dateStringSRLabel
    );
    xAddEventListener(_i.container, platform.click, _i.containerClick, false);
  })();
}

function calendarShortCuts(
  parentObject,
  parentNode,
  shortcutsDefinition,
  callback
) {
  var _i = this;

  // params
  _i.parentObject = parentObject;
  _i.parentNode = parentNode;
  _i.shortcutsDefinition = shortcutsDefinition;
  _i.callback = callback;

  // locals
  _i.container = document.createElement('DIV');
  _i.header = document.createElement('DIV');
  _i.items = [];

  _i.shortcutClick = function (index) {
    _i.callback(_i.shortcutsDefinition.shortcuts[index].dateObject);
  };

  _i.init = (function () {
    _i.container.className = 'blockFloatNot';
    _i.container.style.width = 'auto';
    _i.container.style.height = 'auto';
    _i.container.style.display = 'flex';
    _i.container.style.flexDirection = 'column';
    _i.container.style.marginLeft = '10px';

    _i.header.className = 'blockFloatNot fnt-r-14';
    _i.header.id = 'shortcut-header';
    _i.header.style.color = '#878787';
    _i.header.innerText = _i.shortcutsDefinition.headerLabel;
    _i.header.style.lineHeight = '25px';
    _i.header.style.marginTop = '3px';

    _i.container.appendChild(_i.header);

    for (var z = 0; z < _i.shortcutsDefinition.shortcuts.length; z++) {
      _i.items[_i.items.length] = new calendarShortcutItem(
        _i,
        _i.container,
        _i.items.length,
        _i.shortcutsDefinition.shortcuts[z],
        _i.shortcutClick,
        _i.header.innerHTML
      );
    }
  })();
}

function PopUpCalendar(
  attach,
  label,
  container_style,
  button_style,
  label_style,
  input_style,
  initial_date,
  popup,
  shortformat,
  dropdownID,
  btnID
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.label = label;
  _i.label_style = label_style;
  if (initial_date) _i.selectedDate = new Date(initial_date);
  _i.input_style = input_style;
  _i.container_style = container_style;
  _i.button_style = button_style;
  _i.popup = popup;
  _i.guid = Awe.getGuid();
  _i.shortformat = shortformat;

  // ids
  if (label) {
    _i.labelID = label.replace(/\s+/g, '-') + _i.guid;
  }

  _i.dropdownID = dropdownID;
  _i.btnID = btnID;
  _i.currentSelectionID = 'current-selection' + Awe.getGuid();

  _i.setDate = function (date) {
    _i.selectedDate = date ? new Date(date) : null;

    while (_i.inputContainer.childNodes.length >= 1) {
      _i.inputContainer.removeChild(_i.inputContainer.firstChild);
    }

    if (date) _i.popup.setDate(date);
    _i.createContent();
  };

  _i.onButtonClick = function () {
    _i.popup.toggle('elementDropDownSelect' + _i.guid);
    _i.popup.isShown = true;

    // Sometimes there's no id, so nothing to do...
    if (_i.dropdownID) focusFirstChildElementAfterDelay('#' + _i.dropdownID);
    return false;
  };

  _i.createBackground = function (sprite) {
    _i.bbar = new backgroundBar(sprite);
    _i.buttonContainer.appendChild(_i.bbar.container);
    if (_i.spriteHover) {
      _i.buttonContainer.backgroundHover = new backgroundBar(_i.spriteHover);
      _i.buttonContainer.appendChild(_i.container.backgroundHover.container);
      _i.buttonContainer.backgroundHover.container.style.display = 'none';
      _i.buttonContainer.backgroundHover.container.setAttribute(
        'aria-hidden',
        true
      );
    }
    if (_i.spriteDown) {
      _i.buttonContainer.backgroundDepress = new backgroundBar(_i.spriteDown);
      _i.buttonContainer.appendChild(_i.container.backgroundDepress.container);
      _i.buttonContainer.backgroundDepress.container.style.display = 'none';
      _i.buttonContainer.backgroundDepress.container.setAttribute(
        'aria-hidden',
        true
      );
    }
  };

  _i.createContent = function () {
    if (_i.label) {
      _i.labelText = document.createElement('LABEL');
      _i.labelText.className = _i.label_style;
      _i.labelText.innerText = label;
      _i.labelText.id = _i.labelID;
      _i.inputContainer.appendChild(_i.labelText);
    }
    _i.buttonContainer = document.createElement('BUTTON');
    _i.buttonContainer.className = _i.button_style;
    _i.buttonContainer.id = _i.btnID;
    _i.inputContainer.appendChild(_i.buttonContainer);

    xAddEventListener(
      _i.buttonContainer,
      platform.click,
      _i.onButtonClick,
      false
    );
    xAddEventListener(_i.buttonContainer, 'keypress', _i.onKeypress, false);

    _i.buttonContainer.tabIndex = 0;
    _i.buttonContainer.setAttribute('role', 'button');
    if (_i.label) {
      _i.buttonContainer.setAttribute('aria-labelledby', _i.labelID);
    }

    _i.createBackground(compSpritesButtonsIcons.secondaryButtonRest);

    _i.labelSpan = document.createElement('SPAN');
    _i.labelSpan.id = _i.currentSelectionID;
    _i.labelSpan.className = _i.input_style;
    if (_i.selectedDate) {
      _i.buttonContainer.removeAttribute('aria-labelledby');
      _i.buttonContainer.setAttribute(
        'aria-label',
        document.getElementById(_i.labelID).innerHTML +
          ', currently set to: ' +
          _i.selectedDate.toString().substring(0, 15)
      );
      if (_i.shortformat) {
        _i.labelSpan.innerText = _i.selectedDate.toInputStyleStringShort();
      } else {
        _i.labelSpan.innerText = _i.selectedDate.toInputStyleString();
      }
      _i.buttonContainer.setAttribute(
        'aria-describedby',
        _i.currentSelectionID
      );
    } else {
      _i.labelSpan.innerHTML = '';
    }
    _i.originalText = _i.labelSpan.innerHTML;
    _i.buttonContainer.appendChild(_i.labelSpan);

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    _i.buttonContainer.appendChild(_i.nestedDiv);

    _i.elementDropDownSelect = document.createElement('DIV');
    _i.elementDropDownSelect.className = 'blockFloat iconDropDownSelect';
    _i.elementDropDownSelect.appendChild(
      getSprite(compSpritesButtonsIcons.downArrowMenu)
    );
    _i.elementDropDownSelect.id = 'elementDropDownSelect' + _i.guid;
    _i.nestedDiv.appendChild(_i.elementDropDownSelect);
  };

  _i.constructor = function () {
    _i.inputContainer = document.createElement('DIV');
    _i.inputContainer.className = _i.container_style;
    _i.attachTo.appendChild(_i.inputContainer);
    _i.createContent();
  };

  _i.constructor();
}
