function SelectionToggle(
  attach,
  labelSpan,
  initial_value,
  container_style,
  label_style,
  icon_style,
  tooltip,
  inputID,
  onChangeCallback
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.labelSpan = labelSpan;
  _i.label_style = label_style;
  _i.initial_value = initial_value;
  _i.icon_style = icon_style;
  _i.container_style = container_style;
  _i.callback = null;
  _i.guid = Awe.getGuid();
  _i.alertData = [
    {
      type: 'label',
      value: null,
      label: 'label',
    },
  ];

  // ids
  _i.inputID = inputID || 'toggle-' + _i.guid;

  _i.setEnabled = function (val) {
    while (_i.icon.childNodes.length >= 1) {
      _i.icon.removeChild(_i.icon.firstChild);
    }
    if (val) {
      _i.icon.appendChild(getSprite(compSpritesButtonsIcons.selectionRadialOn));
      _i.inputContainer.setAttribute('aria-checked', true);
    } else {
      _i.icon.appendChild(
        getSprite(compSpritesButtonsIcons.selectionRadialOff)
      );
      _i.inputContainer.setAttribute('aria-checked', false);
    }
    onChangeCallback && onChangeCallback(val);
  };

  _i.onClick = function () {
    if (_i.callback) _i.callback();
  };

  _i.onKeypress = function (e) {
    if (typeof e == 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key == 'Enter' || e.key == ' ') {
      _i.onClick();
      return false;
    }
    return true;
  };

  _i.showAlert = function () {
    if (_i.alertMessage == null || _i.alertMessage == '') return;

    _i.alertPopup.show('alertmsgshow' + _i.guid);
  };

  _i.buildAlertMessage = function () {
    _i.alertData[0].label = _i.alertMessage;
  };

  _i.setAlert = function (alertmsg) {
    _i.alertMessage = alertmsg;
    _i.buildAlertMessage();
    _i.alertPopup = new selectionPopup(
      350,
      40,
      'mainCon',
      _i.alertData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onAlertClick,
      false,
      null /*blurCallback*/,
      'nw',
      null,
      true
    );
    _i.showAlert();
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.getValue = function () {
    return _i.inputText.value;
  };

  _i.constructor = function () {
    _i.inputContainer = document.createElement('DIV');
    _i.inputContainer.className = _i.container_style;
    _i.inputContainer.id = _i.inputID;

    _i.inputContainer.tabIndex = 0;
    _i.inputContainer.setAttribute('role', 'radio');

    _i.inputContainer.style.cursor = 'pointer';
    if (tooltip) {
      _i.inputContainer.title = tooltip;
    }
    _i.attachTo.appendChild(_i.inputContainer);
    xAddEventListener(_i.inputContainer, platform.click, _i.onClick, false);
    xAddEventListener(_i.inputContainer, 'keypress', _i.onKeypress, false);

    _i.icon = document.createElement('DIV');
    _i.icon.className = 'blockFloat ' + _i.icon_style;
    if (_i.initial_value) {
      _i.icon.appendChild(getSprite(compSpritesButtonsIcons.selectionRadialOn));
      _i.inputContainer.setAttribute('aria-checked', true);
    } else {
      _i.icon.appendChild(
        getSprite(compSpritesButtonsIcons.selectionRadialOff)
      );
      _i.inputContainer.setAttribute('aria-checked', false);
    }

    _i.inputContainer.appendChild(_i.icon);

    _i.labelText = document.createElement('LABEL');
    _i.labelText.setAttribute('for', _i.inputID);
    _i.labelText.className = 'blockFloat ' + _i.label_style;
    _i.labelText.appendChild(_i.labelSpan);
    _i.inputContainer.appendChild(_i.labelText);

    _i.alertText = document.createElement('DIV');
    _i.alertText.className = 'blockFloatNot';
    _i.alertText.id = 'alertmsgshow' + _i.guid;
    _i.inputContainer.appendChild(_i.alertText);
  };
  _i.constructor();
}
