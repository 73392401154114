/**
 * @description Renders content for Settings category then attaches it to page container
 * @param {string} attachTo Container element to attach SettingsCategory to
 * @param {string} namespacevalue API namespace for fetching tags
 * @param {string} titleVal text for title of page
 * @param {string} addInputLabelVal text for label of Add Input of dynamic list
 */
function SettingsCategory(
  attachTo,
  namespacevalue,
  titleVal,
  addInputLabelVal
) {
  var _i = this;
  if (!(this instanceof SettingsCategory)) {
    return new SettingsCategory(
      attachTo,
      namespacevalue,
      titleVal,
      addInputLabelVal
    );
  }

  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.namespaceval = namespacevalue;
  _i.titleVal = titleVal;
  _i.addInputLabelVal = addInputLabelVal;
  _i.categoryControllers = [];
  _i.alertIsShowing = false;

  _i.categoryCreated = function () {
    _i.buildCategoryArray();
    _i.refreshList();
    _i.categoryvalue.inputText.value = '';
  };

  _i.numDuplicates = function (proposedValue) {
    var arr = gData.tagsByNameSpace[_i.namespaceval];

    if (!arr) {
      return 0;
    }

    var dupes = 0;

    for (var i = 0; i < arr.length; i++) {
      if (
        arr[i].value.toLowerCase().trim() == proposedValue.toLowerCase().trim()
      ) {
        dupes++;
      }
    }

    return dupes++;
  };

  _i.editModeCallback = function () {
    for (var i = 0; i < _i.categoryControllers.length; i++) {
      _i.categoryControllers[i].cancelEdit();
    }
  };

  _i.addCategory = function () {
    var proposedValue = singleSpace(trim(_i.categoryvalue.getValue()));

    if (!proposedValue) {
      _i.categoryvalue.setAlert(I18n.t('err_valid_value'));
      _i.categoryvalue.inputText.value = '';
      _i.alertIsShowing = true;
      return false;
    }

    if (_i.numDuplicates(proposedValue) > 0) {
      _i.categoryvalue.setAlert(I18n.t('msg_duplicate_value'));
      _i.categoryvalue.inputText.value = '';
      _i.alertIsShowing = true;
      return false;
    }

    var categoryData = {};
    categoryData.name = proposedValue;
    categoryData.value = proposedValue;
    categoryData.namespace = _i.namespaceval;
    gService.createCategoryTag(categoryData, _i.categoryCreated);
  };

  _i.refreshList = function () {
    removeAllChildren(_i.categoryCollection);
    _i.buildCategoryArray();
    _i.displayCategory();
  };

  _i.spacerLineObject = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.categoryCollection.appendChild(spacer);
  };

  _i.displayCategory = function () {
    _i.categoryControllers = [];
    for (var i = 0; _i.categoryValues && i < _i.categoryValues.length; i++) {
      _i.categoryControllers.push(
        SettingsCategoryValue(_i.categoryCollection, _i.categoryValues[i], _i)
      );
      _i.spacerLineObject();
    }
  };

  renderList = function () {
    if (_i.pspinner) {
      _i.pspinner.remove();
      _i.pspinner = null;
    }
    _i.refreshList();
  };

  _i.buildCategoryArray = function () {
    _i.categoryValues = gData.tagsByNameSpace[_i.namespaceval];
    if (_i.categoryValues) {
      _i.categoryValues.sort(function (a, b) {
        var sa = a.value.toLowerCase();
        var sb = b.value.toLowerCase();
        if (sa == sb) return 0;
        return sa > sb ? 1 : -1;
      });
    } else {
      _i.pspinner = new pageSpinner();
      gService.getTagsWithNamespace(_i.namespaceval, renderList);
    }
  };

  _i.onChange = function () {
    if (_i.alertIsShowing) {
      _i.categoryvalue.hideAlert();
      _i.alertIsShowing = false;
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);

    _i.title = document.createElement('H3');
    _i.title.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.title.innerHTML = _i.titleVal;
    _i.container.appendChild(_i.title);

    _i.categoryvalue = new TextInput(
      'settingsMyPreferencesContentContainer',
      _i.addInputLabelVal,
      '',
      'blockFloat settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall',
      null,
      null,
      null,
      _i.addCategory,
      _i.onChange
    );
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.addButton = new primaryButton('settingsPrimaryButton');
    _i.addButton.setButtonText(I18n.t('lbl_add'));
    _i.addButton.setCallback(_i.addCategory);
    _i.container.appendChild(_i.addButton.container);
    _i.addButton.container.tabIndex = 0;

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);

    _i.categoryCollection = document.createElement('DIV');
    _i.categoryCollection.classname = 'blockFloatNot';
    _i.container.appendChild(_i.categoryCollection);

    _i.buildCategoryArray();
    _i.displayCategory();
  };

  _i.constructor();
}
