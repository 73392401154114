function ProjectPercentScheduledSummaryView(
  attach,
  obj,
  str,
  over,
  percent,
  dollars
) {
  var _i = this;
  _i.attachTo = xGetElementById(attach);
  _i.attach = attach;
  _i.project = obj;
  _i.percent = percent;

  _i.projectClicked = function (evt) {
    if (_i.project) {
      var label = 'Project Link from Forecasted Budget: ';
      label += dollars ? 'Currency' : 'Days';
      trackGAEvent('Analytics Page', 'click', label);
      window.location = getBaseURL() + 'projects/' + _i.project.id;
    }
  };

  _i.constructor = function () {
    var sprite = compSpritesButtonsIcons.kpi2Up;
    if (percent < 3) {
      sprite = compSpritesButtonsIcons.kpiEven;
    } else if (over && percent < 8.0) {
      sprite = compSpritesButtonsIcons.kpi1Up;
    } else if (!over && percent <= 8.0) {
      sprite = compSpritesButtonsIcons.kpi1Down;
    } else if (!over && percent > 8.0) {
      sprite = compSpritesButtonsIcons.kpi2Down;
    }

    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot projectSummaryContainer';
    _i.container.id = 'projectSummaryContainer-' + _i.project.guid;
    _i.attachTo.appendChild(_i.container);

    if (_i.percent == 'nobudget') {
      var iconContainer = document.createElement('DIV');
      iconContainer.className =
        'blockFloat projectStatusStatisticIconContainer';
      _i.container.appendChild(iconContainer);
      var labelContainer = document.createElement('DIV');
      labelContainer.className =
        'blockFloat projectStatusStatisticLabelContainer';

      _i.container.appendChild(labelContainer);

      _i.container.style.cursor = 'pointer';
      xAddEventListener(_i.container, platform.click, _i.projectClicked, false);

      return;
    }

    var iconContainer = document.createElement('DIV');
    iconContainer.className = 'blockFloat projectStatusStatisticIconContainer';
    iconContainer.appendChild(getSprite(sprite));

    _i.container.appendChild(iconContainer);

    var labelContainer = document.createElement('DIV');
    labelContainer.className =
      'blockFloat projectStatusStatisticLabelContainer';

    _i.container.appendChild(labelContainer);

    _i.headerText = document.createElement('DIV');
    _i.headerText.className =
      'blockFloatNot fnt-r-12 darkHeaderText projectCalendarCompleteSummaryViewHeader';
    _i.headerText.innerText = str;

    _i.headerText2 = document.createElement('DIV');
    _i.headerText2.className =
      'blockFloatNot fnt-r-12 darkHeaderText projectCalendarCompleteSummaryViewHeader';
    _i.headerText2.innerText = 'or ' + _i.percent.toFixed() + '%';

    _i.container.style.cursor = 'pointer';
    xAddEventListener(_i.container, platform.click, _i.projectClicked, false);

    labelContainer.appendChild(_i.headerText);
    labelContainer.appendChild(_i.headerText2);
  };
  _i.constructor();
}
