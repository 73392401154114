function ProjectStatusView_Data(project, skiputilizationcalc, ignorechildren) {
  var _i = this;

  _i.project = project;
  _i.skiputilizationcalc = skiputilizationcalc;
  _i.ignorechildren = ignorechildren;

  _i.addChildrenToTotal = function (proj, children) {
    if (!children || children.length == 0 || _i.ignorechildren) return;
    for (var i = 0; i < children.length; i++) {
      _i.calculateTotalsForProj(children[i]);

      proj.totalbudgeted += children[i].totalbudgeted;
      proj.totalbudgeteddollars += children[i].totalbudgeteddollars;
      proj.totalcompleted += children[i].totalcompleted;
      proj.totalcompleteddollars += children[i].totalcompleteddollars;
      proj.totalremain += children[i].totalremain;
      proj.totalremaindollars += children[i].totalremaindollars;
      proj.totalassigned += children[i].totalassigned;
      proj.totalassigneddollars += children[i].totalassigneddollars;
      proj.totalexpensebudget += children[i].totalexpensebudget;
      proj.totalexpenses += children[i].totalexpenses;
    }
  };

  _i.calculateTotalsForProj = function (proj) {
    if (
      gData.accountSettings &&
      gData.accountSettings.incurredhours_using == 'confirmed'
    ) {
      proj.totalcompleted =
        proj.confirmed_hours / gData.accountSettings.hours_in_workday;
      proj.totalcompleteddollars = proj.confirmed_dollars;
    } else if (
      gData.accountSettings &&
      gData.accountSettings.incurredhours_using == 'approved'
    ) {
      proj.totalcompleted =
        proj.approved_hours / gData.accountSettings.hours_in_workday;
      proj.totalcompleteddollars = proj.approved_dollars;
    } else {
      // confirmed-unconfirmed
      proj.totalcompleted =
        (proj.confirmed_hours + proj.unconfirmed_hours) /
        gData.accountSettings.hours_in_workday;
      proj.totalcompleteddollars =
        proj.confirmed_dollars + proj.unconfirmed_dollars;
    }
    proj.totalremain =
      proj.future_hours / gData.accountSettings.hours_in_workday;
    proj.totalremaindollars = proj.future_dollars;
    proj.totalassigned = proj.totalcompleted + proj.totalremain;
    proj.totalassigneddollars =
      proj.totalcompleteddollars + proj.totalremaindollars;
    proj.totalexpensebudget = 0;
    proj.totalexpenses = 0;
    proj.totalbudgeteddollars = 0;
    proj.totalbudgeted = 0;

    // Format server response to match attributes expected by statusView
    proj.totalbudgeteddollars = proj.total_budgeted_dollars;
    proj.totalbudgeted =
      proj.total_budgeted_hours / gData.accountSettings.hours_in_workday;
    proj.totalexpensebudget = proj.total_budgeted_expenses;
    proj.totalexpenses =
      gData.accountSettings.incurredexpenses_using === 'approved'
        ? proj.approved_expenses
        : proj.confirmed_expenses;
  };

  _i.constructor = function () {
    today = new Date();
    _i.calculateTotalsForProj(project);
    _i.addChildrenToTotal(project, project.children.data || project.children);
    _i.totalbudgeted = project.totalbudgeted;
    _i.totalbudgeteddollars = project.totalbudgeteddollars;
    if (!_i.totalbudgeteddollars) _i.totalbudgeteddollars = 0;
    _i.totalcompleted = project.totalcompleted;
    _i.totalcompleteddollars = project.totalcompleteddollars;
    _i.totalremain = project.totalremain;
    _i.totalremaindollars = project.totalremaindollars;
    _i.totalassigned = project.totalassigned;
    _i.totalassigneddollars = project.totalassigneddollars;
    _i.totalexpensebudget = project.totalexpensebudget;
    _i.totalexpenses = project.totalexpenses;
    var endsAt = new Date(project.ends_at);
    var startsAt = new Date(project.starts_at);
    _i.percentagecalremain = Math.floor(
      (100 * (endsAt.getTime() - today.getTime())) /
        (endsAt.getTime() - startsAt.getTime())
    );
    if (_i.percentagecalremain < 0) _i.percentagecalremain = 0;
    if (_i.percentagecalremain > 100.0) _i.percentagecalremain = 100.0;
    _i.percentagecalcomplete = 100 - _i.percentagecalremain;
  };

  _i.constructor();
}
