(function (window) {
  'use strict';

  var billingURL = APP_ENDPOINT + '/billing',
    learnMoreURL = 'https://help.smartsheet.com/articles/2480866';

  var boxHeaderText = I18n.t('lbl_custom_field_upsell'),
    messageText = I18n.t('msg_custom_field_upsell'),
    buttonText = I18n.t('lbl_upgrade_to_professional');

  var template = _.template(
    '<div class="blockFloatNot fnt-r-14">' +
      '<h2 class="tk-box__header">' +
      boxHeaderText +
      '</h2>' +
      '<div class="tk-box__message">' +
      messageText +
      ' <a href="' +
      learnMoreURL +
      `">${I18n.t('lbl_learn_more')}</a>` +
      '</div>' +
      '<button class="tk-button tk-button--blue">' +
      buttonText +
      '</button>' +
      '</div>'
  );

  window.SettingsCustomFieldsUpsell = Backbone.View.extend({
    className: 'tk-box blockFloatNot settingsMyPreferencesContentContainer',
    template: template,
    events: {
      'click .tk-button': 'navToBillingPage',
    },
    initialize: function (options) {
      this.attachTo = options.attachTo;
      this.namespacevalue = options.namespacevalue;
      this.label = options.label;
      this.label2 = options.label2;
      this.render();
    },
    render: function () {
      this.$el.html(this.template()).css({
        marginTop: 26,
        marginBottom: 50,
        padding: 40,
      });
      $('#' + this.attachTo).append(this.el);
      this.addOverlayImage();
    },
    navToBillingPage: function () {
      window.location.assign(billingURL);
    },
    addOverlayImage: function () {
      var imageURL = STATIC_IMAGE_URL + '/Custom_Fields_Upgrade_Overlay.png';
      $('#' + this.attachTo).append('<img src="' + imageURL + '">');
    },
  });
})(window);
