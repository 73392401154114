function SettingsCustomField(attach, field, parent) {
  var _i = this;
  if (!(this instanceof SettingsCustomField)) {
    return new SettingsCustomField(attach, field, parent);
  }

  _i.attach = attach;
  _i.field = field;
  _i.parent = parent;
  _i.mode = 'read';
  _i.fieldTypes = [
    {
      type: 'item',
      value: 'Text Field',
      label: I18n.t('lbl_text_field'),
      id: 1,
    },
    {
      type: 'item',
      value: 'Drop Down Menu',
      label: I18n.t('lbl_drop_down_menu'),
      id: 2,
    },
    {
      type: 'item',
      value: 'Multiple Choice Drop Down Menu',
      label: I18n.t('lbl_multiple_choice_drop_down'),
      id: 3,
    },
  ];
  _i.selectedFieldType = null;
  _i.optionsContainer = null;
  _i.isProjectField = field.namespace == 'assignables' ? true : false;

  _i.getTypeFromField = function () {
    type = _i.fieldTypes[0];
    switch (_i.field.data_type) {
      case 'selection_list':
        type = _i.fieldTypes[1];
        break;
      case 'multiple_choice_selection_list':
        type = _i.fieldTypes[2];
        break;
      default:
        break;
    }
    return type;
  };

  _i.getFieldTypeFromSelectedType = function () {
    type = 'string';
    if (_i.selectedFieldType) {
      switch (_i.selectedFieldType.id) {
        case 2:
          type = 'selection_list';
          break;
        case 3:
          type = 'multiple_choice_selection_list';
          break;

        default:
          break;
      }
    }
    return type;
  };

  _i.fieldChanged = function () {
    _i.parent.refreshList();
  };

  _i.deleteEdit = function () {
    var confirmDelete = confirmationPopup(
      I18n.t('msg_delete_custom_field_confirmation'),
      I18n.t('lbl_delete'),
      function () {
        gService.removeCustomField(_i.field, _i.fieldChanged);
      },
      null,
      _i.parent.container,
      null,
      150
    );
    confirmDelete.show(_i.deleteButton.container);
  };

  _i.cancelEdit = function () {
    if (_i.mode == 'edit') {
      _i.listMode();
      _i.parent.refreshList();
    }
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
  };

  _i.numDuplicates = function (proposedValue) {
    var arr = _i.isProjectField
      ? gData.projectCustomFields
      : gData.peopleCustomFields;

    if (!arr) {
      return 0;
    }

    var dupes = 0;

    for (var i = 0; i < arr.length; i++) {
      if (
        arr[i].name.toLowerCase().trim() == proposedValue.toLowerCase().trim()
      ) {
        dupes++;
      }
    }

    return dupes++;
  };

  _i.saveEdit = function () {
    var proposedValue = singleSpace(trim(_i.nameInput.getValue()));

    if (proposedValue == '') {
      return;
    }

    if (
      _i.numDuplicates(proposedValue) > 0 &&
      _i.nameInput.getValue() != _i.nameInput.startingValue
    ) {
      _i.nameInput.setAlert(I18n.t('msg_duplicate_value'));
      return;
    }

    var data = {};
    data.id = _i.field.id;
    data.namespace = _i.field.namespace;
    data.data_type = _i.getFieldTypeFromSelectedType();
    data.name = proposedValue;
    data.default_value = _i.defaultValueInput.getValue();
    data.description = _i.descriptionInput.getValue();
    data.apply_default_to_existing_objects = _i.applyDefaultCheckboxChecked;
    data.is_visible_on_info_page = _i.infoPageVisibilityCheckboxChecked;
    data.is_visible_as_filter = _i.filterVisibilityCheckboxChecked;
    data.is_editable_only_by_admins = _i.allowEditCheckboxChecked;

    if (_i.optionsInput) {
      data.options = _i.optionsInput.getOptions();
    } else {
      data.options = null;
    }
    if (data.id) {
      gService.updateCustomField(data, _i.fieldChanged, _i.onSaveError);
    } else {
      gService.createCustomField(data, _i.fieldChanged, _i.onSaveError);
    }
  };

  _i.onSaveError = function (jqXHR, textStatus, errorThrown) {
    var popupMessage;
    if (jqXHR && jqXHR.responseText.indexOf('INVALID_CUSTOM_FIELD_VALUE') > 1) {
      popupMessage = getErrorMessage('INVALID_CUSTOM_FIELD_VALUE');
    } else {
      popupMessage = I18n.t('msg_save_failed');
    }
    new notificationPopup(I18n.t('lbl_error'), popupMessage, 6000);
  };

  _i.containerMouseover = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainerHover';
    }
  };

  _i.containerMouseout = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    }
  };

  _i.containerClick = function (evt) {
    if (_i.mode != 'edit') {
      // Parent's callback is responsible for setting up edit mode on the Parent
      // and finally invoking it on this controller.
      _i.parent.editModeCallback(_i);
    }
  };

  _i.editMode = function () {
    var MARGIN = '10px',
      FULL_WIDTH = '560px';

    removeAllChildren(_i.valuecontainer);

    _i.valuecontainer.className = 'blockFloatNot customFieldsEditContainer';

    _i.nameInput = new TextInput(
      _i.valuecontainer.id,
      I18n.t('lbl_field_name'),
      _i.field.name || '',
      ' ',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputLarge'
    );

    _i.nameInput.labelText.style.marginTop = MARGIN;
    _i.nameInput.startingValue = _i.nameInput.getValue();

    _i.descriptionInput = new TextInput(
      _i.valuecontainer.id,
      I18n.t('lbl_field_description'),
      _i.field.description || '',
      ' ',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputLarge'
    );

    _i.descriptionInput.labelText.style.marginTop = MARGIN;
    _i.descriptionInput.startingValue = _i.descriptionInput.getValue();

    // Initial selected index is always 0 because for an existing field,
    // there's only one option - type cannot be changed. And for a new
    // field, the first value in the types list is the default.
    if (_i.field.id) {
      _i.displayFieldType();
    } else {
      _i.displayFieldTypeSelector();
    }

    _i.displayAppliesTo();

    _i.defaultValueInput = new TextInput(
      _i.valuecontainer.id,
      I18n.t('lbl_default_value'),
      _i.field.default_value || '',
      ' ',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputLarge'
    );

    _i.defaultValueInput.labelText.style.marginTop = MARGIN;
    _i.defaultValueInput.startingValue = _i.defaultValueInput.getValue();

    _i.displayFieldOptions();

    // Display the option to determine what is done with the default value at creation, but not on edit.
    if (!_i.field.id) {
      _i.displayApplyDefaultCheckbox();
    }

    _i.displayUsageOptions();

    var spacer = document.createElement('DIV');
    spacer.style.marginTop = MARGIN;
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.valuecontainer.appendChild(spacer);

    var buttonsContainer = document.createElement('DIV');
    buttonsContainer.style.marginTop = MARGIN;
    buttonsContainer.style.width = FULL_WIDTH;
    buttonsContainer.style.float = 'left';

    if (_i.field.id) {
      _i.deleteButton = new destructiveButton(
        'settingsSecondaryButtonCategory'
      );
      _i.deleteButton.setButtonText(I18n.t('lbl_delete'));
      _i.deleteButton.setCallback(_i.deleteEdit);
      _i.deleteButton.container.style.margin = 0;
      buttonsContainer.appendChild(_i.deleteButton.container);
      _i.deleteButton.container.tabIndex = 0;
    }

    _i.cancelButton = new secondaryButton('settingsSecondaryButtonCategory');
    _i.cancelButton.setButtonText(I18n.t('lbl_cancel'));
    _i.cancelButton.setCallback(_i.cancelEdit);
    _i.cancelButton.container.style.float = 'right';
    _i.cancelButton.container.style.margin = 0;
    _i.cancelButton.container.tabIndex = 0;

    _i.saveButton = new primaryButton('settingsPrimaryButtonCategory');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveEdit);
    _i.saveButton.container.style.float = 'right';
    _i.saveButton.container.style.margin = '0 0 0 ' + MARGIN;
    _i.saveButton.container.tabIndex = 0;

    _i.nameInput.inputText.focus();
    _i.mode = 'edit';

    buttonsContainer.appendChild(_i.saveButton.container);
    buttonsContainer.appendChild(_i.cancelButton.container);
    _i.valuecontainer.appendChild(buttonsContainer);
  };

  _i.displayFieldTypeSelector = function () {
    // First type is default.
    _i.selectedFieldType = _i.fieldTypes[0];
    var height = 32 * _i.fieldTypes.length;

    _i.fieldTypePopup = new selectionPopup(
      260,
      height,
      'mainCon',
      _i.fieldTypes,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onFieldTypeChanged,
      false /*multiSelect*/,
      null /*blurCallback*/,
      'nw',
      null /*prefferedDirections*/,
      true /*noautorotate*/
    );

    _i.fieldTypePopup.popupContainer.autoOrient = false;
    _i.fieldTypePopup.popupContainer.setDirection('ne');
    _i.fieldTypePopup.isShown = false;

    _i.fieldTypeInput = new PopUpTextInput(
      _i.valuecontainer.id,
      I18n.t('lbl_field_type'),
      0,
      'blockFloatNot customFieldTypePopupInputContainer',
      'blockFloatNot fnt-r-14 customFieldTypePopupInputButton',
      'blockFloatNot fnt-r-13 customFieldTypeLabel',
      'blockFloatNot fnt-r-15 customFieldTypePopupInput',
      _i.fieldTypes,
      _i.fieldTypePopup
    );
  };

  _i.displayFieldType = function () {
    var MARGIN = '10px';

    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-13 customFieldTypeLabel';
    label.innerHTML = I18n.t('lbl_field_type');
    label.style.marginTop = MARGIN;
    _i.valuecontainer.appendChild(label);

    _i.selectedFieldType = _i.getTypeFromField();
    var text = document.createElement('DIV');
    text.className = 'blockFloatNot fnt-r-15 customFieldTypeText';
    text.innerText = _i.selectedFieldType.value;
    _i.valuecontainer.appendChild(text);
  };

  _i.displayAppliesTo = function () {
    var MARGIN = '10px';

    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-13 customFieldTypeLabel';
    label.innerHTML = I18n.t('lbl_applies_to');
    label.style.marginTop = MARGIN;
    _i.valuecontainer.appendChild(label);

    var appliesTo = _i.isProjectField
      ? I18n.t('lbl_projects')
      : I18n.t('lbl_people');
    var text = document.createElement('DIV');
    text.className = 'blockFloatNot fnt-r-15 customFieldTypeText';
    text.innerText = appliesTo;
    _i.valuecontainer.appendChild(text);
  };

  _i.onFieldTypeChanged = function (obj) {
    _i.selectedFieldType = obj[0];
    _i.fieldTypeInput.setValue(_i.matchFieldTypeToValue(_i.selectedFieldType));
    _i.displayFieldOptions();
  };

  _i.displayUsageOptions = function () {
    var MARGIN = '15px';

    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-b-18';
    label.innerHTML = I18n.t('msg_custom_field_usage');
    label.style.marginTop = MARGIN;
    _i.valuecontainer.appendChild(label);

    _i.displayInfoPageVisibilityCheckbox();
    _i.displayUseAsFilterCheckbox();
    if (!_i.isProjectField) {
      _i.displayAllowEditCheckbox();
    }
  };

  _i.displayInfoPageVisibilityCheckbox = function () {
    _i.infoPageVisibilityCheckboxChecked = _i.field.id
      ? _i.field.is_visible_on_info_page
      : true;

    var labelSpan = document.createElement('span');
    labelSpan.innerText = _i.isProjectField
      ? I18n.t('lbl_show_on_project_pages')
      : I18n.t('lbl_show_on_user_page');
    _i.infoPageVisibilityCheckbox = new SelectionCheckbox(
      _i.valuecontainer.id,
      labelSpan,
      _i.infoPageVisibilityCheckboxChecked,
      'blockFloatNot settingsPageCheckboxContainer',
      'fnt-r-14 settingsPrefTag',
      'fnt-r-14 settingsPrefCheckbox'
    );
    _i.infoPageVisibilityCheckbox.setCallback(function () {
      _i.infoPageVisibilityCheckboxChecked =
        !_i.infoPageVisibilityCheckboxChecked;
      _i.infoPageVisibilityCheckbox.setEnabled(
        _i.infoPageVisibilityCheckboxChecked
      );
    });
  };

  _i.displayUseAsFilterCheckbox = function () {
    _i.filterVisibilityCheckboxChecked = _i.field.id
      ? _i.field.is_visible_as_filter
      : false;

    var labelSpan = document.createElement('span');
    labelSpan.innerText = _i.isProjectField
      ? I18n.t('lbl_use_project_filter_option')
      : I18n.t('lbl_people_filter_option');
    _i.filterVisibilityCheckbox = new SelectionCheckbox(
      _i.valuecontainer.id,
      labelSpan,
      _i.filterVisibilityCheckboxChecked,
      'blockFloatNot settingsPageCheckboxContainer',
      'fnt-r-14 settingsPrefTag',
      'fnt-r-14 settingsPrefCheckbox'
    );
    _i.filterVisibilityCheckbox.setCallback(function () {
      _i.filterVisibilityCheckboxChecked = !_i.filterVisibilityCheckboxChecked;
      _i.filterVisibilityCheckbox.setEnabled(
        _i.filterVisibilityCheckboxChecked
      );
    });
  };

  _i.displayApplyDefaultCheckbox = function () {
    _i.applyDefaultCheckboxChecked = false;

    var labelSpan = document.createElement('span');
    labelSpan.innerText = _i.isProjectField
      ? I18n.t('lbl_apply_default_to_projects')
      : I18n.t('msg_apply_default_to_users');
    _i.applyDefaultCheckbox = new SelectionCheckbox(
      _i.valuecontainer.id,
      labelSpan,
      _i.applyDefaultCheckboxChecked,
      'blockFloatNot settingsPageCheckboxContainer',
      'fnt-r-14 settingsPrefTag',
      'fnt-r-14 settingsPrefCheckbox'
    );
    _i.applyDefaultCheckbox.setCallback(function () {
      _i.applyDefaultCheckboxChecked = !_i.applyDefaultCheckboxChecked;
      _i.applyDefaultCheckbox.setEnabled(_i.applyDefaultCheckboxChecked);
    });
  };

  _i.displayAllowEditCheckbox = function () {
    _i.allowEditCheckboxChecked = _i.field.id
      ? _i.field.is_editable_only_by_admins
      : false;

    var labelSpan = document.createElement('span');
    labelSpan.innerText = I18n.t('lbl_allow_admin_to_edit');
    _i.allowEditCheckbox = new SelectionCheckbox(
      _i.valuecontainer.id,
      labelSpan,
      _i.allowEditCheckboxChecked,
      'blockFloatNot settingsPageCheckboxContainer',
      'fnt-r-14 settingsPrefTag',
      'fnt-r-14 settingsPrefCheckbox'
    );
    _i.allowEditCheckbox.setCallback(function () {
      _i.allowEditCheckboxChecked = !_i.allowEditCheckboxChecked;
      _i.allowEditCheckbox.setEnabled(_i.allowEditCheckboxChecked);
    });
  };

  _i.displayFieldOptions = function () {
    if (_i.optionsContainer) {
      _i.valuecontainer.removeChild(_i.optionsContainer);
    }
    _i.optionsContainer = null;

    if (
      _i.selectedFieldType != null &&
      (_i.selectedFieldType.id == 2 || _i.selectedFieldType.id == 3)
    ) {
      _i.optionsContainer = document.createElement('DIV');
      _i.optionsContainer.className =
        'blockFloatNot customFieldsOptionsContainer';
      _i.optionsContainer.id = 'customFieldOptionsContainer' + getGuid();
      _i.valuecontainer.insertBefore(
        _i.optionsContainer,
        _i.defaultValueInput.inputContainer
      );

      _i.optionsInput = new CustomFieldOptionsEditView(
        I18n.t('lbl_field_options')
      );
      _i.optionsInput.show(
        _i.optionsContainer,
        _i.field.options ? _i.field.options.slice(0) : []
      ); // Clone - field itself should not be modified until user hits Save.

      _i.optionsInput.startingValue = '';
    }
  };

  _i.matchFieldTypeToValue = function (obj) {
    for (i = 0; i < _i.fieldTypes.length; i++) {
      if (_i.fieldTypes[i].id == obj.id) return i;
    }
    return 0;
  };

  _i.listMode = function () {
    removeAllChildren(_i.valuecontainer);
    var name = document.createElement('DIV');
    name.className = 'blockFloat fnt-r-14 ellipsis settingsCategoryName';
    _i.valuecontainer.appendChild(name);
    name.innerText = _i.field.name || '';

    _i.mode = 'list';
  };

  _i.constructor = function () {
    _i.valuecontainer = document.createElement('DIV');
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    _i.valuecontainer.id = 'settingsUserContainer' + getGuid();
    _i.attach.appendChild(_i.valuecontainer);
    xAddEventListener(
      _i.valuecontainer,
      'mouseover',
      _i.containerMouseover,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      'mouseout',
      _i.containerMouseout,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      platform.click,
      _i.containerClick,
      true
    );
    _i.listMode();
  };
  _i.constructor();
}
