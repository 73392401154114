function SettingsStatusOption(attach, statusOption, parent) {
  'use strict';
  var _i = this;
  if (!(this instanceof SettingsStatusOption)) {
    return new SettingsStatusOption(attach, statusOption, parent);
  }

  var arbitraryOrderIncrement = 10;

  _i.attach = attach;
  _i.statusOption = statusOption;
  _i.parent = parent;
  _i.mode = 'read';

  _i.statusOptionChanged = function () {
    _i.statusOption.tempOrder = null;
    _i.parent.refreshList();
  };

  _i.deleteEdit = function () {
    var confirmDelete = confirmationPopup(
      I18n.t('lbl_delete') + ' ' + _i.statusOption.label + '?',
      I18n.t('lbl_delete'),
      function () {
        gService.deleteStatusOption(_i.statusOption, _i.statusOptionChanged);
      },
      null,
      _i.parent.container
    );
    confirmDelete.show(_i.deleteButton.container);
  };

  _i.cancelEdit = function () {
    if (_i.mode == 'edit') {
      _i.listMode();
    }
    if (_.isNumber(_i.statusOption.tempOrder)) {
      _i.statusOption.tempOrder = null;
      _i.parent.refreshList();
    }
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
  };

  _i.isDuplicate = function (color, label, stage) {
    return _i.parent.statusOptions.some(function (so) {
      if (so.id === _i.statusOption.id) return false;
      return so.color === color && so.label === label && so.stage === stage;
    });
  };

  _i.saveEdit = function () {
    var proposedColor = _i.selectedColor || _i.statusOption.color;
    var proposedLabel = singleSpace(trim(_i.labelTextInput.getValue()));
    var proposedOrder = _.isNumber(_i.statusOption.tempOrder)
      ? _i.statusOption.tempOrder
      : _i.statusOption.order;

    if (proposedLabel === '') return;

    if (_i.isDuplicate(proposedColor, proposedLabel, _i.statusOption.stage)) {
      _i.labelTextInput.setAlert(I18n.t('msg_duplicate_value'));
      return;
    }

    var data = {};
    data.id = _i.statusOption.id;
    data.label = proposedLabel;
    data.color = proposedColor;
    data.order = proposedOrder;
    gService.updateStatusOption(data, _i.statusOptionChanged);
  };

  _i.containerMouseover = function (evt) {
    if (_i.mode !== 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainerHover';
    }
  };

  _i.containerMouseout = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    }
  };

  _i.containerClick = function (evt) {
    if (_i.canEdit()) {
      _i.parent.editModeCallback();
      _i.editMode();
      _i.valuecontainer.style.overflow = 'visible';
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainerEdit';
    }
  };

  _i.onColorChanged = function (newColor) {
    _i.selectedColor = newColor;
    _i.colorInput.hide();
  };

  _i.canEdit = function () {
    // NOTE SettingsStatusOptions keeps track of instances of SettingsStatusOption which tracks mode
    return (
      _i.mode != 'edit' &&
      !_.find(_i.parent.statusOptionControllers, function (s) {
        return s.mode == 'edit';
      })
    );
  };

  _i.canMoveUp = function () {
    var i = _i.parent.statusOptions.indexOf(_i.statusOption);
    var above = _i.parent.statusOptions[i - 1];
    return above && above.stage === _i.statusOption.stage;
  };

  _i.canMoveDown = function () {
    var i = _i.parent.statusOptions.indexOf(_i.statusOption);
    var below = _i.parent.statusOptions[i + 1];
    return below && below.stage === _i.statusOption.stage;
  };

  _i.onArrowClick = function (direction) {
    var currentOrder = _.isNumber(_i.statusOption.tempOrder)
      ? _i.statusOption.tempOrder
      : _i.statusOption.order;
    var stage = _i.statusOption.stage;
    var otherOptionsInStage = _.filter(_i.parent.statusOptions, function (so) {
      return so !== _i.statusOption && so.stage === stage;
    });
    if (otherOptionsInStage.length === 0) return;
    var i = -1;
    while (++i < otherOptionsInStage.length) {
      if (otherOptionsInStage[i].order > currentOrder) break;
    }
    var above =
      direction === 'up' ? otherOptionsInStage[i - 2] : otherOptionsInStage[i];
    var below =
      direction === 'up'
        ? otherOptionsInStage[i - 1]
        : otherOptionsInStage[i + 1];
    if (above && below) {
      _i.statusOption.tempOrder = (above.order + below.order) / 2;
    } else if (below && direction === 'up') {
      _i.statusOption.tempOrder = below.order - arbitraryOrderIncrement;
    } else if (above && direction === 'down') {
      _i.statusOption.tempOrder = above.order + arbitraryOrderIncrement;
    }
    _i.parent.refreshList();
  };

  _i.onUpArrowClick = function () {
    _i.onArrowClick('up');
  };

  _i.onDownArrowClick = function () {
    _i.onArrowClick('down');
  };

  _i.renderColorInput = function () {
    var colorInputOptions = {
      removeBorder: true,
      removeLabel: true,
      directions: ['ec', 'wc', 'en', 'wn', 'es', 'ws'],
      viewport: 'mainCon',
    };
    var colorMap = _i.parent.getColorMap();
    _i.colorInput = new ColorPicker(
      42,
      _.keys(colorMap).length * 30 + 10,
      _i.statusOption.color,
      colorMap,
      { colorSelectedCallback: _i.onColorChanged },
      colorInputOptions
    );
    _i.colorInput.show(_i.valuecontainer);
  };

  _i.editMode = function () {
    removeAllChildren(_i.valuecontainer);
    _i.valuecontainer.style.paddingTop = '8px';
    _i.valuecontainer.style.paddingBottom = '9px';
    _i.renderColorInput();
    _i.labelTextInput = new TextInput(
      _i.valuecontainer.id,
      null,
      _i.statusOption.label != '' ? _i.statusOption.label : '- empty -',
      'blockFloat',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall workStatusInput',
      152
    );

    _i.labelTextInput.startingValue = _i.labelTextInput.getValue();
    _i.labelTextInput.inputContainer.style.marginLeft = '12px';

    _i.deleteButton = new destructiveButton('settingsSecondaryButtonCategory');
    _i.deleteButton.setButtonText(I18n.t('lbl_delete'));
    _i.deleteButton.setCallback(_i.deleteEdit);
    _i.valuecontainer.appendChild(_i.deleteButton.container);
    _i.deleteButton.container.tabIndex = 0;

    _i.saveButton = new primaryButton('settingsSecondaryButtonCategory');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveEdit);
    _i.valuecontainer.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;

    _i.cancelButton = new spritesButton(
      'settingsCancelButtonCategory',
      null,
      compSpritesButtonsIcons.closeButtonRest,
      compSpritesButtonsIcons.closeButtonHover,
      compSpritesButtonsIcons.closeButtonDown
    );
    _i.cancelButton.setButtonText('');
    _i.cancelButton.setCallback(_i.cancelEdit);
    _i.valuecontainer.appendChild(_i.cancelButton.container);

    var arrowWidth = 11;
    var $arrowContainer = $('<div></div>').css({
      float: 'right',
      width: arrowWidth + 'px',
      height: '20px',
      marginTop: '-2px',
    });
    var $upArrow = $(
      '<svg width="' +
        arrowWidth +
        '" viewBox="0 0 12 12"><title>icon_arrow-up</title><polygon points="6 2 0 8 12 8 6 2"/></svg>'
    );
    if (_i.canMoveUp()) {
      $upArrow.on('click', _i.onUpArrowClick).css({ cursor: 'pointer' });
    } else {
      $upArrow.css({ opacity: 0.25 });
    }
    $arrowContainer.append($upArrow);
    var $downArrow = $(
      '<svg width="' +
        arrowWidth +
        '" viewBox="0 0 12 12"><title>icon_arrow-down</title><polygon points="6 9 12 3 0 3 6 9"/></svg>'
    );
    if (_i.canMoveDown()) {
      $downArrow.on('click', _i.onDownArrowClick).css({ cursor: 'pointer' });
    } else {
      $downArrow.css({ opacity: 0.25 });
    }
    $arrowContainer.append($downArrow);
    _i.valuecontainer.appendChild($arrowContainer[0]);

    _i.mode = 'edit';
  };

  _i.listMode = function () {
    removeAllChildren(_i.valuecontainer);
    _i.valuecontainer.style.paddingTop = null;
    _i.valuecontainer.style.paddingBottom = null;
    var color = document.createElement('div');
    color.className = 'blockFloat resourceColorElement';
    color.style.backgroundColor =
      _i.parent.getColorMap()[_i.statusOption.color].rgb;
    _i.valuecontainer.appendChild(color);

    var name = document.createElement('div');
    name.className = 'blockFloat fnt-r-14 ellipsis settingsCategoryName';
    name.style.marginTop = '7px';
    name.style.marginLeft = '20px';
    _i.valuecontainer.appendChild(name);
    name.innerText =
      _i.statusOption.label != '' ? _i.statusOption.label : '- empty -';

    _i.mode = 'list';
  };

  _i.constructor = function () {
    _i.valuecontainer = document.createElement('DIV');
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    _i.valuecontainer.id = 'settingsUserContainer' + getGuid();
    _i.attach.appendChild(_i.valuecontainer);
    xAddEventListener(
      _i.valuecontainer,
      'mouseover',
      _i.containerMouseover,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      'mouseout',
      _i.containerMouseout,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      platform.click,
      _i.containerClick,
      true
    );
    _i.listMode();
  };
  _i.constructor();
}
