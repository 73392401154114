function ActivityView(attach, user, container_style, project) {
  var _i = this;
  if (!(this instanceof ActivityView)) {
    return new ActivityView(attach, user, container_style, project);
  }

  _i.attachTo = xGetElementById(attach);
  _i.project = project;
  _i.user = user;
  _i.feed = {};
  _i.showOldFeed = false;
  _i.showNewFeed = false;
  _i.filterVal = 0;
  _i.myCoworkers = [];
  _i.container = null;
  _i.container_style = container_style;

  // callback for when the filter value changes via pop-up
  _i.onFilterChanged = function (obj) {
    _i.filterValSpan.innerText = obj[0].label;
    _i.filterVal = obj[0].value;
    _i.buildDisplay();
  };

  _i.showFilterSelectionPopup = function () {
    _i.filterPopup.showAtPoint(
      xPageX(_i.elementDropDownSelect) - 40,
      xPageY(_i.elementDropDownSelect) + 7,
      0,
      0
    );
    return false;
  };

  _i.activityLabelClicked = function () {
    document.getElementById('mainCon').style.position = 'absolute';
  };

  _i.testHistoryFeedItemInFilter = function (feeditem) {
    if (feeditem.history_log != null && feeditem.history_log.log_user == null)
      return false;
    if (feeditem.history_log.log_action == 'create') {
      if (
        feeditem.history_log.log_objectOfAction == 'project' &&
        _i.isMyProject(feeditem.history_log.log_data.project)
      ) {
        return true;
      } else if (feeditem.history_log.log_objectOfAction == 'assignment') {
        var proj = null;
        if (feeditem.history_log.log_assignment) {
          for (i = 0; i < gData.assignments.length; i++) {
            if (
              gData.assignments[i].id ==
              feeditem.history_log.log_objectOfActionId
            ) {
              assignment = gData.assignments[i];
              break;
            }
          }
          if (assignment) {
            proj = gData.getAssignableOrChild(assignment.assignable_id, false);
          }
        }
        if (proj && _i.isMyProject(proj)) {
          return true;
        }
      } else if (feeditem.history_log.log_objectOfAction == 'status') {
        var status =
          gData.statusesByUserId[feeditem.history_log.log_data.status.user_id];
        if (status && status.id == feeditem.history_log.log_data.status.id) {
          return false;
        }
        return _i.isUserIdCoworker(
          feeditem.history_log.log_data.status.user_id
        );
      }
    } else if (feeditem.history_log.log_action == 'update') {
      if (feeditem.history_log.log_objectOfAction == 'project') {
        var proj = gData.getAssignableOrChild(
          feeditem.history_log.log_objectOfActionId,
          false
        );
        if (proj && _i.isMyProject(proj)) {
          return true;
        }
      } else if (feeditem.history_log.log_objectOfAction == 'assignment') {
        var assignment = null;
        var localuser = null;
        var proj = null;
        for (i = 0; i < gData.assignments.length; i++) {
          if (
            gData.assignments[i].id == feeditem.history_log.log_objectOfActionId
          ) {
            assignment = gData.assignments[i];
            break;
          }
        }
        if (assignment) {
          proj = gData.getAssignableOrChild(assignment.assignable_id, false);
          if (proj && _i.isMyProject(proj)) {
            return true;
          }
        }
      }
    } else if (feeditem.history_log.log_action == 'destroy') {
      if (
        feeditem.history_log.log_assignment &&
        _i.isMyProject(feeditem.history_log.log_assignment)
      )
        return true;
    }
    return false;
  };

  _i.testStatusFeedItemInFilter = function (feeditem) {
    return _i.isUserIdCoworker(feeditem.status.user_id);
  };

  _i.testForFeedItemInFilter = function (feeditem) {
    if (!feeditem) return false;
    if (feeditem.status != null) {
      return _i.testStatusFeedItemInFilter(feeditem);
    } else if (feeditem.history_log != null) {
      return _i.testHistoryFeedItemInFilter(feeditem);
    }
  };

  _i.buildDisplay = function () {
    removeAllChildren(_i.feeditemscontainer);
    _i.setOldFeed(true);
  };

  _i.isUserIdCoworker = function (id) {
    for (var i = 0; i < _i.myCoworkers.length; i++) {
      if (id == _i.myCoworkers[i].id) return true;
    }
    return false;
  };

  _i.isMyProject = function (proj) {
    for (var i = 0; i < _i.myProjects.length; i++) {
      if (
        proj.id == _i.myProjects[i].id ||
        proj.parent_id == _i.myProjects[i].id ||
        proj.id == _i.myProjects[i].parent_id ||
        (proj.parent_id != null && proj.parent_id == _i.myProjects[i].parent_id)
      ) {
        return true;
      }
    }
    return false;
  };

  _i.isUserCoworker = function (user) {
    for (var i = 0; i < _i.myCoworkers.length; i++) {
      if (user.id == _i.myCoworkers[i].id) return true;
    }
    return false;
  };

  _i.addUsersAsCoworkers = function (users) {
    if (users == null || users.length == 0) return;
    for (var j = 0; j < users.length; j++) {
      if (!_i.isUserCoworker(users[j])) {
        _i.myCoworkers.push(users[j]);
      }
    }
  };

  _i.getCoWorkers = function () {
    // consider myself a coworker
    if (_i.user) {
      _i.myCoworkers.push(_i.user);
    }
    for (var i = 0; i < _i.myProjects.length; i++) {
      _i.addUsersAsCoworkers(_i.myProjects[i].getParticipants());
    }
  };

  _i.appendOldFeed = function (container) {
    var itemcount = 0;
    for (var i = 0; i < gData.feeditems.length; i++) {
      if (
        settingsFilter[_i.filterVal].value == 1 ||
        _i.testForFeedItemInFilter(gData.feeditems[i])
      ) {
        FeedItemView(container, gData.feeditems[i]);
        itemcount++;
      }
    }
  };

  _i.setOldFeed = function (hasEmptyText) {
    var itemcount = 0;
    for (var i = 0; i < gData.feeditems.length; i++) {
      if (
        settingsFilter[_i.filterVal].value == 1 ||
        _i.testForFeedItemInFilter(gData.feeditems[i])
      ) {
        FeedItemView(_i.feeditemscontainer, gData.feeditems[i]);
        itemcount++;
      }
    }
    if (
      hasEmptyText &&
      itemcount == 0 &&
      _i.myProjects.length == 0 &&
      settingsFilter[_i.filterVal].value != 1
    ) {
      var spacer = document.createElement('DIV');
      spacer.className = 'spacer1';

      _i.feeditemscontainer.appendChild(spacer);
      var noitems = document.createElement('DIV');
      noitems.className = 'blockFloatNot fnt-r-12';
      noitems.innerHTML = I18n.t('msg_no_assigned_projects');
      _i.feeditemscontainer.appendChild(noitems);
    }
  };

  _i.loadFeedItems = function (obj) {
    // load data for filter
    if (_i.user) {
      _i.myProjects = _i.user.getCurrentProjects();
    } else if (_i.project) {
      _i.myProjects = [];
      _i.myProjects.push(_i.project);
    }
    _i.getCoWorkers();
    _i.buildDisplay();
  };

  _i.leaveTypeFinished = function (obj) {
    var currentTime = new Date();
    var yearago = new Date(currentTime.getTime() - 1000 * 60 * 60 * 24 * 365);
    var month = yearago.getMonth() + 1;
    var day = yearago.getDate();
    var year = yearago.getFullYear();
    var startdate = year + '-' + month + '-' + day;
    if (_i.user) {
      gService.getHistoryLogs(_i.loadFeedItems, startdate, null);
    } else if (_i.project) {
      gService.getHistoryLogs(_i.loadFeedItems, startdate, null, _i.project.id);
    } else {
      _i.loadFeedItems(obj);
    }
  };

  _i.setNewNotifications = function (count) {
    if (count == 0) {
      _i.notificationIcon.style.display = 'none';
      return;
    }
    _i.notificationcount = count;
    _i.notificationIcon.innerText = count < 100 ? count : '+';
    _i.notificationIcon.style.display = 'block';
  };

  _i.buildFeedHeader = function () {
    if (_i.user && _i.showfeed) {
      _i.nestedDiv = document.createElement('DIV');
      _i.nestedDiv.className = 'blockFloatNot';
      _i.nestedDiv.id = 'activityViewPopupView';
      if (!_i.showNewFeed) {
        _i.container.appendChild(_i.nestedDiv);
      }

      xAddEventListener(
        _i.nestedDiv,
        platform.click,
        _i.showFilterSelectionPopup,
        false
      );

      _i.filterPopup = new selectionPopup(
        150,
        65,
        'mainCon',
        settingsFilter,
        compSpritesButtonsIcons.wideDialogSpecs,
        _i.onFilterChanged,
        false,
        null /*blurCallback*/,
        'nw',
        null /*prefferedDirections*/,
        true /*noautorotate*/
      );

      _i.filterPopup.popupContainer.autoOrient = false;
      _i.filterPopup.popupContainer.setDirection('ne');
      _i.filterPopup.isShown = false;

      _i.filterButton = document.createElement('DIV');
      _i.filterButton.className = 'blockFloat activityViewFilterButton';
      _i.nestedDiv.appendChild(_i.filterButton);

      var labelSpan = document.createElement('SPAN');
      labelSpan.className = 'fnt-r-13 activityViewButtonShow';
      labelSpan.innerHTML = I18n.t('lbl_show_colon');
      _i.filterButton.appendChild(labelSpan);

      _i.filterValSpan = document.createElement('SPAN');
      _i.filterValSpan.className = 'fnt-r-13 activityViewButtonShow';
      _i.filterValSpan.innerText = settingsFilter[_i.filterVal].label;
      _i.filterButton.appendChild(_i.filterValSpan);

      _i.elementDropDownSelect = document.createElement('DIV');
      _i.elementDropDownSelect.className = 'blockFloat activityDropDownSelect';
      _i.elementDropDownSelect.appendChild(
        getSprite(compSpritesButtonsIcons.downArrowMenu)
      );
      _i.elementDropDownSelect.id = _i.guid + 'elementDropDownSelect';
      _i.nestedDiv.appendChild(_i.elementDropDownSelect);
    }
  };

  _i.buildHeader = function () {
    _i.header = document.createElement('DIV');
    _i.header.className = 'blockFloatNot activityHeaderContainer';
    _i.container.appendChild(_i.header);

    _i.headerText = document.createElement('DIV');
    _i.headerText.className = 'blockFloat';
    _i.header.appendChild(_i.headerText);
    if (_i.showfeed) {
      _i.activitylabel = document.createElement('DIV');
      _i.activitylabel.className = 'blockFloat fnt-b-20';
      _i.activitylabel.innerHTML = I18n.t('lbl_activity');
      xAddEventListener(
        _i.activitylabel,
        platform.click,
        _i.activityLabelClicked,
        false
      );
      _i.headerText.appendChild(_i.activitylabel);
      _i.activitylabel.style.width = xWidth(_i.activitylabel) + 1 + 'px';
    }

    _i.notificationIcon = document.createElement('DIV');
    _i.notificationIcon.className =
      'blockFloat fnt-b-12 taskThumbnailFrameImageContainerActivity';
    _i.headerText.appendChild(_i.notificationIcon);
    getSprite(compSpritesButtonsIcons.notificationIcon, _i.notificationIcon);
    _i.notificationIcon.style.display = 'none';
    _i.buildFeedHeader();
  };

  _i.constructor = function () {
    _i.showfeed = gData.accountSettings.canUserReadModel(
      gData.getMe(),
      'Feed',
      null
    );
    _i.container = document.createElement('DIV');
    _i.container.className = _i.container_style;
    _i.container.id = 'activityViewContainer';
    _i.attachTo.appendChild(_i.container);
    _i.buildHeader();

    var currentLocale = navigator.language;
    var supportedLanguages = ['es', 'fr', 'de', 'it', 'ja', 'pt', 'ru'];

    if (supportedLanguages.some((locale) => currentLocale.includes(locale))) {
      _i.notification = document.createElement('DIV');
      _i.notification.className = 'blockFloatNot translationNotification';
      _i.notification.innerHTML =
        "<h1 style='font-size: 14px'>" +
        I18n.t('msg_translation_not_available') +
        '</h1>' +
        '<p>' +
        I18n.t('msg_activity_feed_cannot_be_translated') +
        '</p>';
      _i.container.appendChild(_i.notification);
    }

    _i.feeditemscontainer = document.createElement('DIV');
    _i.feeditemscontainer.id = 'feeditemscontainer';
    _i.feeditemscontainer.className = 'blockFloatNot';
    _i.container.appendChild(_i.feeditemscontainer);

    _i.feeditemscontainer.style.display = 'block';
    if (_i.nestedDiv) _i.nestedDiv.style.display = 'block';

    if (_i.user) {
      _i.leaveTypeFinished();
    } else {
      gService.getLeaveTypes(_i.leaveTypeFinished);
    }
  };

  // Mixin new activity feed interfaces.
  // IMPORTANT: Must be called immediately before constructor is ran.
  ActivityFeedMixin(_i);
  _i.constructor();
}
