function ProjectNotificationsControl(attachTo, parent, projectId) {
  var _i = this;

  if (!(this instanceof ProjectNotificationsControl)) {
    return new ProjectNotificationsControl(attachTo, parent, projectId);
  }

  // params
  _i.attachTo = xGetElementById(attachTo, parent);
  _i.projectId = projectId;
  _i.projectNotificationsEnabled = true;

  _i.toggleCallbackEnableNotifications = function () {
    _i.projectNotificationsEnabled = true;
    _i.selectionNotificationsEnabled.setEnabled(true);
    _i.selectionNotificationsDisabled.setEnabled(false);
  };

  _i.toggleCallbackDisableNotifications = function () {
    _i.projectNotificationsEnabled = false;
    _i.selectionNotificationsEnabled.setEnabled(false);
    _i.selectionNotificationsDisabled.setEnabled(true);
  };

  _i.buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-14 editprojectSpan';
    ret.innerText = str;
    return ret;
  };

  _i.buildSelections = function () {
    _i.selectionNotificationsEnabled = new SelectionToggle(
      _i.headercontainer,
      _i.buildPlainTextSpan(I18n.t('lbl_enable_email_notifications')),
      _i.projectNotificationsEnabled,
      'blockFloatNot editprojectSelectorItemSmallBudget',
      'fnt-r-15 editprojectSelectorText2 ',
      'editprojectSelectorIcon'
    );
    _i.selectionNotificationsEnabled.setCallback(
      _i.toggleCallbackEnableNotifications
    );

    _i.selectionNotificationsDisabled = new SelectionToggle(
      _i.headercontainer,
      _i.buildPlainTextSpan(I18n.t('lbl_mute_email_notifications')),
      !_i.projectNotificationsEnabled,
      'blockFloatNot editprojectSelectorItemSmallNoMarginBudget',
      'fnt-r-15 editprojectSelectorText2 ',
      'editprojectSelectorIcon'
    );
    _i.selectionNotificationsDisabled.setCallback(
      _i.toggleCallbackDisableNotifications
    );
  };

  var constructor = (function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot expensesControlContentContainer';
    _i.attachTo.appendChild(_i.container);

    var label = document.createElement('H3');
    label.className =
      'blockFloatNot fnt-b-14 expensesControlHeaderContainer editProjectHeaders';
    label.innerHTML = I18n.t('lbl_notifications');
    _i.container.appendChild(label);

    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 expenseHeaderLabel';
    label.innerHTML = I18n.t('msg_notification_setting_description');
    _i.container.appendChild(label);

    _i.headercontainer = document.createElement('DIV');
    _i.headercontainer.className = 'blockFloatNot';
    _i.container.appendChild(_i.headercontainer);

    if (_i.projectId) {
      gService.getProjectNotificationSettings(
        _i.projectId,
        function (settings) {
          _i.projectNotificationsEnabled =
            settings && settings.notifications_enabled == true;
          _i.buildSelections();
        }
      );
    } else {
      _i.buildSelections();
    }
  })();
}

ProjectNotificationsControl.prototype.getNotificationsEnabled = function () {
  return this.projectNotificationsEnabled;
};
