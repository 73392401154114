function CustomFieldsView(name) {
  var _i = this,
    _elId = 'customfieldsview_' + _.uniqueId(),
    _t =
      '<div id="<%= id %>" class="blockFloatNot fnt-r-13 customFieldsContainer">' +
      '<div id="<%= id %>_customFieldsList" class="fnt-r-13" style=""></div>' +
      '</div>',
    _$el = $(_.template(_t, { id: _elId, name: name })),
    _el = _$el[0],
    _$customFieldsList = $('#' + _elId + '_customFieldsList', _$el),
    _customFieldsList = _$customFieldsList[0],
    _fieldTemplate =
      '<div class="fnt-r-13" style="">' +
      '<div class="nameText customFieldName" title="<%= name %>"><%= name %></div>' +
      '<div class="valueText customFieldValue" title="value"><%= value %></div>' +
      '<br />' +
      '</div>';
  _i.show = function (container, listOfFieldsWithValues) {
    container.appendChild(_el);
    if (listOfFieldsWithValues) {
      listOfFieldsWithValues.forEach(function (fv) {
        var val = '';
        if (fv[1] && fv[1].length > 0) {
          for (var i = 0; i < fv[1].length && fv[1][i]; i++) {
            val += parseForURLs(fv[1][i]);
            if (i < fv[1].length - 1) {
              val += ', ';
            }
          }
        }

        // Don't show fields that have no value e.g.if default value is NULL.
        if (val != '') {
          $t = $(_.template(_fieldTemplate, { name: fv[0], value: val }));
          _customFieldsList.appendChild($t[0]);
        }
      });
    }
  };
}
