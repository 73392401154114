/* readonly display of availability information 
 *
 * usage:

     var a = new availabilityInfo("test", [
              { starts_at: null, ends_at: "04-12-2013", days: [0,8,8,8,8,8,0] },
              { starts_at: "04-13-2013", ends_at: "04-27-2013", days: [0,8,8,4,4,0,0] },
              { starts_at: "04-28-2013", ends_at: null, days: [0,8,8,8,8,8,0] }
            ]);

 */
function availabilityInfo(id, data) {
  var _i = this,
    _t_outer =
      '<div id="<%= id %>" class="availability-info fnt-r-13">' +
      '<div class="header">' +
      '<div class="column first">&nbsp;</div>' +
      `<div class="column">${I18n.t('lbl_sunday_abbr_uc')}</div>` +
      `<div class="column">${I18n.t('lbl_monday_abbr_uc')}</div>` +
      `<div class="column">${I18n.t('lbl_tuesday_abbr_uc')}</div>` +
      `<div class="column">${I18n.t('lbl_wednesday_abbr_uc')}</div>` +
      `<div class="column">${I18n.t('lbl_thursday_abbr_uc')}</div>` +
      `<div class="column">${I18n.t('lbl_friday_abbr_uc')}</div>` +
      `<div class="column">${I18n.t('lbl_saturday_abbr_uc')}</div>` +
      '</div>' +
      '<%= content %>' +
      '</div>',
    _t_row =
      '<div class="row">' +
      '<div class="column first"><%= label %></div>' +
      '<div class="column"><%= days[0] ? days[0] : \'&nbsp;\' %></div>' +
      '<div class="column"><%= days[1] ? days[1] : \'&nbsp;\' %></div>' +
      '<div class="column"><%= days[2] ? days[2] : \'&nbsp;\' %></div>' +
      '<div class="column"><%= days[3] ? days[3] : \'&nbsp;\' %></div>' +
      '<div class="column"><%= days[4] ? days[4] : \'&nbsp;\' %></div>' +
      '<div class="column"><%= days[5] ? days[5] : \'&nbsp;\' %></div>' +
      '<div class="column"><%= days[6] ? days[6] : \'&nbsp;\' %></div>' +
      '</div>',
    _t_content = '',
    _$el,
    _el;

  _i.getElement = function () {
    return _el;
  };

  _i.getHeight = function () {
    // rows and header are 28px tall each
    return data.length * 28 + 28;
  };

  var _ctor = (function () {
    var l;
    data.forEach(function (a) {
      if (!a.starts_at) {
        l =
          `${I18n.t('lbl_up_to')} ` +
          new Date(`${a.ends_at}T00:00:00`).toLocaleDateString(
            window.I18n.locale,
            {
              day: '2-digit',
              month: 'short',
            }
          );
      } else if (!a.ends_at) {
        l =
          `${I18n.t('lbl_from')} ` +
          new Date(`${a.starts_at}T00:00:00`).toLocaleDateString(
            window.I18n.locale,
            {
              day: '2-digit',
              month: 'short',
            }
          ) +
          ' ...';
      } else {
        l =
          new Date(`${a.starts_at}T00:00:00`).toLocaleDateString(
            window.I18n.locale,
            {
              day: '2-digit',
              month: 'short',
            }
          ) +
          ' - ' +
          new Date(`${a.ends_at}T00:00:00`).toLocaleDateString(
            window.I18n.locale,
            {
              day: '2-digit',
              month: 'short',
            }
          );
      }
      _t_content += _.template(_t_row, { label: l, days: a.days });
    });

    _$el = $(
      _.template(_t_outer, {
        id: id,
        content: _t_content,
      })
    );

    _el = _$el[0];
  })();

  return _i;
}
