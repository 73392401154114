function ThreeBarCircleGraph(
  attach,
  width,
  height,
  chartheight,
  val1,
  val2,
  val3,
  val4,
  incurredColor,
  color2,
  color3,
  backgroundColor
) {
  var _i = this;

  if (!(this instanceof ThreeBarCircleGraph)) {
    return new ThreeBarCircleGraph(
      attach,
      width,
      height,
      chartheight,
      val1,
      val2,
      val3,
      val4,
      incurredColor,
      color2,
      color3,
      backgroundColor
    );
  }

  _i.attachTo = xGetElementById(attach);
  _i.chartheight = chartheight;

  _i.constructor = function () {
    _i.canvas = document.createElement('CANVAS');
    _i.canvas.width = width;
    _i.canvas.height = height;
    _i.myColor = [incurredColor, color2, color3];
    _i.myGoalColor = backgroundColor;

    var myAvailable = val1;
    var myIncurred = val2;
    var myFuture = val3;
    var myGoalPercentage = val4;
    _i.myGoal = myGoalPercentage * myAvailable;
    if (myIncurred + myFuture > myAvailable) {
      // more than 100%
      _i.myTotal = myIncurred + myFuture;
    } else {
      _i.myTotal = myAvailable;
    }

    myRemaining = _i.myTotal - myIncurred - myFuture;
    _i.myData = [myIncurred, myFuture, myRemaining]; // incurred, future and remaining as percentage

    plotData();
    _i.attachTo.appendChild(_i.canvas);
  };

  function plotData() {
    var ctx;
    var lastend = -Math.PI / 2;

    ctx = _i.canvas.getContext('2d');
    ctx.clearRect(0, 0, _i.canvas.width, _i.canvas.height);
    // draw incurred, future and remaining available
    for (var i = 0; i < _i.myData.length; i++) {
      ctx.strokeStyle = _i.myColor[i];
      ctx.beginPath();
      ctx.arc(
        50,
        50,
        40,
        lastend,
        lastend + Math.PI * 2 * (_i.myData[i] / _i.myTotal),
        false
      );
      ctx.lineWidth = 15;
      ctx.stroke();
      lastend += Math.PI * 2 * (_i.myData[i] / _i.myTotal);
    }
    //draw goal
    ctx.strokeStyle = _i.myGoalColor;
    ctx.beginPath();
    lastend = -Math.PI / 2;
    ctx.arc(
      50,
      50,
      40,
      lastend,
      lastend + Math.PI * 2 * (_i.myGoal / _i.myTotal),
      false
    );
    ctx.lineWidth = 5;
    ctx.stroke();
  }

  _i.constructor();
}

function PercentageThreeBarGraph(
  attach,
  width,
  height,
  chartheight,
  val1,
  val2,
  val3,
  color1,
  color2,
  color3,
  color4,
  color5,
  scale,
  topPadding
) {
  var _i = this;

  if (!(this instanceof PercentageThreeBarGraph)) {
    return new PercentageThreeBarGraph(
      attach,
      width,
      height,
      chartheight,
      val1,
      val2,
      val3,
      color1,
      color2,
      color3,
      color4,
      color5,
      scale,
      topPadding
    );
  }

  _i.attachTo = xGetElementById(attach);
  _i.width = width;
  _i.height = height;
  _i.chartheight = chartheight;
  _i.val1 = val1;
  _i.val2 = val2;
  _i.val3 = val3;
  _i.color1 = color1;
  _i.color2 = color2;
  _i.color3 = color3;
  _i.color4 = color4;
  _i.color5 = color5;
  _i.scale = scale;
  _i.topPadding = topPadding;

  _i.constructor = function () {
    _i.canvas = document.createElement('CANVAS');
    _i.canvas.width = _i.width;
    _i.canvas.height = _i.height;
    _i.canvas.style.position = 'relative';
    if (_i.topPadding != null && _i.topPadding > 0)
      _i.canvas.style.top = '+' + _i.topPadding + 'px';
    var context = _i.canvas.getContext('2d');
    var middleheight = Math.floor(_i.chartheight / 3);
    var centerY = _i.canvas.height / 2;

    var maxval = scale;
    if (!maxval) {
      maxval = val2 * 100;
      if (maxval < (val1 + val3) * 100) maxval = (val1 + val3) * 100;
    }

    context.beginPath();
    context.fillStyle = _i.color1;
    var lastwidth = (((val1 + val3) * 100) / maxval) * width;
    context.fillRect(
      0,
      centerY - _i.chartheight / 2,
      lastwidth,
      _i.chartheight
    );
    _i.attachTo.appendChild(_i.canvas);
    context.closePath();

    context.fillStyle = _i.color3;
    var firstwidth = ((val3 * 100) / maxval) * _i.width;
    context.fillRect(
      0,
      centerY - _i.chartheight / 2,
      firstwidth,
      _i.chartheight
    );
    context.closePath();

    context.beginPath();
    context.fillStyle = _i.color2;
    var middlewidth = ((val2 * 100) / maxval) * _i.width;
    context.fillRect(0, centerY - middleheight / 2, middlewidth, middleheight);
    context.closePath();

    context.beginPath();
    context.fillStyle = _i.color4;
    context.fillRect(
      0,
      centerY - middleheight / 2,
      middlewidth < lastwidth ? middlewidth : lastwidth,
      middleheight
    );
    context.closePath();
    var end = firstwidth;
    if (lastwidth > firstwidth) end = lastwidth;
    if (
      middlewidth < _i.width &&
      middlewidth < end &&
      _i.val2 &&
      _i.color5 != '#FFFFFF'
    ) {
      //This is a bit obscure using the color FFFFFF(white) to indicate that you don't want to color in the exceeds bar.
      context.beginPath();
      context.fillStyle = _i.color5;
      context.fillRect(
        middlewidth,
        centerY - middleheight / 2,
        end - middlewidth,
        middleheight
      );
      context.closePath();
    }
    _i.attachTo.appendChild(_i.canvas);
  };

  _i.constructor();
}

function ThreeBarGraph(
  attach,
  width,
  height,
  chartheight,
  val1,
  val2,
  val3,
  color1,
  color2,
  color3,
  color4,
  color5,
  scale,
  topPadding
) {
  var _i = this;

  if (!(this instanceof ThreeBarGraph)) {
    return new ThreeBarGraph(
      attach,
      width,
      height,
      chartheight,
      val1,
      val2,
      val3,
      color1,
      color2,
      color3,
      color4,
      color5,
      scale,
      topPadding
    );
  }

  _i.attachTo = xGetElementById(attach);
  _i.width = width;
  _i.height = height;
  _i.chartheight = chartheight;
  _i.val1 = val1;
  _i.val2 = val2;
  _i.val3 = val3;
  _i.color1 = color1;
  _i.color2 = color2;
  _i.color3 = color3;
  _i.color4 = color4;
  _i.color5 = color5;
  _i.scale = scale;
  _i.topPadding = topPadding;

  _i.constructor = function () {
    _i.canvas = document.createElement('CANVAS');
    _i.canvas.width = _i.width;
    _i.canvas.height = _i.height;
    _i.canvas.style.position = 'relative';
    if (_i.topPadding != null && _i.topPadding > 0)
      _i.canvas.style.top = '+' + _i.topPadding + 'px';
    var context = _i.canvas.getContext('2d');
    var middleheight = Math.floor(_i.chartheight / 3);
    var centerY = _i.canvas.height / 2;

    var maxval = scale;
    if (!maxval) {
      maxval = val1;
      if (maxval < val2) maxval = val2;
      if (maxval < val3) maxval = val3;
    }

    context.fillStyle = _i.color1;
    var firstwidth = (val1 / maxval) * _i.width;
    context.fillRect(
      0,
      centerY - _i.chartheight / 2,
      firstwidth,
      _i.chartheight
    );
    context.closePath();

    context.beginPath();
    context.fillStyle = _i.color2;
    var middlewidth = (val2 / maxval) * _i.width;
    context.fillRect(0, centerY - middleheight / 2, middlewidth, middleheight);
    context.closePath();

    context.beginPath();
    context.fillStyle = _i.color3;
    var lastwidth = (val3 / maxval) * width;
    context.fillRect(
      0,
      centerY - _i.chartheight / 2,
      lastwidth,
      _i.chartheight
    );
    _i.attachTo.appendChild(_i.canvas);
    context.closePath();

    context.beginPath();
    context.fillStyle = _i.color4;
    context.fillRect(
      0,
      centerY - middleheight / 2,
      middlewidth < lastwidth ? middlewidth : lastwidth,
      middleheight
    );
    context.closePath();
    var end = firstwidth;
    if (lastwidth > firstwidth) end = lastwidth;
    if (
      middlewidth < _i.width &&
      middlewidth < end &&
      _i.val2 &&
      _i.color5 != '#FFFFFF'
    ) {
      //This is a bit obscure using the color FFFFFF(white) to indicate that you don't want to color in the exceeds bar.
      context.beginPath();
      context.fillStyle = _i.color5;
      context.fillRect(
        middlewidth,
        centerY - middleheight / 2,
        end - middlewidth,
        middleheight
      );
      context.closePath();
    }
    _i.attachTo.appendChild(_i.canvas);
  };

  _i.constructor();
}

function TwoBarGraph(
  attach,
  width,
  height,
  chartheight,
  val1,
  val2,
  color1,
  color2,
  scale
) {
  var _i = this;

  if (!(this instanceof TwoBarGraph)) {
    return new TwoBarGraph(
      attach,
      width,
      height,
      chartheight,
      val1,
      val2,
      color1,
      color2,
      scale
    );
  }

  _i.attachTo = xGetElementById(attach);
  _i.width = width;
  _i.height = height;
  _i.chartheight = chartheight;
  _i.val1 = val1;
  _i.val2 = val2;
  _i.color1 = color1;
  _i.color2 = color2;
  _i.scale = scale;

  _i.constructor = function () {
    _i.canvas = document.createElement('CANVAS');
    _i.canvas.width = _i.width;
    _i.canvas.height = _i.height;
    var context = _i.canvas.getContext('2d');
    var middleheight = Math.floor(_i.chartheight / 3);
    var centerY = _i.canvas.height / 2;

    var maxval = scale;
    if (!maxval) {
      maxval = val1;
      if (maxval < val2) maxval = val2;
    }

    context.fillStyle = _i.color1;
    var firstwidth = (val1 / maxval) * _i.width;
    context.fillRect(
      0,
      centerY - _i.chartheight / 2,
      firstwidth,
      _i.chartheight
    );
    context.closePath();
    context.beginPath();
    context.fillStyle = _i.color2;
    var middlewidth = (val2 / maxval) * _i.width;
    context.fillRect(
      0,
      centerY - _i.chartheight / 2,
      middlewidth,
      _i.chartheight
    );
    context.closePath();
    _i.attachTo.appendChild(_i.canvas);
  };

  _i.constructor();
}

function ProgressGraph(
  attach,
  width,
  height,
  chartheight,
  val1,
  val2,
  val3,
  color1,
  color2,
  color3,
  scale,
  strokecolor
) {
  var _i = this;

  if (!(this instanceof ProgressGraph)) {
    return new ProgressGraph(
      attach,
      width,
      height,
      chartheight,
      val1,
      val2,
      val3,
      color1,
      color2,
      color3,
      scale,
      strokecolor
    );
  }

  _i.attachTo = attach;
  _i.width = width;
  _i.height = height;
  _i.chartheight = chartheight;
  _i.val1 = val1;
  _i.val2 = val2;
  _i.val3 = val3;
  _i.color1 = color1;
  _i.color2 = color2;
  _i.color3 = color3;
  _i.scale = scale;
  _i.strokecolor = strokecolor;

  _i.constructor = function () {
    _i.canvas = document.createElement('CANVAS');
    _i.canvas.width = _i.width;
    _i.canvas.height = _i.height;
    var context = _i.canvas.getContext('2d');
    var middleheight = Math.floor(_i.chartheight / 3);
    var centerY = _i.canvas.height / 2;

    var maxval = _i.val1 + _i.val2 + _i.val3;
    if (_i.scale > maxval) maxval = _i.scale;

    context.fillStyle = _i.color1;
    var firstwidth = (val1 / maxval) * _i.width;
    firstwidth = Number(firstwidth.toFixed());
    var xPos = 0;
    context.fillRect(
      xPos,
      centerY - _i.chartheight / 2,
      firstwidth,
      _i.chartheight
    );
    context.closePath();
    xPos = firstwidth - 1;
    context.beginPath();
    context.fillStyle = '#ffffff';
    context.fillRect(xPos, centerY - _i.chartheight / 2, 1, _i.chartheight);
    context.closePath();
    xPos += 1;

    if (val2 > 0) {
      context.beginPath();
      context.fillStyle = _i.color2;
      var middlewidth = (val2 / maxval) * _i.width;
      middlewidth = Number(middlewidth.toFixed());
      if (middlewidth < 1) middlewidth = 1;
      if (_i.strokecolor && middlewidth > 2) {
        context.fillStyle = _i.strokecolor;
        context.fillRect(
          xPos,
          centerY - _i.chartheight / 2,
          middlewidth,
          _i.chartheight
        );
        context.clearRect(
          xPos + 1,
          centerY - _i.chartheight / 2 + 1,
          middlewidth - 2,
          _i.chartheight - 2
        );
      } else {
        context.fillRect(
          xPos,
          centerY - _i.chartheight / 2,
          middlewidth,
          _i.chartheight
        );
      }
      context.closePath();
      xPos += middlewidth;
      context.beginPath();
      context.fillStyle = '#ffffff';
      context.fillRect(xPos, centerY - _i.chartheight / 2, 1, _i.chartheight);
      context.closePath();
      xPos += 1;
    }
    context.beginPath();
    context.fillStyle = _i.color3;
    var lastwidth = (val3 / maxval) * _i.width;
    lastwidth = Number(lastwidth.toFixed());
    context.fillRect(
      xPos,
      centerY - _i.chartheight / 2,
      lastwidth,
      _i.chartheight
    );
    context.closePath();
    _i.attachTo.appendChild(_i.canvas);
  };

  _i.constructor();
}
