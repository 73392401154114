function SettingsPlaceholderResources(attachTo, label, description) {
  var _i = this;
  if (!(this instanceof SettingsPlaceholderResources)) {
    return new SettingsPlaceholderResources(attachTo, label, description);
  }

  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.labelval = label;
  _i.descriptionval = description;
  _i.resourceControllers = [];

  _i.editModeCallback = function (controller) {
    _i.hideElementsAboveTheList();
    _i.displaySingleResource(null, controller);
  };

  _i.refreshList = function () {
    _i.showElementsAboveTheList();
    removeAllChildren(_i.resourcesCollection);
    _i.buildResourcesArray();
    _i.displayResourcesList();
  };

  _i.spacerLineObject = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.resourcesCollection.appendChild(spacer);
  };

  var changeDisplayOfElementsAboveTheList = function (displayValue) {
    _.each(_i.elementsAboveTheList, function (element) {
      element.style.display = displayValue;
    });
  };

  _i.showElementsAboveTheList = function () {
    changeDisplayOfElementsAboveTheList('block');
  };

  _i.hideElementsAboveTheList = function () {
    changeDisplayOfElementsAboveTheList('none');
  };

  _i.displayResourcesList = function () {
    _i.resourceControllers = (_i.resources || []).map(function (resource) {
      var controller = SettingsPlaceholderResource(
        _i.resourcesCollection,
        resource,
        _i
      );
      _i.spacerLineObject();
      return controller;
    });
  };

  _i.displaySingleResource = function (resource, controller) {
    removeAllChildren(_i.resourcesCollection);
    if (!controller) {
      controller = SettingsPlaceholderResource(
        _i.resourcesCollection,
        resource,
        _i
      );
    }
    _i.resourceControllers = [controller];
    _i.resourcesCollection.appendChild(controller.valuecontainer);
    controller.editMode();
  };

  _i.buildResourcesArray = function () {
    _i.resources = gData.getPlaceholderResources();
    if (_i.resources) {
      _i.resources.sort(function (a, b) {
        var sa = a.displayName;
        var sb = b.displayName;
        if (sa == sb) return 0;
        return sa > sb ? 1 : -1;
      });
    } else {
      gService.getPlaceholderResources(
        _i.refreshList,
        false /* Include custom field values */
      );
    }
  };

  _i.newResourceView = function () {
    _i.hideElementsAboveTheList();
    removeAllChildren(_i.resourcesCollection);
    _i.displaySingleResource({
      title: '',
      billrate: -1,
      thumbnail: STATIC_IMAGE_URL + '/anon.jpg',
      color: 'Blue',
    });
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);
    _i.label = document.createElement('DIV');

    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.label.innerHTML = I18n.t('msg_placeholders_html');
    _i.container.appendChild(_i.label);

    _i.addButton = new primaryButton('settingsPrimaryButton');
    _i.addButton.setButtonText(I18n.t('lbl_add'));
    _i.addButton.setCallback(_i.newResourceView);
    _i.container.appendChild(_i.addButton.container);
    _i.addButton.container.tabIndex = 0;

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);

    _i.elementsAboveTheList = _.toArray(_i.container.children);

    _i.resourcesCollection = document.createElement('DIV');
    _i.resourcesCollection.classname = 'blockFloatNot';
    _i.container.appendChild(_i.resourcesCollection);

    _i.buildResourcesArray();
    _i.displayResourcesList();
  };

  _i.constructor();
}
