function SettingsSsoSettings(attachTo, label, description) {
  var _i = this;

  _i.attachTo = xGetElementById(attachTo);
  _i.labelText = label;
  _i.descriptionText = description;
  _i.settingsData = null;

  _i.editButtonCallback = function () {
    _i.displayEditSettings(_i.settingsData);
  };

  _i.refresh = function () {
    _i.removeViewControls();
    _i.removeEditControls();
    _i.showViewElements();
    _i.buildSettingsView();
  };

  _i.removeEditControls = function () {
    if (_i.editContainer) {
      removeAllChildren(_i.editContainer);
      _i.container.removeChild(_i.editContainer);
      _i.editContainer = null;
    }
  };

  _i.removeViewControls = function () {
    if (_i.addButton) {
      _i.container.removeChild(_i.noSettings);
      _i.container.removeChild(_i.addButton.container);
      _i.addButton = null;
      _i.noSettings = null;
    } else if (_i.settingsInfoContainer) {
      removeAllChildren(_i.settingsInfoContainer);
      _i.container.removeChild(_i.settingsInfoContainer);
      _i.settingsInfoContainer = null;
    }
  };

  var changeDisplayOfViewElements = function (displayValue) {
    _.each(_i.viewElements, function (element) {
      element.style.display = displayValue;
    });
  };

  _i.showViewElements = function () {
    changeDisplayOfViewElements('block');
  };

  _i.hideViewElements = function () {
    changeDisplayOfViewElements('none');
  };

  _i.displaySettingsView = function () {
    if (!_i.settingsData) {
      _i.showAddSettings();
    } else {
      _i.showSettingsInfo();
    }
  };

  _i.showAddSettings = function () {
    _i.noSettings = document.createElement('DIV');
    _i.noSettings.className = 'blockFloatNot fnt-r-14 settingsSsoHeaderLabel';
    _i.noSettings.innerHTML = I18n.t('msg_no_sso_html');
    _i.container.appendChild(_i.noSettings);

    _i.addButton = new primaryButton('settingsPrimaryButton');
    _i.addButton.setButtonText(I18n.t('lbl_setup_sso'));
    _i.addButton.setCallback(function () {
      window.clientEventLogger &&
        window.clientEventLogger.push({
          eventTimestamp: Date.now(),
          eventData: {
            eventType: 'Clicked',
            featureArea: 'RM Authentication',
            description: 'A user has initiated the SSO configuration process',
            objectName: 'Setup SSO',
            objectType: 'Button',
            viewName: 'Account Settings',
          },
        });
      _i.displayNewSettings();
    });
    _i.container.appendChild(_i.addButton.container);
    _i.addButton.container.tabIndex = 0;
  };

  _i.showSettingsInfo = function () {
    _i.settingsInfoContainer = document.createElement('DIV');
    _i.settingsInfoContainer.classname = 'blockFloatNot';
    _i.container.appendChild(_i.settingsInfoContainer);

    _i.settingsText = document.createElement('DIV');
    _i.settingsText.className = 'blockFloatNot fnt-r-14 settingsSsoHeaderLabel';
    _i.settingsText.innerHTML = I18n.t('msg_sso_configured_html');
    _i.settingsInfoContainer.appendChild(_i.settingsText);

    var controller = SettingsEditSsoSettings(
      _i.settingsInfoContainer,
      _i.settingsData,
      _i
    );
    _i.settingsInfoContainer.appendChild(controller.valuecontainer);
    controller.displayMode();

    gService.getActiveSsoUsers(_i.showSettingsEditOptions);
  };

  _i.showSettingsEditOptions = function (ssoActiveUsers) {
    _i.editButton = new primaryButton('settingsPrimaryButton');
    _i.editButton.setButtonText(I18n.t('lbl_edit'));
    _i.editButton.setCallback(_i.editButtonCallback);
    _i.settingsInfoContainer.appendChild(_i.editButton.container);
    _i.editButton.container.tabIndex = 0;

    if (ssoActiveUsers && ssoActiveUsers.data.length > 0) {
      _i.showEditWarning();
    } else {
      _i.settingsEditText = document.createElement('DIV');
      _i.settingsEditText.className =
        'blockFloatNot fnt-r-14 settingsSsoHeaderLabel';
      _i.settingsEditText.innerHTML = I18n.t('msg_sso_no_login_html');
      _i.settingsInfoContainer.appendChild(_i.settingsEditText);
    }
  };

  _i.showEditWarning = function () {
    _i.settingsEditWarningText = document.createElement('DIV');
    _i.settingsEditWarningText.className =
      'blockFloatNot fnt-r-14 settingsMyPrefEmailLabel';
    _i.settingsEditWarningText.innerHTML = I18n.t('msg_sso_login_html');
    _i.settingsInfoContainer.appendChild(_i.settingsEditWarningText);
  };

  _i.displayEditSettings = function (setting) {
    _i.hideViewElements();
    _i.removeViewControls();
    _i.editContainer = document.createElement('DIV');
    _i.editContainer.classname = 'blockFloatNot';
    _i.container.appendChild(_i.editContainer);

    var controller = SettingsEditSsoSettings(_i.editContainer, setting, _i);
    _i.editContainer.appendChild(controller.valuecontainer);
    controller.editMode();
  };

  _i.buildSettingsView = function () {
    _i.settingsData = null;
    var settingsData = gData.getSsoSettings();
    if (!settingsData) {
      gService.getSsoSettings(_i.refresh);
    } else {
      if (settingsData.length > 0) {
        _i.settingsData = settingsData[0];
      }
      _i.displaySettingsView();
    }
  };

  _i.displayNewSettings = function () {
    _i.displayEditSettings({});
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);

    _i.label = document.createElement('DIV');
    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.label.innerHTML = _i.labelText;
    _i.container.appendChild(_i.label);

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);

    _i.viewElements = _.toArray(_i.container.children);

    _i.buildSettingsView();
  };

  _i.constructor();
}
