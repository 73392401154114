function SettingsHolidays(attachTo) {
  var _i = this;
  if (!(this instanceof SettingsHolidays)) {
    return new SettingsHolidays(attachTo);
  }

  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.holidayControllers = [];
  _i.expectedcallbacks = 0;

  _i.holidayError = function () {
    _i.expectedcallbacks--;
    if (_i.expectedcallbacks == 0) gPageHeader.setSpinner(false);
    const customPrompt = ''; // Create a prompt for the empty string
    notificationPopup(I18n.t('lbl_holiday_duplicate'), customPrompt, 5000);
  };

  _i.holidayCreated = function () {
    _i.expectedcallbacks--;
    if (_i.expectedcallbacks == 0) gPageHeader.setSpinner(false);
    _i.buildHolidaysArray();
    _i.refreshList();
    notificationPopup(
      I18n.t('lbl_holiday_added'),
      I18n.t('msg_holiday_added_confirm') +
        ': ' +
        _i.holidayname.inputText.value,
      5000
    );
    _i.holidayname.inputText.value = '';
  };

  _i.editModeCallback = function () {
    for (var i = 0; i < _i.holidayControllers.length; i++) {
      _i.holidayControllers[i].cancelEdit();
    }
  };

  _i.addHoliday = function () {
    if (!_i.holidayname.getValue()) {
      _i.holidayname.setAlert(I18n.t('err_valid_name'));
      return false;
    }
    var holidayData = {};
    holidayData.name = _i.holidayname.getValue();
    holidayData.date = _i.holidaydate.selectedDate;
    _i.expectedcallbacks++;
    gPageHeader.setSpinner(true);
    gService.createHoliday(holidayData, _i.holidayCreated, _i.holidayError);
  };

  _i.dateSelected = function (date) {
    _i.holidaydate.setDate(date);
    _i.adddate = new Date(date);
  };

  _i.refreshList = function () {
    removeAllChildren(_i.holidaysCollection);
    _i.buildHolidaysArray();
    _i.displayHolidays();
  };

  _i.spacerLineObject = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.holidaysCollection.appendChild(spacer);
  };

  _i.displayHolidays = function () {
    _i.holidayControllers = [];
    for (var i = 0; i < _i.holidays.length; i++) {
      _i.holidayControllers.push(
        SettingsHoliday(_i.holidaysCollection, _i.holidays[i], _i)
      );
      _i.spacerLineObject();
    }
  };

  _i.buildHolidaysArray = function () {
    _i.holidays = [];
    var first = new Date();
    first.setHours(0, 0, 0, 0);
    first.setMonth(0);
    first.setDate(1);
    first.setYear(first.getFullYear() - 3);
    for (var i = 0; i < gData.accountSettings.holidays.length; i++) {
      var comp = parseRubyDateTime(
        gData.accountSettings.holidays[i].date,
        true
      );
      if (first <= comp) {
        _i.holidays.push(gData.accountSettings.holidays[i]);
      }
    }

    _i.holidays.sort(function (a, b) {
      var sa = parseRubyDateTime(a.date, true);
      var sb = parseRubyDateTime(b.date, true);
      if (sa == sb) return 0;
      return sa > sb ? -1 : 1;
    });
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);
    _i.label = document.createElement('H3');

    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.label.innerHTML = I18n.t('lbl_add_holiday_colon');
    _i.container.appendChild(_i.label);

    _i.holidayname = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_holiday'),
      '',
      'blockFloat settingsPageEditInputContainerSmaller',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmaller'
    );
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    var date = new Date();
    _i.datePopup = new calendarPopup(
      230,
      210,
      'mainCon',
      date,
      compSpritesButtonsIcons.wideDialogSpecsGray,
      _i.dateSelected,
      false,
      false,
      'ne',
      true
    );
    _i.holidaydate = new PopUpCalendar(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_date'),
      'blockFloat settingsPageEditInputContainerSmallerDate',
      'blockFloatNot fnt-r-14 settingsInputContainerSmallDateFull',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15',
      date,
      _i.datePopup
    );

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.addButton = new primaryButton('settingsPrimaryButton');
    _i.addButton.setButtonText(I18n.t('lbl_add'));
    _i.addButton.setCallback(_i.addHoliday);
    _i.container.appendChild(_i.addButton.container);
    _i.addButton.container.tabIndex = 0;

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);

    _i.holidaysCollection = document.createElement('DIV');
    _i.holidaysCollection.classname = 'blockFloatNot';
    _i.container.appendChild(_i.holidaysCollection);

    _i.buildHolidaysArray();
    _i.displayHolidays();
  };

  _i.constructor();
}
