var MAX_LENGTH = 255;
function SettingsBudgetCategories(attachTo, itemType, addText, details) {
  var _i = this;
  if (!(this instanceof SettingsBudgetCategories)) {
    return new SettingsBudgetCategories(attachTo, itemType, addText, details);
  }
  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.itemType = itemType;
  _i.addText = addText;
  _i.details = details;
  _i.sendInvite = false;
  _i.categoryControllers = [];

  _i.categoryCreated = function () {
    gPageHeader.setSpinner(false);
    _i.buildCategoriesArrays();
    _i.refreshList();
    _i.category.inputText.value = '';
    _i.category.inputText.focus();
    _i.category.inputText.select();
    if (_i.amount) _i.amount.inputText.value = '';
    if (_i.item) _i.item.inputText.value = '';
  };

  _i.editModeCallback = function () {
    for (var i = 0; i < _i.categoryControllers.length; i++) {
      _i.categoryControllers[i].cancelEdit();
    }
  };

  _i.verifyString = function (str) {
    return str != null && str != '';
  };

  _i.verifyForm = function () {
    var proposedValue = singleSpace(trim(_i.category.getValue()));
    if (proposedValue == '') {
      _i.category.setAlert(I18n.t('err_valid_value'));
      _i.category.inputText.value = '';
      return false;
    }
    if (_i.amount) {
      var number = _i.amount.getValueAsNumber();
      if (isNaN(number)) {
        _i.amount.setAlert(I18n.t('msg_numeric_budget_value'));
        return false;
      }
    }
    return true;
  };

  _i.addCategory = function () {
    if (!_i.verifyForm()) {
      return false;
    }
    var data = {};
    data.category = _i.category.getValue();
    if (_i.amount) {
      var amount = _i.amount.getValueAsNumber();
      if (amount == 0) amount = null;
      data.peritem_amount = amount;
    }
    if (_i.item) {
      data.peritem_label = _i.item.getValue();
      if (data.peritem_label == '') data.peritem_label = null;
    }
    data.item_type = _i.itemType;
    gPageHeader.setSpinner(true);
    gService.createBudgetSingle(data, null, _i.categoryCreated);
  };

  _i.refreshList = function () {
    removeAllChildren(_i.categoriesCollection);
    _i.buildCategoriesArrays();
    _i.displayCategories();
  };

  _i.spacerLineUser = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.categoriesCollection.appendChild(spacer);
  };

  _i.displayCategories = function () {
    _i.categoryControllers = [];
    for (var i = 0; i < _i.categories.length; i++) {
      _i.categoryControllers.push(
        SettingsBudgetCategory(
          _i.categoriesCollection,
          _i.categories[i],
          _i,
          _i.details
        )
      );
      _i.spacerLineUser();
    }
  };

  _i.buildCategoriesArrays = function () {
    _i.categories = gData.getBudgetByItemType(_i.itemType);
    _i.categories.sort(function (a, b) {
      var sa = a.category.toLowerCase();
      var sb = b.category.toLowerCase();
      return sa < sb ? -1 : 1;
    });
  };

  _i.buildLinkTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-b-13 adduserLink';
    ret.innerText = str;
    return ret;
  };

  _i.buildLightTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-13 adduserLightSpan';
    ret.innerText = str;
    return ret;
  };

  _i.buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-13 adduserSpan';
    ret.innerText = str;
    return ret;
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.container.setAttribute(
      'data-client-id',
      _i.itemType + 'CategoriesContainer'
    );
    _i.container.style.width = '100%';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);
    _i.label = document.createElement('H3');

    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.label.innerText = _i.addText;
    _i.container.appendChild(_i.label);

    // Add a container around each input element when user wants to add an expense category
    _i.addCategoryInput = document.createElement('DIV');
    _i.addCategoryInput.className = 'blockFloatNot addCategoryInputContainer';
    _i.addCategoryInput.id = 'addCategoryInputContainer';
    _i.container.appendChild(_i.addCategoryInput);

    // expenseCategoriesContainer is a new class so that input elements are aligned to the bottom of addCategoryInput
    _i.category = new TextInput(
      'addCategoryInputContainer',
      I18n.t('lbl_category'),
      '',
      'blockFloat expenseCategoriesContainer',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmaller',
      null,
      null,
      null,
      _i.addCategory,
      null,
      null,
      null,
      { maxLength: MAX_LENGTH }
    );
    _i.category.inputText.style.width = '152px';
    _i.category.inputText.setAttribute(
      'data-client-id',
      _i.itemType + 'CategoryInput'
    );

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.addCategoryInput.appendChild(spacer);
    if (_i.details) {
      _i.amount = new TextInput(
        'addCategoryInputContainer',
        I18n.t('lbl_amount_optional'),
        '',
        'blockFloat expenseCategoriesContainer',
        'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
        'blockFloatNot fnt-r-15 settingsPageTextInputSmaller',
        null,
        TextInputFilter.Numeric,
        null,
        _i.addCategory
      );
      _i.amount.inputText.style.width = '104px';
      _i.amount.inputText.setAttribute(
        'data-client-id',
        _i.itemType + 'AmountInput'
      );

      spacer = document.createElement('DIV');
      spacer.className = 'blockFloat fnt-r-14 settingsSpacer6';
      spacer.style.alignSelf = 'flex-end';
      spacer.innerHTML = I18n.t('lbl_per');
      _i.addCategoryInput.appendChild(spacer);

      _i.item = new TextInput(
        'addCategoryInputContainer',
        I18n.t('lbl_item_optional'),
        '',
        'blockFloat expenseCategoriesContainer',
        'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
        'blockFloatNot fnt-r-15 settingsPageTextInputSmaller',
        null,
        null,
        null,
        _i.addCategory
      );
      _i.item.inputText.style.width = '104px';
      _i.item.inputText.setAttribute(
        'data-client-id',
        _i.itemType + 'ItemInput'
      );

      spacer = document.createElement('DIV');
      spacer.className = 'blockFloat settingsSpacer1';
      _i.addCategoryInput.appendChild(spacer);
    }
    _i.addButton = new primaryButton('settingsPrimaryButton');
    _i.addButton.container.style.margin = '0';
    _i.addButton.container.style.padding = '0';

    _i.addButton.container.style.alignSelf = 'flex-end';
    _i.addButton.setButtonText(I18n.t('lbl_add'));
    _i.addButton.setCallback(_i.addCategory);
    _i.addCategoryInput.appendChild(_i.addButton.container);
    _i.addButton.container.tabIndex = 0;
    _i.addButton.container.setAttribute(
      'data-client-id',
      _i.itemType + 'AddButton'
    );

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);

    _i.categoriesCollection = document.createElement('DIV');
    _i.categoriesCollection.classname = 'blockFloatNot';
    _i.container.appendChild(_i.categoriesCollection);

    _i.buildCategoriesArrays();
    _i.displayCategories();
  };

  _i.constructor();
}
