function SettingsLeaveType(attach, leave, parent) {
  var _i = this;
  if (!(this instanceof SettingsLeaveType)) {
    return new SettingsLeaveType(attach, leave, parent);
  }

  _i.attach = attach;
  _i.leave = leave;
  _i.parent = parent;
  _i.mode = 'read';

  _i.itemChanged = function () {
    _i.parent.refreshList();
  };

  _i.deleteEdit = function () {
    var confirmDelete = confirmationPopup(
      I18n.t('lbl_delete') + ' ' + _i.leave.name + '?',
      I18n.t('lbl_delete'),
      function () {
        gService.deleteLeaveType(_i.leave, _i.itemChanged);
      },
      null,
      _i.parent.container
    );
    confirmDelete.show(_i.deleteButton.container);
  };

  _i.cancelEdit = function () {
    if (_i.mode == 'edit') {
      _i.listMode();
    }
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
  };

  _i.saveEdit = function () {
    var data = {};
    data.id = _i.leave.id;
    data.name = _i.tagvalue.getValue();
    data.leave_type = _i.tagvalue.getValue();
    gService.saveLeaveType(data, _i.itemChanged);
  };

  _i.containerMouseover = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainerHover';
    }
  };

  _i.containerMouseout = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    }
  };

  _i.containerClick = function (evt) {
    if (_i.mode != 'edit') {
      _i.parent.editModeCallback();
      _i.editMode();
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainerEdit';
    }
  };

  _i.editMode = function () {
    removeAllChildren(_i.valuecontainer);
    _i.tagvalue = new TextInput(
      _i.valuecontainer.id,
      null,
      _i.leave.name,
      'blockFloat',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall'
    );

    _i.deleteButton = new destructiveButton('settingsSecondaryButtonCategory');
    _i.deleteButton.setButtonText(I18n.t('lbl_delete'));
    _i.deleteButton.setCallback(_i.deleteEdit);
    _i.valuecontainer.appendChild(_i.deleteButton.container);
    _i.deleteButton.container.tabIndex = 0;

    _i.saveButton = new primaryButton('settingsSecondaryButtonCategory');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveEdit);
    _i.valuecontainer.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;

    _i.cancelButton = new spritesButton(
      'settingsCancelButtonCategory',
      null,
      compSpritesButtonsIcons.closeButtonRest,
      compSpritesButtonsIcons.closeButtonHover,
      compSpritesButtonsIcons.closeButtonDown
    );
    _i.cancelButton.setButtonText('');
    _i.cancelButton.setCallback(_i.cancelEdit);
    _i.valuecontainer.appendChild(_i.cancelButton.container);

    _i.mode = 'edit';
  };

  _i.listMode = function () {
    removeAllChildren(_i.valuecontainer);
    var name = document.createElement('DIV');
    name.className = 'blockFloat fnt-r-14 settingsLeaveTypeName';
    _i.valuecontainer.appendChild(name);
    name.innerText = _i.leave.name;

    _i.mode = 'list';
  };

  _i.constructor = function () {
    _i.valuecontainer = document.createElement('DIV');
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    _i.valuecontainer.id = 'settingsUserContainer' + getGuid();
    _i.attach.appendChild(_i.valuecontainer);
    xAddEventListener(
      _i.valuecontainer,
      'mouseover',
      _i.containerMouseover,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      'mouseout',
      _i.containerMouseout,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      platform.click,
      _i.containerClick,
      true
    );
    _i.listMode();
  };
  _i.constructor();
}
