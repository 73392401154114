function ActiveProjectView(attach, data, parent) {
  var _i = this;
  if (!(this instanceof ActiveProjectView)) {
    return new ActiveProjectView(attach, data, parent);
  }

  _i.attachTo = attach;
  _i.data = data;
  _i.parent = parent;
  _i.loaded = false;

  _i.projectClicked = function (evt) {
    window.clientEventLogger.push({
      eventTimestamp: Date.now(),
      eventData: {
        eventType: 'Clicked',
        featureArea: 'RM Analytics',
        objectName: 'Active Project',
        objectType: 'Button',
        viewName: 'Analytics Page',
      },
    });
    if (_i.myProj) {
      var label = 'Direct Project Link: ';
      label += _i.dollar ? 'Currency' : 'Days';
      trackGAEvent('Analytics Page', 'click', label);
      window.location = getBaseURL() + 'projects/' + _i.myProj.id;
    }
  };

  _i.reportDataLoaded = function (obj) {
    _i.myProj = obj;
    _i.data = new ProjectStatusView_Data(_i.myProj, true, _i.assignments);
    _i.loaded = true;
  };

  _i.render = function (scale, dollar) {
    _i.dollar = dollar;
    _i.scale = scale;
    var container = document.createElement('DIV');
    container.className = 'blockFloatNot activeProjectEntry';
    _i.attachTo.appendChild(container);

    var labelcontainer = document.createElement('DIV');
    labelcontainer.className = 'blockFloat activeProjectLabel';
    container.appendChild(labelcontainer);
    labelcontainer.style.cursor = 'pointer';
    xAddEventListener(labelcontainer, platform.click, _i.projectClicked, false);

    var client = document.createElement('DIV');
    client.className = 'labeltext blockFloatNot fnt-r-12';
    client.innerText = _i.data.project.client ? _i.data.project.client : '';
    labelcontainer.appendChild(client);
    var over = true;
    if (dollar) {
      var money = _i.data.totalassigneddollars - _i.data.totalbudgeteddollars;
      if (money < 0) {
        money *= -1;
        over = false;
      }
      var moneyString = formatCurrency(money);

      _i.budgetstring =
        moneyString +
        ' ' +
        (over ? I18n.t('lbl_over_budget_lc') : I18n.t('lbl_remaining_lc'));
      _i.over = over;
    } else {
      var days = _i.data.totalassigned - _i.data.totalbudgeted;
      if (days < 0) {
        days *= -1;
        over = false;
      }
      var daystring = days.toFixed();
      if (Number(daystring) != Number(days.toFixed(1))) {
        daystring = days.toFixed(1);
      }
      _i.budgetstring =
        daystring +
        ' ' +
        (over
          ? I18n.t('lbl_days_over_budget_lc')
          : I18n.t('lbl_days_remaining_lc'));
      _i.over = over;
    }

    var projname = document.createElement('DIV');
    projname.className = 'labeltext blockFloatNot fnt-b-12';
    projname.innerText = _i.data.project.name;
    labelcontainer.appendChild(projname);

    var graphcontainer = document.createElement('DIV');
    graphcontainer.className = 'blockFloat activeProjectGraph';
    graphcontainer.id = 'projgraph' + getGuid();
    container.appendChild(graphcontainer);
    if (dollar) {
      ThreeBarGraph(
        graphcontainer.id,
        395,
        20,
        20,
        _i.data.totalassigneddollars,
        _i.data.totalbudgeteddollars,
        _i.data.totalcompleteddollars,
        '#84d5f7',
        '#195E91',
        '#24a2eb',
        '#195E91',
        '#E81A56',
        _i.scale
      );
    } else {
      ThreeBarGraph(
        graphcontainer.id,
        395,
        20,
        20,
        _i.data.totalassigned,
        _i.data.totalbudgeted,
        _i.data.totalcompleted,
        '#84d5f7',
        '#195E91',
        '#24a2eb',
        '#195E91',
        '#E81A56',
        _i.scale
      );
    }
  };

  _i.constructor = function () {
    _i.reportDataLoaded(_i.data.project);
  };

  _i.constructor();
}
