function SettingsBilling(attachTo) {
  var _i = this;

  if (!(this instanceof SettingsBilling)) {
    return new SettingsBilling(attachTo);
  }

  // params
  _i.attachTo = xGetElementById(attachTo);

  _i.wizardView = null;
  _i.billingWizard = null;
  _i.billingVM = null;

  _i.subscribeOnClickCallback = function () {
    location.assign(APP_ENDPOINT + '/billing');
  };

  _i.updateCCClickCallback = function () {
    location.assign(APP_ENDPOINT + '/billing?cc=1');
  };

  _i.wizardOnClosedCallback = function () {
    removeAllChildren(_i.container);
    _i.buildPageContent(_i.billingVM.currentBillingInfo);
  };

  _i.buildPageContent = function (currentBillingInfo) {
    var spacer, d;
    var activeUserCount = _i.billingVM.currentUserCount;
    var resourceUserCount = _i.billingVM.resourceUserCount;
    var hideCloseAccountOptions = false;

    if (_i.pspinner) {
      _i.pspinner.remove();
      _i.pspinner = null;
    }

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot wizardVspacer15px';
    _i.container.appendChild(spacer);

    var getSwitchValue = function () {
      if (currentBillingInfo.status === 'quote_requested')
        return 'quote_requested';
      if (currentBillingInfo.payment_method === 'invoice') return 'invoice';
      return currentBillingInfo.status;
    };

    if (currentBillingInfo.plan) {
      _i.billingVM.setCurrentPlan(currentBillingInfo.plan);
      var currentPlan = _i.billingVM.getPlan(currentBillingInfo.plan);
      var currentSku =
        {
          1: 'Basic',
          2: 'Professional',
          3: 'Enterprise',
        }[currentBillingInfo.sku || currentPlan.sku] || 'Basic';
    }

    switch (getSwitchValue()) {
      case 'trial':
        var today = new Date();
        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-r-14 settingsBillingCurrentInfoLabel';
        if (currentBillingInfo.trial_ends_at < Date.addDays(today, 1))
          d.innerHTML = I18n.t('msg_trial_ended', {
            date: currentBillingInfo.trial_ends_at.toLocaleDateString(window.I18n.locale, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          });
        else
          d.innerHTML = I18n.t('msg_trial_is_ending_html', {
            date: currentBillingInfo.trial_ends_at.toLocaleDateString(window.I18n.locale, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
          });
        _i.container.appendChild(d);

        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-r-14 settingsBillingCurrentInfoLabel';
        if (activeUserCount == 1) {
          d.innerHTML = I18n.t('msg_one_team_member');
        } else {
          d.innerHTML = I18n.t('msg_active_user_count', {
            activeUserCount: activeUserCount,
          });
        }
        _i.container.appendChild(d);

        _i.subscribeButton = new primaryButton('blockFloatNot');
        _i.subscribeButton.setButtonText(I18n.t('lbl_purchase_now'));
        _i.subscribeButton.setCallback(_i.subscribeOnClickCallback);
        _i.container.appendChild(_i.subscribeButton.container);
        _i.subscribeButton.container.tabIndex = 0;

        spacer = document.createElement('DIV');
        spacer.className = 'blockFloatNot wizardVspacer35px';
        _i.container.appendChild(spacer);

        break;

      case 'pending':
      case 'pending_update':
      case 'active':
      case 'error':
      case 'outstanding_payment':
        var billingCycle =
          currentBillingInfo.payment_cycle == 'yearly'
            ? I18n.t('lbl_year_lc')
            : I18n.t('lbl_month_lc');

        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-r-14 settingsBillingCurrentInfoLabel';
        d.innerHTML = I18n.t('msg_active_users', {
          sku: currentSku,
          maxUsers: window.accountSettings.subscription.allowed_licensed,
          activeUserCount: activeUserCount,
          resourceUserCount: resourceUserCount,
        });
        _i.container.appendChild(d);

        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-r-14 settingsBillingCurrentInfoLabel';
        d.innerHTML = I18n.t('msg_billing_cycle', {
          billingCycle: billingCycle,
        });
        _i.container.appendChild(d);

        _i.subscribeButton = new primaryButton('blockFloatNot');
        _i.subscribeButton.setButtonText(I18n.t('lbl_update_plan'));
        _i.subscribeButton.setCallback(_i.subscribeOnClickCallback);
        _i.container.appendChild(_i.subscribeButton.container);
        _i.subscribeButton.container.tabIndex = 0;

        spacer = document.createElement('DIV');
        spacer.className = 'blockFloatNot wizardVspacer35px';
        _i.container.appendChild(spacer);

        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-b-14';
        d.innerHTML = I18n.t('lbl_credit_card_info');
        _i.container.appendChild(d);

        spacer = document.createElement('DIV');
        spacer.className = 'blockFloatNot wizardVspacer15px';
        _i.container.appendChild(spacer);

        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-r-14 settingsBillingCurrentInfoLabel';
        d.innerHTML = I18n.t('msg_card_expires', {
          last4: currentBillingInfo.last4,
          expiryMonth: currentBillingInfo.expiry_month,
          expiryYear: currentBillingInfo.expiry_year,
        });
        _i.container.appendChild(d);

        _i.subscribeButton = new primaryButton('blockFloatNot');
        _i.subscribeButton.setButtonText(I18n.t('lbl_update_credit_card'));
        _i.subscribeButton.setCallback(_i.updateCCClickCallback);
        _i.container.appendChild(_i.subscribeButton.container);
        _i.subscribeButton.container.tabIndex = 0;

        spacer = document.createElement('DIV');
        spacer.className = 'blockFloatNot wizardVspacer35px';
        _i.container.appendChild(spacer);

        break;

      case 'quote_requested':
        var currentPlan = _i.billingVM.getPlan(currentBillingInfo.plan);
        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-r-14 settingsBillingCurrentInfoLabel';
        d.innerHTML =
          'Your quote request has been received and we will be in contact soon. ' +
          'Meanwhile, you have full access for up to ' +
          window.accountSettings.subscription.allowed_licensed +
          ' licensed users. ';
        _i.container.appendChild(d);
        break;

      case 'invoice':
        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-r-14 settingsBillingCurrentInfoLabel';
        d.innerHTML = I18n.t('msg_active_users', {
          sku: currentSku,
          maxUsers: window.accountSettings.subscription.allowed_licensed,
          activeUserCount: activeUserCount,
          resourceUserCount: resourceUserCount,
        });
        d.innerHTML +=
          ' Your account is being billed directly via invoice. Contact support for questions.';

        var mgnTop = " style='margin-top: 10px;'";
        var invoiceTemplate = _.template(
          "<div class='settingsSpacer3'></div>" +
            "<div class='fnt-b-14'>Invoice information</div>" +
            '<div>' +
            '<div' +
            mgnTop +
            '>Company Name: <br/><%= invoice_company %></div>' +
            '<div' +
            mgnTop +
            ">Address: <br/><%= (invoice_address || '').replace(/\\n|\\r/g,'<br/>') %></div>" +
            '<div' +
            mgnTop +
            '>Contact Name: <br/><%= invoice_contact %></div>' +
            '<div' +
            mgnTop +
            '>Contact Email: <br/><%= invoice_email %></div>' +
            '<% if (invoice_po_number) { %><div' +
            mgnTop +
            '>PO Number: <br/><%= invoice_po_number %></div><% } %>' +
            '</div>'
        );
        d.innerHTML += '<br/>' + invoiceTemplate(currentBillingInfo);
        _i.container.appendChild(d);
        break;

      case 'free':
        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-r-14 settingsBillingCurrentInfoLabel';
        d.innerHTML = "You're on the free plan, because you're special. Enjoy!";
        _i.container.appendChild(d);
        hideCloseAccountOptions = true;
        break;

      case 'group':
        d = document.createElement('DIV');
        d.className = 'blockFloatNot fnt-r-14 settingsBillingCurrentInfoLabel';
        d.innerHTML =
          "You're on a group plan. Contact support for questions or changes.";
        _i.container.appendChild(d);
        hideCloseAccountOptions = true;
        break;
    }

    if (hideCloseAccountOptions == false) {
      d = document.createElement('DIV');
      d.className = 'blockFloatNot fnt-b-14';
      d.innerHTML = I18n.t('lbl_closing_account');
      _i.container.appendChild(d);

      spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot wizardVspacer15px';
      _i.container.appendChild(spacer);

      d = document.createElement('DIV');
      d.className = 'blockFloatNot fnt-r-14';
      d.innerHTML = I18n.t('msg_close_account_contact');
      _i.container.appendChild(d);

      spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot wizardVspacer15px';
      _i.container.appendChild(spacer);

      _i.closeAccountButton = new destructiveButton(
        'blockFloatNot spritesButtonTextContainerWide closeaccountbutton'
      );
      _i.closeAccountButton.setButtonText(I18n.t('lbl_close_account'));
      _i.closeAccountButton.container.id = 'closeaccountbutton';
      _i.closeAccountButton.setCallback(function () {
        report_page_view('/close_account_widget');
        window.open(window.SMARTSHEET_SUPPORT_URL, '_blank');
      });
      _i.container.appendChild(_i.closeAccountButton.container);
      _i.closeAccountButton.container.tabIndex = 0;
    }
  };

  _i.constructor = function () {
    var remainingCallbacks = 2;

    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);

    _i.wizardView = new wizardView();
    _i.wizardView.onclosed = _i.wizardOnClosedCallback;

    _i.billingVM = new billingVM();

    _i.pspinner = new pageSpinner();

    function cb() {
      if (--remainingCallbacks == 0) {
        _i.billingWizard = new billingWizard(_i.wizardView, _i.billingVM);
        _i.buildPageContent(_i.billingVM.currentBillingInfo);
      }
    }

    _i.billingVM.loadPlans(cb);
    _i.billingVM.getCurrentBillingInfo(cb);
  };

  _i.constructor();
}
