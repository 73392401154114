function loadingSpinnerView(attachTo) {
  var _i = this;

  // support a string to get the id or a dom node
  _i.attachTo =
    typeof attachTo === 'string' ? xGetElementById(attachTo) : attachTo;

  _i.numCircles = 8;

  // setAttributeNS requires a custom namespace parameter,
  // even if it's not used. Just pass null.
  _i.nsNamespace = null;
  _i.namespaceURI = 'http://www.w3.org/2000/svg';

  _i.attributeMap = {
    0: {
      color: '#008592',
      x: '7.5',
      y: '1.5',
      r: '1.5',
    },
    1: {
      color: '#00948e',
      x: '11.7426407',
      y: '3.25735931',
      r: '1.5',
    },
    2: {
      color: '#01A68B',
      x: '13.75',
      y: '7.5',
      r: '1.5',
    },
    3: {
      color: '#00B887',
      x: '11.7426407',
      y: '11.7426407',
      r: '1.5',
    },
    4: {
      color: '#02C883',
      x: '7.5',
      y: '13.5',
      r: '1.5',
    },
    5: {
      color: '#02D880',
      x: '3.25735931',
      y: '11.7426407',
      r: '1.5',
    },
    6: {
      color: '#02E47D',
      x: '1.5',
      y: '7.5',
      r: '1.5',
    },
    7: {
      color: '#007A93',
      x: '3.25735931',
      y: '3.25735931',
      r: '1.5',
    },
  };

  _i.render = function () {
    _i.spinnerContainer = document.createElement('div');
    _i.spinnerContainer.className = 'loadingContainer blockFloatNot';

    var svgContainer = document.createElement('div');
    svgContainer.className = 'view-box-15';

    var svg = document.createElementNS(_i.namespaceURI, 'svg');
    svg.setAttributeNS(null, 'viewBox', '0 0 15 15');

    for (var i = 0; i < _i.numCircles; i++) {
      var circle = document.createElementNS(_i.namespaceURI, 'circle');
      circle.setAttributeNS(_i.nsNamespace, 'cy', _i.attributeMap[i].y);
      circle.setAttributeNS(_i.nsNamespace, 'cx', _i.attributeMap[i].x);
      circle.setAttributeNS(_i.nsNamespace, 'r', _i.attributeMap[i].r);
      circle.setAttributeNS(_i.nsNamespace, 'fill', _i.attributeMap[i].color);
      svg.appendChild(circle);
    }

    svgContainer.appendChild(svg);

    var loadingText = document.createElement('span');
    loadingText.innerHTML = I18n.t('lbl_loading');
    loadingText.className = 'loadingSpinnerText fnt-r-14';
    _i.spinnerContainer.appendChild(svgContainer);
    _i.spinnerContainer.appendChild(loadingText);
    _i.attachTo.appendChild(_i.spinnerContainer);
  };

  _i.render();

  // Return the container to it can be referenced for removal via
  // Node.removeChild();
  return _i.spinnerContainer;
}
