function assignmentCountRowItem(acbvm, config, row, gvm, glm) {
  var _ELEMENT_MARGIN_DEFAULT = 0,
    _starts_at = new Date(acbvm.startTime),
    _ends_at = new Date(acbvm.endTime),
    _assignmentCount = acbvm.assignmentCount;

  var _i = this,
    _el,
    _elLabelWraper,
    _elScheduledHeaderLabel,
    _elScheduledHeaderLabelWidth,
    _elScheduledLabel,
    _elScheduledLabelWidth,
    _elUnusedLabel;

  _i.acbvm = acbvm;
  _i.row = row;
  _i.gvm = gvm;

  function getAssignmentCountLabel() {
    if (_assignmentCount == 0) {
      return I18n.t('lbl_no_assignments');
    } else if (_assignmentCount == 1) {
      return I18n.t('lbl_one_assigment');
    } else {
      return I18n.t('lbl_multiple_assignments', {
        count: _assignmentCount,
      });
    }
  }

  // assignment count items are not editable.
  _i.isEditable = false;
  _i.isDraggable = false;

  _i.getId = function () {
    return _i.acbvm.startTime; // use start time as the unique id
  };

  _i.getConfig = function () {
    return config;
  };

  _i.getStartsAt = function () {
    return _starts_at;
  };

  _i.setStartsAt = function (d, callback) {
    __A(false, 'not editable');
  };

  _i.getEndsAt = function () {
    return _ends_at;
  };

  _i.setEndsAt = function (d, callback) {
    __A(false, 'not editable');
  };

  _i.getStackPosition = function () {
    return 0;
  };

  _i.getColor = function () {
    var a = _assignmentCount;
    if (a > 0) {
      return 'a100';
    } else {
      return 'a0';
    }
  };

  _i.getOpacity = function () {
    return 1.0;
  };

  _i.measure = function () {
    if (!_elScheduledLabelWidth) {
      // lazy init, after the element has been attached
      _elScheduledLabelWidth = _elScheduledLabel.offsetWidth;
    }
  };

  /* updateElement does two things. 1). Ensure that the contents are positioned
   * with the specified margin, so that when scrolling, we maximize visibility of that
   * content and 2). sub-element properties are set appropriately to ensure important
   * imformation is given priority in the event the available space cannot fit everything.
   *
   * The basic mechnism for the latter is based on the prototype here
   * http://jsfiddle.net/4F3et/11/
   */
  _i.updateElement = function (width, marginLeft) {
    var cw = width - _ELEMENT_MARGIN_DEFAULT * 2; // content width

    _i.measure();

    if (marginLeft && marginLeft > _ELEMENT_MARGIN_DEFAULT) {
      _el.style.marginLeft = marginLeft + 'px';
      cw = width - marginLeft - _ELEMENT_MARGIN_DEFAULT /* marginRight */;
    } else {
      _el.style.marginLeft = _ELEMENT_MARGIN_DEFAULT + 'px';
    }

    if (cw > _elScheduledLabelWidth) {
      _elScheduledHeaderLabel.style.maxWidth =
        cw - _elScheduledLabelWidth + 'px';
    } else {
      _elScheduledHeaderLabel.style.maxWidth = '';
    }

    _elScheduledHeaderLabel.style.display = cw < 100 ? 'none' : '';
    _el.style.display = cw < 40 ? 'none' : '';
  };

  _i.getElement = function () {
    if (!_el) {
      _el = Awe.createElement('div', null, {
        attrs: {
          className:
            'rowItemContent ' +
            (_assignmentCount == 0
              ? 'fnt-r-12-ws utilizationItemLabelDark'
              : 'fnt-r-12-ds'),
        },
        styles: {
          position: 'absolute',
          left: '0px',
          right: '0px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          marginLeft: _ELEMENT_MARGIN_DEFAULT + 'px',
          marginRight: _ELEMENT_MARGIN_DEFAULT + 'px',
        },
      });

      _elLabelWraper = Awe.createElement('div', _el, {
        attrs: { className: 'utilizationRowItemLabelWrapper' },
      });

      _elScheduledHeaderLabel = Awe.createElement('div', _elLabelWraper, {
        attrs: { className: 'rowItemLabel1 utilizationItemLabel' },
      });
      // _elScheduledHeaderLabel.innerHTML =  "<span class='fnt-r-14-ds'>" + "Scheduled</span>";
      _elScheduledLabel = Awe.createElement('div', _elLabelWraper, {
        attrs: { className: 'rowItemLabel4 utilizationItemLabel' },
      });
      _elScheduledLabel.innerHTML = getAssignmentCountLabel();

      // todo cleanup _elUnusedLabel
      _elUnusedLabel = Awe.createElement('div', _elLabelWraper, {
        attrs: { className: 'rowItemLabel3 utilizationItemLabel' },
      });
    }

    return _el;
  };

  _i.getTitleText = function () {
    return '';
  };

  _i.getGridMenuItems = function (date, timeUnit) {
    return null;
  };
}
