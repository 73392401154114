function CustomFieldValuesSelectionList(
  field,
  selectionChangedCallback,
  containerId,
  labelText,
  blockFloat,
  multiSelect
) {
  var _i = this,
    _nilPlaceholder = '[ None ]';
  _i.fieldOptions = [];
  _i.fieldOptionsPopup = null;
  _i.fieldOptionsInput = null;
  _i.selectedIndices = [];

  function setSelectedIndices(currentValues) {
    _i.selectedIndices = [];
    var valuesNotFound = [];
    var selectionText = [];

    if (currentValues && field.options) {
      for (var i = 0; i < currentValues.length; i++) {
        // Ignore null and blank values.
        if (currentValues[i] == null || currentValues[i] == '') continue;

        index = field.options.indexOf(currentValues[i]);
        if (index >= 0) {
          _i.selectedIndices.push(index);
          _i.fieldOptions[index].selected = true;
          selectionText.push(currentValues[i]);
        } else {
          valuesNotFound.push(currentValues[i]);
        }
      }
    }

    if (valuesNotFound.length > 0) {
      // Values are not present in the list of valid options - existing values may have been deleted as an option.
      // They are still valid since they're already on the project. Add as the first options.
      for (var i = 0; i < valuesNotFound.length; i++) {
        var index = _i.fieldOptions.length;
        _i.fieldOptions.push({ type: 'item', id: index });
        _i.fieldOptions[index].label = valuesNotFound[i];
        _i.fieldOptions[index].value = valuesNotFound[i];
        _i.fieldOptions[index].selected = true;
        _i.selectedIndices.push(index);
        selectionText.push(valuesNotFound[i]);
      }
    }

    return selectionText.length > 0
      ? selectionText.join(', ')
      : _nilPlaceholder;
  }

  function populateFieldOptions(currentValues) {
    _i.fieldOptions = [];

    // First push all the valid options.
    field.options &&
      _.each(field.options, function (option) {
        var length = _i.fieldOptions.length;
        _i.fieldOptions.push({ type: 'item' });
        _i.fieldOptions[length].value = option;
        _i.fieldOptions[length].label = option;
        _i.fieldOptions[length].id = length;
        _i.fieldOptions[length].selected = false;
      });

    // Set selected indices and mark values selected. This may add additional options if
    // current values are not in list of valid options.
    return setSelectedIndices(currentValues);
  }

  function setInputText(value) {
    _i.fieldOptionInput.inputText.innerText = value;
  }

  function onFieldValueChanged(values) {
    _i.selectedIndices = [];
    var textValues = [];
    var selected = [];
    values &&
      _.each(values, function (value) {
        _i.selectedIndices.push(value.id);
        textValues.push(value.value);
        selected[value.id] = 1;
      });

    for (var i = 0; i < _i.fieldOptions.length; i++) {
      if (selected[i]) {
        _i.fieldOptions[i].selected = true;
      } else {
        _i.fieldOptions[i].selected = false;
      }
    }

    setInputText(
      textValues.length > 0 ? textValues.join(', ') : _nilPlaceholder
    );

    $(_i.fieldOptionInput.labelText).css({
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      width: 'inherit',
      'white-space': 'nowrap',
    });

    if (selectionChangedCallback) {
      selectionChangedCallback(field, textValues);
    }
  }

  function isValidOption(value) {
    return _.find(_i.fieldOptions, function (option) {
      return stringComparerCaseInsensitive(option.value, value) == 0;
    });
  }

  _i.show = function (currentValues) {
    var selectionText = populateFieldOptions(currentValues);

    _i.fieldOptionsPopup = new filterPopup(
      240,
      260,
      containerId,
      _i.fieldOptions,
      compSpritesButtonsIcons.blackBarDialog,
      onFieldValueChanged,
      multiSelect /*multiSelect*/,
      null /*blurCallback*/,
      'ws',
      ['ws', 'wc', 'wn'],
      true,
      false,
      null, // parentPopupContainer
      null
    );

    _i.fieldOptionInput = new PopUpTextInput(
      containerId,
      labelText,
      null,
      'blockFloat' +
        (blockFloat ? '' : 'Not') +
        ' editCustomFieldEditInputContainer',
      'blockFloat fnt-r-14 editCustomFieldPopupInputButton',
      'blockFloat fnt-r-13 editCustomFieldTextInputLabel',
      'blockFloat fnt-r-15 editCustomFieldPopupInput',
      _i.fieldOptions,
      _i.fieldOptionsPopup
    );

    setInputText(selectionText);

    if (field.description) {
      _i.fieldOptionInput.labelText.title = field.description;
    }

    $(_i.fieldOptionInput.labelText).css({
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      width: 'inherit',
      'white-space': 'nowrap',
    });
  };
}
