function PercentageArc(
  attach,
  diameter,
  percentage,
  color_complete,
  color_empty
) {
  var _i = this;

  if (!(this instanceof PercentageArc)) {
    return new PercentageArc(
      attach,
      diameter,
      percentage,
      color_complete,
      color_empty
    );
  }

  _i.attachTo = xGetElementById(attach);
  _i.diameter = diameter;
  _i.percentage = percentage;
  _i.color_complete = color_complete;
  _i.color_empty = color_empty;
  _i.constructor = function () {
    _i.canvas = document.createElement('CANVAS');
    _i.canvas.width = _i.diameter;
    _i.canvas.height = _i.diameter;
    var context = _i.canvas.getContext('2d');
    var centerX = _i.canvas.width / 2;
    var centerY = _i.canvas.height / 2;
    var radius = _i.diameter / 2; // - strokeWidth;
    var startingAngle = -0.5 * Math.PI;
    var endingAngle = 2.0 * Math.PI * _i.percentage - 0.5 * Math.PI;
    if (_i.percentage == 0) endingAngle = startingAngle - 0.01;
    var counterclockwise = false;

    if (percentage > 0.0) {
      context.arc(
        centerX,
        centerY,
        radius,
        startingAngle,
        endingAngle,
        counterclockwise
      );
      context.lineTo(centerX, centerY);
      context.fillStyle = _i.color_complete;
      context.fill();
    } else {
      context.arc(
        centerX,
        centerY,
        radius,
        startingAngle,
        endingAngle,
        counterclockwise
      );
      context.lineTo(centerX, centerY);
      context.fillStyle = _i.color_empty;
      context.fill();
    }
    context.closePath();
    context.beginPath();
    if (percentage < 1.0) {
      context.arc(
        centerX,
        centerY,
        radius,
        endingAngle,
        startingAngle,
        counterclockwise
      );
      context.lineTo(centerX, centerY);
      context.fillStyle = _i.color_empty;
      context.fill();
    }
    _i.attachTo.appendChild(_i.canvas);
  };

  _i.constructor();
}
