function certificateUpload(attach, callback, replace) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.callback = callback;
  _i.base64EncodedFile = null;
  _i.replace = replace;

  _i.chooseFile = function () {
    document.getElementById('upfile').click();
  };

  _i.checkFile = function () {
    var fileName = _i.file.value.toUpperCase();
    var suffix = fileName.split('.').pop().toLowerCase();
    if (!(['cer', 'crt', 'pem'].indexOf(suffix) >= 0)) {
      new notificationPopup(
        I18n.t('err_invalid_file_type'),
        I18n.t('msg_valid_file_ext_type'),
        6000
      );
      return false;
    }
    return true;
  };

  _i.fileSelected = function () {
    if (!_i.checkFile()) {
      return;
    }
    _i.base64EncodeFile();

    // Set replace mode.
    _i.replace = true;
    _i.editCertificateButton.setButtonText(
      I18n.t('lbl_replace_certificate_file')
    );
  };

  _i.base64EncodeFile = function () {
    var reader = new FileReader();
    if (_i.file.files && _i.file.files.length > 0) {
      reader.readAsDataURL(_i.file.files[0]);
      reader.onload = function () {
        _i.base64EncodedFile = reader.result.split(',')[1];
        _i.callback(true, _i.base64EncodedFile);
      };
      reader.onerror = function (error) {
        _i.callback(false, null);
      };
    } else {
      _i.callback(false, null);
    }
  };

  _i.constructor = function () {
    var MARGIN = '10px';

    _i.labelText = document.createElement('DIV');
    _i.labelText.className = 'blockFloatNot fnt-r-13 defaultTextInputLabel';
    _i.labelText.style.marginTop = MARGIN;

    _i.labelText.innerHTML = I18n.t('msg_certificate_encoded');
    _i.attachTo.appendChild(_i.labelText);

    _i.editCertificateButton = new secondaryButton(
      'blockFloatNot chooseCertificateButton'
    );
    if (_i.replace) {
      _i.editCertificateButton.setButtonText(
        I18n.t('lbl_replace_certificate_file')
      );
    } else {
      _i.editCertificateButton.setButtonText(
        I18n.t('lbl_choose_certificate_file')
      );
    }
    _i.editCertificateButton.setCallback(_i.chooseFile);
    _i.attachTo.appendChild(_i.editCertificateButton.container);
    _i.editCertificateButton.container.tabIndex = 0;

    _i.form = document.createElement('FORM');
    _i.form.method = 'post';
    _i.form.enctype = 'multipart/form-data';
    _i.form.encoding = 'multipart/form-data';
    _i.attachTo.appendChild(_i.form);

    _i.hide = document.createElement('DIV');
    _i.hide.style.height = '0px';
    _i.hide.style.display = 'none';
    _i.hide.style.overflow = 'hidden';
    _i.form.appendChild(_i.hide);

    _i.file = document.createElement('INPUT');
    _i.file.type = 'file';
    _i.file.name = 'file';
    _i.file.id = 'upfile';

    _i.hide.style.height = '32px';
    _i.hide.style.width = '95px';
    _i.hide.style.position = 'absolute';
    _i.hide.style.top = '50px';

    _i.file.style.height = 'inherit';
    _i.file.style.opacity = '0';

    _i.hide.appendChild(_i.file);

    xAddEventListener(_i.file, 'change', _i.fileSelected, false);
  };
  _i.constructor();
}
