(function (window) {
  'use strict';

  var billingURL = 'https://www.smartsheet.com/contact/10000ft-billing?fts=p',
    learnMoreURL =
      'https://www.10000ft.com/plans/reference/integration/api-overview';

  var boxHeaderText = I18n.t('lbl_subscribe_api_access'),
    messageText = I18n.t('msg_api_upsell_description'),
    buttonText = I18n.t('lbl_contact_us');

  var template = _.template(
    '<div class="blockFloatNot fnt-r-14">' +
      '<h2 class="tk-box__header">' +
      boxHeaderText +
      '</h2>' +
      '<div class="tk-box__message">' +
      messageText +
      ' <a target = "_blank" href="' +
      learnMoreURL +
      `">${I18n.t('lbl_learn_more')}</a>` +
      '</div>' +
      '<button class="tk-button tk-button--primary">' +
      buttonText +
      '</button>' +
      '</div>'
  );

  window.SettingsDeveloperApiUpsell = Backbone.View.extend({
    className: 'tk-box blockFloatNot settingsMyPreferencesContentContainer',
    template: template,
    events: {
      'click .tk-button': 'navToBillingPage',
    },
    initialize: function (options) {
      this.attachTo = options.attachTo;
      this.render();
    },
    render: function () {
      this.$el.html(this.template()).css({
        marginTop: 26,
        marginBottom: 50,
        padding: 40,
      });
      $('#' + this.attachTo).append(this.el);
      this.addOverlayImage();
    },
    navToBillingPage: function () {
      window.location.assign(billingURL);
    },
    addOverlayImage: function () {
      var imageURL = STATIC_IMAGE_URL + '/Developer_API_Upgrade_Overlay.jpg';
      var img = $('<img src="' + imageURL + '">').css({
        width: 560,
        height: 600,
        opacity: 0.25,
      });

      $('#' + this.attachTo).append(img);
    },
  });
})(window);
