function ProjectCalendarCompleteSummaryView(attach, obj, percent) {
  var _i = this;
  _i.attachTo = xGetElementById(attach);
  _i.attach = attach;
  _i.project = obj;

  _i.constructor = function () {
    _i.headerText = document.createElement('DIV');
    _i.headerText.className =
      'blockFloatNot fnt-b-12 darkHeaderText projectCalendarCompleteSummaryViewHeader';
    _i.headerText.innerText =
      (_i.project.client ? _i.project.client + ' ' : '') + _i.project.name;
    _i.attachTo.appendChild(_i.headerText);
    ProjectPercentageStatisticView(
      _i.attach,
      percent / 100,
      percent.toFixed() + '%',
      I18n.t('lbl_calendar_days_in')
    );
  };
  _i.constructor();
}
