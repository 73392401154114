/*
 * A popup that provides a prompt and standard yes/no options. The popup will
 * dismiss itself when either the yes or no option is chosen by the user, and corresponding
 * callbacks will be invoked.
 *
 * If the user dismisses the popup using standard popup dismiss action (e.g. hitting
 * the ESC key), the cancelledCallback will not be invoked.
 *
 * prompt - A text message, usually in the form of a yes/no question
 * yesButtonText - String value to use for the yes button. Pass null to use default
 * confirmationCallback - function to invoke when the user selects the yes option.
 * cancelledCallback - optional function to invoke when the user select the no option.
 * parentPopupContainer - container to append to
 * blurCallback - function for onBlur callback
 * height - optional height
 * width - optional width
 * additionalButtons - Array of buttons objects to include
 * titleText - String parameter that specifies a title for the confirmationPopup instance
 * adjustWidthForLanguage - optional Boolean to apply styling intended for localization
 */

function confirmationPopup(
  prompt,
  yesButtonText,
  confirmedCallback,
  cancelledCallback,
  parentPopupContainer,
  blurCallback,
  height,
  width,
  additionalButtons, // [ { text: "text", callback: cbFunction }, ... ],
  titleText,
  adjustWidthForLanguage,
  adjustWidthForLanguageAvailabilityPopup
) {
  if (!(this instanceof confirmationPopup)) {
    return new confirmationPopup(
      prompt,
      yesButtonText,
      confirmedCallback,
      cancelledCallback,
      parentPopupContainer,
      blurCallback,
      height,
      width,
      additionalButtons,
      titleText,
      adjustWidthForLanguage,
      adjustWidthForLanguageAvailabilityPopup
    );
  }

  POPUP_MAX_WIDTH = 340;
  AVAILABILITY_POPUP_MAX_WIDTH = 280;
  var WIDTH = width ? width : 240;
  var HEIGHT = height ? height : 100;
  var MESSAGE_AREA_PADDING = 10;
  var MESSAGE_AREA_HEIGHT = HEIGHT - 40;

  if (adjustWidthForLanguage) {
    if (adjustWidthForLanguageAvailabilityPopup) {
      WIDTH = Math.min(
        WIDTH * window.rm.l10n.getWidthMultiplier(),
        AVAILABILITY_POPUP_MAX_WIDTH
      );
    } else {
      WIDTH = Math.min(
        WIDTH * window.rm.l10n.getWidthMultiplier(),
        POPUP_MAX_WIDTH
      );
    }
  }

  var _i = this;

  _i.confirmedCallback = confirmedCallback;
  _i.cancelledCallback = cancelledCallback;
  _i.blurCallback = blurCallback;
  _i.additionalButtons = additionalButtons ? additionalButtons : [];
  _i.popupContainer;

  function confirmedCbInternal() {
    _i.popupContainer.hide();
    _i.popupContainer.remove();
    if (_i.confirmedCallback) _i.confirmedCallback();
  }

  function cancelledCbInternal() {
    _i.popupContainer.hide();
    _i.popupContainer.remove();
    if (_i.cancelledCallback) _i.cancelledCallback();
  }

  function blurCbInternal() {
    _i.popupContainer.remove();
    _i.blurCallback && _i.blurCallback();
  }

  _i.show = function (attachToElement) {
    _i.popupContainer.pointAtElement(attachToElement);
  };

  _i.showAtPoint = function (x, y) {
    _i.popupContainer.pointAt(x, y, 0, 0);
  };

  _i.blur = function () {
    blurCbInternal();
  };

  function init() {
    var spec = parentPopupContainer
      ? compSpritesButtonsIcons.wideDialogSpecsGray
      : compSpritesButtonsIcons.wideDialogSpecs;

    var viewport = document.getElementById('mainCon');

    var content = document.createElement('DIV');
    content.style.width = WIDTH + 'px';
    content.style.height = HEIGHT + 'px';
    content.id = 'content';
    adjustWidthForLanguage
      ? (content.className = 'confirmationPopupContainer')
      : (content.className = null);
    content.tabIndex = '0';

    var message = document.createElement('DIV');
    adjustWidthForLanguage
      ? (message.className =
          'blockFloatNot fnt-r-14 confirmationPopupLabelContainer')
      : (message.className = 'blockFloatNot fnt-r-14');
    message.id = 'float-alert-message';
    message.tabIndex = '-1';
    message.setAttribute('title', prompt);
    message.style.width = WIDTH - MESSAGE_AREA_PADDING * 2 + 'px';
    adjustWidthForLanguage
      ? (message.style.maxHeight = 'auto')
      : (message.style.height =
          MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 2 + 'px');
    message.style.padding = MESSAGE_AREA_PADDING + 'px';
    message.style.boxSizing = 'content-box';
    message.style.hyphens = 'auto';
    message.innerText = prompt;

    content.appendChild(message);

    if (titleText) {
      var title = document.createElement('p');
      title.style.fontWeight = 'bold';
      title.innerText = titleText;
      message.prepend(title);
    }

    var buttons = document.createElement('DIV');
    var availabilityPopup = false;
    if (adjustWidthForLanguage && adjustWidthForLanguageAvailabilityPopup) {
      buttons.className =
        'blockFloatNot confirmationAvailabilityPopupButtonContainer';
      availabilityPopup = true;
    } else {
      adjustWidthForLanguage
        ? (buttons.className = 'blockFloatNot confirmationPopupButtonContainer')
        : (buttons.className = 'blockFloatNot');
    }
    availabilityPopup
      ? (buttons.style.width = '100%')
      : (buttons.style.width = 'auto');
    adjustWidthForLanguage
      ? (buttons.style.height = 'auto')
      : (buttons.style.height =
          HEIGHT - MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 3 + 'px');
    buttons.style.paddingLeft = buttons.style.paddingRight =
      MESSAGE_AREA_PADDING + 'px';
    if (!availabilityPopup) {
      buttons.style.paddingBottom = MESSAGE_AREA_PADDING + 'px';
    }
    buttons.style.float = 'right';

    content.appendChild(buttons);

    var cancelButton = new secondaryButton('confirmationPopupCancelButton');
    cancelButton.setButtonText(I18n.t('lbl_cancel'));
    cancelButton.setCallback(cancelledCbInternal);
    adjustWidthForLanguage
      ? (cancelButton.container.style.width = '100%')
      : (cancelButton.container.style.width = 'auto');

    buttons.appendChild(cancelButton.container);

    _i.additionalButtons.forEach(function (ab) {
      var btn = new destructiveButton(
        'confirmationPopupMiddleButton confirmationPopupYesButton'
      );
      btn.setButtonText(ab.text);
      btn.setCallback(function () {
        _i.popupContainer.hide();
        _i.popupContainer.remove();
        ab.callback && ab.callback();
      });
      adjustWidthForLanguage
        ? (btn.container.style.width = '100%')
        : (btn.container.style.width = 'auto');
      buttons.appendChild(btn.container);
    });

    var confirmButton = new destructiveButton('confirmationPopupYesButton');
    confirmButton.container.id = 'confirmButtonId';
    confirmButton.setButtonText(yesButtonText || I18n.t('lbl_yes'));
    confirmButton.setCallback(confirmedCbInternal);
    adjustWidthForLanguage
      ? (confirmButton.container.style.width = '100%')
      : (confirmButton.container.style.width = 'auto');

    buttons.appendChild(confirmButton.container);

    _i.popupContainer = new popupContainer(
      spec,
      WIDTH,
      HEIGHT,
      3, // innerMargin
      true, // autorotate
      'wc',
      viewport,
      ['wc'],
      _i.blur, // blurCallback
      parentPopupContainer
    );

    _i.popupContainer.attachContent(content);
  }

  init();
}

function primaryConfirmationOnlyPopup(
  prompt,
  yesButtonText,
  confirmedCallback,
  cancelledCallback,
  parentPopupContainer,
  blurCallback,
  height,
  width
) {
  if (!(this instanceof primaryConfirmationOnlyPopup)) {
    return new primaryConfirmationOnlyPopup(
      prompt,
      yesButtonText,
      confirmedCallback,
      cancelledCallback,
      parentPopupContainer,
      blurCallback,
      height,
      width
    );
  }

  var WIDTH = width ? width : 260;
  var HEIGHT = height ? height : 100;
  var MESSAGE_AREA_PADDING = 10;
  var MESSAGE_AREA_HEIGHT = HEIGHT - 40;

  var _i = this;

  _i.confirmedCallback = confirmedCallback;
  _i.cancelledCallback = cancelledCallback;
  _i.blurCallback = blurCallback;
  _i.popupContainer;

  function confirmedCbInternal() {
    _i.popupContainer.hide();
    _i.popupContainer.remove();
    if (_i.confirmedCallback) _i.confirmedCallback();
  }

  function cancelledCbInternal() {
    _i.popupContainer.hide();
    _i.popupContainer.remove();
    if (_i.cancelledCallback) _i.cancelledCallback();
  }

  function blurCbInternal() {
    _i.popupContainer.remove();
    _i.blurCallback && _i.blurCallback();
  }

  _i.show = function (attachToElement) {
    _i.popupContainer.pointAtElement(attachToElement);
  };

  _i.showAtPoint = function (x, y) {
    _i.popupContainer.pointAt(x, y, 0, 0);
  };

  _i.blur = function () {
    blurCbInternal();
  };

  function init() {
    var spec = parentPopupContainer
      ? compSpritesButtonsIcons.wideDialogSpecsGray
      : compSpritesButtonsIcons.wideDialogSpecs;

    var viewport = document.getElementById('mainCon');

    var content = document.createElement('DIV');
    content.style.width = WIDTH + 'px';
    content.style.height = HEIGHT + 'px';
    content.style.textAlign = 'center';

    var message = document.createElement('DIV');
    message.className = 'blockFloatNot fnt-r-14';
    message.style.width = WIDTH - MESSAGE_AREA_PADDING * 2 + 'px';
    message.style.height =
      MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 2 + 'px';
    message.style.padding = MESSAGE_AREA_PADDING + 'px';
    message.style.boxSizing = 'content-box';
    message.style.textAlign = 'left';

    message.innerText = prompt;

    content.appendChild(message);

    var buttons = document.createElement('DIV');
    buttons.style.width = 'auto';
    buttons.style.height =
      HEIGHT - MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 3 + 'px';
    buttons.style.display = 'inline-block';
    //buttons.style.paddingLeft = buttons.style.paddingRight = buttons.style.paddingBottom = MESSAGE_AREA_PADDING + "px";
    //buttons.style.float = "right";

    content.appendChild(buttons);

    var confirmButton = new primaryButton('confirmationPopupYesButton');
    confirmButton.setButtonText(yesButtonText || I18n.t('lbl_yes'));
    confirmButton.setCallback(confirmedCbInternal);

    buttons.appendChild(confirmButton.container);

    _i.popupContainer = new popupContainer(
      spec,
      WIDTH,
      HEIGHT,
      3, // innerMargin
      true, // autorotate
      'wc',
      viewport,
      ['wc'],
      _i.blur, // blurCallback
      parentPopupContainer
    );

    _i.popupContainer.attachContent(content);
  }

  init();
}

function threeButtonPopup(
  prompt,
  buttonText1,
  buttonText2,
  buttonText3,
  callback1,
  callback2,
  callback3,
  parentPopupContainer,
  blurCallback,
  height
) {
  if (!(this instanceof threeButtonPopup)) {
    return new threeButtonPopup(
      prompt,
      buttonText1,
      buttonText2,
      buttonText3,
      callback1,
      callback2,
      callback3,
      parentPopupContainer,
      blurCallback,
      height
    );
  }
  var WIDTH = 400;
  var HEIGHT = height ? height : 100;
  var MESSAGE_AREA_PADDING = 10;
  var MESSAGE_AREA_HEIGHT = HEIGHT - 40;

  var _i = this;

  _i.callback1 = callback1;
  _i.callback2 = callback2;
  _i.callback3 = callback3;
  _i.buttonText1 = buttonText1;
  _i.buttonText2 = buttonText2;
  _i.buttonText3 = buttonText3;
  _i.blurCallback = blurCallback;
  _i.popupContainer;

  _i.buttonClick1 = function () {
    _i.popupContainer.hide();
    if (_i.callback1) _i.callback1();
  };

  _i.buttonClick2 = function () {
    _i.popupContainer.hide();
    if (_i.callback2) _i.callback2();
  };

  _i.buttonClick3 = function () {
    _i.popupContainer.hide();
    if (_i.callback3) _i.callback3();
  };

  _i.show = function (attachToElement) {
    _i.popupContainer.pointAtElement(attachToElement);
  };

  _i.showAtPoint = function (x, y) {
    _i.popupContainer.pointAt(x, y, 0, 0);
  };

  _i.blur = function () {
    if (_i.blurCallback) _i.blurCallback();
  };

  _i.init = function () {
    var spec = parentPopupContainer
      ? compSpritesButtonsIcons.wideDialogSpecsGray
      : compSpritesButtonsIcons.wideDialogSpecs;

    var viewport = document.getElementById('mainCon');

    var content = document.createElement('DIV');
    content.style.width = WIDTH + 'px';
    content.style.height = HEIGHT + 'px';

    var message = document.createElement('DIV');
    message.className = 'blockFloatNot fnt-r-14';
    message.style.width = WIDTH - MESSAGE_AREA_PADDING * 2 + 'px';
    message.style.height =
      MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 2 + 'px';
    message.style.padding = MESSAGE_AREA_PADDING + 'px';
    message.innerText = prompt;

    content.appendChild(message);

    var buttons = document.createElement('DIV');
    buttons.className = 'blockFloatNot';
    buttons.style.width = 'auto';
    buttons.style.height =
      HEIGHT - MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 3 + 'px';
    buttons.style.paddingLeft =
      buttons.style.paddingRight =
      buttons.style.paddingBottom =
        MESSAGE_AREA_PADDING + 'px';
    buttons.style.float = 'right';

    content.appendChild(buttons);

    var button = new secondaryButton('confirmationPopupCancelButton');
    button.setButtonText(_i.buttonText1);
    button.setCallback(_i.buttonClick1);
    buttons.appendChild(button.container);

    button = new primaryButtonNarrow('confirmationPopupMiddleButton');
    button.setButtonText(_i.buttonText2);
    button.setCallback(_i.buttonClick2);
    buttons.appendChild(button.container);

    button = new primaryButtonNarrow('confirmationPopupYesButton');
    button.setButtonText(_i.buttonText3);
    button.setCallback(_i.buttonClick3);
    buttons.appendChild(button.container);

    _i.popupContainer = new popupContainer(
      spec,
      WIDTH,
      HEIGHT,
      3, // innerMargin
      true, // autorotate
      'wc',
      viewport,
      ['wc'],
      _i.blur, // blurCallback
      parentPopupContainer
    );

    _i.popupContainer.attachContent(content);
  };

  _i.init();
}

/*
 * A popup that provides a prompt and standard yes/no options. The popup will
 * dismiss itself when either the yes or no option is chosen by the user, and corresponding
 * callbacks will be invoked.
 *
 * If the user dismisses the popup using standard popup dismiss action (e.g. hitting
 * the ESC key), the cancelledCallback will not be invoked.
 *
 * prompt - A text message, usually in the form of a yes/no question
 * yesButtonText - String value to use for the yes button. Pass null to use default
 * confirmationCallback - function to invoke when the user selects the yes option.
 * cancelledCallback - optional function to invoke when the user select the no option.
 * parentPopupContainer - container to append to
 * blurCallback - function for onBlur callback
 * height - optional height
 * width - optional width
 * additionalButtons - Array of buttons objects to include
 * titleText - String parameter that specifies a title for the confirmationPopup instance
 * adjustWidthForLanguage - optional Boolean to apply styling intended for localization
 */
function confirmationPopupPrimaryButton(
  prompt,
  yesButtonText,
  confirmedCallback,
  cancelledCallback,
  parentPopupContainer,
  blurCallback,
  height,
  width,
  additionalButtons, // [ { text: "text", callback: cbFunction }, ... ],
  titleText,
  adjustWidthForLanguage = false
) {
  if (!(this instanceof confirmationPopupPrimaryButton)) {
    return new confirmationPopupPrimaryButton(
      prompt,
      yesButtonText,
      confirmedCallback,
      cancelledCallback,
      parentPopupContainer,
      blurCallback,
      height,
      width,
      additionalButtons,
      titleText,
      adjustWidthForLanguage
    );
  }

  var MAX_WIDTH = 300;
  var WIDTH = width ? width : 240;
  var MESSAGE_AREA_MARGIN = 10;
  var HEIGHT = height ? height : 100;
  var MESSAGE_AREA_HEIGHT = HEIGHT - 40;
  if (adjustWidthForLanguage && !width) {
    WIDTH = Math.min(WIDTH * window.rm.l10n.getWidthMultiplier(), MAX_WIDTH);
    HEIGHT = height ? height : 140;
    MESSAGE_AREA_HEIGHT = HEIGHT - 75;
  }

  var _i = this;

  _i.confirmedCallback = confirmedCallback;
  _i.cancelledCallback = cancelledCallback;
  _i.blurCallback = blurCallback;
  _i.additionalButtons = additionalButtons ? additionalButtons : [];
  _i.popupContainer;

  function confirmedCbInternal() {
    _i.popupContainer.hide();
    _i.popupContainer.remove();
    if (_i.confirmedCallback) _i.confirmedCallback();
  }

  function cancelledCbInternal() {
    _i.popupContainer.hide();
    _i.popupContainer.remove();
    if (_i.cancelledCallback) _i.cancelledCallback();
  }

  function blurCbInternal() {
    _i.popupContainer.remove();
    _i.blurCallback && _i.blurCallback();
  }

  _i.show = function (attachToElement) {
    _i.popupContainer.pointAtElement(attachToElement);
  };

  _i.showAtPoint = function (x, y) {
    _i.popupContainer.pointAt(x, y, 0, 0);
  };

  _i.blur = function () {
    blurCbInternal();
  };

  function init() {
    var spec = parentPopupContainer
      ? compSpritesButtonsIcons.wideDialogSpecsGray
      : compSpritesButtonsIcons.wideDialogSpecs;

    var viewport = document.getElementById('mainCon');

    var content = document.createElement('DIV');
    content.style.width = WIDTH + 'px';
    content.style.height = HEIGHT + 'px';
    content.id = 'content';
    content.tabIndex = '0';

    var message = document.createElement('DIV');
    adjustWidthForLanguage
      ? (message.className =
          'blockFloatNot fnt-r-14 confirmationPopupLabelContainer')
      : (message.className = 'blockFloatNot fnt-r-14');
    message.id = 'float-alert-message';
    message.tabIndex = '-1';
    message.style.width = WIDTH - MESSAGE_AREA_MARGIN * 2 + 'px';
    message.style.height = MESSAGE_AREA_HEIGHT - MESSAGE_AREA_MARGIN * 2 + 'px';
    message.style.margin = MESSAGE_AREA_MARGIN + 'px';
    message.style.boxSizing = 'content-box';
    message.innerText = prompt;
    adjustWidthForLanguage
      ? (message.style.maxHeight = 'auto')
      : (message.style.height =
          MESSAGE_AREA_HEIGHT - MESSAGE_AREA_MARGIN * 2 + 'px');
    content.appendChild(message);

    if (titleText) {
      var title = document.createElement('p');
      title.style.fontWeight = 'bold';
      title.innerText = titleText;
      message.prepend(title);
    }

    var buttons = document.createElement('DIV');
    buttons.className = 'blockFloatNot';
    if (adjustWidthForLanguage) {
      buttons.className += ' confirmationPopupButtonContainer';
      buttons.style.height = 'auto';
    } else {
      buttons.style.height =
        100 - MESSAGE_AREA_HEIGHT - MESSAGE_AREA_MARGIN * 3 + 'px';
    }
    buttons.style.paddingLeft =
      buttons.style.paddingRight =
      buttons.style.paddingBottom =
        MESSAGE_AREA_MARGIN + 'px';
    buttons.style.float = 'right';

    content.appendChild(buttons);

    var cancelButton = adjustWidthForLanguage
      ? new secondaryButton('buttonContainerCenterWide', I18n.t('lbl_cancel'))
      : new secondaryButton(
          'confirmationPopupCancelButton',
          I18n.t('lbl_cancel')
        );
    cancelButton.setButtonText('Cancel');
    cancelButton.setCallback(cancelledCbInternal);

    buttons.appendChild(cancelButton.container);

    _i.additionalButtons.forEach(function (ab) {
      var btn = adjustWidthForLanguage
        ? new primaryButton('buttonContainerCenterWide')
        : new primaryButtonNarrow(
            'confirmationPopupMiddleButton confirmationPopupYesButton'
          );
      btn.setButtonText(ab.text);
      btn.setCallback(function () {
        _i.popupContainer.hide();
        _i.popupContainer.remove();
        ab.callback && ab.callback();
      });
      buttons.appendChild(btn.container);
    });

    var confirmButton = adjustWidthForLanguage
      ? new primaryButton('buttonContainerCenterWide', I18n.t('lbl_confirm'))
      : new primaryButtonNarrow(
          'confirmationPopupMiddleButton confirmationPopupCancelButton',
          I18n.t('lbl_confirm')
        );
    confirmButton.setButtonText(yesButtonText || I18n.t('lbl_yes'));
    confirmButton.setCallback(confirmedCbInternal);

    buttons.appendChild(confirmButton.container);

    _i.popupContainer = new popupContainer(
      spec,
      WIDTH,
      HEIGHT,
      3, // innerMargin
      true, // autorotate
      'wc',
      viewport,
      ['wc'],
      _i.blur, // blurCallback
      parentPopupContainer
    );

    _i.popupContainer.attachContent(content);
  }

  init();
}
