SPRITE_FILE_NAME =
  STATIC_IMAGE_URL + '/compSpritesButtonsIconsLodeStar_2021-10-25.png?1.0';
BACKGROUND = '#FFFFFF';

var compSpritesButtonsIcons = {
  inTheOffice: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -395,
    yOffset: -60,
  },

  workingFromHome: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -425,
    yOffset: -60,
  },

  onTheRoad: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -365,
    yOffset: -60,
  },

  homeSick: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -305,
    yOffset: -60,
  },

  onVacation: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -335,
    yOffset: -60,
  },
  outOffOffice: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -60,
    yOffset: -100,
  },

  listUpDownBackground: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -5,
    yOffset: -200,
  },

  listUpDownBackgroundHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -80,
    yOffset: -200,
  },

  listUpDownBackgroundDepress: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -155,
    yOffset: -200,
  },

  listUpArrow: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 26,
    height: 16,
    xOffset: -310,
    yOffset: -620,
  },

  listDownArrow: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 26,
    height: 16,
    xOffset: -340,
    yOffset: -620,
  },

  externalMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -235,
    yOffset: -250,
  },

  confirmedMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -235,
    yOffset: -250,
  },

  internalMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -255,
    yOffset: -250,
  },

  tentativeMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -295,
    yOffset: -250,
  },

  leaveMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -275,
    yOffset: -250,
  },

  wideDialogSpecsObsolete: {
    backgroundImage: 'images/dropdownDialog.png',
    width: 288,
    height: 194,
    xOffset: 0,
    yOffset: 0,
    xA: [27, 123, 165, 261],
    yA: [25, 69, 111, 165],
    relativeOffsets: true,
  },

  wideDialogSpecs: {
    backgroundImage: SPRITE_FILE_NAME,
    backgroundColor: '#fff',
    width: 286,
    height: 192,
    xOffset: -206,
    yOffset: -656,
    leftPointerWidth: 18,
    rightPointerWidth: 18,
    topPointerHeight: 16,
    bottomPointerHeight: 20,
    xA: [232, 328, 370, 465],
    yA: [680, 724, 766, 820],
  },

  blackBarDialog: {
    backgroundImage: SPRITE_FILE_NAME,
    backgroundColor: '#fff',
    headerBackgroundColor: '#000',
    width: 286,
    height: 192,
    xOffset: -206,
    yOffset: -961,
    leftPointerWidth: 18,
    rightPointerWidth: 18,
    topPointerHeight: 16,
    bottomPointerHeight: 20,
    xA: [232, 328, 370, 465],
    yA: [1027, 1029, 1071, 1126],
  },

  wideDialogSpecsGray: {
    backgroundImage: SPRITE_FILE_NAME,
    backgroundColor: BACKGROUND,
    width: 286,
    height: 192,
    xOffset: -496,
    yOffset: -486,
    leftPointerWidth: 18,
    rightPointerWidth: 18,
    topPointerHeight: 16,
    bottomPointerHeight: 20,
    xA: [522, 618, 660, 755],
    yA: [510, 554, 596, 650],
  },

  blackBarDialogGray: {
    backgroundImage: SPRITE_FILE_NAME,
    backgroundColor: BACKGROUND,
    headerBackgroundColor: '#000',
    width: 286,
    height: 192,
    xOffset: -496,
    yOffset: -291,
    leftPointerWidth: 18,
    rightPointerWidth: 18,
    topPointerHeight: 16,
    bottomPointerHeight: 20,
    xA: [522, 618, 660, 755],
    yA: [357, 359, 401, 456],
  },

  feedPopup: {
    backgroundImage: SPRITE_FILE_NAME,
    backgroundColor: BACKGROUND,
    width: 286,
    height: 192,
    xOffset: -961,
    yOffset: -6,
    leftPointerWidth: 18,
    rightPointerWidth: 18,
    topPointerHeight: 16,
    bottomPointerHeight: 20,
    xA: [987, 1083, 1125, 1220],
    yA: [30, 74, 116, 170],
  },
  verticalScrollIndicator: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 3,
    height: 131,
    xOffset: -415,
    yOffset: -340,
  },

  grabDots: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 5,
    height: 24,
    xOffset: -300,
    yOffset: -620,
  },

  orangeVbar: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 40,
    height: 131,
    xOffset: -425,
    yOffset: -340,
  },

  sickIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -5,
    yOffset: -5,
  },

  vacIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -60,
    yOffset: -5,
  },

  roadIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -115,
    yOffset: -5,
  },

  inIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -170,
    yOffset: -5,
  },

  homeIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -225,
    yOffset: -5,
  },
  oofIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -5,
    yOffset: -100,
  },

  sickIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -305,
    yOffset: -60,
  },

  vacIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -335,
    yOffset: -60,
  },

  roadIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -365,
    yOffset: -60,
  },

  inIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -395,
    yOffset: -60,
  },

  homeIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -425,
    yOffset: -60,
  },
  oofIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -60,
    yOffset: -100,
  },

  addIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -280,
    yOffset: -5,
  },

  addIconGrey: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 30,
    height: 30,
    xOffset: -305,
    yOffset: -95,
  },
  addIconGreyHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 30,
    height: 30,
    xOffset: -345,
    yOffset: -95,
  },
  addIconGreyDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 30,
    height: 30,
    xOffset: -385,
    yOffset: -95,
  },

  removeIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -335,
    yOffset: -5,
  },

  changeIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -390,
    yOffset: -5,
  },

  blueTime: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -5,
    yOffset: -60,
  },

  purpleTime: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -65,
    yOffset: -60,
  },

  grayTime: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -125,
    yOffset: -60,
  },

  orangeTime: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -185,
    yOffset: -60,
  },

  blueTimeLocked: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -5,
    yOffset: -1475,
  },

  purpleTimeLocked: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -65,
    yOffset: -1475,
  },

  grayTimeLocked: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -125,
    yOffset: -1475,
  },

  orangeTimeLocked: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -185,
    yOffset: -1475,
  },

  blackTime: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 58,
    height: 34,
    xOffset: -125,
    yOffset: -95,
  },

  textBox: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -245,
    yOffset: -60,
  },
  textBoxLocked: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 54,
    height: 34,
    xOffset: -245,
    yOffset: -1475,
  },
  feedBubble: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 487,
    height: 64,
    xOffset: -5,
    yOffset: -95,
  },

  primaryButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -5,
    yOffset: -165,
  },

  primaryButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -80,
    yOffset: -165,
  },

  primaryButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -155,
    yOffset: -165,
  },

  secondaryButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -5,
    yOffset: -200,
  },

  secondaryButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -80,
    yOffset: -200,
  },

  secondaryButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -155,
    yOffset: -200,
  },

  newFeatureButtonRest: {
    width: 48,
    height: 24,
    xOffset: -5,
    yOffset: -180,
  },

  destructiveButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -5,
    yOffset: -235,
  },

  destructiveButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -80,
    yOffset: -235,
  },

  destructiveButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -155,
    yOffset: -235,
  },

  secondaryButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -5,
    yOffset: -270,
  },

  secondaryButtonRestTransparent: {
    width: 70,
    height: 30,
    xOffset: -5,
    yOffset: -270,
  },

  secondaryButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -80,
    yOffset: -270,
  },

  secondaryButtonHoverTransparent: {
    width: 70,
    height: 30,
    xOffset: -80,
    yOffset: -270,
  },

  secondaryButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -155,
    yOffset: -270,
  },

  secondaryButtonDownTransparent: {
    width: 70,
    height: 30,
    xOffset: -155,
    yOffset: -270,
  },

  midButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -5,
    yOffset: -305,
  },

  midButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -80,
    yOffset: -305,
  },

  midButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -155,
    yOffset: -305,
  },

  menuSelect: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -230,
    yOffset: -165,
  },

  menuHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -305,
    yOffset: -165,
  },

  searchBox: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 30,
    xOffset: -380,
    yOffset: -165,
  },

  confirmHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 64,
    height: 44,
    xOffset: -230,
    yOffset: -200,
  },

  confirmDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 64,
    height: 44,
    xOffset: -300,
    yOffset: -200,
  },

  purpleMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -255,
    yOffset: -250,
  },

  blueMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -235,
    yOffset: -250,
  },

  grayMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -295,
    yOffset: -250,
  },

  orangeMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -275,
    yOffset: -250,
  },

  purpleProject: {
    backgroundImage: SPRITE_FILE_NAME,
    repeatableTileImage: 'images/purpleProject.png',
    width: 402,
    height: 44,
    xOffset: -6,
    yOffset: -430,
  },

  blueProject: {
    backgroundImage: SPRITE_FILE_NAME,
    repeatableTileImage: 'images/blueProject.png',
    width: 402,
    height: 44,
    xOffset: -6,
    yOffset: -340,
  },

  grayProject: {
    backgroundImage: SPRITE_FILE_NAME,
    repeatableTileImage: 'images/grayProject.png',
    width: 402,
    height: 44,
    xOffset: -6,
    yOffset: -475,
  },

  orangeProject: {
    backgroundImage: SPRITE_FILE_NAME,
    repeatableTileImage: 'images/orangeProject.png',
    width: 402,
    height: 44,
    xOffset: -6,
    yOffset: -385,
  },

  blackProject: {
    backgroundImage: SPRITE_FILE_NAME,
    repeatableTileImage: 'images/blackProject.png',
    width: 402,
    height: 44,
    xOffset: -506,
    yOffset: -1060,
  },

  lightgrayProject: {
    backgroundImage: SPRITE_FILE_NAME,
    repeatableTileImage: 'images/lightgrayProject.png',
    width: 402,
    height: 44,
    xOffset: -6,
    yOffset: -520,
  },

  redPercentage: {
    backgroundImage: SPRITE_FILE_NAME,
    repeatableTileImage: 'images/redPercentage.png',
    width: 215,
    height: 11,
    xOffset: -5,
    yOffset: -570,
  },

  blackPercentage: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 215,
    height: 8,
    xOffset: -5,
    yOffset: -580,
  },

  greenPercentage: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 215,
    height: 8,
    xOffset: -5,
    yOffset: -590,
  },

  userThumbFrame: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 184,
    height: 184,
    xOffset: -10,
    yOffset: -615,
  },

  projectThumbFrameXS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 185,
    height: 110,
    xOffset: -280,
    yOffset: -1165,
  },

  projectThumbFrameS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 184,
    height: 144,
    xOffset: -10,
    yOffset: -805,
  },

  projectThumbFrameL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 234,
    height: 184,
    xOffset: -495,
    yOffset: -5,
  },

  grayLine: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 560,
    height: 1,
    xOffset: -10,
    yOffset: -955,
  },

  tempProject: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 160,
    height: 90,
    xOffset: -10,
    yOffset: -960,
  },
  tempProjectLarge: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 214,
    height: 120,
    xOffset: -5,
    yOffset: -1155,
  },
  checkMark: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 12,
    xOffset: -200,
    yOffset: -620,
  },

  sliderBkg: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 71,
    height: 21,
    xOffset: -230,
    yOffset: -565,
  },
  sliderFill: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 70,
    height: 17,
    xOffset: -305,
    yOffset: -565,
  },
  sliderButton: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 44,
    height: 21,
    xOffset: -390,
    yOffset: -565,
  },
  selectionRadialOn: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 19,
    height: 17,
    xOffset: -225,
    yOffset: -595,
  },

  selectionRadialOff: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 19,
    height: 18,
    xOffset: -250,
    yOffset: -595,
  },

  selectedCheckbox: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -245,
    yOffset: -620,
  },

  unselectedCheckbox: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 14,
    height: 14,
    xOffset: -225,
    yOffset: -620,
  },

  filterDropdownArrow: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 6,
    height: 3,
    xOffset: -265,
    yOffset: -630,
  },

  downArrowMenu: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 5,
    xOffset: -265,
    yOffset: -620,
  },

  leftArrow: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 6,
    height: 13,
    xOffset: -275,
    yOffset: -620,
  },

  rightArrow: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 6,
    height: 13,
    xOffset: -285,
    yOffset: -620,
  },

  upArrow: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 26,
    height: 16,
    xOffset: -310,
    yOffset: -620,
  },

  downArrow: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 26,
    height: 16,
    xOffset: -340,
    yOffset: -620,
  },

  warningIcon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 13,
    height: 11,
    xOffset: -375,
    yOffset: -620,
  },

  assignIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -470,
    yOffset: -200,
  },
  bulletIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -470,
    yOffset: -235,
  },
  RemoveIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -495,
    yOffset: -200,
  },

  AddIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -520,
    yOffset: -200,
  },

  NoteIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -545,
    yOffset: -200,
  },

  AllocationIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -570,
    yOffset: -200,
  },

  StartDateIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -595,
    yOffset: -200,
  },

  EndDateIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -620,
    yOffset: -200,
  },

  SplitIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -645,
    yOffset: -200,
  },

  ShiftIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -645,
    yOffset: -200,
  },

  forceIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -670,
    yOffset: -200,
  },

  trashIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -690,
    yOffset: -235,
  },
  editIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -765,
    yOffset: -205,
  },
  completeIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -715,
    yOffset: -235,
  },
  timeIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -740,
    yOffset: -260,
  },
  commentIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -765,
    yOffset: -260,
  },
  phaseIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -765,
    yOffset: -235,
  },
  archiveIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -1130,
    yOffset: -220,
  },
  filterIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -1155,
    yOffset: -220,
  },
  projectIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -1180,
    yOffset: -220,
  },
  navIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -740,
    yOffset: -260,
  },
  taskBullet: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 7,
    height: 7,
    xOffset: -695,
    yOffset: -270,
  },
  commentBubble: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 30,
    height: 40,
    xOffset: -1132,
    yOffset: -305,
  },
  commentBlueBubble: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 30,
    height: 40,
    xOffset: -1167,
    yOffset: -305,
  },
  notificationIcon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 20,
    height: 15,
    xOffset: -1200,
    yOffset: -305,
  },
  dueIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -740,
    yOffset: -235,
  },
  dueGlyphRed: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 30,
    height: 40,
    xOffset: -1027,
    yOffset: -305,
  },
  dueGlyphGray: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 30,
    height: 40,
    xOffset: -1062,
    yOffset: -305,
  },
  assignGroup: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 30,
    height: 40,
    xOffset: -1097,
    yOffset: -305,
  },
  taskAdd: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 564,
    height: 71,
    xOffset: -505,
    yOffset: -975,
  },
  calendarDayRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 32,
    height: 28,
    xOffset: -495,
    yOffset: -235,
  },

  calendarDayHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 32,
    height: 28,
    xOffset: -530,
    yOffset: -235,
  },

  calendarDayDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 32,
    height: 28,
    xOffset: -565,
    yOffset: -235,
  },

  calendarDaySelected: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 32,
    height: 28,
    xOffset: -600,
    yOffset: -235,
  },

  simplePopupMenu: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -635,
    yOffset: -235,
  },

  calendarIcon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 19,
    height: 16,
    xOffset: -440,
    yOffset: -620,
  },

  kpi2Down: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 15,
    xOffset: -215,
    yOffset: -865,
  },

  kpi1Down: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 15,
    xOffset: -260,
    yOffset: -865,
  },

  kpiEven: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 15,
    xOffset: -305,
    yOffset: -865,
  },

  kpi1Up: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 15,
    xOffset: -350,
    yOffset: -865,
  },

  kpi2Up: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 15,
    xOffset: -395,
    yOffset: -865,
  },

  artefactIcon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 100,
    height: 40,
    xOffset: -10,
    yOffset: -1058,
  },

  removeIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -495,
    yOffset: -200,
  },

  addIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -520,
    yOffset: -200,
  },

  noteIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -545,
    yOffset: -200,
  },

  allocationIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -570,
    yOffset: -200,
  },

  startDateIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -595,
    yOffset: -200,
  },
  endDateIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -620,
    yOffset: -200,
  },
  splitIconS: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -645,
    yOffset: -200,
  },
  calendarDayRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 32,
    height: 28,
    xOffset: -495,
    yOffset: -235,
  },
  calendarDayHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 32,
    height: 28,
    xOffset: -530,
    yOffset: -235,
  },
  calendarDayDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 32,
    height: 28,
    xOffset: -565,
    yOffset: -235,
  },
  calendarDaySelected: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 30,
    height: 26,
    xOffset: -600,
    yOffset: -235,
  },
  noteIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -740,
    yOffset: -5,
  },
  allocationIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -795,
    yOffset: -5,
  },
  startDateIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -850,
    yOffset: -5,
  },
  endDateIconL: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 50,
    xOffset: -905,
    yOffset: -5,
  },
  loginLogo: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 252,
    height: 56,
    xOffset: -530,
    yOffset: -756,
  },
  signupLogo: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 252,
    height: 56,
    xOffset: -530,
    yOffset: -700,
  },
  shortLogo: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 100,
    height: 48,
    xOffset: -530,
    yOffset: -704,
  },
  defaultProject: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 500,
    height: 500,
    xOffset: -585,
    yOffset: -685,
  },
  barAvailable: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 15,
    xOffset: -700,
    yOffset: -194,
  },
  barGray: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 15,
    xOffset: -700,
    yOffset: -210,
  },
  barDarkblue: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 15,
    xOffset: -720,
    yOffset: -210,
  },
  barLightblue: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 15,
    height: 15,
    xOffset: -740,
    yOffset: -210,
  },

  blueButton: {
    backgroundImage: SPRITE_FILE_NAME,
    height: 15,
    xOffset: -740,
    yOffset: -210,
  },

  spacerLineFull: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 560,
    height: 1,
    xOffset: -520,
    yOffset: -1220,
  },
  closeButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 20,
    height: 20,
    xOffset: -220,
    yOffset: -905,
  },
  closeButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 20,
    height: 20,
    xOffset: -250,
    yOffset: -905,
  },
  closeButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 20,
    height: 20,
    xOffset: -280,
    yOffset: -905,
  },
  defaultProjectThumb: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 214,
    height: 140,
    xOffset: -800,
    yOffset: -210,
  },
  grayLine180: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 180,
    height: 1,
    xOffset: -520,
    yOffset: -1225,
  },
  errorDialogSpecs: {
    backgroundImage: SPRITE_FILE_NAME,
    backgroundColor: '#fff',
    width: 286,
    height: 192,
    xOffset: -806,
    yOffset: -386,
    leftPointerWidth: 18,
    rightPointerWidth: 18,
    topPointerHeight: 16,
    bottomPointerHeight: 20,
    xA: [832, 928, 970, 1065],
    yA: [410, 454, 496, 550],
  },
  oobePopupSpecs: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 286,
    height: 192,
    xOffset: -806,
    yOffset: -386,
    leftPointerWidth: 18,
    rightPointerWidth: 18,
    topPointerHeight: 16,
    bottomPointerHeight: 20,
    xA: [832, 928, 970, 1065],
    yA: [410, 454, 496, 550],
  },

  dot49: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 1,
    height: 49,
    xOffset: -740,
    yOffset: -70,
  },
  dot40: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 1,
    height: 40,
    xOffset: -740,
    yOffset: -70,
    backgroundRepeat: true,
  },
  thickgrayLine560: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 560,
    height: 2,
    xOffset: -520,
    yOffset: -1215,
  },
  grayLine300: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 300,
    height: 1,
    xOffset: -485,
    yOffset: -1250,
  },
  archiveButton: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 22,
    height: 22,
    xOffset: -955,
    yOffset: -335,
  },
  copyButton: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 22,
    height: 22,
    xOffset: -980,
    yOffset: -335,
  },
  deleteButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 22,
    height: 22,
    xOffset: -880,
    yOffset: -335,
  },
  deleteButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 22,
    height: 22,
    xOffset: -905,
    yOffset: -335,
  },
  deleteButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 22,
    height: 22,
    xOffset: -930,
    yOffset: -335,
  },
  closeDialogButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 22,
    height: 22,
    xOffset: -800,
    yOffset: -335,
  },
  closeDialogButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 22,
    height: 22,
    xOffset: -825,
    yOffset: -335,
  },
  closeDialogButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 22,
    height: 22,
    xOffset: -850,
    yOffset: -335,
  },
  proportionalButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 23,
    xOffset: -760,
    yOffset: -70,
  },
  proportionalButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 23,
    xOffset: -820,
    yOffset: -70,
  },
  proportionalButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 23,
    xOffset: -875,
    yOffset: -70,
  },
  normalizedButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 23,
    xOffset: -760,
    yOffset: -95,
  },
  normalizedButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 23,
    xOffset: -820,
    yOffset: -95,
  },
  normalizedButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 50,
    height: 23,
    xOffset: -875,
    yOffset: -95,
  },
  gridviewButton: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 17,
    xOffset: -710,
    yOffset: -725,
  },
  listviewButton: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 17,
    xOffset: -740,
    yOffset: -725,
  },
  reportIconLarge: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 29,
    height: 34,
    xOffset: -1072,
    yOffset: -220,
  },
  reportIconSmall: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 20,
    height: 22,
    xOffset: -1105,
    yOffset: -220,
  },
  creditcardImages: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 251,
    height: 26,
    xOffset: -1025,
    yOffset: -275,
  },
  thumbView: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 23,
    height: 17,
    xOffset: -750,
    yOffset: -125,
  },
  listView: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 23,
    height: 17,
    xOffset: -750,
    yOffset: -155,
  },
  confirmedIcon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 40,
    height: 34,
    xOffset: -780,
    yOffset: -130,
  },
  internalIcon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 40,
    height: 34,
    xOffset: -825,
    yOffset: -130,
  },
  tentativeIcon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 40,
    height: 34,
    xOffset: -870,
    yOffset: -130,
  },
  sortarrowDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 8,
    height: 4,
    xOffset: -920,
    yOffset: -135,
  },
  sortarrowUp: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 8,
    height: 4,
    xOffset: -930,
    yOffset: -135,
  },
  bullet1: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -805,
    yOffset: -360,
  },
  bullet2: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -815,
    yOffset: -360,
  },
  bullet3: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -825,
    yOffset: -360,
  },
  bullet4: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -835,
    yOffset: -360,
  },
  bullet5: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -845,
    yOffset: -360,
  },
  bullet6: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -855,
    yOffset: -360,
  },
  bullet7: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -865,
    yOffset: -360,
  },
  bullet8: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -875,
    yOffset: -360,
  },
  bullet9: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -885,
    yOffset: -360,
  },
  bullet10: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -895,
    yOffset: -360,
  },
  bullet11: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -905,
    yOffset: -360,
  },
  bullet12: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -915,
    yOffset: -360,
  },
  bullet13: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -925,
    yOffset: -360,
  },
  bullet14: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -935,
    yOffset: -360,
  },
  bullet15: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -945,
    yOffset: -360,
  },
  bullet16: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -955,
    yOffset: -360,
  },
  bullet17: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -965,
    yOffset: -360,
  },
  bullet18: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -975,
    yOffset: -360,
  },
  bullet19: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -985,
    yOffset: -360,
  },
  bullet20: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -995,
    yOffset: -360,
  },
  bullet21: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -1005,
    yOffset: -360,
  },
  bullet22: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -1015,
    yOffset: -360,
  },
  bullet23: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -1025,
    yOffset: -360,
  },
  bullet24: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -1035,
    yOffset: -360,
  },
  bullet25: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 10,
    height: 14,
    xOffset: -1045,
    yOffset: -360,
  },
  smallAddIcon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 9,
    height: 9,
    xOffset: -715,
    yOffset: -270,
  },
  phaseIcon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 16,
    height: 12,
    xOffset: -495,
    yOffset: -265,
  },
  helpButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 130,
    height: 82,
    xOffset: -510,
    yOffset: -1115,
  },
  helpButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 130,
    height: 82,
    xOffset: -650,
    yOffset: -1115,
  },
  helpButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 130,
    height: 82,
    xOffset: -790,
    yOffset: -1115,
  },
  calculatorButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 20,
    height: 29,
    xOffset: -1120,
    yOffset: -350,
  },
  calculatorButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 20,
    height: 29,
    xOffset: -1145,
    yOffset: -350,
  },
  calculatorButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 20,
    height: 29,
    xOffset: -1170,
    yOffset: -350,
  },
  expenseBack: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 564,
    height: 194,
    xOffset: -5,
    yOffset: -1280,
  },
  zoomsliderButtonRest: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 16,
    height: 21,
    xOffset: -305,
    yOffset: -130,
  },
  zoomsliderButtonHover: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 16,
    height: 21,
    xOffset: -325,
    yOffset: -130,
  },
  zoomsliderButtonDown: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 16,
    height: 21,
    xOffset: -345,
    yOffset: -130,
  },
  peopleGrid: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -1101,
    yOffset: -313,
  },
  projectsGrid: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -1119,
    yOffset: -394,
  },
  projectExpand: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -1155,
    yOffset: -420,
  },
  projectCollapse: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -1120,
    yOffset: -420,
  },
  blueExpand: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -1150,
    yOffset: -450,
  },
  blueCollapse: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -1120,
    yOffset: -450,
  },
  whiteClose: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 11,
    height: 10,
    xOffset: -1165,
    yOffset: -409,
  },
  colorIconBlue: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -576,
    yOffset: -1480,
  },
  colorIconNavy: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -612,
    yOffset: -1480,
  },
  colorIconViolet: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -648,
    yOffset: -1480,
  },
  colorIconFuchsia: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -684,
    yOffset: -1480,
  },
  colorIconCrimson: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -720,
    yOffset: -1480,
  },
  colorIconSalmon: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -756,
    yOffset: -1480,
  },
  colorIconOrange: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -792,
    yOffset: -1480,
  },
  colorIconYellow: {
    backgroundImage: SPRITE_FILE_NAME,
    width: 24,
    height: 24,
    xOffset: -828,
    yOffset: -1480,
  },
};
