/* A custom scroll bar control.
 *
 * note: Currently, only supports the vertical orientation
 */
function scrollBar(
  id,
  containerElement,
  styles,
  contentHeight,
  initialPosition
) {
  var THUMB_COLOR = 'rgba(132, 132, 132, 0.6)',
    THUMB_COLOR_HIGHLIGHTED = 'rgba(99, 99, 99, 1.0)';

  var _i = this,
    sbEl,
    thumbEl,
    _pos = initialPosition || 0,
    thumbSize = 100, // todo make dependent on content size
    thumbTop = 0,
    height,
    scrolling = false;
  animationFrameHandle = null;
  dy = 0;

  _.extend(_i, Backbone.Events);

  // top, bottom, right and zIndex as a style enumeration
  _i.updateLayout = function (styles) {
    Awe.applyStyles(sbEl, styles);
    height = xHeight(sbEl);
  };

  // position : 0..1
  _i.update = function (position) {
    __A(
      position >= 0 && position <= 1,
      'position must be a value equal to or in between 0 and 1'
    );
    _pos = position;
    thumbTop = pos2Top(position);
    Awe.applyStyles(thumbEl, { top: thumbTop + 'px' });
  };

  sbEl = Awe.createElement('div', containerElement, {
    attrs: {
      id: id,
      className: 'scrollBar',
    },
    styles: {
      position: 'absolute',
      width: '10px',
      top: styles.top,
      bottom: styles.bottom,
      right: styles.right,
      zIndex: styles.zIndex,
      borderRadius: '5px',
      margin: '5px 0px 5px 0px',
    },
  });

  height = xHeight(sbEl);

  thumbEl = Awe.createElement('div', sbEl, {
    attrs: {
      className: 'scrollBarThumb',
    },
    styles: {
      position: 'absolute',
      width: '4px',
      top: thumbTop + 'px',
      height: thumbSize + 'px',
      backgroundColor: THUMB_COLOR,
      borderRadius: '2px',
      margin: '0px 3px 0px 3px',
      cursor: 'pointer',
    },
  });

  sbEl.addEventListener('mouseover', function () {
    Awe.applyStyles(thumbEl, { backgroundColor: THUMB_COLOR_HIGHLIGHTED });
  });

  sbEl.addEventListener('mouseout', function () {
    Awe.applyStyles(thumbEl, {
      backgroundColor: scrolling ? THUMB_COLOR_HIGHLIGHTED : THUMB_COLOR,
    });
  });

  function pos2Top(position) {
    return Math.round(position * (height - thumbSize));
  }

  function top2Pos(top) {
    return top / (height - thumbSize);
  }

  function animateScroll() {
    var newTop = Awe.clamp(0, height - thumbSize, thumbTop + dy);
    if (newTop != thumbTop) {
      thumbTop = newTop;
      Awe.applyStyles(thumbEl, { top: thumbTop + 'px' });
      _i.trigger('scrolled', { position: top2Pos(thumbTop) });
    }
    dy = 0;
    if (scrolling) {
      animationFrameHandle = requestAnimationFrame(animateScroll);
    }
  }

  Awe.enableDrag(thumbEl, {
    cancelEvents: true,

    filters: new Awe.NullFilter(),
    updater: new Awe.NullUpdater(),

    onDragStart: function (evt) {
      dy = 0;
      scrolling = true;
      animationFrameHandle = requestAnimationFrame(animateScroll);
    },

    onDragMove: function (evt) {
      if (evt.delta.y != 0) {
        dy += evt.delta.y;
      }
    },

    onDragEnd: function (evt) {
      scrolling = false;
      Awe.applyStyles(thumbEl, { backgroundColor: THUMB_COLOR });
    },
  });
}
