function TagEditView(
  getTagsFn,
  getAllTagsFn,
  setTagsFn,
  canCreateNewTags,
  namespace
) {
  var _i = this,
    _elId = 'tageditview_' + _.uniqueId(),
    _t =
      '<div id="<%= id %>"i class="unselectable" style="margin-bottom:20px;">' +
      `<input type="button" role="button" id="<%= id %>_addBtn" class="fnt-r-14-ls formContentButton" aria-label="${I18n.t(
        'lbl_add_tags'
      )}" value="${I18n.t('lbl_add_tags')}"/>` +
      '<div id="<%= id %>_currentTags" class="fnt-r-14">The list of tags will be displayed here.<div>' +
      '</div>',
    _$el = $(_.template(_t, { id: _elId })),
    _el = _$el[0],
    _$addButton = $('#' + _elId + '_addBtn', _$el),
    _addButton = _$addButton[0],
    _$currentTags = $('#' + _elId + '_currentTags', _$el),
    _currentTags = _$currentTags[0],
    _tagTemplate =
      '<div class="blockFloat fnt-r-14 tag unselectable" style="">' +
      '<div class="tagText fnt-r-14 blockFloat" style=""><%- value %></div>' +
      '<div ' +
      'class="blockFloat removeIcon" ' +
      'style="" ' +
      'data-tagid="<%= id %>" ' +
      'role="button" ' +
      `aria-label="${I18n.t('lbl_delete_tag', { tagName: '<%- value %>' })}"` +
      'tabindex=0>' +
      '</div>' +
      '</div>',
    _container = null,
    _listOfTags = null,
    _listOfAllTags = null,
    _listOfAllTagsSorted = false,
    _DEBOUNCE_TIME = 250,
    _initialized = false;
  // default params
  getTagsFn =
    getTagsFn ||
    function () {
      return [];
    };
  getAllTagsFn =
    getAllTagsFn ||
    function () {
      return [];
    };
  setTagsFn = setTagsFn || function () {};
  namespace = namespace || '';

  function initialize() {
    if (!_initialized) {
      _addButton.addEventListener(
        'click',
        _.debounce(onAddButtonClick, _DEBOUNCE_TIME)
      );
      _initialized = true;
    }
  }

  function getTagSelectionListItems() {
    var listItems = [];

    // lazy load
    if (!_listOfAllTags) {
      _listOfAllTags = getAllTagsFn();
      if (!_listOfAllTags) {
        _listOfAllTags = [];
      }
    }

    // lazy sort
    if (!_listOfAllTagsSorted) {
      _listOfAllTags = _listOfAllTags.sort(function (a, b) {
        return stringComparerCaseInsensitive(a.value, b.value);
      });
      _listOfAllTagsSorted = true;
    }

    _listOfAllTags.forEach(function (t) {
      if (!isInCurrentList(t)) {
        if (t.value && t.value.trim() != '') {
          listItems.push({
            type: 'item',
            selected: false,
            label: t.value,
            id: t.id,
          });
        }
      }
    });
    return listItems;
  }

  function isInCurrentList(tag) {
    return _listOfTags.some(function (t) {
      return stringComparerCaseInsensitive(t.value, tag.value) == 0;
    });
  }

  function addToListOfTags(tagId) {
    var t = findTag(tagId);
    if (t) {
      _listOfTags.push(t);
    }
  }

  function removeFromListOfTags(tagId) {
    _listOfTags = _listOfTags.filter(function (t) {
      return t.id != tagId;
    });
  }

  function findTag(id) {
    return _.find(_listOfAllTags, function (t) {
      return t.id == id;
    });
  }

  function findTagByValue(value) {
    return _.find(_listOfAllTags, function (t) {
      return stringComparerCaseInsensitive(t.value, value) == 0;
    });
  }

  function onAddButtonClick() {
    var currentSelection = null,
      onAddTagsCallback = function (items) {
        currentSelection = items;
      },
      onEnterCallback = function (value) {
        if (value && value.trim() != '') {
          value = value.trim();
          var tag = findTagByValue(value);
          if (tag) {
            if (!isInCurrentList(tag)) {
              _listOfTags.push(tag);
            }
          } else if (canCreateNewTags) {
            // create a placeholder tag and add it to the current list
            tag = {
              value: value,
              id: '__new_' + _.uniqueId(),
              namespace: namespace,
            };
            !isInCurrentList(tag) && _listOfTags.push(tag);
          }
        }
      };
    (onAddTagMenuBlur = function () {
      currentSelection &&
        currentSelection.forEach(function (item) {
          addToListOfTags(item.id);
        });
      updateListOfTags();
      setTagsFn(_listOfTags);
      currentSelection = null;
    }),
      (popup = new filterPopup(
        240,
        260,
        'mainCon',
        getTagSelectionListItems(),
        compSpritesButtonsIcons.blackBarDialog,
        onAddTagsCallback,
        true,
        onAddTagMenuBlur,
        'ws',
        ['ws', 'wc', 'wn'],
        true,
        false,
        null, // parentPopupContainer
        onEnterCallback
      ));
    popup.show(_addButton);
  }

  function onRemoveKeyPress(e) {
    if (e.key == 'Enter' || e.key == ' ') {
      onRemoveButtonClick(e);
    }
  }

  function onRemoveButtonClick(e) {
    var tagId = $(e.target).data('tagid');
    removeFromListOfTags(tagId);
    updateListOfTags();
    setTagsFn(_listOfTags);
  }

  function updateListOfTags() {
    var t;

    removeAllChildren(_currentTags);

    if (!_listOfTags) {
      _listOfTags = getTagsFn();
      if (!_listOfTags) {
        _listOfTags = [];
      }
    }

    if (_listOfTags) {
      _listOfTags.forEach(function (tag) {
        $t = $(_.template(_tagTemplate, { value: tag.value, id: tag.id }));
        removeButton = $('.removeIcon', $t)[0];
        removeButton.addEventListener('click', onRemoveButtonClick);
        removeButton.addEventListener('keypress', onRemoveKeyPress);
        _currentTags.appendChild($t[0]);
      });
    }
  }

  _i.show = function (container) {
    initialize();
    container.appendChild(_el);
    _container = container;
    updateListOfTags();
  };

  _i.hide = function () {
    _container.removeChild(_el);
  };
}
