function TagView(
  attach,
  name,
  value,
  container_style,
  name_style,
  value_style,
  url,
  value_icon
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.name = name;
  _i.name_style = name_style;
  _i.value = value;
  _i.value_style = value_style;
  _i.container_style = container_style;
  _i.url = url;

  _i.tagClicked = function () {
    if (_i.url) {
      window.location.href = _i.url;
    }
  };
  _i.constructor = function () {
    _i.tagContainer = document.createElement('DIV');
    _i.tagContainer.className = _i.container_style;
    _i.attachTo.appendChild(_i.tagContainer);

    if (_i.url) {
      _i.tagContainer.style.cursor = 'pointer';
      xAddEventListener(_i.tagContainer, platform.click, _i.tagClicked, false);
    }

    _i.tagNameText = document.createElement('DIV');
    _i.tagNameText.className = name_style;
    _i.tagNameText.innerText = name;
    _i.tagContainer.appendChild(_i.tagNameText);

    _i.tagValueText = document.createElement('H2');
    _i.tagValueText.className = value_style;
    if (value) _i.tagValueText.innerHTML = parseForURLs(value);

    if (!value_icon) {
      _i.tagContainer.appendChild(_i.tagValueText);
    } else {
      var vtContainer = document.createElement('DIV');
      vtContainer.className = 'blockFloatNot';
      value_icon.className = 'blockFloat';
      _i.tagValueText.className += ' blockFloat';

      _i.tagContainer.appendChild(vtContainer);
      vtContainer.appendChild(value_icon);
      vtContainer.appendChild(_i.tagValueText);
    }
  };
  _i.constructor();
}
