function stretchyBar(spriteDef, x, y, attachTo) {
  var _i = this;

  // params
  _i.spriteDef = spriteDef;
  _i.attachX = x;
  _i.attachY = y;
  _i.attachTo = xGetElementById(attachTo);

  // locals
  _i.container = null;
  _i.bground = null;
  _i.leftGrab = null;
  _i.rightGrab = null;
  _i.hideHandlesTimeout = null;

  // const
  _i.minBarWidth = 40;

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);

    if (_i.container.parentNode) {
      _i.container.parentNode.removeChild(_i.container);
    }

    _i.attachTo.appendChild(_i.container);
  };

  _i.containerMouseover = function () {
    _i.showHandles();
  };

  _i.containerMouseout = function () {
    _i.hideHandles();
  };

  _i.hideHandles = function () {
    _i.rightGrabIcon.style.display = 'none';
    _i.leftGrabIcon.style.display = 'none';
  };

  _i.showHandles = function () {
    clearTimeout(_i.hideHandlesTimeout);
    _i.rightGrabIcon.style.display = 'block';
    _i.leftGrabIcon.style.display = 'block';
  };

  _i.rightGrabDragStart = function (el, x, y, evt) {
    cancelEvent(evt);
    _i.disableMouseInOut();
  };

  _i.rightGrabDrag = function (el, dx, dy, evt) {
    cancelEvent(evt);

    var newWidth = xWidth(_i.container) + dx;

    if (newWidth < _i.minBarWidth) {
      newWidth = _i.minBarWidth;
    }

    xWidth(_i.container, newWidth);
  };

  _i.rightGrabDragEnd = function (el, x, y, evt) {
    cancelEvent(evt);
    _i.enableMouseInOut();
    _i.hideHandles();
  };

  _i.leftGrabDragStart = function (el, x, y, evt) {
    cancelEvent(evt);
    _i.disableMouseInOut();
  };

  _i.leftGrabDrag = function (el, dx, dy, evt) {
    cancelEvent(evt);

    var newWidth = xWidth(_i.container) - dx;

    if (newWidth < _i.minBarWidth) {
      var dxdx = _i.minBarWidth - newWidth;
      dx -= dxdx;
      newWidth = _i.minBarWidth;
    }

    xWidth(_i.container, newWidth);
    xLeft(_i.container, xLeft(_i.container) + dx);
  };

  _i.leftGrabDragEnd = function (dl, x, y, evt) {
    cancelEvent(evt);
    _i.enableMouseInOut();
    _i.hideHandles();
  };

  _i.enableMouseInOut = function () {
    xAddEventListener(_i.container, 'mouseover', _i.containerMouseover, false);
    xAddEventListener(_i.container, 'mouseout', _i.containerMouseout, false);
    xAddEventListener(_i.container, 'mousemove', _i.containerMouseover, false);
  };

  _i.disableMouseInOut = function () {
    xRemoveEventListener(
      _i.container,
      'mouseover',
      _i.containerMouseover,
      false
    );
    xRemoveEventListener(_i.container, 'mouseout', _i.containerMouseout, false);
    xRemoveEventListener(
      _i.container,
      'mousemove',
      _i.containerMouseover,
      false
    );
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.style.position = 'absolute';
    _i.container.style.left = _i.attachX + 'px';
    _i.container.style.top = _i.attachY + 'px';
    _i.container.style.width = '200px';
    _i.container.style.height = _i.spriteDef.height + 'px';

    _i.bground = new backgroundBar(_i.spriteDef);

    _i.rightGrab = document.createElement('DIV');
    _i.rightGrab.style.position = 'absolute';
    _i.rightGrab.style.right = '0px';
    _i.rightGrab.style.top = '0px';
    _i.rightGrab.style.width = '24px';
    _i.rightGrab.style.height = '100%';
    _i.rightGrab.style.cursor = 'pointer';
    _i.rightGrabIcon = getSprite(compSpritesButtonsIcons.grabDots);
    _i.rightGrabIcon.style.position = 'absolute';
    _i.rightGrabIcon.style.top = '9px';
    _i.rightGrabIcon.style.right = '8px';
    _i.rightGrabIcon.style.display = 'none';
    _i.rightGrab.appendChild(_i.rightGrabIcon);

    _i.leftGrab = _i.rightGrab.cloneNode(false);
    _i.leftGrab.style.left = '0px';
    _i.leftGrabIcon = _i.rightGrabIcon.cloneNode(false);
    _i.leftGrab.appendChild(_i.leftGrabIcon);

    if (_i.attachTo) {
      _i.attachTo.appendChild(_i.container);
    }

    _i.container.appendChild(_i.bground.container);
    _i.container.appendChild(_i.rightGrab);
    _i.container.appendChild(_i.leftGrab);

    new vEnableDrag(
      _i.leftGrab,
      _i.leftGrabDragStart,
      _i.leftGrabDrag,
      _i.leftGrabDragEnd
    );
    new vEnableDrag(
      _i.rightGrab,
      _i.rightGrabDragStart,
      _i.rightGrabDrag,
      _i.rightGrabDragEnd
    );

    _i.enableMouseInOut();
  };

  _i.constructor();
}
