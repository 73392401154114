function PopUpInput(
  attach,
  label,
  initial_value,
  container_style,
  button_style,
  label_style,
  input_style,
  input_data,
  popup,
  popupDelegate /* if popup is null, and to be created lazily */,
  dropdownID,
  btnID
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.label = label;
  _i.label_style = label_style;
  _i.initial_value = initial_value;
  _i.input_data = input_data;
  _i.input_style = input_style;
  _i.selected_value = initial_value;
  _i.container_style = container_style;
  _i.button_style = button_style;
  _i.popup = popup;
  _i.guid = Awe.getGuid();

  _i.alertData = [
    {
      type: 'label',
      value: null,
      label: 'label',
    },
  ];

  // ids
  _i.dropdownID = dropdownID || 'dropdown' + Awe.getGuid();
  _i.btnID = btnID || 'button' + Awe.getGuid();
  _i.currentSelectionID = 'current-selection' + Awe.getGuid();

  if (label) {
    _i.labelID =
      label.replace(/\s+/g, '-').toLowerCase().concat('-label') + Awe.getGuid();
  }

  _i.showAlert = function () {
    if (_i.alertMessage == null || _i.alertMessage == '') return;

    _i.alertPopup.show(_i.guid + 'alertmsgshow');
  };

  _i.buildAlertMessage = function () {
    _i.alertData[0].label = _i.alertMessage;
  };

  _i.setAlert = function (alertmsg) {
    _i.alertMessage = alertmsg;
    _i.buildAlertMessage();
    _i.alertPopup = new selectionPopup(
      360,
      40,
      'mainCon',
      _i.alertData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onAlertClick,
      false,
      null /*blurCallback*/,
      'nw',
      null /*preferredDirections*/,
      true /*noautorotate*/
    );

    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = _i.inputContainer.getBoundingClientRect(),
      offsetTop = elemRect.bottom - bodyRect.top,
      offsetLeft = elemRect.right - bodyRect.left;
    _i.alertPopup.showAtPoint(offsetLeft, offsetTop);
    _i.showAlert();
  };

  _i.setValue = function (val) {
    _i.selected_value = val;
    _i.inputContainer.innerHTML = '';
    _i.createContent();
  };

  _i.onButtonClick = function () {
    if (!_i.popup) {
      _i.popup = popupDelegate();
    }
    _i.popup.show(_i.guid + 'elementDropDownSelect');
    _i.popup.isShown = true;
    return false;
  };

  _i.handleKeypress = function (e) {
    if (typeof e == 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key == 'Enter' || e.key == ' ') {
      _i.onButtonClick();
      focusFirstChildElementAfterDelay('#' + _i.dropdownID);
    }
    return true;
  };

  _i.createBackground = function (sprite) {
    _i.bbar = new backgroundBar(sprite);
    _i.buttonContainer.appendChild(_i.bbar.container);
    if (_i.spriteHover) {
      _i.buttonContainer.backgroundHover = new backgroundBar(_i.spriteHover);
      _i.buttonContainer.appendChild(_i.container.backgroundHover.container);
      _i.buttonContainer.backgroundHover.container.style.display = 'none';
    }
    if (_i.spriteDown) {
      _i.buttonContainer.backgroundDepress = new backgroundBar(_i.spriteDown);
      _i.buttonContainer.appendChild(_i.container.backgroundDepress.container);
      _i.buttonContainer.backgroundDepress.container.style.display = 'none';
    }
  };

  _i.createContent = function () {
    if (_i.label) {
      _i.labelText = document.createElement('DIV');
      _i.labelText.className = label_style;
      _i.labelText.id = _i.labelID;
      _i.labelText.innerText = label;
      _i.inputContainer.appendChild(_i.labelText);
    }
    _i.buttonContainer = document.createElement('DIV');

    _i.buttonContainer.className = _i.button_style;
    _i.buttonContainer.id = _i.btnID;
    _i.buttonContainer.setAttribute('aria-describedby', _i.currentSelectionID);
    _i.inputContainer.appendChild(_i.buttonContainer);

    xAddEventListener(
      _i.buttonContainer,
      platform.click,
      _i.onButtonClick,
      false
    );
    xAddEventListener(_i.buttonContainer, 'keypress', _i.handleKeypress, false);

    _i.buttonContainer.tabIndex = 0;
    _i.buttonContainer.setAttribute('role', 'listbox');
    if (_i.label) {
      _i.buttonContainer.setAttribute('aria-labelledby', _i.labelID);
    }

    _i.createBackground(compSpritesButtonsIcons.secondaryButtonRest);

    if (
      _i.selected_value != null &&
      _i.selected_value < _i.input_data.length &&
      _i.input_data[_i.selected_value].icon != null
    ) {
      _i.iconImage = document.createElement('DIV');
      _i.iconImage.className = 'blockFloat iconImage';
      getSprite(
        compSpritesButtonsIcons[_i.input_data[_i.selected_value].icon],
        _i.iconImage
      );
      _i.buttonContainer.appendChild(_i.iconImage);
    }

    _i.labelSpan = document.createElement('SPAN');
    _i.labelSpan.setAttribute('role', 'option');
    _i.labelSpan.className = _i.input_style;
    _i.labelSpan.id = _i.currentSelectionID;
    if (_i.selected_value != null && _i.selected_value < _i.input_data.length)
      _i.labelSpan.innerText = _i.input_data[_i.selected_value].label;

    _i.originalText = _i.labelSpan.innerHTML;
    _i.buttonContainer.appendChild(_i.labelSpan);

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    _i.buttonContainer.appendChild(_i.nestedDiv);

    _i.elementDropDownSelect = document.createElement('DIV');
    _i.elementDropDownSelect.className = 'blockFloat iconDropDownSelect';
    _i.elementDropDownSelect.appendChild(
      getSprite(compSpritesButtonsIcons.downArrowMenu)
    );
    _i.elementDropDownSelect.id = _i.guid + 'elementDropDownSelect';
    _i.nestedDiv.appendChild(_i.elementDropDownSelect);
  };

  _i.constructor = function () {
    _i.inputContainer = document.createElement('DIV');
    _i.inputContainer.className = _i.container_style;
    _i.attachTo.appendChild(_i.inputContainer);
    _i.createContent();
  };

  _i.constructor();
}
