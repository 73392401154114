function AuthorizedApplications(attachTo) {
  var _i = this;
  _i.attachTo = document.getElementById(attachTo);

  _i.revokeApplication = function (applicationId, callback) {
    gService.revokeOAuthApplication(applicationId, callback);
  };

  _i.attachEventListeners = function () {
    var els = document.getElementsByClassName('revokeAuthorizedApp');
    if (els.length === 0) return;
    for (var i = 0; i < els.length; i++) {
      var el = els[i];
      var id = el.getAttribute('data-application-id');
      var td = el.parentElement;
      var tr = td.parentElement;
      el.addEventListener('click', function (e) {
        var confirm = confirmationPopup(
          `${I18n.t('msg_revoke_access_confirmation')} ` +
            el.getAttribute('data-name') +
            `? ${I18n.t('msg_no_access_to_account')}`,
          I18n.t('lbl_revoke'),
          function () {
            _i.revokeApplication(id, function () {
              var app = _.find(_i.data, { id: id });
              _i.data.splice(_i.data.indexOf(app), 1);
              _i.container.innerHTML = '';
              _i.buildPage(_i.data);
            });
          },
          e.target
        );
        confirm.show(e.target);
      });
    }
  };

  _i.getScopesList = function (scopes) {
    return scopes
      .map(function (element, index) {
        if (index === scopes.length - 1)
          return (
            I18n.t('lbl_and_lc') +
            ' ' +
            I18n.t('lbl_' + element).toLowerCase() +
            '.'
          );
        return I18n.t('lbl_' + element).toLowerCase();
      })
      .join(', ');
  };

  _i.buildListItem = function (application) {
    return (
      '<tr><td><strong>' +
      application.name +
      '</strong></td><td>' +
      I18n.t('lbl_has_access_to') +
      _i.getScopesList(application.scopes) +
      '<br>' +
      I18n.t('lbl_authorized_on') +
      new Date(application.created_at).toStartOfDay().toLocaleDateString(window.I18n.locale, {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }) +
      ' at ' +
      new Date(application.created_at).toLocaleTimeString(window.I18n.locale, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }) +
      '</br>' +
      "</td><td><button data-name='" +
      application.name +
      "' data-application-id='" +
      application.id +
      "' class='revokeAuthorizedApp'>Revoke</button></td></tr>"
    );
  };

  _i.buildList = function (data) {
    if (data.length === 0) return '';
    var apps = data.map(function (application) {
      return _i.buildListItem(application);
    });
    return (
      "<table class='authorizedApplications'>" +
      "<tr class='authorizedApplicationsHeader'></tr>" +
      apps +
      '</table>'
    );
  };

  _i.markup = function (data) {
    if (data.length > 0) {
      return _i.buildList(data);
    }
    var str = I18n.t('msg_you_have_granted_html');
    return str;
  };

  _i.buildPage = function (data) {
    _i.data = data;
    _i.container = document.createElement('div');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'authorizedApplications';

    var temp = _.template(_i.markup(_i.data));
    _i.container.innerHTML = temp();

    _i.attachTo.appendChild(_i.container);
    _i.attachEventListeners();
  };

  _i.getData = function (callback) {
    gService.getUserAuthorizedApplications(_i.buildPage);
  };

  _i.constructor = function () {
    _i.getData(_i.buildPage);
  };

  _i.constructor();
}
