/**
 *
 * @param {Object} vbOptions - Contains string/number type properties: minX, minY, x, y
 * @param {string} originalViewBox - viewBox attribute string for an SVG element, format = `${minX} ${minY} ${width} ${height}`
 * @returns {string} a new viewBox string
 */
(function (window, document, namespace, undefined) {
  namespace = namespace || 'SvgUtils';

  function buildViewBox(vbOptions, originalViewBox) {
    let viewBox = originalViewBox
      ? originalViewBox.replace(/,\s*/g, ' ').split(' ').map(Number)
      : [0, 0, 0, 0]; // Default values if there's no original viewBox

    if (vbOptions) {
      if (vbOptions.minX !== undefined) viewBox[0] = vbOptions.minX;
      if (vbOptions.minY !== undefined) viewBox[1] = vbOptions.minY;
      if (vbOptions.x !== undefined) viewBox[2] = vbOptions.x;
      if (vbOptions.y !== undefined) viewBox[3] = vbOptions.y;
    }

    return viewBox.join(' ');
  }

  const SvgUtils = {
    /**
     *
     * @param {string} svgName string name of the svg content from svgIcons.js
     * @param {Object} options styling options for svg element
     * @param {Object} tagOptions options for g tag
     * @returns {string} the custom loaded svg string
     */
    loadSvg: function (svgName, options, tagOptions) {
      const svgString = window.svgs[svgName];
      if (!svgString) {
        throw new Error(`SVG "${svgName}" not found.`);
      }

      // Parse the SVG string into a DOM object
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
      const svg = svgDoc.documentElement;

      // Build viewBox if necessary
      const originalViewBox = svg.getAttribute('viewBox');

      // Loop over the properties in the options object
      for (const key in options) {
        if (options.hasOwnProperty(key)) {
          if (key === 'viewBox') {
            options[key] = buildViewBox(options[key], originalViewBox);
          }
          // Set the attribute in the SVG element
          if (options[key] !== undefined) svg.setAttribute(key, options[key]);
        }
      }

      // Loop over the properties in the tagOptions object
      for (const tagName in tagOptions) {
        if (tagOptions.hasOwnProperty(tagName)) {
          let tag = svg.querySelector(tagName);
          if (!tag) {
            // If the tag doesn't exist, create it and append it to the SVG
            const paths = Array.from(svg.querySelectorAll('path'));
            tag = svgDoc.createElementNS('http://www.w3.org/2000/svg', tagName);
            paths.forEach((path) => {
              tag.appendChild(path.cloneNode(true)); // Clone each path before appending
              svg.removeChild(path); // Remove the original path from the svg
            });
            svg.appendChild(tag);
          }

          // Loop over the properties in the tagOptions[tagName] object
          for (const attrName in tagOptions[tagName]) {
            if (tagOptions[tagName].hasOwnProperty(attrName)) {
              // Set the attribute in the tag
              tag.setAttribute(attrName, tagOptions[tagName][attrName]);
            }
          }
        }
      }

      // Serialize the DOM object back into a string
      const serializer = new XMLSerializer();
      return serializer.serializeToString(svg);
    },

    /**
     *
     * @param {string} svg A string representing the built svg element
     * @param {Object} options The object that contains the styling options for the div element
     * @returns {string} the wrapped svg element
     */
    wrapSvgIcon: function (SVG_ICON, options) {
      let style = {};
      for (const key in options) {
        if (options.hasOwnProperty(key)) {
          if (typeof options[key] !== 'object') {
            style[key] = options[key];
          } else {
            console.error(`Error: The value of ${key} is an object.`);
          }
        }
      }
      const div = document.createElement('div');
      Object.assign(div.style, style);
      div.innerHTML = SVG_ICON;
      return div.outerHTML;
    },
  };

  window[namespace] = SvgUtils;
})(window, document);
