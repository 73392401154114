function projectOverflowRowItem(psrivm, config, row, gvm, glm) {
  var _i = this,
    _el = null,
    _elBarWrapper = null,
    _elBackground = null,
    _rEndcap,
    _lEndcap;

  var MenuAction = {
    AddAssignment: 0,
    AddPhase: 1,
    ChangeStartsAt: 2,
    ChangeEndsAt: 3,
    Expand: 4,
    Collapse: 5,
  };

  _i.gvm = gvm;
  _i.rvm = row.rvm;
  _i.psrivm = psrivm;

  _i.isEditable = false;
  _i.isDraggable = false;

  _i.getId = function () {
    return _i.psrivm.startTime + '_o'; // use start time as the unique id seed
  };

  _i.getConfig = function () {
    return config;
  };

  _i.getStartsAt = function () {
    return new Date(_i.psrivm.activityStartTime);
  };

  _i.getEndsAt = function () {
    return new Date(_i.psrivm.activityEndTime);
  };

  _i.getStackPosition = function () {
    return 0;
  };

  // color values below match the corresponding gradient stop colors in grid.css
  _i.getColor = function () {
    switch (_i.psrivm.assignable.project_state) {
      case 'Confirmed':
        return 'rgb(0, 194, 247)'; // blue
      case 'Tentative':
        return 'rgb(187, 187, 187)'; // gray
      case 'Internal':
        return 'rgb(162, 124, 216)'; // purple
    }
  };

  _i.getOpacity = function () {
    return 1.0;
  };

  _i.updateElement = function (width, marginLeft) {
    // no-op
  };

  _i.getElement = function () {
    if (!_el) {
      _el = Awe.createElement('div', null, {
        attrs: {
          className: 'gridProjectStatusItem',
        },
        styles: {
          position: 'absolute',
          left: '0px',
          right: '0px',
          height: config.height + 'px',
          overflow: 'visible',
          opacity: 0.5,
        },
      });

      _elBarWrapper = Awe.createElement('div', _el, {
        attrs: { className: 'gridProjectStatusItemBarWrapper' },
        styles: {
          position: 'absolute',
          left: '0px',
          right: '0px',
          height: config.height + 'px',
          overflow: 'hidden',
        },
      });

      _elBackground = Awe.createElement('div', _elBarWrapper, {
        attrs: { className: 'gridProjectStatusItemBackground' },
        styles: {
          position: 'absolute',
          left: '0px',
          right: '0px',
          height: '3px',
          backgroundColor: _i.getColor(),
          marginTop: '20px',
        },
      });

      _rEndcap = new triangleEndcap(_i.getColor(), 'right');
      Awe.applyStyles(_rEndcap, {
        position: 'absolute',
        right: '0px',
        top: '22px',
      });
      _elBarWrapper.appendChild(_rEndcap);

      _lEndcap = new triangleEndcap(_i.getColor());
      Awe.applyStyles(_lEndcap, {
        position: 'absolute',
        left: '0px',
        top: '22px',
      });
      _elBarWrapper.appendChild(_lEndcap);
    }

    return _el;
  };

  _i.getTitleText = function () {
    return I18n.t('msg_project_activity_extends');
  };

  _i.getGridMenuItems = function (date, timeUnit) {
    return null;
  };
}
