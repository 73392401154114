function MinimalBillingWithLinkToSMARForm(attachTo) {
  var _i = this;

  if (!(this instanceof MinimalBillingWithLinkToSMARForm)) {
    return new MinimalBillingWithLinkToSMARForm(attachTo);
  }

  // trial is defined by account status of `trial` (current trial)
  // or `due` (expired trial)
  _i.accountIsTrial = function () {
    return window.accountSettings.subscription_status === 'trial';
  };

  _i.buildSectionContent = function (opts) {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    var explain = document.createElement('DIV');
    explain.className = 'blockFloatNot fnt-r-14';
    explain.innerHTML = opts.paragraph1;
    var spacer2 = document.createElement('DIV');
    spacer2.className = 'blockFloatNot wizardVspacer15px';
    var explain2 = document.createElement('DIV');
    explain2.className = 'blockFloatNot fnt-r-14';
    explain2.innerHTML = opts.paragraph2;
    var billingButton = new primaryButton('blockFloatNot');
    billingButton.setButtonText(opts.buttonText);
    // fts is form traffic source; p is prospect, c is customer
    // (these are marketing department requirements)
    billingButton.setCallback(function () {
      window.open(
        'https://www.smartsheet.com/contact/resource-management-billing' +
          opts.queryParam
      );
    });
    var spacer3 = document.createElement('DIV');
    spacer3.className = 'blockFloatNot wizardVspacer15px';
    return [
      spacer,
      explain,
      spacer2,
      explain2,
      spacer3,
      billingButton.container,
    ];
  };

  _i.buildTrialContent = function () {
    return _i.buildSectionContent({
      paragraph1: I18n.t('msg_thanks_for_evalutaing_rm'),
      paragraph2: I18n.t('msg_purchase_subscription'),
      buttonText: I18n.t('lbl_contact_us'),
      queryParam: '?fts=p',
    });
  };

  _i.buildActiveContent = function () {
    return _i.buildSectionContent({
      paragraph1: I18n.t('msg_upgrade_or_modify_plan'),
      paragraph2: I18n.t('msg_fill_out_form'),
      buttonText: I18n.t('lbl_modify_plan'),
      queryParam: '?fts=c',
    });
  };

  _i.attachTo = xGetElementById(attachTo);

  _i.constructor = function () {
    var pageContent = document.createElement('DIV');

    var contents = _i.accountIsTrial()
      ? _i.buildTrialContent()
      : _i.buildActiveContent();

    contents.map(function (child) {
      pageContent.appendChild(child);
    });

    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.container.appendChild(pageContent);
    _i.attachTo.appendChild(_i.container);
  };

  _i.constructor();
}
