function UserThumbnailLargeView(attach, obj) {
  var _i = this;
  if (!(this instanceof UserThumbnailLargeView)) {
    return new UserThumbnailLargeView(attach, obj);
  }

  _i.attachTo = xGetElementById(attach);
  _i.user = obj;

  _i.constructor = function () {
    _i.userThumbnailView = document.createElement('DIV');
    _i.userThumbnailView.className = 'userThumbnailFrameContainer';
    getSprite(compSpritesButtonsIcons.userThumbFrame, _i.userThumbnailView);

    _i.attachTo.appendChild(_i.userThumbnailView);

    _i.thumbnailContainer = document.createElement('DIV');
    _i.thumbnailContainer.className =
      'blockFloatNot userThumbnailFrameImageContainer';
    _i.userThumbnailView.appendChild(_i.thumbnailContainer);

    _i.thumbnailCropprContainer = document.createElement('DIV');
    _i.thumbnailCropprContainer.style.height = 'auto';
    _i.thumbnailCropprContainer.style.width = '100%';
    _i.thumbnailContainer.appendChild(_i.thumbnailCropprContainer);

    _i.thumbnail = document.createElement('IMG');
    _i.thumbnail.setAttribute('alt', '');
    _i.thumbnail.src = _i.user.thumbnail;
    _i.thumbnail.style.height = 'auto';
    _i.thumbnail.style.width = '100%';
    _i.thumbnailCropprContainer.appendChild(_i.thumbnail);
  };
  _i.constructor();
}
