function SettingsLeaveTypes(attachTo, label) {
  var _i = this;
  if (!(this instanceof SettingsLeaveTypes)) {
    return new SettingsLeaveTypes(attachTo, label);
  }

  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.labelval = label;

  _i.categoryControllers = [];

  _i.categoryCreated = function () {
    _i.buildCategoryArray();
    _i.refreshList();
    _i.categoryvalue.inputText.value = '';
  };

  _i.editModeCallback = function () {
    for (var i = 0; i < _i.categoryControllers.length; i++) {
      _i.categoryControllers[i].cancelEdit();
    }
  };

  _i.addLeaveType = function () {
    name = _i.categoryvalue.getValue();
    if (!name) {
      _i.categoryvalue.setAlert(I18n.t('err_valid_value'));
      return false;
    }

    for (var i = 0; i < _i.categoryValues.length; i++) {
      if (name === _i.categoryValues[i].name) {
        _i.categoryvalue.setAlert(I18n.t('msg_duplicate_value'));
        return false;
      }
    }

    var categoryData = {};
    categoryData.name = _i.categoryvalue.getValue();
    categoryData.leave_type = _i.categoryvalue.getValue();
    categoryData.value = _i.categoryvalue.getValue();
    gService.createLeaveType(categoryData, _i.categoryCreated);
  };

  _i.refreshList = function () {
    removeAllChildren(_i.categoryCollection);
    _i.buildCategoryArray();
    _i.displayCategory();
  };

  _i.spacerLineObject = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.categoryCollection.appendChild(spacer);
  };

  _i.displayCategory = function () {
    _i.categoryControllers = [];
    for (var i = 0; i < _i.categoryValues.length; i++) {
      _i.categoryControllers.push(
        SettingsLeaveType(_i.categoryCollection, _i.categoryValues[i], _i)
      );
      _i.spacerLineObject();
    }
  };

  _i.buildCategoryArray = function () {
    _i.categoryValues = gData.getLeaveTypes(true);
    if (_i.categoryValues && _i.categoryValues.length > 0) {
      _i.categoryValues.sort(function (a, b) {
        var sa = a.name.toLowerCase();
        var sb = b.name.toLowerCase();
        if (sa == sb) return 0;
        return sa > sb ? 1 : -1;
      });
    } else {
      gService.getLeaveTypesV1(_i.refreshList);
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);
    _i.label = document.createElement('H3');

    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.label.innerText = I18n.t('lbl_' + _i.labelval);
    _i.container.appendChild(_i.label);

    _i.categoryvalue = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_leave_type'),
      '',
      'blockFloat settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall'
    );
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.addButton = new primaryButton('settingsPrimaryButton');
    _i.addButton.setButtonText(I18n.t('lbl_add'));
    _i.addButton.setCallback(_i.addLeaveType);
    _i.container.appendChild(_i.addButton.container);
    _i.addButton.container.tabIndex = 0;

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);

    _i.categoryCollection = document.createElement('DIV');
    _i.categoryCollection.classname = 'blockFloatNot';
    _i.container.appendChild(_i.categoryCollection);

    _i.buildCategoryArray();
    _i.displayCategory();
  };

  _i.constructor();
}
