StringMatcher = function () {
  var _i = this;

  // Returns a list of matches which contain HTML for the match an id for the match
  _i.matchString = function (s) {
    var searchData = gData.getSearchData();
    var projects = searchData.project;
    var users = searchData.user;
    var projectMatches = [];
    var userMatches = [];
    var result = {
      input: s,
      projectMatches: projectMatches,
      userMatches: userMatches,
    };
    if (s.length > 0) {
      // Score each matching string. Scoring prioritizes matches closer to the start of the string
      // initially and then for matches with the same start position, they are scored higher for
      // shorter words. i.e. typing the letter Ba, will match the string Bar better than the string
      // Barbie
      var re = new RegExp(regExpEscape(s), 'i');
      for (var i = 0; projects && i < projects.length; ++i) {
        var o = projects[i];
        var match1 = re.exec(o.match);
        if (match1) {
          var icon = 'externalMark';
          if (o.param == 'Internal') {
            icon = 'internalMark';
          } else if (o.param == 'Tentative') {
            icon = 'grayMark';
          }
          projectMatches.push({
            text: o.match,
            id: o.id,
            guid: o.guid,
            type: o.item_type,
            score: -match1.index * 100 - o.match.length,
            icon: icon,
          });
        }
      }

      for (var i = 0; users && i < users.length; ++i) {
        var o = users[i];
        var match1 = re.exec(o.match);
        if (match1) {
          userMatches.push({
            text: o.match,
            id: o.id,
            guid: o.guid,
            type: o.item_type,
            score: -match1.index * 100 - o.match.length - 10000,
            icon: o.param,
          });
        }
      }
      function sortStrings(a, b) {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        }
        return 0;
      }
      userMatches.sort(function (a, b) {
        return b.score - a.score || sortStrings(a.text, b.text);
      });
      projectMatches.sort(function (a, b) {
        return b.score - a.score || sortStrings(a.text, b.text);
      });
    }
    return result;
  };
};

function SearchBoxHeader(parent, w, h, resultsWidth, matcher) {
  var _i = this;
  var margin = 8;

  _i.state = {
    isSearching: false,
  };

  _i.content = Awe.createElement('DIV', parent, {
    className: 'fnt-r-14 blockFloat',
    styles: {
      width: w + 'px',
      height: h - margin * 2 + 'px',
      margin: '0px 3px 0px 10px',
    },
  });

  var searchDiv = Awe.createElement('DIV', _i.content, {
    styles: {
      position: 'absolute',
      top: margin + 'px',
      width: '100%',
      height: '100%',
    },
  });

  var input = Awe.createElement('INPUT', searchDiv, {
    attrs: {
      placeholder: 'Search',
      ariaLabel: 'Resource Management by Smartsheet application searchbox',
    },
    className: 'fnt-r-14 searchBox',
    styles: {
      position: 'absolute',
      left: '0px',
      top: '0px',
      paddingLeft: '8px',
      width: w + 'px',
      height: '100%',
    },
  });

  input.tabIndex = '0';

  function onSearchSelected(selection) {
    if (selection.length) {
      var selected = selection[0];
      if (selected.itemType == 'project') {
        report_page_view(
          '/search/view_project?from=' + window.location.pathname
        );
        _i.usedResults = true;
        window.location.href =
          getBaseURL() + 'viewproject' + '?id=' + selected.id;
      } else if (selected.itemType == 'user') {
        report_page_view('/search/view_user?from=' + window.location.pathname);
        _i.usedResults = true;
        window.location.href = getBaseURL() + '?user_id=' + selected.guid;
      }
    }
    input.blur();
  }

  var suggestions,
    container,
    contentSource = {},
    delegateObject = {};

  _i.makeSubstringBold = function (string, substring) {
    var pos = string.toLowerCase().indexOf(substring.toLowerCase());
    if (pos >= 0) {
      var html = string.slice(0, pos) + '<strong class="fnt-b-14">';
      html += string.slice(pos, pos + substring.length);
      html += '</strong>';
      html += string.slice(pos + substring.length);
      return html;
    }
    return string;
  };

  _i.debouncedFetchAndSetSearchResults = _.debounce(function () {
    _i.showLoading();
    gService.getSearchResults(input.value, function (results) {
      gData.setSearchData(results);
      _i.resetContainer();
      _i.updateSuggestions();
    });
  }, 250);

  _i.updateSuggestions = function () {
    if (!_i.trackedThisUse) {
      report_page_view('/search?from=' + window.location.pathname);
      _i.trackedThisUse = true;
      _i.usedResults = false;
    }
    _i.matchResult = matcher.matchString(input.value);

    var projects = _i.matchResult.projectMatches;
    var users = _i.matchResult.userMatches;
    var data = [];

    if (projects.length) {
      data.push({
        type: 'label',
        label: I18n.t('lbl_projects'),
      });
    }

    for (var i = 0; i < projects.length; ++i) {
      data.push({
        id: projects[i].id,
        guid: projects[i].guid,
        type: 'item',
        value: projects[i].id,
        label: _i.makeSubstringBold(
          _.escape(projects[i].text),
          _.escape(input.value)
        ),
        itemType: projects[i].type,
        iconMarginTop: 9,
        icon: projects[i].icon,
        escaped: true,
      });
    }

    if (users.length) {
      data.push({
        type: 'label',
        label: I18n.t('lbl_people'),
      });
    }

    for (var i = 0; i < users.length; ++i) {
      data.push({
        id: users[i].id,
        guid: users[i].guid,
        type: 'item',
        value: users[i].id,
        label: _i.makeSubstringBold(
          _.escape(users[i].text),
          _.escape(input.value)
        ),
        itemType: users[i].type,
        iconURL: users[i].icon || STATIC_IMAGE_URL + '/anon.jpg',
        escaped: true,
      });
    }

    // NOTE if we dont have any data and we're "searching", show "no results" message
    if (data.length === 0 && _i.state.isSearching) {
      data.push({
        type: 'label',
        label: I18n.t('lbl_no_results'),
      });
    }

    if (data.length > 0) {
      contentSource.numberOfRows = function () {
        return data.length;
      };
      contentSource.contentForRowAtIndex = function (i) {
        return new selectionlistItemBasic(31, i, data[i], false).container;
      };
      delegateObject.onSelectRow = function (i, element) {
        if (data[i].type == 'item') {
          element.style.backgroundColor = '#666';
          element.style.color = '#fff';
          suggestions.freeze();
          onSearchSelected([data[i]]);
        }
      };
      delegateObject.onFocusRow = function (i, element) {
        element.style.backgroundColor = '#f2f2f2';
      };
      delegateObject.onBlurRow = function (i, element) {
        element.style.backgroundColor = 'transparent';
      };
      if (suggestions) suggestions.hide();
      var contentHeight = 31 * Math.min(12, data.length) + 2;
      suggestions = new jsvlv(
        resultsWidth,
        contentHeight,
        contentSource,
        delegateObject
      );

      if (container) {
        container.container.style.height = contentHeight + 45 + 'px';
      } else {
        container = new popupContainer(
          compSpritesButtonsIcons.wideDialogSpecs,
          resultsWidth,
          contentHeight,
          3,
          false,
          'ne',
          'pageHdr'
        );
        container.pointAtElement(input, 0, 15);
      }

      var lastChild =
        container.container.children[container.container.children.length - 1];
      lastChild.style.height = contentHeight + 'px';
      suggestions.show(lastChild);
    }
  };

  _i.hideSuggestions = function () {
    if (suggestions) {
      suggestions.hide();
      suggestions = null;
      _i.resetContainer();
    }
  };

  _i.buildSpinner = function () {
    var spinner = document.createElement('IMG');
    spinner.src = STATIC_IMAGE_URL + '/progress-indicator_15.gif';
    return spinner;
  };

  _i.showLoading = function () {
    // NOTE if user has typed query show loading, otherwise hide loading
    if (input.value.length > 0) {
      if (!container) {
        container = new popupContainer(
          compSpritesButtonsIcons.wideDialogSpecs,
          resultsWidth,
          24, // contentHeight
          3,
          false,
          'ne',
          'pageHdr'
        );

        var $content = $('<div>').css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '24px',
        });

        var spinner = _i.buildSpinner();
        var label = $('<div>Loading...</div>').css({
          marginLeft: '6px',
          fontSize: '14px',
          fontFamily: 'GibsonRegular, Verdana, sans-serif',
        });

        $content.append(spinner).append(label);

        container.attachContent($content[0]);
        container.pointAtElement(input, 0, 15);
      }
    } else {
      _i.resetContainer();
    }
  };

  _i.setSearching = function (nextState) {
    _i.state.isSearching = nextState;
  };

  _i.resetContainer = function () {
    if (container) container.remove();
    container = null;
  };

  xAddEventListener(input, 'blur', function () {
    input.value = '';
    _i.matchResult = null;
    removeClass(input, 'focus');
    if (_i.trackedThisUse) {
      if (!_i.usedResults) {
        report_page_view('/search/not_found?from=' + window.location.pathname);
      }
      _i.usedResults = false;
      _i.trackedThisUse = false;
    }
    setTimeout(_i.hideSuggestions, 250);
  });

  xAddEventListener(input, 'focus', function () {
    _i.buildSpinner(); // NOTE need to build spinner so image gets loaded before any AJAX call
    _i.setSearching(false);
    addClass(input, 'focus');
  });

  xAddEventListener(input, 'keydown', function (evt) {
    if (evt.keyCode === 27 /* escape */) input.blur();
  });

  xAddEventListener(input, 'keyup', function (evt) {
    var ignoredKeys = [
      37,
      38,
      39,
      40, // arrow keys
      13, // enter
      16, // shift
      17, // ctrl
      18, // option or alt
      91,
      93, // osx command or windows keys
    ];

    // if the keyup isn't an ignored key
    if (ignoredKeys.indexOf(evt.keyCode) < 0) {
      if (input.value.length > 0) {
        _i.setSearching(true);
        _i.debouncedFetchAndSetSearchResults();
      } else {
        _i.setSearching(false);
        _i.updateSuggestions();
      }
    }
  });
}
