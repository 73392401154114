// Params:
//
//   specs : object (like "wideDialogSpecs") that specifies the base art and offsets for pointers and corners
//     e.g.
//      wideDialogSpecs : {
//          backgroundImage : "images/compSpritesButtonsIcons.png",
//          width : 286,
//          height : 192,
//          xOffset : -206,
//          yOffset : -656,
//          leftPointerWidth : 18,
//          rightPointerWidth : 18,
//          topPointerHeight : 16,
//          bottomPointerHeight : 20,
//          xA : [232,328,370,465],
//          yA : [680,724,766,820]
//        }
//
//   contentWidth : the width of the content that will be going into the popup
//   contentHeight : the height of the content that will be going into the popup
//   innerMargin : the space between the content and the edge of the popup graphic
//   autoOrient : false for fixed pointer, true to adjust pointer to fit inside viewport
//   defaultDirection : the pointer direction, starting from the top-left and going clockwise, valid directions are
//     "nw" point north in the westmost position
//     "nc" point north in the center position
//     "ne" point north in the eastmost position
//     "en" point east in the northmost position
//     "ec" point east in the center position
//     "es" point east in the southmost position
//     "se" point south in the eastmost position
//     "sc" point south in the central position
//     "sw" point south in the westmost position
//     "ws" point west in the southmost position
//     "wc" point west in the center position
//     "wn" point west in the northmost position
//   dialogViewport : string or DOM element specifying the area that the popup is trying to stay within
//   preferDirections : array of directions like ["es","nw"] showing the directions you would prefer when auto-orienting
//
// Interface:
//
//   attachContent( ele ) : DOM element with content to add to the popupContainer
//   pointAtElement( ele ) : the "show" function for the popup, opens relative to the element passed
//
// Example Usage:
//
//   myPopupContainer = new popupContainer( wideDialogSpecs, 200, 300, 2, true, "nw", "mainCon", ["nw","se] );
//   myPopupContainer.pointAtElement( "someElementThatYouWantToPointAt" );
//
function popupContainer(
  specs,
  contentWidth,
  contentHeight,
  innerMargin,
  autoOrient,
  defaultDirection,
  dialogViewport,
  preferDirections,
  blurCallback,
  parentPopupContainer,
  hidePointer,
  options
) {
  var _i = this;

  // params
  _i.specs = specs;
  _i.contentWidth = contentWidth;
  _i.contentHeight = contentHeight;
  _i.innerMargin = innerMargin;
  _i.autoOrient = autoOrient;
  _i.defaultDirection = defaultDirection;
  _i.dialogViewport = xGetElementById(dialogViewport);
  _i.preferDirections = preferDirections;
  _i.blurCallback = blurCallback;
  _i.parentPopupContainer = parentPopupContainer;
  _i.hidePointer = hidePointer;
  _i.options = options || {};

  // locals
  _i.container = null;
  _i.outerWidth = null;
  _i.outerHeight = null;
  _i.direction = null;

  // id
  _i.dropdownID = _i.options.dropdownID || 'dropdown' + Awe.getGuid();

  // const
  _i.maxMultiplier = 4; // allow dialog resize up to X times

  _i.remove = function () {
    if (_i.container.parentNode) {
      _i.container.parentNode.removeChild(_i.container);
    }
  };

  _i.isVisible = function () {
    return _i.container.style.visibility != 'hidden';
  };

  _i.getPointerRelativeOffsets = function () {
    _i.npLeft.pointerRelativeX = Math.round(
      parseInt(_i.npLeft.style.left) + parseInt(_i.np.style.width) / 2
    );
    _i.npCenter.pointerRelativeX = Math.round(
      parseInt(_i.npCenter.style.left) + parseInt(_i.np.style.width) / 2
    );
    _i.npRight.pointerRelativeX = Math.round(
      parseInt(_i.npRight.style.left) + parseInt(_i.np.style.width) / 2
    );

    _i.npLeft.pointerRelativeY = 0;
    _i.npCenter.pointerRelativeY = 0;
    _i.npRight.pointerRelativeY = 0;

    _i.spLeft.pointerRelativeX = Math.round(
      parseInt(_i.spLeft.style.left) + parseInt(_i.sp.style.width) / 2
    );
    _i.spCenter.pointerRelativeX = Math.round(
      parseInt(_i.spCenter.style.left) + parseInt(_i.sp.style.width) / 2
    );
    _i.spRight.pointerRelativeX = Math.round(
      parseInt(_i.spRight.style.left) + parseInt(_i.sp.style.width) / 2
    );

    _i.spLeft.pointerRelativeY = _i.outerHeight;
    _i.spCenter.pointerRelativeY = _i.outerHeight;
    _i.spRight.pointerRelativeY = _i.outerHeight;

    _i.wpTop.pointerRelativeX = 0;
    _i.wpCenter.pointerRelativeX = 0;
    _i.wpBottom.pointerRelativeX = 0;

    _i.wpTop.pointerRelativeY = Math.round(
      parseInt(_i.wpTop.style.top) + parseInt(_i.wp.style.height) / 2
    );
    _i.wpCenter.pointerRelativeY = Math.round(
      parseInt(_i.wpCenter.style.top) + parseInt(_i.wp.style.height) / 2
    );
    _i.wpBottom.pointerRelativeY = Math.round(
      parseInt(_i.wpBottom.style.top) + parseInt(_i.wp.style.height) / 2
    );

    _i.epTop.pointerRelativeX = _i.outerWidth;
    _i.epCenter.pointerRelativeX = _i.outerWidth;
    _i.epBottom.pointerRelativeX = _i.outerWidth;

    _i.epTop.pointerRelativeY = Math.round(
      parseInt(_i.epTop.style.top) + parseInt(_i.ep.style.height) / 2
    );
    _i.epCenter.pointerRelativeY = Math.round(
      parseInt(_i.epCenter.style.top) + parseInt(_i.ep.style.height) / 2
    );
    _i.epBottom.pointerRelativeY = Math.round(
      parseInt(_i.epBottom.style.top) + parseInt(_i.ep.style.height) / 2
    );
  };

  _i.calculatePointerOffsets = function () {
    _i.leftHorizontalOffset = Math.round(
      _i.outerWidth / 4 - parseInt(_i.np.style.width) / 2
    );
    _i.centerHorizontalOffset = Math.round(
      _i.outerWidth / 2 - parseInt(_i.np.style.width) / 2
    );
    _i.rightHorizontalOffset = Math.round(
      (_i.outerWidth / 4) * 3 - parseInt(_i.np.style.width) / 2
    );

    _i.topVerticalOffset = Math.round(
      _i.outerHeight / 4 - parseInt(_i.ep.style.height) / 2
    );
    _i.centerVerticalOffset = Math.round(
      _i.outerHeight / 2 - parseInt(_i.ep.style.height) / 2
    );
    _i.bottomVerticalOffset = Math.round(
      (_i.outerHeight / 4) * 3 - parseInt(_i.ep.style.height) / 2
    );

    _i.topVerticalOffset = Math.max(
      parseInt(_i.wp.style.height) / 2,
      _i.topVerticalOffset
    );

    _i.npRight.style.left = _i.rightHorizontalOffset + 'px';
    _i.npRight.style.top = '0px';
    _i.npCenter.style.left = _i.centerHorizontalOffset + 'px';
    _i.npCenter.style.top = '0px';
    _i.npLeft.style.left = _i.leftHorizontalOffset + 'px';
    _i.npLeft.style.top = '0px';

    _i.spRight.style.left = _i.rightHorizontalOffset + 'px';
    _i.spRight.style.bottom = '0px';
    _i.spCenter.style.left = _i.centerHorizontalOffset + 'px';
    _i.spCenter.style.bottom = '0px';
    _i.spLeft.style.left = _i.leftHorizontalOffset + 'px';
    _i.spLeft.style.bottom = '0px';

    _i.wpTop.style.top = _i.topVerticalOffset + 'px';
    _i.wpTop.style.left = '0px';
    _i.wpCenter.style.top = _i.centerVerticalOffset + 'px';
    _i.wpCenter.style.left = '0px';
    _i.wpBottom.style.top = _i.bottomVerticalOffset + 'px';
    _i.wpBottom.style.left = '0px';

    _i.epTop.style.top = _i.topVerticalOffset + 'px';
    _i.epTop.style.right = '0px';
    _i.epCenter.style.top = _i.centerVerticalOffset + 'px';
    _i.epCenter.style.right = '0px';
    _i.epBottom.style.top = _i.bottomVerticalOffset + 'px';
    _i.epBottom.style.right = '0px';

    _i.getPointerRelativeOffsets();
  };

  _i.pointersOff = function () {
    for (var p in _i.pointerCollection) {
      _i.pointerCollection[p].style.display = 'none';
    }
  };

  _i.showCurrentPointer = function () {
    if (_i.hidePointer) return;
    _i.pointerCollection[_i.direction].style.display = 'block';
  };

  _i.setDirection = function (direction) {
    _i.direction = direction;
    setTimeout(_i.pointersOff);
    setTimeout(_i.showCurrentPointer);
  };

  _i.overageFromDirection = function (direction) {
    var coords = _i.dialogCoordsFromDirection(direction);

    var retVal = 0;

    var viewLoX = xPageX(_i.dialogViewport);
    var viewLoY = xPageY(_i.dialogViewport);
    var viewHiX = viewLoX + xWidth(_i.dialogViewport);
    var viewHiY = viewLoY + xHeight(_i.dialogViewport);

    var dlgLoX = coords.x + xPageX(_i.dialogViewport);
    var dlgLoY = coords.y + xPageY(_i.dialogViewport);
    var dlgHiX = dlgLoX + _i.outerWidth;
    var dlgHiY = dlgLoY + _i.outerHeight;

    var xunder = 0;
    var xover = 0;
    var yunder = 0;
    var yover = 0;

    if (dlgLoX < viewLoX) {
      xunder = viewLoX - dlgLoX;
      retVal += (viewLoX - dlgLoX) * _i.outerHeight;
    }

    if (dlgLoY < viewLoY) {
      yunder = viewLoY - dlgLoY;
      retVal += (viewLoY - dlgLoY) * _i.outerWidth;
    }

    if (dlgHiX > viewHiX) {
      xover = dlgHiX - viewHiX;
      retVal += (dlgHiX - viewHiX) * _i.outerHeight;
    }

    if (dlgHiY > viewHiY) {
      yover = dlgHiY - viewHiY;
      retVal += (dlgHiY - viewHiY) * _i.outerWidth;
    }

    return retVal;
  };

  // relative coords
  _i.dialogCoordsFromDirection = function (direction) {
    var majorDirection = direction.substr(0, 1);

    var offsetX = 0;
    var offsetY = 0;

    if (majorDirection == 'n') {
      offsetX = 0;
      offsetY = _i.pointerOffsetY;
    }

    if (majorDirection == 's') {
      offsetX =
        _i.options && _i.options.allowOffsetOverride ? _i.pointerOffsetX : 0;
      offsetY = -_i.pointerOffsetY;
    }

    if (majorDirection == 'e') {
      offsetX = -_i.pointerOffsetX;
      offsetY = 0;
    }

    if (majorDirection == 'w') {
      offsetX = _i.pointerOffsetX;
      offsetY = 0;
    }

    var domOffsetX = xPageX(_i.dialogViewport);
    var domOffsetY = xPageY(_i.dialogViewport);

    var dlgX =
      _i.pointerX +
      offsetX -
      _i.pointerCollection[direction].pointerRelativeX -
      domOffsetX;
    var dlgY =
      _i.pointerY +
      offsetY -
      _i.pointerCollection[direction].pointerRelativeY -
      domOffsetY;

    return { x: dlgX, y: dlgY };
  };

  _i.getPreferredDirection = function () {
    var retVal = null;

    if (_i.preferDirections != null) {
      for (var p in _i.preferDirections) {
        if (_i.overageFromDirection(p) == 0) {
          retVal = p;
        }
      }
    }

    return retVal;
  };

  _i.getBestDirection = function () {
    var bestDirection = '';
    var minOverage = 10000000000; // arbitrarily high number

    for (var p in _i.pointerCollection) {
      if (_i.overageFromDirection(p, true) < minOverage) {
        // we need to observe the height and be sure not to pick edges
        // when the height is too small to render correctly
        if (
          _i.outerHeight < 150 &&
          (p == 'wn' || p == 'ws' || p == 'en' || p == 'es')
        ) {
          continue;
        } else {
          minOverage = _i.overageFromDirection(p);
          bestDirection = p;
        }
      }
    }

    return bestDirection;
  };

  _i.pointAtElement = function (ele, xoffset, yoffset) {
    var x = xPageX(ele) + xWidth(ele) / 2;
    var y = xPageY(ele) + xHeight(ele) / 2;

    var dX = Math.round(xWidth(ele) / 2);
    var dY = Math.round(xHeight(ele) / 2);

    if (xoffset) dX += xoffset;
    if (yoffset) dY += yoffset;

    // TODO Make this configurable via popup-config, because it needs to be dependent on the border assets
    var offsetAdjustment = 15;
    _i.pointAt(
      x,
      y,
      _i.options && _i.options.allowNegativeValues
        ? dX - offsetAdjustment
        : Awe.positiveOrZero(dX - offsetAdjustment),
      _i.options && _i.options.allowNegativeValues
        ? dY - offsetAdjustment
        : Awe.positiveOrZero(dY - offsetAdjustment)
    );
  };

  _i.pointAt = function (x, y, offsetX, offsetY) {
    _i.pointerX = x || _i.pointerX || 0;
    _i.pointerY = y || _i.pointerY || 0;
    _i.pointerOffsetX = offsetX || _i.pointerOffsetX || 0;
    _i.pointerOffsetY = offsetY || _i.pointerOffsetY || 0;

    if (!_i.constructorDone) {
      setTimeout(_i.pointAt, 10);
      return;
    }

    if (_i.autoOrient && _i.overageFromDirection(_i.direction) > 0) {
      if (_i.getPreferredDirection()) {
        _i.setDirection(_i.getPreferredDirection());
      } else {
        _i.setDirection(_i.getBestDirection());
      }
    }
    _i.show();
  };

  _i.show = function () {
    var dlgCoords = _i.dialogCoordsFromDirection(_i.direction);
    _i.container.style.left = dlgCoords.x + 'px';
    _i.container.style.top = dlgCoords.y + 'px';
    _i.container.style.zIndex = Z_POPUP;

    Awe.showPopup(
      _i.container,
      _i.blurCallback,
      _i.parentPopupContainer ? _i.parentPopupContainer.container : null
    );
  };

  _i.hide = function () {
    Awe.hidePopup(_i.container);
  };

  _i.fillVertical = function (parentElement, childPattern) {
    var numToFill = Math.ceil(
      (_i.outerHeight * _i.maxMultiplier) / parseInt(childPattern.style.height)
    );

    for (var z = 0; z < numToFill; z++) {
      var dv = childPattern.cloneNode(false);
      dv.className = 'blockFloatNot';
      parentElement.appendChild(dv);
    }
  };

  _i.attachContent = function (ele) {
    if (ele) {
      _i.content = ele;
    }

    if (!_i.constructorDone) {
      setTimeout(_i.attachContent, 50);
      return;
    }

    _i.contentHolder.appendChild(_i.content);
  };

  _i.fillHorizontal = function (parentElement, childPattern) {
    var numToFill = Math.ceil(
      (_i.outerWidth * _i.maxMultiplier) / parseInt(childPattern.style.width)
    );

    for (var z = 0; z < numToFill; z++) {
      var dv = childPattern.cloneNode(false);
      dv.className = 'blockFloat';
      parentElement.appendChild(dv);
    }
  };

  _i.makePointerTemplates = function () {
    // west pointer, north pointer, east pointer, south pointer
    _i.wp = document.createElement('DIV');
    _i.wp.style.position = 'absolute';
    _i.wp.style.width = _i.specs.xA[0] + _i.specs.xOffset + 'px';
    _i.wp.style.height = _i.specs.yA[2] - _i.specs.yA[1] + 'px';
    _i.wp.style.left = '0px';
    _i.wp.style.top = '35px';
    _i.wp.style.backgroundImage = _i.bgUrl;
    _i.wp.style.backgroundRepeat = 'no-repeat';
    _i.wp.style.backgroundPosition =
      _i.specs.xOffset + 'px ' + -_i.specs.yA[1] + 'px';
    _i.wp.style.display = 'none';

    _i.np = document.createElement('DIV');
    _i.np.style.position = 'absolute';
    _i.np.style.width = _i.specs.xA[2] - _i.specs.xA[1] + 'px';
    _i.np.style.height = _i.specs.yA[0] + _i.specs.yOffset + 'px';
    _i.np.style.left = '35px';
    _i.np.style.top = '0px';
    _i.np.style.backgroundImage = _i.bgUrl;
    _i.np.style.backgroundRepeat = 'no-repeat';
    _i.np.style.backgroundPosition =
      -_i.specs.xA[1] + 'px ' + _i.specs.yOffset + 'px';
    _i.np.style.display = 'none';

    _i.ep = document.createElement('DIV');
    _i.ep.style.position = 'absolute';
    _i.ep.style.width =
      _i.specs.width -
      _i.specs.xA[_i.specs.xA.length - 1] -
      _i.specs.xOffset +
      'px';
    _i.ep.style.height = _i.specs.yA[2] - _i.specs.yA[1] + 'px';
    _i.ep.style.right = '0px';
    _i.ep.style.top = '35px';
    _i.ep.style.backgroundImage = _i.bgUrl;
    _i.ep.style.backgroundRepeat = 'no-repeat';
    _i.ep.style.backgroundPosition =
      -_i.specs.xA[_i.specs.xA.length - 1] + 'px ' + -_i.specs.yA[1] + 'px';
    _i.ep.style.display = 'none';

    _i.sp = document.createElement('DIV');
    _i.sp.style.position = 'absolute';
    _i.sp.style.width = _i.specs.xA[2] - _i.specs.xA[1] + 'px';
    _i.sp.style.height =
      _i.specs.height -
      _i.specs.yA[_i.specs.yA.length - 1] -
      _i.specs.yOffset +
      'px';
    _i.sp.style.bottom = '0px';
    _i.sp.style.left = '35px';
    _i.sp.style.backgroundImage = _i.bgUrl;
    _i.sp.style.backgroundRepeat = 'no-repeat';
    _i.sp.style.backgroundPosition =
      -_i.specs.xA[1] + 'px ' + -_i.specs.yA[_i.specs.yA.length - 1] + 'px';
    _i.sp.style.display = 'none';
  };

  _i.resizeTo = function (contentWidth, contentHeight) {
    _i.setDimensions(contentWidth, contentHeight);
    _i.calculatePointerOffsets();
    _i.setDirection(_i.direction);
    _i.pointAt();
  };

  _i.setDimensions = function (contentWidth, contentHeight) {
    _i.contentWidth = contentWidth;
    _i.contentHeight = contentHeight;
    _i.outerWidth =
      _i.specs.leftPointerWidth +
      _i.innerMargin +
      _i.contentWidth +
      _i.innerMargin +
      _i.specs.rightPointerWidth;
    _i.outerHeight =
      _i.specs.topPointerHeight +
      _i.innerMargin +
      _i.contentHeight +
      _i.innerMargin +
      _i.specs.bottomPointerHeight;

    if (!_i.container) {
      _i.container = document.createElement('DIV');
      _i.container.className = 'popupContainer';
      _i.container.style.position = 'absolute';
      _i.container.style.left = '100px';
      _i.container.style.top = '100px';
      _i.container.style.visibility = 'hidden';
    }

    if (!_i.contentHolder) {
      _i.contentHolder = document.createElement('DIV');
      _i.contentHolder.style.position = 'absolute';
      _i.contentHolder.style.left =
        _i.specs.leftPointerWidth + _i.innerMargin + 'px';
      _i.contentHolder.style.top =
        _i.specs.topPointerHeight + _i.innerMargin + 'px';
      _i.contentHolder.style.backgroundColor = _i.specs.backgroundColor;
    }

    _i.container.style.width = _i.outerWidth + 'px';
    _i.container.style.height = _i.outerHeight + 'px';

    _i.contentHolder.style.width = _i.contentWidth + 'px';
    _i.contentHolder.style.height = _i.contentHeight + 'px';
  };

  _i.buildDialog = function () {
    _i.bgUrl = 'url(' + _i.specs.backgroundImage + ')';

    _i.makePointerTemplates();

    _i.setDimensions(_i.contentWidth, _i.contentHeight);

    _i.leftCornerWidth = _i.specs.xA[0] + _i.specs.xOffset;
    _i.rightCornerWidth =
      _i.specs.width - _i.specs.xOffset - _i.specs.xA[_i.specs.xA.length - 1];
    _i.topCornerHeight = _i.specs.yA[0] + _i.specs.yOffset;
    _i.bottomCornerHeight =
      _i.specs.height - _i.specs.yOffset - _i.specs.yA[_i.specs.yA.length - 1];

    // center tile
    _i.c = document.createElement('DIV');
    _i.c.style.position = 'absolute';
    _i.c.style.left = _i.leftCornerWidth + 'px';
    _i.c.style.top = _i.topCornerHeight + 'px';
    _i.c.style.right = _i.rightCornerWidth + 'px';
    _i.c.style.bottom = _i.bottomCornerHeight + 'px';
    _i.c.style.backgroundColor = '#FFFFFF';
    _i.c.style.overflow = 'hidden';
    _i.c.style.backgroundColor = 'FFFFFF';

    // ul corner, ur corner, ll corner, lr corner
    _i.ul = document.createElement('DIV');
    _i.ul.style.position = 'absolute';
    _i.ul.style.left = '0px';
    _i.ul.style.top = '0px';
    _i.ul.style.width = _i.leftCornerWidth + 'px';
    _i.ul.style.height = _i.topCornerHeight + 'px';
    _i.ul.style.backgroundImage = 'url(' + _i.specs.backgroundImage + ')';
    _i.ul.style.backgroundPosition =
      _i.specs.xOffset + 'px ' + _i.specs.yOffset + 'px';
    _i.ul.style.backgroundRepeat = 'no-repeat';

    _i.ur = document.createElement('DIV');
    _i.ur.style.position = 'absolute';
    _i.ur.style.top = '0px';
    _i.ur.style.right = '0px';
    _i.ur.style.width = _i.rightCornerWidth + 'px';
    _i.ur.style.height = _i.topCornerHeight + 'px';
    _i.ur.style.backgroundImage = _i.bgUrl;
    _i.ur.style.backgroundPosition =
      -specs.xA[specs.xA.length - 1] + 'px ' + _i.specs.yOffset + 'px';
    _i.ur.style.backgroundRepeat = 'no-repeat';

    _i.ll = document.createElement('DIV');
    _i.ll.style.position = 'absolute';
    _i.ll.style.left = '0px';
    _i.ll.style.bottom = '0px';
    _i.ll.style.width = _i.leftCornerWidth + 'px';
    _i.ll.style.height = _i.bottomCornerHeight + 'px';
    _i.ll.style.backgroundImage = _i.bgUrl;
    _i.ll.style.backgroundPosition =
      _i.specs.xOffset + 'px ' + -_i.specs.yA[_i.specs.yA.length - 1] + 'px';
    _i.ll.style.backgroundRepeat = 'no-repeat';

    _i.lr = document.createElement('DIV');
    _i.lr.style.position = 'absolute';
    _i.lr.style.bottom = '0px';
    _i.lr.style.right = '0px';
    _i.lr.style.width = _i.rightCornerWidth + 'px';
    _i.lr.style.height = _i.bottomCornerHeight + 'px';
    _i.lr.style.backgroundImage = _i.bgUrl;
    _i.lr.style.backgroundPosition =
      -_i.specs.xA[_i.specs.xA.length - 1] +
      'px ' +
      -_i.specs.yA[_i.specs.yA.length - 1] +
      'px';
    _i.lr.style.backgroundRepeat = 'no-repeat';

    // top side, bottom side, left side, right side
    _i.t = document.createElement('DIV');
    _i.t.style.position = 'absolute';
    _i.t.style.left = _i.leftCornerWidth + 'px';
    _i.t.style.right = _i.rightCornerWidth + 'px';
    _i.t.style.height = _i.topCornerHeight + 'px';
    _i.t.style.overflow = 'hidden';

    _i.b = document.createElement('DIV');
    _i.b.style.position = 'absolute';
    _i.b.style.left = _i.leftCornerWidth + 'px';
    _i.b.style.right = _i.rightCornerWidth + 'px';
    _i.b.style.height = _i.bottomCornerHeight + 'px';
    _i.b.style.bottom = '0px';
    _i.b.style.overflow = 'hidden';

    _i.l = document.createElement('DIV');
    _i.l.style.position = 'absolute';
    _i.l.style.left = '0px';
    _i.l.style.top = _i.topCornerHeight + 'px';
    _i.l.style.bottom = _i.bottomCornerHeight + 'px';
    _i.l.style.width = _i.leftCornerWidth + 'px';
    _i.l.style.overflow = 'hidden';

    _i.r = document.createElement('DIV');
    _i.r.style.position = 'absolute';
    _i.r.style.top = _i.topCornerHeight + 'px';
    _i.r.style.bottom = _i.bottomCornerHeight + 'px';
    _i.r.style.width = _i.rightCornerWidth + 'px';
    _i.r.style.right = '0px';
    _i.r.style.overflow = 'hidden';

    // little chunks that fill the interior of top, left, bottom and right bars
    _i.topHorizontalChunk = document.createElement('DIV');
    _i.topHorizontalChunk.style.left = '0px';
    _i.topHorizontalChunk.style.top = '0px';
    _i.topHorizontalChunk.style.width = _i.specs.xA[1] - _i.specs.xA[0] + 'px';
    _i.topHorizontalChunk.style.height = _i.topCornerHeight + 'px';
    _i.topHorizontalChunk.style.backgroundImage = _i.bgUrl;
    _i.topHorizontalChunk.style.backgroundRepeat = 'no-repeat';
    _i.topHorizontalChunk.style.backgroundPosition =
      -_i.specs.xA[0] + 'px ' + _i.specs.yOffset + 'px';

    _i.bottomHorizontalChunk = document.createElement('DIV');
    _i.bottomHorizontalChunk.style.left = '0px';
    _i.bottomHorizontalChunk.style.top = '0px';
    _i.bottomHorizontalChunk.style.width =
      _i.specs.xA[1] - _i.specs.xA[0] + 'px';
    _i.bottomHorizontalChunk.style.height = _i.bottomCornerHeight + 'px';
    _i.bottomHorizontalChunk.style.backgroundImage = _i.bgUrl;
    _i.bottomHorizontalChunk.style.backgroundRepeat = 'no-repeat';
    _i.bottomHorizontalChunk.style.backgroundPosition =
      -_i.specs.xA[0] + 'px ' + -_i.specs.yA[_i.specs.yA.length - 1] + 'px';

    _i.leftVerticalChunk = document.createElement('DIV');
    _i.leftVerticalChunk.style.left = '0px';
    _i.leftVerticalChunk.style.top = '0px';
    _i.leftVerticalChunk.style.width = _i.leftCornerWidth + 'px';
    _i.leftVerticalChunk.style.height = _i.specs.yA[1] - _i.specs.yA[0] + 'px';
    _i.leftVerticalChunk.style.backgroundImage = _i.bgUrl;
    _i.leftVerticalChunk.style.backgroundRepeat = 'no-repeat';
    _i.leftVerticalChunk.style.backgroundPosition =
      _i.specs.xOffset + 'px ' + -_i.specs.yA[0] + 'px';

    _i.rightVerticalChunk = document.createElement('DIV');
    _i.rightVerticalChunk.style.left = '0px';
    _i.rightVerticalChunk.style.top = '0px';
    _i.rightVerticalChunk.style.width = _i.rightCornerWidth + 'px';
    _i.rightVerticalChunk.style.height = _i.specs.yA[1] - _i.specs.yA[0] + 'px';
    _i.rightVerticalChunk.style.backgroundImage = _i.bgUrl;
    _i.rightVerticalChunk.style.backgroundRepeat = 'no-repeat';
    _i.rightVerticalChunk.style.backgroundPosition =
      -_i.specs.xA[_i.specs.xA.length - 1] + 'px ' + -_i.specs.yA[0] + 'px';

    // top inner, left inner, et al, get filled with chunks
    _i.ti = document.createElement('DIV');
    _i.ti.style.position = 'absolute';
    _i.ti.style.left = '0px';
    _i.ti.style.top = '0px';
    _i.ti.style.width = _i.outerWidth * _i.maxMultiplier + 'px';
    _i.ti.style.height = '100%';

    _i.bi = _i.ti.cloneNode(false);

    _i.li = document.createElement('DIV');
    _i.li.style.position = 'absolute';
    _i.li.style.left = '0px';
    _i.li.style.top = '0px';
    _i.li.style.width = '100%';
    _i.li.style.height = _i.outerHeight * _i.maxMultiplier + 'px';

    _i.ri = _i.li.cloneNode(false);

    _i.container.appendChild(_i.ul);
    _i.container.appendChild(_i.t);
    _i.container.appendChild(_i.ur);
    _i.container.appendChild(_i.l);
    _i.container.appendChild(_i.ll);
    _i.container.appendChild(_i.b);
    _i.container.appendChild(_i.lr);
    _i.container.appendChild(_i.r);
    _i.container.appendChild(_i.c);

    _i.npRight = _i.container.appendChild(_i.np.cloneNode(false));
    _i.npCenter = _i.container.appendChild(_i.np.cloneNode(false));
    _i.npLeft = _i.container.appendChild(_i.np.cloneNode(false));

    _i.epTop = _i.container.appendChild(_i.ep.cloneNode(false));
    _i.epCenter = _i.container.appendChild(_i.ep.cloneNode(false));
    _i.epBottom = _i.container.appendChild(_i.ep.cloneNode(false));

    _i.wpTop = _i.container.appendChild(_i.wp.cloneNode(false));
    _i.wpCenter = _i.container.appendChild(_i.wp.cloneNode(false));
    _i.wpBottom = _i.container.appendChild(_i.wp.cloneNode(false));

    _i.spRight = _i.container.appendChild(_i.sp.cloneNode(false));
    _i.spCenter = _i.container.appendChild(_i.sp.cloneNode(false));
    _i.spLeft = _i.container.appendChild(_i.sp.cloneNode(false));

    _i.pointerCollection = {};

    _i.pointerCollection['nw'] = _i.npLeft;
    _i.pointerCollection['nc'] = _i.npCenter;
    _i.pointerCollection['ne'] = _i.npRight;
    _i.pointerCollection['wn'] = _i.wpTop;
    _i.pointerCollection['wc'] = _i.wpCenter;
    _i.pointerCollection['ws'] = _i.wpBottom;
    _i.pointerCollection['en'] = _i.epTop;
    _i.pointerCollection['ec'] = _i.epCenter;
    _i.pointerCollection['es'] = _i.epBottom;
    _i.pointerCollection['sw'] = _i.spLeft;
    _i.pointerCollection['sc'] = _i.spCenter;
    _i.pointerCollection['se'] = _i.spRight;

    _i.t.appendChild(_i.ti);
    _i.l.appendChild(_i.li);
    _i.r.appendChild(_i.ri);
    _i.b.appendChild(_i.bi);

    _i.fillHorizontal(_i.ti, _i.topHorizontalChunk);
    _i.fillHorizontal(_i.bi, _i.bottomHorizontalChunk);
    _i.fillVertical(_i.li, _i.leftVerticalChunk);
    _i.fillVertical(_i.ri, _i.rightVerticalChunk);

    _i.calculatePointerOffsets();
    _i.setDirection(_i.defaultDirection);

    _i.contentHolder.id = _i.dropdownID;
    _i.dialogViewport.appendChild(_i.container);
    _i.container.appendChild(_i.contentHolder);

    _i.constructorDone = true;
  };

  _i.constructor = function () {
    // reindex preferred directions
    if (_i.preferDirections) {
      var obj = {};

      for (var z = 0; z < _i.preferDirections.length; z++) {
        obj[_i.preferDirections[z]] = _i.preferDirections[z];
      }

      _i.preferDirections = obj;
    }

    _i.buildDialog();
  };

  _i.constructor();
}
