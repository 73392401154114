function SettingsHoliday(attach, holiday, parent) {
  var _i = this;
  if (!(this instanceof SettingsHoliday)) {
    return new SettingsHoliday(attach, holiday, parent);
  }

  _i.attach = attach;
  _i.holiday = holiday;
  _i.parent = parent;
  _i.mode = 'read';
  _i.expectedcallbacks = 0;

  var CONFIRMATION_POPUP_NAME_MAX = 86;

  _i.holidayError = function () {
    _i.expectedcallbacks--;
    if (_i.expectedcallbacks == 0) gPageHeader.setSpinner(false);
  };

  _i.holidayChanged = function () {
    _i.expectedcallbacks--;
    if (_i.expectedcallbacks == 0) gPageHeader.setSpinner(false);
    _i.parent.refreshList();
  };

  _i.deleteEdit = function () {
    var holidayDisplayName =
      _i.holiday.name.length > CONFIRMATION_POPUP_NAME_MAX
        ? _i.holiday.name.slice(0, CONFIRMATION_POPUP_NAME_MAX - 1) + '...'
        : _i.holiday.name;

    var confirmDelete = confirmationPopup(
      I18n.t('lbl_delete') + ' ' + holidayDisplayName + '?',
      I18n.t('lbl_delete'),
      function () {
        _i.expectedcallbacks++;
        gPageHeader.setSpinner(true);
        gService.deleteHoliday(_i.holiday, _i.holidayChanged, _i.holidayError);
      },
      null,
      _i.parent.container
    );
    confirmDelete.show(_i.deleteButton.container);
  };

  _i.cancelEdit = function () {
    if (_i.mode == 'edit') {
      _i.listMode();
    }
    _i.holidaycontainer.className = 'blockFloatNot settingsUserContainer';
  };

  _i.saveEdit = function () {
    var data = {};
    data.id = _i.holiday.id;
    data.name = _i.holidayname.getValue();
    data.date = _i.holidaydate.selectedDate;
    _i.expectedcallbacks++;
    gPageHeader.setSpinner(true);
    gService.updateHoliday(data, _i.holidayChanged, _i.holidayError);
  };

  _i.containerMouseover = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.holidaycontainer.className =
        'blockFloatNot settingsUserContainerHover';
    }
  };

  _i.containerMouseout = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.holidaycontainer.className = 'blockFloatNot settingsUserContainer';
    }
  };

  _i.containerClick = function (evt) {
    if (_i.mode != 'edit') {
      _i.parent.editModeCallback();
      _i.editMode();
      _i.holidaycontainer.className = 'blockFloatNot settingsUserContainerEdit';
    }
  };

  _i.dateSelected = function (date) {
    _i.holidaydate.setDate(date);
    _i.adddate = new Date(date);
  };

  _i.editMode = function () {
    removeAllChildren(_i.holidaycontainer);
    _i.holidayname = new TextInput(
      _i.holidaycontainer.id,
      null,
      _i.holiday.name,
      'blockFloat settingsPageEditInputContainerSmallerCategoryHoliday',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmallerHoliday'
    );
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer2';
    _i.holidaycontainer.appendChild(spacer);
    var date = new Date(_i.holiday.date);
    _i.datePopup = new calendarPopup(
      230,
      210,
      'mainCon',
      date,
      compSpritesButtonsIcons.wideDialogSpecsGray,
      _i.dateSelected,
      false,
      false,
      'ne',
      true
    );
    _i.holidaydate = new PopUpCalendar(
      _i.holidaycontainer.id,
      I18n.t('lbl_date'),
      'blockFloat settingsPageEditInputContainerSmallerCategoryHoliday',
      'blockFloatNot fnt-r-14 settingsInputContainerSmallDate',
      'visually-hidden',
      'blockFloatNot fnt-r-15 settingsTextInputSmallDate',
      date,
      _i.datePopup,
      true
    );

    _i.deleteButton = new destructiveButton('settingsSecondaryButtonCategory');
    _i.deleteButton.setButtonText(I18n.t('lbl_delete'));
    _i.deleteButton.setCallback(_i.deleteEdit);
    _i.holidaycontainer.appendChild(_i.deleteButton.container);
    _i.deleteButton.container.tabIndex = 0;

    _i.saveButton = new primaryButtonNarrow(
      'settingsSecondaryButtonCategoryNarrow'
    );
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveEdit);
    _i.holidaycontainer.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;

    _i.cancelButton = new spritesButton(
      'settingsCancelButtonCategory',
      null,
      compSpritesButtonsIcons.closeButtonRest,
      compSpritesButtonsIcons.closeButtonHover,
      compSpritesButtonsIcons.closeButtonDown
    );
    _i.cancelButton.setButtonText('');
    _i.cancelButton.setCallback(_i.cancelEdit);
    _i.holidaycontainer.appendChild(_i.cancelButton.container);

    _i.mode = 'edit';
  };

  _i.listMode = function () {
    removeAllChildren(_i.holidaycontainer);
    var name = document.createElement('DIV');
    name.className = 'blockFloat fnt-r-14 settingsUserName';
    _i.holidaycontainer.appendChild(name);
    name.innerText = _i.holiday.name;

    var type = document.createElement('DIV');
    type.className = 'blockFloat fnt-r-14 settingsUserType';
    _i.holidaycontainer.appendChild(type);
    type.innerText = _i.holiday.date.toInputStyleStringShort();
    _i.mode = 'list';
  };

  _i.constructor = function () {
    _i.holidaycontainer = document.createElement('DIV');
    _i.holidaycontainer.className = 'blockFloatNot settingsUserContainer';
    _i.holidaycontainer.id = 'settingsUserContainer' + getGuid();
    _i.attach.appendChild(_i.holidaycontainer);
    xAddEventListener(
      _i.holidaycontainer,
      'mouseover',
      _i.containerMouseover,
      true
    );
    xAddEventListener(
      _i.holidaycontainer,
      'mouseout',
      _i.containerMouseout,
      true
    );
    xAddEventListener(
      _i.holidaycontainer,
      platform.click,
      _i.containerClick,
      true
    );
    _i.listMode();
  };
  _i.constructor();
}
