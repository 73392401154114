function CustomFieldOptionsEditView(titleText) {
  var _i = this,
    _titleText = titleText;
  (_elId = 'customfieldoptionseditview' + _.uniqueId()),
    (_t =
      '<div id="<%= id %>"i class="unselectable">' +
      '<div id="<%= id %>_inputContainer"></div>' +
      '<div id="<%= id %>_currentOptions" class="blockFloatNot fnt-r-14 customFieldsOptionsList"></div>' +
      '</div>'),
    (_$el = $(_.template(_t, { id: _elId, title: titleText }))),
    (_el = _$el[0]),
    (_$currentOptions = $('#' + _elId + '_currentOptions', _$el)),
    (_currentOptions = _$currentOptions[0]),
    (_$inputContainer = $('#' + _elId + '_inputContainer', _$el)),
    (_inputContainer = _$inputContainer[0]),
    (_optionTemplate =
      '<div class="blockFloat tag fnt-r-14 unselectable" style="">' +
      '<div class="tagText fnt-r-14 blockFloat" style="" title="<%- value %>"><%- value %></div>' +
      '<div class="blockFloat removeIcon" style="" data-value="<%- value %>"></div>' +
      '</div>'),
    (_initialized = false),
    (_optionsList = null),
    (_newOptionInput = null),
    (_spacer = null),
    (_addButton = null);

  function initialize() {
    if (!_initialized) {
      _container.appendChild(_el);
      _newOptionInput = new TextInput(
        _inputContainer.id,
        _titleText,
        '',
        'blockFloat settingsPageEditInputContainerSmall',
        'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
        'blockFloatNot fnt-r-15 settingsPageTextInputSmall',
        null,
        null,
        null,
        onNewOptionAdded
      );

      var _spacer = document.createElement('DIV');
      _spacer.className = 'blockFloat settingsSpacer1';
      _inputContainer.appendChild(_spacer);

      _addButton = new primaryButton('settingsPrimaryButton');
      _addButton.setButtonText(I18n.t('lbl_add'));
      _addButton.setCallback(onNewOptionAdded);
      _inputContainer.appendChild(_addButton.container);
      _addButton.container.tabIndex = 0;

      _initialized = true;
    }
  }

  function isInCurrentOptions(option) {
    return (
      _optionsList &&
      _optionsList.some(function (o) {
        return stringComparerCaseInsensitive(o, option) == 0;
      })
    );
  }

  function addToCurrentOptions(option) {
    if (option && option.length && !isInCurrentOptions(option)) {
      _optionsList.push(option);
    }
  }

  function removeFromCurrentOptions(option) {
    _optionsList = _optionsList.filter(function (o) {
      return stringComparerCaseInsensitive(o, option) != 0;
    });
  }

  function onNewOptionAdded() {
    var proposedValue = singleSpace(trim(_newOptionInput.getValue()));
    addToCurrentOptions(proposedValue);
    _newOptionInput.setValue('');
    updateCurrentOptions();
  }

  function onRemoveButtonClick(e) {
    var value = $(e.target).attr('data-value');
    removeFromCurrentOptions(value);
    updateCurrentOptions();
  }

  function updateCurrentOptions() {
    var t;

    removeAllChildren(_currentOptions);

    if (_optionsList && _optionsList.length) {
      _optionsList.forEach(function (option) {
        $t = $(_.template(_optionTemplate, { value: option }));
        removeButton = $('.removeIcon', $t)[0];
        removeButton.addEventListener('click', onRemoveButtonClick);
        _currentOptions.appendChild($t[0]);
      });
    }
  }

  _i.show = function (container, options) {
    _container = container;
    _optionsList = options;
    initialize();
    updateCurrentOptions();
  };

  _i.hide = function () {
    _container.removeChild(_el);
  };

  _i.getOptions = function () {
    return _optionsList;
  };
}
