if (!COOKIE_DOMAIN) {
  COOKIE_DOMAIN = '';
}

var AppCookie = {
  DeperecatedAuthToken: 'auth_token',
  AuthToken: COOKIE_NAME,
  ProjectFilter: 'ProjectsFilter',
  ProjectsView: 'ProjectsView',
  ProjectsSort: 'ProjectsSort',
  ProjectView: 'ProjectView',
  SettingsFilter: 'SettingsFilter',
  ReportFilter: 'ReportFilter',
  ReportTitle: 'ReportTitle',
  GridTimeUnit: 'GridTimeUnit',
  GridFilterValue: 'GridFilterValue',
  GridFilterLabel: 'GridFilterLabel',
  CookieVersion: 'cookie_version',
  SettingsCookie: 'combined_settings',
  PersistCookies: 'PersistCookies',
  ImpMode: 'imp-mode',
  FederatedAutoLogin: 'rm_fed_al',
};

function areCookiesPersisted() {
  var persistValue = getCookie(AppCookie.CookiesPersisted);
  return persistValue == '1';
}

function getCookie(name) {
  if (name == AppCookie.AuthToken) {
    // catch callers
    throw 'session cookie not readable from script';
  }
  if (
    name == AppCookie.ProjectsView ||
    name == AppCookie.ProjectView ||
    name == AppCookie.SettingsFilter ||
    name == AppCookie.CookiesPersisted
  ) {
    var currentval = xCookie.get(AppCookie.SettingsCookie);
    if (!currentval) return '';
    var vals = currentval.split(';');
    if (vals.length != 8) {
      __A(false, 'bad settings cookie');
      return '';
    }
    // deprecated vals[0]; -- locale
    // deprecated vals[1]; -- menu_mask
    // deprecated vals[2]; -- AccountStatus
    if (name == AppCookie.ProjectsView) return vals[3];
    else if (name == AppCookie.SettingsFilter) return vals[4];
    // deprecated vals[5]; -- SubscriptionEndsDate
    else if (name == AppCookie.CookiesPersisted) return vals[6];
    else if (name == AppCookie.ProjectView) return vals[7];
  } else if (
    name == AppCookie.ReportTitle ||
    name == AppCookie.GridFilterValue ||
    name == AppCookie.GridFilterLabel ||
    name == AppCookie.BulkImportText
  ) {
    var val = xCookie.get(name);
    return val ? decodeURIComponent(val) : val;
  } else return xCookie.get(name);
}

function internalSetCookie(name, value, persist) {
  var domain = '',
    expiresInDays = PERSISTED_SESSION_TTL + 2;

  // We add 2 days to PERSISTED_SESSION_TTL to guarantee JS written cookies don't expire before the server set cookies

  if (name == AppCookie.AuthToken) {
    __A(false, 'session cookie not writable from script');
    return;
  }

  // TODO Do we need different expiry logic per cookie?
  if (
    name == AppCookie.ProjectsView ||
    name == AppCookie.ProjectView ||
    name == AppCookie.SettingsFilter ||
    name == AppCookie.CookiesPersisted
  ) {
    var currentval = xCookie.get(AppCookie.SettingsCookie);
    if (!currentval) currentval = ';;;;;;;';
    var vals = currentval.split(';');
    __A(vals.length == 8, 'bad settings cookie');
    // deprecated vals[0]; -- locale
    // deprecated vals[1]; -- menu_mask
    // deprecated vals[2]; -- AccountStatus
    if (name == AppCookie.ProjectsView) vals[3] = value;
    else if (name == AppCookie.SettingsFilter) vals[4] = value;
    // deprecated vals[5]; -- SubscriptionEndsDate
    else if (name == AppCookie.CookiesPersisted) vals[6] = value;
    else if (name == AppCookie.ProjectView) vals[7] = value;
    xCookie.set(
      AppCookie.SettingsCookie,
      vals[0] +
        ';' +
        vals[1] +
        ';' +
        vals[2] +
        ';' +
        vals[3] +
        ';' +
        vals[4] +
        ';' +
        vals[5] +
        ';' +
        vals[6] +
        ';' +
        vals[7],
      persist ? expiresInDays : null, // days
      '/',
      domain,
      false
    );
  } else if (
    name == AppCookie.ReportTitle ||
    name == AppCookie.UserDisplayName ||
    name == AppCookie.GridFilterValue ||
    name == AppCookie.GridFilterLabel ||
    name == AppCookie.BulkImportText
  ) {
    xCookie.set(
      name,
      encodeURIComponent(value),
      persist ? expiresInDays : null,
      '/',
      domain,
      false
    );
  } else {
    xCookie.set(
      name,
      value,
      persist ? expiresInDays : null,
      '/',
      domain,
      false
    );
  }
}

function setCookie(name, value) {
  internalSetCookie(name, value, areCookiesPersisted());
}

function setSessionCookie(name, value) {
  internalSetCookie(name, value, false /* do not persist */);
}

function setCookiesAfterSignIn(data, persist, impMode) {
  if (impMode) {
    // do not persist while in impMode
    persist = false;
    internalSetCookie(AppCookie.ImpMode, 'true');
  }

  internalSetCookie(
    AppCookie.ProjectFilter,
    '%5B%5B%22Active%20Projects%22%5D%2C%5B%5D%2C%5B%5D%5D',
    persist
  );
  internalSetCookie(
    AppCookie.ProjectsSort,
    '[{"label":"Project Name","sortAscending":true,"selected":true},{"label":"Client","sortAscending":true,"selected":false},{"label":"Project Type","sortAscending":true,"selected":false}]',
    persist
  );
  internalSetCookie(AppCookie.GridTimeUnit, 'week', persist);
  internalSetCookie(AppCookie.GridFilterValue, '', persist);
  internalSetCookie(AppCookie.GridFilterLabel, '', persist);
  internalSetCookie(AppCookie.CookieVersion, COOKIE_VERSION, persist);
  internalSetCookie(AppCookie.ProjectsView, 0, persist);
  internalSetCookie(AppCookie.SettingsFilter, 1, persist);
  internalSetCookie(AppCookie.ProjectView, 0, persist);
  internalSetCookie(AppCookie.CookiesPersisted, persist ? '1' : '', persist);

  // Remove cookies not used since moving to HttpOnly cookie
  xCookie.del(AppCookie.AuthToken, null, COOKIE_DOMAIN);

  // unused legacy auth cookie
  xCookie.del(AppCookie.DeperecatedAuthToken);
}

function deleteCookie(name) {
  xCookie.del(name);
}

function deleteAllCookies() {
  xCookie.del(AppCookie.SettingsCookie);
  xCookie.del(AppCookie.ProjectFilter);
  xCookie.del(AppCookie.ProjectsSort);
  xCookie.del(AppCookie.ReportFilter);
  xCookie.del(AppCookie.ReportTitle);
  xCookie.del(AppCookie.GridTimeUnit);
  xCookie.del(AppCookie.GridFilterValue);
  xCookie.del(AppCookie.GridFilterLabel);
  xCookie.del(AppCookie.CookieVersion);
  xCookie.del(AppCookie.ImpMode);

  // Cleanup legacy auth cookie.
  xCookie.del(AppCookie.AuthToken, null, COOKIE_DOMAIN);

  // Cleanup deprecated cookies
  xCookie.del('user_id');
  xCookie.del('user_type_id');
  xCookie.del('email');
  xCookie.del('user_display_name');
  xCookie.del('company_display_name');
  xCookie.del('company_guid');
}

function refreshAllCookies(onrefreshCallback) {
  gService.refreshSession(function (data) {
    setCookiesAfterSignIn(data);
    onrefreshCallback && onrefreshCallback();
  });
}
