function BillRateUser(attach, billrateuser, parent) {
  var _i = this;
  if (!(this instanceof BillRateUser)) {
    return new BillRateUser(attach, billrateuser, parent);
  }

  _i.attach = attach;
  _i.billrateuser = billrateuser;
  _i.parent = parent;

  _i.valueFromInput = function () {
    var val = _i.inputText.value;
    if (val == '') return 'empty';
    if (!val) return 0;
    val = convertCurrencyToNumber(val);
    val = parseFloat(val);

    return isNumber(val) ? val : 0;
  };

  _i.getValue = function () {
    var newrate = _i.valueFromInput(),
      data = {};

    data.id = _i.billrateuser.rate.id;
    if (_i.billrateuser.rate.starts_at) {
      data.starts_at = _i.billrateuser.rate.starts_at;
    }
    if (_i.billrateuser.rate.ends_at) {
      data.ends_at = _i.billrateuser.rate.ends_at;
    }
    data.role_id = null;
    data.discipline_id = null;
    data.assignable_id = _i.parent.projectId;
    data.user_id = _i.billrateuser.user.id;
    data.rate = newrate;
    return data;
  };

  _i.addToSaveArray = function (array, force) {
    var newrate = _i.valueFromInput();
    if (force || newrate != _i.billrateuser.rate.rate) {
      array.push(_i.getValue());
    }
  };

  _i.listMode = function () {
    removeAllChildren(_i.usercontainer);

    var name = document.createElement('LABEL'),
      nameHtml = '',
      isDateSpecific =
        billrateuser.rate.starts_at != null ||
        billrateuser.rate.ends_at != null;

    name.className = 'blockFloat fnt-r-14 settingsBillrateUserName';
    _i.usercontainer.appendChild(name);
    nameHtml = billrateuser.user.displayName;
    if (isDateSpecific) {
      nameHtml += ' ( ';

      if (billrateuser.rate.starts_at) {
        nameHtml +=
          'from ' +
          parseRubyDate(billrateuser.rate.starts_at).toLocaleDateString(window.I18n.locale, {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          });
      }
      if (billrateuser.rate.ends_at) {
        if (billrateuser.rate.starts_at) {
          nameHtml += ' to ';
        } else {
          nameHtml += ' up to ';
        }
        nameHtml += parseRubyDate(billrateuser.rate.ends_at).toLocaleDateString(window.I18n.locale, {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        });
      }

      nameHtml += ' )';
    }
    name.innerHTML = nameHtml;
    name.title = nameHtml;
    name.id = nameHtml.replace(/\s+/g, '-');

    _i.inputText = document.createElement('input');
    _i.inputText.setAttribute('type', 'text');
    _i.inputText.setAttribute('aria-labelledby', nameHtml.replace(/\s+/g, '-'));
    _i.inputText.className =
      'blockFloat fnt-r-14 settingsBillRateTextInputLarge';
    var option = {};
    option['precision'] = 2;
    _i.inputText.value = formatCurrency(_i.billrateuser.rate.rate, option);
    _i.usercontainer.appendChild(_i.inputText);
    var rate = _i.billrateuser.user.billrate;
    if (rate != -1) {
      _i.defaultrate = rate;
      if (rate != _i.billrateuser.rate.rate) {
        _i.inputText.style.color = '#CC2E3B';
      }
    } else {
      var license_type = _i.billrateuser.user.license_type;
      if (license_type === 'licensed' || license_type === 'managed_resource') {
        var discipline = _.find(gData.getTagsByNamespace('discipline'), {
          value: _i.billrateuser.user.discipline,
        });
        var role = _.find(gData.getTagsByNamespace('resource type'), {
          value: _i.billrateuser.user.role,
        });
        var disciplineId = _i.billrateuser.user.discipline
          ? discipline && discipline.id
          : null;
        var roleId = _i.billrateuser.user.role ? role && role.id : null;
        _i.defaultrate = gData.getDefaultBillRateForDisciplineRole(
          disciplineId,
          roleId
        );
        if (
          _i.defaultrate &&
          _i.defaultrate.rate != _i.billrateuser.rate.rate
        ) {
          _i.inputText.style.color = '#CC2E3B';
        }
      }
      if (_i.billrateuser.user.type === 'PlaceholderResource') {
        // Placeholderes use the v1 api, which doesn't include the discipline and role tags objects to pluck their ids.
        // So we have to loop through all disciplines and roles. Perf isn't great with a high number of each,
        // but this shouldn't be common.
        var discipline = _.find(gData.getTagsByNamespace('discipline'), {
          value: _i.billrateuser.user.discipline,
        });
        var disciplineId = discipline && discipline.id;
        var role = _.find(gData.getTagsByNamespace('resource type'), {
          value: _i.billrateuser.user.role,
        });
        var roleId = role && role.id;
        _i.defaultrate = gData.getDefaultBillRateForDisciplineRole(
          disciplineId,
          roleId
        );
        if (
          _i.defaultrate &&
          _i.defaultrate.rate != _i.billrateuser.rate.rate
        ) {
          _i.inputText.style.color = '#CC2E3B';
        }
      }
    }
  };

  _i.constructor = function () {
    _i.usercontainer = document.createElement('DIV');
    _i.usercontainer.className = 'blockFloatNot settingsBillRateUserContainer';
    _i.usercontainer.id = 'settingsUserContainer' + getGuid();
    _i.attach.appendChild(_i.usercontainer);
    _i.listMode();
  };
  _i.constructor();
}
