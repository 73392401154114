function whosOnTheBench(attachTo) {
  var _i = this;

  _i.attachTo = xGetElementById(attachTo);
  _i.cont;

  _i.constructor = function () {
    try {
      gData.getUsersOnTheBench(_i.render);
    } catch (e) {
      var errorMsg = document.createElement('div');
      errorMsg.className =
        'blockFloat fnt-r-12 organizationWhosOnTheBenchError';
      errorMsg.innerHTML =
        `${I18n.t('err_invalid_availability_data')} ` +
        window.SMARTSHEET_SUPPORT_URL;
      gPageHeader.setSpinner(false);
      _i.attachTo.appendChild(errorMsg);
    }
  };

  _i.render = function () {
    _i.cont = document.createElement('DIV');
    _i.cont.className = 'blockFloat onTheBenchContainer';

    _i.thisWeekHeader = document.createElement('H3');
    _i.thisWeekHeader.className =
      'blockFloatNot fnt-r-13 darkHeaderText onTheBenchGroupHeader';
    if (!window.benchResult) return;
    if (window.benchResult.restOfThisWeek.length > 0)
      _i.thisWeekHeader.innerHTML = I18n.t('lbl_this_week_remaining');
    else _i.thisWeekHeader.innerHTML = I18n.t('msg_unavailable_this_week');
    _i.cont.appendChild(_i.thisWeekHeader);

    _i.thisWeekThumbnailContainer = document.createElement('DIV');
    _i.thisWeekThumbnailContainer.className =
      'blockFloat onTheBenchGroupContainer';
    _i.cont.appendChild(_i.thisWeekThumbnailContainer);

    window.benchResult.restOfThisWeek.forEach(function (u) {
      var pt = new personThumbnail(
        u.userId,
        _i.thisWeekThumbnailContainer,
        false,
        'Analytics Page'
      );
      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloat personThumbnailContainerSpacer';
      _i.thisWeekThumbnailContainer.appendChild(spacer);
    });

    _i.nextWeekHeader = document.createElement('H3');
    _i.nextWeekHeader.className =
      'blockFloatNot fnt-r-13 darkHeaderText onTheBenchGroupHeader';
    if (window.benchResult.nextWeek.length > 0)
      _i.nextWeekHeader.innerHTML = I18n.t('lbl_next_week');
    else _i.nextWeekHeader.innerHTML = I18n.t('msg_unavailable_next_week');

    _i.cont.appendChild(_i.nextWeekHeader);

    _i.nextWeekThumbnailContainer = document.createElement('DIV');
    _i.nextWeekThumbnailContainer.className =
      'blockFloat onTheBenchGroupContainer';
    _i.cont.appendChild(_i.nextWeekThumbnailContainer);

    window.benchResult.nextWeek.forEach(function (u) {
      var pt = new personThumbnail(
        u.userId,
        _i.nextWeekThumbnailContainer,
        false,
        'Analytics Page'
      );
      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloat personThumbnailContainerSpacer';
      _i.nextWeekThumbnailContainer.appendChild(spacer);
    });

    _i.attachTo.appendChild(_i.cont);
  };

  _i.constructor();
}
