function AWSCropprImageUpload(
  attach,
  securitymethod,
  resourceid,
  acl,
  imgContainer,
  width,
  height,
  initFrom
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.securitymethod = securitymethod;
  _i.resourceid = resourceid;
  _i.aclval = acl;
  _i.imgContainer = imgContainer;
  _i.width = width;
  _i.height = height;
  _i.hasThumbnailChanged = false;
  _i.initializedFrom = initFrom;
  _i.userCropDim = 166;
  _i.projectCropW = 214;
  _i.projectCropH = 120;
  _i.orgCropW = 250;
  _i.orgCropH = 40;
  _i.contentType =
    _i.initializedFrom === 'branding' ? 'image/png' : 'image/jpeg';
  _i.fileExtension = _i.initializedFrom === 'branding' ? 'png' : 'jpg';

  _i.setResourceId = function (rid) {
    _i.resourceid = rid;
  };

  _i.setThumb = function (imgContainer) {
    _i.imgContainer = imgContainer;
  };

  _i.chooseFile = function () {
    document.getElementById('file-input').click();
  };

  _i.initCroppr = function (origImage, img, width, height) {
    img.src = origImage.src;
    img.style.height = 'inherit';
    img.style.width = 'inherit';

    if (img.src !== '') {
      img.id = 'croppr';
      _i.croppr = new Croppr('#croppr', {
        aspectRatio: height / width,
        startSize: [img.width - 5, img.height - 5, 'px'],
      });
    }
  };

  _i.getUrl = function () {
    if (_i.saveurl) {
      return _i.saveurl;
    }
    return null;
  };

  _i.processAndSendImage = function (
    height,
    width,
    image,
    callback,
    failureCallback
  ) {
    performance.mark('processImg Start');
    _i.callback = callback;
    _i.uploadFailureCallback = failureCallback;
    // Process img crop
    var img = image;
    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    var croppedImage = new Image();
    canvas.width = width;
    canvas.height = height;
    croppedImage.src = img.src;
    if (_i.initializedFrom === 'branding') {
      ctx.drawImage(
        croppedImage,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );
    } else {
      var cropData = _i.croppr.getValue();
      ctx.drawImage(
        croppedImage,
        cropData.x,
        cropData.y,
        cropData.width,
        cropData.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
    }

    canvas.toBlob(
      function (croppedBlob) {
        _i.blob = croppedBlob;
        performance.mark('processImg End');
        performance.measure('processImg', 'processImg Start', 'processImg End');
        var processTime = performance.getEntriesByName('processImg', 'measure');
        trackGAUserTiming(
          'Image Processing',
          'process',
          processTime[0].duration
        );
        // Get credentials and then send to S3
        _i.securitymethod(
          _i.fileExtension,
          _i.resourceid,
          _i.setFormCredsAndSendToS3Sigv4
        );
      },
      _i.contentType,
      0.9
    );
  };

  _i.setFormCredsAndSendToS3Sigv4 = function (signature) {
    var credentials = signature.presigned_post_url_fields;
    var formData = new FormData();

    _i.saveurl = signature.presigned_post_url + '/' + credentials.key;
    _i.path = signature.presigned_post_url;

    // Iterate presigned url fields and append to formdata
    _.forEach(credentials, function (value, key) {
      formData.append(key, value);
    });

    formData.append('cache-control', 'max-age=31536000');
    formData.append('file', _i.blob);
    formData.append('Content-Type', _i.contentType);

    var oReq = new XMLHttpRequest();
    oReq.open('POST', signature.presigned_post_url, true);
    oReq.onload = function () {
      if (oReq.status == 200) {
        _i.callback();
      } else {
        _i.uploadFailureCallback();
      }
    };
    oReq.send(formData);
  };

  // NOTE: `thumbnailchange` styles the img to fit in the thumbnail comtainer and inits a croppr instance
  _i.thumbnailchange = function (image) {
    var aspectRatioW = image.width / image.height;
    var aspectRatioH = image.height / image.width;
    var containerW;
    var containerH;
    var fitHeight;
    var fitWidth;

    if (_i.initializedFrom === 'branding') {
      _i.brandingThumbnailChange(image, aspectRatioW, aspectRatioH);
    } else {
      if (_i.initializedFrom === 'project') {
        containerW = _i.projectCropW;
        containerH = _i.projectCropH;
      } else {
        containerW = _i.userCropDim;
        containerH = _i.userCropDim;
      }

      // Set centering on container
      _i.imgContainer.thumbnailContainer.style.display = 'flex';
      _i.imgContainer.thumbnailContainer.style.alignItems = 'center';

      // Fit image to container with correct aspect ratio
      if (image.width > image.height) {
        fitHeight = containerW * aspectRatioH;
        _i.imgContainer.thumbnailCropprContainer.style.height =
          fitHeight + 'px';
        _i.imgContainer.thumbnailCropprContainer.style.width =
          containerW + 'px';
      } else {
        fitWidth = containerH * aspectRatioW;
        _i.imgContainer.thumbnailCropprContainer.style.height =
          containerH + 'px';
        _i.imgContainer.thumbnailCropprContainer.style.width = fitWidth + 'px';
      }

      _i.initCroppr(image, _i.imgContainer.thumbnail, _i.width, _i.height);
    }

    _i.hasThumbnailChanged = true;
  };

  _i.brandingThumbnailChange = function (image, aspectRatioW, aspectRatioH) {
    var containerW;
    var containerH;
    var fitHeight;
    var fitWidth;

    containerW = _i.orgCropW;
    containerH = _i.orgCropH;

    _i.imgContainer.paddingDiv.removeChild(
      _i.imgContainer.thumbnailCropprContainer
    );
    _i.imgContainer.thumbnailCropprContainer = document.createElement('DIV');

    if (image.src.localeCompare(DEFAULT_LOGO_URL) !== 0) {
      _i.imgContainer.thumbnailCropprContainer.className =
        'plansHeaderLogoCustom';
      _i.imgContainer.thumbnailCropprContainer.style.width = 'auto';
      _i.imgContainer.thumbnailCropprContainer.style.display = 'inline-block';
      _i.imgContainer.thumbnail = document.createElement('IMG');
      _i.imgContainer.thumbnail.className = 'headerLogoCustomImage';

      // Fit image to container with correct aspect ratio
      if (image.height > containerH) {
        fitWidth = containerH * aspectRatioW;
        _i.imgContainer.thumbnailCropprContainer.style.height =
          containerH + 'px';
        _i.imgContainer.thumbnailCropprContainer.style.width = fitWidth + 'px';
      } else if (image.width > containerW) {
        fitHeight = containerW * aspectRatioH;
        _i.imgContainer.thumbnailCropprContainer.style.height =
          fitHeight + 'px';
        _i.imgContainer.thumbnailCropprContainer.style.width =
          containerW + 'px';
      }
      _i.imgContainer.thumbnail.src = image.src;
    } else {
      _i.imgContainer.thumbnailCropprContainer.className = 'plansHeaderLogo';
      _i.imgContainer.thumbnail = document.createElement('IMG');
      _i.imgContainer.thumbnail.className = 'headerLogoCustomImage';
      _i.imgContainer.thumbnail.src = image.src;
    }

    _i.imgContainer.thumbnailCropprContainer.appendChild(
      _i.imgContainer.thumbnail
    );
    _i.imgContainer.paddingDiv.appendChild(
      _i.imgContainer.thumbnailCropprContainer
    );

    _i.hasThumbnailChanged = true;
  };

  // NOTE: `previewFile` loads the image element from the users computer and calls `_i.thumbnailchange`
  _i.previewFile = function () {
    const file = document.querySelector('input[type=file]').files[0];
    if (file) {
      if (_i.croppr) {
        _i.croppr.destroy();
      }

      const reader = new FileReader();

      reader.onloadend = function () {
        var image = new Image();
        image.src = reader.result;

        image.onload = function () {
          _i.thumbnailchange(image);
        };
        image.onerror = function () {
          new notificationPopup(
            I18n.t('msg_image_failed_to_load'),
            I18n.t('msg_file_was_not_image_file'),
            8000
          );
        };
      };

      reader.readAsDataURL(file);
    }
  };

  _i.constructor = function () {
    // Set file input buttons
    _i.editThumbButton = new secondaryButton('awsChooseThumbButton');
    _i.editThumbButton.id = 'select-image-button-label';

    _i.editThumbButton.setButtonText(I18n.t('lbl_choose_file'));
    _i.editThumbButton.setCallback(_i.chooseFile);
    _i.attachTo.appendChild(_i.editThumbButton.container);
    _i.editThumbButton.container.setAttribute('role', 'button');
    _i.editThumbButton.container.setAttribute(
      'aria-describedby',
      'thumbnailSelectionText'
    );
    _i.editThumbButton.container.tabIndex = 0;

    _i.file = document.createElement('INPUT');
    _i.file.type = 'file';
    _i.file.name = 'file';
    _i.file.id = 'file-input';
    _i.file.accept = 'image/*';
    _i.file.onchange = _i.previewFile;
    _i.file.style.visibility = 'hidden';
    _i.attachTo.appendChild(_i.file);
  };
  _i.constructor();
}
