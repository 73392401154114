function ProjectThumbnailViewLarge(attach, obj) {
  var _i = this;
  _i.attachTo = xGetElementById(attach);
  _i.project = obj;

  _i.randomProjSprite = function () {
    var ret = {};
    ret.backgroundImage = SPRITE_FILE_NAME;
    ret.xOffset = compSpritesButtonsIcons.defaultProject.xOffset;
    ret.yOffset = compSpritesButtonsIcons.defaultProject.yOffset;

    var widthrange =
      compSpritesButtonsIcons.defaultProject.width -
      compSpritesButtonsIcons.tempProjectLarge.width;
    ret.xOffset -= Math.floor(Math.random() * widthrange);
    ret.width = compSpritesButtonsIcons.tempProjectLarge.width;

    var heightrange =
      compSpritesButtonsIcons.defaultProject.height -
      compSpritesButtonsIcons.tempProjectLarge.height;
    ret.yOffset -= Math.floor(Math.random() * heightrange);
    ret.height = compSpritesButtonsIcons.tempProjectLarge.height;
    return ret;
  };

  _i.getStatusIcon = function (proj) {
    var retVal = null;

    if (!(proj && proj.project_state)) {
      return retVal;
    }

    switch (proj.project_state) {
      case 'Internal':
        retVal = getSprite(compSpritesButtonsIcons['internalMark']);
        break;
      case 'Tentative':
        retVal = getSprite(compSpritesButtonsIcons['tentativeMark']);
        break;
      case 'Confirmed':
        retVal = getSprite(compSpritesButtonsIcons['confirmedMark']);
        break;
    }

    if (retVal) {
      retVal.style.marginLeft = '10px';
      retVal.style.marginTop = '2px';
      retVal.style.marginRight = '-3px';
    }

    return retVal;
  };

  _i.constructor = function () {
    _i.projectThumbnailView = document.createElement('DIV');
    _i.projectThumbnailView.className = 'projectThumbnailFrameContainerLarge';
    getSprite(
      compSpritesButtonsIcons.projectThumbFrameL,
      _i.projectThumbnailView
    );

    _i.attachTo.appendChild(_i.projectThumbnailView);

    _i.thumbnailContainer = document.createElement('DIV');
    _i.thumbnailContainer.className =
      'projectThumbnailFrameImageContainerLarge';
    _i.projectThumbnailView.appendChild(_i.thumbnailContainer);

    _i.thumbnailCropprContainer = document.createElement('DIV');
    _i.thumbnailCropprContainer.style.height = '100%';
    _i.thumbnailCropprContainer.style.width = '100%';
    _i.thumbnailCropprContainer.style.background =
      'radial-gradient(107.00px at 50% 50%, #ffffff 0%, #F2F2F2 100%)';
    _i.thumbnailContainer.appendChild(_i.thumbnailCropprContainer);

    _i.thumbnail = document.createElement('IMG');
    _i.thumbnail.className = 'projectThumbnailFrameImageLarge';
    _i.thumbnail.setAttribute('alt', '');
    _i.thumbnail.style.height = 'auto';
    _i.thumbnail.style.width = '100%';

    if (_i.project && _i.project.thumbnail && _i.project.thumbnail != '') {
      _i.thumbnail.src = _i.project.thumbnail;
    } else {
      _i.thumbnail.src = '';
    }

    _i.thumbnailCropprContainer.appendChild(_i.thumbnail);

    if (_i.project) {
      _i.statusIcon = _i.getStatusIcon(_i.project);

      _i.projectTagView = new TagView(
        _i.projectThumbnailView,
        _i.project.client ? _i.project.client : '',
        _i.project.name ? _i.project.name : '',
        'projectThumbTagContainerLarge',
        'fnt-r-15 projectThumbTagNameLarge',
        'fnt-b-15 projectThumbTagNameLarge',
        null,
        _i.statusIcon
      );
    }
  };
  _i.constructor();
}
