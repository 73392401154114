function SettingsDeveloperApi(attachTo) {
  if (!gData.accountSettings.moduleEnabled('api')) {
    return new SettingsDeveloperApiUpsell({
      attachTo: attachTo,
    });
  }

  var _i = this;
  if (!(this instanceof SettingsDeveloperApi)) {
    return new SettingsDeveloperApi(attachTo);
  }

  _i.attachTo = xGetElementById(attachTo);
  _i.tokenLabel = null;
  _i.expectedcallback = 0;

  _i.settingsSaved = function (data) {
    _i.expectedcallback--;
    if (_i.expectedcallback <= 0) {
      notificationPopup(
        I18n.t('lbl_account_settings'),
        I18n.t('lbl_settings_saved_successfully'),
        4000
      );
    }
  };

  _i.saveSettings = function () {
    var newDeveloperContactEmail = _i.developerContactEmail.getValue();
    if (
      gData.accountSettings.developer_contact_email != newDeveloperContactEmail
    ) {
      if (!verifyEmail(_i.developerContactEmail.getValue())) {
        _i.developerContactEmail.setAlert(I18n.t('err_valid_email'));
        return false;
      }
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'developer_contact_email',
        newDeveloperContactEmail,
        _i.settingsSaved
      );
    }
  };

  _i.constructor = function () {
    var label;

    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);

    label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    label.innerHTML = I18n.t('msg_developer_api_html');

    _i.container.appendChild(label);

    label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-12 settingsIncrementLabel';
    label.innerHTML = I18n.t('msg_api_token_description');
    _i.container.appendChild(label);

    _i.tokenLabel = document.createElement('TEXTAREA');
    _i.tokenLabel.readOnly = true;
    _i.tokenLabel.className = 'blockFloatNot apitoken';
    _i.container.appendChild(_i.tokenLabel);

    xAddEventListener(
      _i.tokenLabel,
      platform.click,
      _i.tokenLabel.select,
      true
    );

    gService.getApiTokens(function (data) {
      if (window.accountSettings.moduleEnabled('api')) {
        if (window.accountSettings.moduleEnabled('api_token_management')) {
          if (
            window.accountSettings.moduleEnabled('hide_api_tokens') &&
            data.status === 403
          ) {
            _i.tokenLabel.innerText = I18n.t('msg_contact_account_owner');
          } else {
            primaryToken = data.find((token) => token.title === 'primary');
            _i.tokenLabel.innerText = primaryToken?.token || data[0].token;
          }
        } else {
          _i.tokenLabel.innerText = data[0].value;
        }
      }
    });

    var currentDeveloperContactEmail = gData.accountSettings
      .developer_contact_email
      ? gData.accountSettings.developer_contact_email
      : '';
    _i.developerContactEmail = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_developer_email_optional'),
      currentDeveloperContactEmail,
      'blockFloatNot settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall'
    );

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage1';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    _i.container.appendChild(_i.nestedDiv);

    _i.saveButton = new primaryButton('settingsSecondaryButtonRight');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveSettings);
    _i.nestedDiv.appendChild(_i.saveButton.container);
  };

  _i.constructor();
}
