function ellipsize(txt, numChars) {
  if (txt.length <= numChars) {
    return txt;
  }

  txt = txt.substr(0, numChars) + '&hellip;';

  return txt;
}

function setRotation(ele, deg) {
  var rotationString = 'rotate(' + deg + 'deg)';

  for (var z = 0; z < transformPropertyNames.length; z++) {
    ele.style[transformPropertyNames[z]] = rotationString;
  }
}

function setFloat(ele, clsName) {
  addClass(ele, clsName);
}

function setMargin(ele, t, r, b, l) {
  ele.style.marginTop = t + 'px';
  ele.style.marginRight = r + 'px';
  ele.style.marginBottom = b + 'px';
  ele.style.marginLeft = l + 'px';
}

function hasClass(ele, cls) {
  patt = new RegExp('\\b' + cls + '\\b', 'g');
  return ele.className.match(patt);
}

function removeClass(ele, cls) {
  patt = new RegExp('\\b' + cls + '\\b', 'g');
  ele.className = trim(ele.className.replace(patt, ''));
}

function addClass(ele, cls) {
  removeClass(ele, cls);
  ele.className += ' ' + cls;
}

// Escape a user-input string for use in regexp
regExpEscape = function (s) {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};

function animateOpacity(ele, callback) {
  var _i = this;

  // params
  _i.ele = ele;
  _i.callback = callback;

  // locals
  _i.ani = null;
  _i.isRunning = null;

  // const
  _i.incAmt = 0.0044;

  _i.setAnimationTarget = function (targetOpacity) {
    _i.targetOpacity = targetOpacity;

    if (_i.ani) {
      return;
    }

    if (xOpacity(_i.ele) != _i.targetOpacity) {
      _i.ele.style.display = 'block';
      _i.ani = Awe.addAnimationCallback(_i.opacityFrame);
    }
  };

  _i.sign = function (num) {
    return num == Math.abs(num) ? 1 : -1;
  };

  _i.opacityFrame = function (delta, elapsed) {
    if (_i.targetOpacity == xOpacity(_i.ele)) {
      if (xOpacity(_i.ele) == 0) {
        _i.ele.style.display = 'none';
      }
      _i.ani = null;
      if (_i.callback) {
        _i.callback();
      }
      return true;
    }

    var cur = xOpacity(_i.ele);
    var sign = _i.sign(_i.targetOpacity - cur);
    var newVal = Awe.clamp(sign * _i.incAmt * delta + cur, 0, 1);

    xOpacity(_i.ele, newVal);

    return false;
  };

  _i.constructor = function () {};

  _i.constructor();
}
