function utilizationBarVM(
  startTime,
  endTime,
  utilization,
  isOverAllocated,
  percentOverAllocated
) {
  var _i = this;
  _i.startTime = startTime;
  // timeline endDate is exclusive
  _i.endTime = new Date(endTime).addDays(-1).getTime();
  _i.utilization = utilization;
  _i.isOverAllocated = isOverAllocated;
  _i.percentOverAllocated = percentOverAllocated;

  _i.extend = function (newEndTime) {
    // timeline endDate is exclusive
    _i.endTime = new Date(newEndTime).addDays(-1).getTime();
  };
}
