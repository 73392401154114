function SettingsStatusOptions(attachTo, namespacevalue, label) {
  'use strict';
  var _i = this;
  if (!(this instanceof SettingsStatusOptions)) {
    return new SettingsStatusOptions(attachTo, namespacevalue, label);
  }

  // Constant color map.
  var ColorMap = {
    gray: { rgb: '#E1E1E1' },
    blue_bright: { rgb: '#92D4F4' },
    blue_dark: { rgb: '#24A2EB' },
    green_dark: { rgb: '#007C3F' },
    green_bright: { rgb: '#00BF64' },
    yellow: { rgb: '#FAC905' },
    orange: { rgb: '#FF8533' },
    red: { rgb: '#E81A56' },
    purple: { rgb: '#C285FF' },
  };

  var defaultColor = 'gray';

  _i.getColorMap = _.constant(ColorMap);

  var stageToLabelMap = {
    planned: I18n.t('lbl_planned'),
    in_progress: I18n.t('lbl_in_progress'),
    done: I18n.t('lbl_complete'),
  };

  var arbitraryOrderIncrement = 10;

  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.namespaceval = namespacevalue;
  _i.labelval = label;
  _i.statusOptionControllers = [];
  _i.alertIsShowing = false;

  _i.statusOptionCreated = function () {
    _i.buildOptionsArray();
    _i.refreshList();
    _i.textInputControl.inputText.value = '';
  };

  _i.isDuplicate = function (color, label, stage) {
    return _i.statusOptions.some(function (statusOption) {
      return (
        statusOption.color === color &&
        statusOption.label === label &&
        statusOption.stage === stage
      );
    });
  };

  _i.editModeCallback = function () {
    _i.statusOptionControllers.forEach(function (c) {
      c.cancelEdit();
    });
  };

  _i.addStatusOption = function () {
    var proposedColor = _i.selectedColor || defaultColor;
    var proposedLabel = singleSpace(trim(_i.textInputControl.getValue()));
    var proposedStage = _i.selectedStage || 'planned';

    if (!proposedLabel) {
      _i.textInputControl.setAlert(I18n.t('err_valid_value'));
      _i.textInputControl.inputText.value = '';
      _i.alertIsShowing = true;
      return false;
    }

    if (_i.isDuplicate(proposedColor, proposedLabel, proposedStage)) {
      _i.textInputControl.setAlert(I18n.t('msg_duplicate_value'));
      _i.textInputControl.inputText.value = '';
      _i.alertIsShowing = true;
      return false;
    }

    var maxOrder = _i.statusOptions.reduce(function (maxOrder, statusOption) {
      return statusOption.order > maxOrder ? statusOption.order : maxOrder;
    }, 0);

    var statusOptionData = {};
    statusOptionData.label = proposedLabel;
    statusOptionData.stage = proposedStage;
    statusOptionData.color = proposedColor;
    statusOptionData.order = maxOrder + arbitraryOrderIncrement;
    gService.createStatusOption(statusOptionData, _i.statusOptionCreated);
  };

  _i.refreshList = function () {
    removeAllChildren(_i.listContainer);
    _i.buildOptionsArray();
    _i.renderOptionsList();
  };

  _i.spacerLineObject = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.listContainer.appendChild(spacer);
  };

  _i.addStageHeader = function (stage) {
    var label = stageToLabelMap[stage];
    var header = $(
      '<h3 class="blockFloatNot settingsProgressLabel fnt-b-14">' +
        label +
        '</h3>'
    )[0];
    _i.listContainer.appendChild(header);
    _i.spacerLineObject();
  };

  _i.renderOptionsList = function () {
    _i.statusOptionControllers = [];
    var currentStage;
    var stageQueue = ['planned', 'in_progress', 'done'];
    _i.statusOptions.forEach(function (statusOption) {
      // Assuming _i.statusOptions is sorted first by stage, then by order.
      while (stageQueue.length && currentStage !== statusOption.stage) {
        currentStage = stageQueue.shift();
        _i.addStageHeader(currentStage);
      }
      if (!ColorMap[statusOption.color]) statusOption.color = defaultColor;
      var soc = SettingsStatusOption(_i.listContainer, statusOption, _i);
      if (_.isNumber(statusOption.tempOrder)) soc.containerClick();
      _i.statusOptionControllers.push(soc);
      _i.spacerLineObject();
    });
    // stageQueue will usually be empty at this point, but not always.
    stageQueue.forEach(_i.addStageHeader);
  };

  _i.buildOptionsArray = function () {
    _i.statusOptions = _.filter(
      gData.getStatusOptions(),
      function (statusOption) {
        return statusOption.deleted_at == null;
      }
    );
    if (_i.statusOptions) {
      var stageMap = { planned: 0, in_progress: 1, done: 2 };
      _i.statusOptions.sort(function (a, b) {
        var sa = stageMap[a.stage];
        var sb = stageMap[b.stage];
        if (sa === sb) {
          sa = _.isNumber(a.tempOrder) ? a.tempOrder : a.order;
          sb = _.isNumber(b.tempOrder) ? b.tempOrder : b.order;
          if (sa === sb) return 0;
        }
        return sa > sb ? 1 : -1;
      });
    }
  };

  _i.onChange = function () {
    if (_i.alertIsShowing) {
      _i.textInputControl.hideAlert();
      _i.alertIsShowing = false;
    }
  };

  _i.onColorChanged = function (newColor) {
    _i.selectedColor = newColor;
    _i.colorInput.hide();
  };

  _i.onStageChange = function (stages) {
    _i.selectedStage = stages[0].stage;
    _i.stage.setValue(
      ['planned', 'in_progress', 'done'].indexOf(_i.selectedStage)
    );
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);
    _i.label = document.createElement('DIV');

    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.label.innerHTML = '';
    _i.container.appendChild(_i.label);

    var colorInputOptions = {
      removeBorder: true,
      containerCSS: { marginTop: '4px' },
      viewport: 'mainCon',
    };
    _i.colorInput = new ColorPicker(
      42,
      _.keys(ColorMap).length * 30 + 10,
      defaultColor,
      ColorMap,
      { colorSelectedCallback: _i.onColorChanged },
      colorInputOptions
    );
    _i.colorInput.setColor(defaultColor);
    _i.colorInput.show(_i.container);

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.textInputControl = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_name'),
      '',
      'blockFloat settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall',
      140,
      null,
      null,
      _i.addStatusOption,
      _i.onChange
    );

    var stageData = ['planned', 'in_progress', 'done'].map(function (stage) {
      return { stage: stage, label: stageToLabelMap[stage], type: 'item' };
    });

    _i.stagePopup = new selectionPopup(
      200,
      31 * stageData.length,
      'mainCon',
      stageData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onStageChange,
      false,
      null /*blurCallback*/,
      'nw',
      null /*prefferedDirections*/,
      true /*noautorotate*/
    );
    _i.stage = new PopUpInput(
      _i.container.id,
      'Stage',
      0,
      'blockFloat settingsPageEditInputContainerSmallerCategory',
      'blockFloatNot fnt-r-14 settingsInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsTextInputSmall',
      stageData,
      _i.stagePopup
    );
    _i.stage.inputContainer.style.marginTop = '14px';

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.addButton = new primaryButton('settingsPrimaryButton');
    _i.addButton.setButtonText(I18n.t('lbl_add'));
    _i.addButton.setCallback(_i.addStatusOption);
    _i.container.appendChild(_i.addButton.container);
    _i.addButton.container.tabIndex = 0;

    _i.listContainer = document.createElement('DIV');
    _i.listContainer.classname = 'blockFloatNot';
    _i.container.appendChild(_i.listContainer);

    _i.buildOptionsArray();
    _i.renderOptionsList();
  };

  _i.constructor();
}
