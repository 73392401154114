function SettingsBulkApprove(attachTo) {
  var _i = this;
  if (!(this instanceof SettingsBulkApprove)) {
    return new SettingsBulkApprove(attachTo);
  }

  var ACCOUNT_SETTINGS_MENU_INDEX = 2;

  var lineBreak = function (css) {
    return $('<div></div>').css(
      _.extend(
        {
          width: '100%',
          float: 'left',
        },
        css
      )
    )[0];
  };

  _i.getApprovablesText = function () {
    return (
      (_i.timeEntriesCheckboxChecked
        ? `${I18n.t('lbl_time_entries_lc')} `
        : '') +
      (_i.timeEntriesCheckboxChecked && _i.expensesCheckboxChecked
        ? `${I18n.t('lbl_and_lc')} `
        : '') +
      (_i.expensesCheckboxChecked ? `${I18n.t('lbl_expenses_lc')} ` : '')
    );
  };

  var infoSectionID = 'bulkApproveInfoSection';
  var template = _.template(
    '<div class="blockFloatNot settingsSpacer3"></div>' +
      '<div id="' +
      infoSectionID +
      '">' +
      '<div class="fnt-r-15 blockFloatNot"><%= instructions %></div>' +
      '<div class="fnt-b-14 blockFloatNot" style="margin-top: 15px"><%= checkboxSectionHeaderText %></div>' +
      '</div>'
  );

  _i.container = document.createElement('div');

  let lockTimesheetsUponApprovalSettingEnabled =
    gData.accountSettings.lock_entries_upon_approval === '1';

  const instructions = lockTimesheetsUponApprovalSettingEnabled
    ? I18n.t('msg_bulk_approve_and_lock_description')
    : I18n.t('msg_bulk_approve_description');

  const checkboxSectionHeaderText = lockTimesheetsUponApprovalSettingEnabled
    ? I18n.t('lbl_approve_and_lock_all')
    : I18n.t('lbl_approve_all');

  _i.container.innerHTML = template({
    instructions,
    checkboxSectionHeaderText,
  });

  attachTo || (attachTo = 'pageMainInfoContentContainer');
  _i.attachTo = xGetElementById(attachTo);
  _i.attachTo.appendChild(_i.container);

  _i.infoSection = xGetElementById(infoSectionID);

  if (gData.accountSettings.time_entry_approvals == '1') {
    var labelSpan = $(`<span>${I18n.t('lbl_time_entries_lc')}</span>`)[0];
    _i.timeEntriesCheckboxChecked = true;
    _i.timeEntriesCheckbox = new SelectionCheckbox(
      _i.infoSection,
      labelSpan,
      _i.timeEntriesCheckboxChecked,
      'blockFloatNot',
      'fnt-r-14 settingsPrefTag',
      'fnt-r-14 settingsPrefCheckbox'
    );
    _i.timeEntriesCheckbox.setCallback(function (a) {
      _i.timeEntriesCheckboxChecked = !_i.timeEntriesCheckboxChecked;
      if (!_i.timeEntriesCheckboxChecked && !_i.expensesCheckboxChecked) {
        _i.expensesCheckboxChecked = true;
        _i.expensesCheckbox.setEnabled(true);
      }
      _i.timeEntriesCheckbox.setEnabled(_i.timeEntriesCheckboxChecked);
    });
  }
  if (gData.accountSettings.expense_approvals == '1') {
    var labelSpan = $(`<span>${I18n.t('lbl_expenses_lc')}</span>`)[0];
    _i.expensesCheckboxChecked = true;
    _i.expensesCheckbox = new SelectionCheckbox(
      _i.infoSection,
      labelSpan,
      _i.expensesCheckboxChecked,
      'blockFloatNot',
      'fnt-r-14 settingsPrefTag',
      'fnt-r-14 settingsPrefCheckbox'
    );
    _i.expensesCheckbox.setCallback(function () {
      _i.expensesCheckboxChecked = !_i.expensesCheckboxChecked;
      if (!_i.expensesCheckboxChecked && !_i.timeEntriesCheckboxChecked) {
        _i.timeEntriesCheckboxChecked = true;
        _i.timeEntriesCheckbox.setEnabled(true);
      }
      _i.expensesCheckbox.setEnabled(_i.expensesCheckboxChecked);
    });
  }

  _i.datePickerInstructions = $(
    '<div class="fnt-b-14 blockFloatNot" style="margin-top: 15px;">' +
      I18n.t('lbl_bulk_approve_date_instructions') +
      '</div>'
  )[0];
  _i.infoSection.appendChild(_i.datePickerInstructions);

  _i.selectDate = function (date) {
    _i.dateSelected = date;
    _i.dateInput.labelSpan.innerText = date.toDateFormattedString();
  };
  _i.datePicker = new calendarPopup(
    230,
    215,
    'mainCon',
    new Date(),
    compSpritesButtonsIcons.wideDialogSpecsGray,
    _i.selectDate,
    false,
    false,
    'wc',
    true,
    ['wc', 'ec'],
    _i.container
  );

  var dateInputContainerId = 'dateInputContainer';
  var dateInputContainer = $(
    '<div id="' + dateInputContainerId + '"></div>'
  )[0];
  _i.infoSection.appendChild(lineBreak());
  _i.infoSection.appendChild(dateInputContainer);
  _i.dateInput = new PopUpInput(
    dateInputContainerId,
    I18n.t('lbl_select_a_date'),
    null,
    'blockFloat settingsPageEditInputContainerSmall',
    'blockFloatNot fnt-r-14 settingsInputContainer',
    'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
    'blockFloatNot fnt-r-15 settingsTextInput',
    null,
    _i.datePicker
  );
  _i.selectDate(new Date());

  _i.bulkApproveButton = new primaryButton(
    'settingsPrimaryButton settingsSecondaryButton blockFloatRight'
  );
  _i.bulkApproveButton.setButtonText(
    lockTimesheetsUponApprovalSettingEnabled
      ? I18n.t('lbl_approve_and_lock')
      : I18n.t('lbl_bulk_approve')
  );
  _i.bulkApproveButton.setCallback(function () {
    var callback = function () {
      var type = null;
      if (_i.timeEntriesCheckboxChecked && !_i.expensesCheckboxChecked) {
        type = 'TimeEntry';
      } else if (_i.expensesCheckboxChecked && !_i.timeEntriesCheckboxChecked) {
        type = 'ExpenseItem';
      }
      gService.bulkApprove(
        new Date(2012, 0, 1),
        _i.dateSelected,
        type,
        function (res) {
          _i.infoSection.innerHTML = _.template(
            '<div class="fnt-b-14 blockFloatNot" style="margin: 7px 0 10px 0;"><%= headerText %></div>' +
              '<div class="fnt-r-15 blockFloatNot"><%- message %></div>',
            {
              headerText: I18n.t('lbl_bulk_approval_progress'),
              message: I18n.t('msg_bulk_approval_progress'),
            }
          );
          _i.cancelButton.setButtonText(I18n.t('lbl_return_account_settings'));
          _i.container.removeChild(_i.bulkApproveButton.container);
          if (_i.note) {
            _i.container.removeChild(_i.note);
          }
        }
      );
    };
    var cp = new confirmationPopup(
      I18n.t(
        lockTimesheetsUponApprovalSettingEnabled
          ? 'lbl_bulk_approve_and_lock_confirm'
          : 'lbl_bulk_approve_confirm',
        {
          approvables: _i.getApprovablesText(),
          date: _i.dateSelected.toDateFormattedString(),
        }
      ),
      I18n.t(
        lockTimesheetsUponApprovalSettingEnabled
          ? 'lbl_approve_and_lock'
          : 'lbl_approve_all'
      ),
      callback,
      null,
      _i.container,
      null,
      lockTimesheetsUponApprovalSettingEnabled ? 160 : 120,
      240 * window.rm.l10n.getWidthMultiplier()
    );

    cp.show(_i.bulkApproveButton.container);
  });
  _i.container.appendChild(
    lineBreak({
      height: '1px',
      background: '#eee',
      'margin-top': '15px',
    })
  );

  if (lockTimesheetsUponApprovalSettingEnabled) {
    _i.note = document.createElement('div');
    _i.note.className = 'fnt-r-15 blockFloatNot';
    _i.note.style.paddingTop = '10px';
    _i.note.innerHTML = I18n.t('msg_bulk_approve_and_lock_note');
    _i.container.appendChild(_i.note);
  }

  _i.bulkApproveButton.container.style.padding = 0;
  _i.container.appendChild(_i.bulkApproveButton.container);

  _i.cancelButton = new secondaryButton(
    'settingsSecondaryButton blockFloatRight'
  );
  _i.cancelButton.setButtonText(I18n.t('lbl_cancel'));
  _i.cancelButton.setCallback(function () {
    gPage.menuItemCallback(ACCOUNT_SETTINGS_MENU_INDEX);
  });

  _i.container.appendChild(_i.cancelButton.container);
}
