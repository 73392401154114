function CustomFieldsEditView(name, namespace, customFieldableId, opts) {
  var _i = this,
    _name = name,
    _isProjectView = namespace == 'assignables',
    _namespace = namespace,
    _customFieldableId = customFieldableId,
    _customFieldValues = [],
    _customFieldsContainer = null;

  _i.fallbackToDefault = opts && opts.fallbackToDefault;
  _i.isFieldEditableCallback = opts && opts.isEditableCallback;
  _i.createLROnChangeListener =
    opts && opts.createLROnChangeListener
      ? opts.createLROnChangeListener
      : () => {};
  _i.lRChangeListeners = {};

  function initializeData() {
    gService.getCustomFields(_namespace, customFieldsLoaded);
  }

  function customFieldsLoaded() {
    if (_customFieldableId) {
      gService.getCustomFieldValues(
        _customFieldableId,
        _namespace,
        customFieldValuesLoaded
      );
    } else {
      customFieldValuesLoaded();
    }
  }

  function customFieldValuesLoaded() {
    var shouldAddSpacer = false;
    var fields = _isProjectView
      ? gData.projectCustomFields
      : gData.peopleCustomFields;
    _.each(fields, function (field) {
      if (_i.isFieldEditableCallback && !_i.isFieldEditableCallback(field)) {
        return;
      }

      if (shouldAddSpacer) {
        const spacer = document.createElement('DIV');
        spacer.className = 'blockFloat editCustomFieldsLineSpacer';
        _customFieldsContainer.appendChild(spacer);
        shouldAddSpacer = false;
      } else {
        shouldAddSpacer = true;
      }

      // In create, custom field values default values are displayed if no
      // values exist for the fieldable object (project or user). getCustomFieldValue is called with fallbackToDefault = true.
      // This shouldn't be the case on edit in order to avoid accidentally re-saving a default value
      // that's been removed.
      var fallback = _i.fallbackToDefault;
      var values = gData.getValuesForCustomField(
        _customFieldableId,
        field.id,
        _namespace,
        fallback
      );

      const fieldIsDropdown =
        field.data_type === 'selection_list' ||
        field.data_type === 'multiple_choice_selection_list';
      let initialTrackingValues;
      let doTweakTrackingValues =
        !values ||
        (values.length === 1 &&
          (values[0] === null || values[0] === undefined));
      if (!fieldIsDropdown) {
        doTweakTrackingValues ||= values.length === 0;
      }

      if (doTweakTrackingValues && fieldIsDropdown) {
        initialTrackingValues = [];
      } else if (doTweakTrackingValues) {
        initialTrackingValues = [''];
      } else {
        initialTrackingValues = JSON.parse(JSON.stringify(values)); // stringify and then parse to get a copy, rather than a reference
      }

      _i.lRChangeListeners[field.name] = _i.createLROnChangeListener(
        field.name,
        initialTrackingValues
      );

      // Initialize custom field values with the current value for this field.
      _customFieldValues.push({});
      var index = _customFieldValues.length - 1;
      _customFieldValues[index].id = field.id;
      _customFieldValues[index].name = field.name;
      _customFieldValues[index].values = values;

      if (
        field.data_type === 'selection_list' ||
        field.data_type === 'multiple_choice_selection_list'
      ) {
        displayCustomFieldValueSelectionList(
          _customFieldsContainer,
          field,
          values,
          shouldAddSpacer
        );
      } else {
        __A(field.data_type === 'string', 'Unsupported custom field data type');
        displayCustomFieldValueInput(
          _customFieldsContainer,
          field,
          values,
          shouldAddSpacer
        );
      }
    });
  }

  function getTextForCustomFieldLabel(field) {
    if (field.description) {
      return field.name + ' (' + field.description + ')';
    } else {
      return field.name;
    }
  }

  function displayCustomFieldValueSelectionList(
    container,
    field,
    values,
    shouldAddSpacer
  ) {
    // If a spacer came before it, the field input should not float since it's the last
    // on the line.
    __A(
      field.data_type === 'selection_list' ||
        field.data_type === 'multiple_choice_selection_list'
    );
    var allowMultiSelect = field.data_type == 'selection_list' ? false : true;
    var fieldSelector = new CustomFieldValuesSelectionList(
      field,
      onFieldValueChanged,
      _customFieldsContainer.id,
      getTextForCustomFieldLabel(field),
      !shouldAddSpacer,
      allowMultiSelect
    );
    fieldSelector.show(values);
  }

  function onFieldValueChanged(field, newValues) {
    var attrs = { id: field.id },
      changedDataObject = _.find(_customFieldValues, attrs);
    changedDataObject.name = field.name;
    changedDataObject.values = newValues;
    _i.lRChangeListeners[field.name] &&
      _i.lRChangeListeners[field.name](newValues);
  }

  function displayCustomFieldValueInput(
    container,
    field,
    values,
    shouldAddSpacer
  ) {
    // For a text custom field there should be only one value per fieldable type.
    __A(!values || values.length <= 1);
    var value = values && values.length > 0 ? values[0] : '';

    var noteChangedValue = function (text) {
      var attrs = { id: field.id },
        changedDataObject = _.find(_customFieldValues, attrs);
      changedDataObject.name = field.name;
      changedDataObject.values[0] = text;
      _i.lRChangeListeners[field.name] &&
        _i.lRChangeListeners[field.name](changedDataObject.values);
    };

    inputControl = new TextInput(
      _customFieldsContainer.id,
      getTextForCustomFieldLabel(field),
      value,
      'blockFloat' +
        (shouldAddSpacer ? 'Not' : '') +
        ' editCustomFieldEditInputContainer',
      'blockFloat fnt-r-13 editCustomFieldTextInputLabel',
      'blockFloat fnt-r-15 editCustomFieldInputSmall',
      null /*width*/,
      TextInputFilter.None,
      null /* onBlur */,
      null /* onEnter */,
      noteChangedValue
    );

    $(inputControl.labelText).css({
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      width: 'inherit',
      'white-space': 'nowrap',
    });

    if (field.description) {
      inputControl.labelText.title = field.description;
    }
  }

  _i.constructor = function () {
    _customFieldsContainer = document.createElement('DIV');
    _customFieldsContainer.id = 'customfieldseditview_' + _.uniqueId();
    _customFieldsContainer.className = 'blockFloatNot';
    initializeData();
  };

  _i.show = function (container) {
    container.appendChild(_customFieldsContainer);
  };

  _i.getCustomFieldIdsAndValues = function () {
    var customFieldIdsValues = [];
    _customFieldValues &&
      _customFieldValues.forEach(function (cfv) {
        cfv.values &&
          cfv.values.forEach(function (value) {
            customFieldIdsValues.push({});
            var index = customFieldIdsValues.length - 1;
            customFieldIdsValues[index]['custom_field_id'] = cfv.id;
            customFieldIdsValues[index]['value'] = value;
          });
      });
    return customFieldIdsValues;
  };

  _i.constructor();
}
