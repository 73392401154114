function FAQPage(attachTo) {
  var _i = this;

  _i.attachTo = attachTo;

  _i.gotoSettings = function () {
    window.location.href = getBaseURL() + 'settings?page=settings';
  };

  _i.load = function () {
    var pageContainer = document.createElement('DIV');
    pageContainer.className = 'blockFloatNot gettingStartedPageContainer';

    var pageFloat = document.createElement('DIV');
    pageFloat.className = 'gettingStartedPageFloat';

    var pageContent = document.createElement('DIV');
    pageContent.className = 'blockFloatNot fnt-r-14 faqPageContent';

    pageContent.innerHTML = I18n.t('settings.faqtext');

    pageFloat.appendChild(pageContent);
    pageContainer.appendChild(pageFloat);

    _i.attachTo.appendChild(pageContainer);
  };
}
