const DRAG_HANDLE_ICON = `
<svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M2.24993 3.79996C1.86333 3.79996 1.54993 4.11336 1.54993 4.49996C1.54993 4.88656 1.86333 5.19996 2.24993 5.19996H5.74993C6.13653 5.19996 6.44993 4.88656 6.44993 4.49996C6.44993 4.11336 6.13653 3.79996 5.74993 3.79996H2.24993Z" fill="#111111"/>
<path d="M2.24993 6.79996C1.86333 6.79996 1.54993 7.11336 1.54993 7.49996C1.54993 7.88656 1.86333 8.19996 2.24993 8.19996H5.74993C6.13653 8.19996 6.44993 7.88656 6.44993 7.49996C6.44993 7.11336 6.13653 6.79996 5.74993 6.79996H2.24993Z" fill="#111111"/>
<path d="M1.54993 10.5C1.54993 10.1134 1.86333 9.79996 2.24993 9.79996H5.74993C6.13653 9.79996 6.44993 10.1134 6.44993 10.5C6.44993 10.8866 6.13653 11.2 5.74993 11.2H2.24993C1.86333 11.2 1.54993 10.8866 1.54993 10.5Z" fill="#111111"/>
<path d="M2.24993 12.8C1.86333 12.8 1.54993 13.1134 1.54993 13.5C1.54993 13.8866 1.86333 14.2 2.24993 14.2H5.74993C6.13653 14.2 6.44993 13.8866 6.44993 13.5C6.44993 13.1134 6.13653 12.8 5.74993 12.8H2.24993Z" fill="#111111"/>
</g>
</svg>`;

const CHEVRON_DOWN_ICON = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 7.5L10 12.5L5 7.5" stroke="#979797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
const CHEVRON_UP_ICON = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12.5L10 7.5L15 12.5" stroke="#979797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const svgIcons = {
  DRAG_HANDLE_ICON: DRAG_HANDLE_ICON,
  CHEVRON_DOWN_ICON: CHEVRON_DOWN_ICON,
  CHEVRON_UP_ICON: CHEVRON_UP_ICON,
};

window.svgs = svgIcons;
