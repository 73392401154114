function ShowPageSpinner(delayRenderTimeout, hideGraphic) {
  if (window.__ps == undefined) {
    window.__ps = new pageSpinner(delayRenderTimeout, hideGraphic);
  }
}

function RemovePageSpinner() {
  if (window.__ps) {
    window.__ps.remove();
    window.__ps = undefined;
  }
}

function UpdatePageSpinner(newText) {
  if (window.__ps) {
    window.__ps.updateMessage(newText);
  }
}

function pageSpinner(delayRenderTimeout, hideGraphic) {
  var _i = this,
    _messageText = null,
    _message = null;

  var WIDTH = 400;
  var HEIGHT = 44;

  _i.wasShown = false;
  _i.wasCancelled = false;

  _i.eat = function () {
    var test = 'yes';
    return true;
  };

  _i.updateMessage = function (newText) {
    _messageText = newText;
    if (_message) {
      _message.innerHTML = newText;
    }
  };

  _i.remove = function () {
    _i.wasCancelled = true;
    if (_i.wasShown) {
      _i.cont.className = 'fullScreenOverlay fullScreenOverlayFade';
      setTimeout(function () {
        document.body.style.overflow = '';
        document.body.removeChild(_i.cont);
      }, 250);
    }
  };

  _i.constructor = function () {
    if (delayRenderTimeout == 0) {
      _i.delayRender();
    } else {
      if (delayRenderTimeout == null) {
        delayRenderTimeout = 500;
      }
      setTimeout(_i.delayRender, delayRenderTimeout);
    }
  };

  _i.createGraphic = function (hideGraphic, content) {
    if (hideGraphic) return;
    var image = document.createElement('IMG');
    image.src = STATIC_IMAGE_URL + '/progress-indicator_24.gif';
    image.className = 'blockFloat spinnerImage';
    content.appendChild(image);
  };

  _i.delayRender = function () {
    if (_i.wasCancelled) return;

    _i.cont = document.createElement('DIV');
    _i.cont.className = 'fullScreenOverlay';
    _i.cont.id = 'spinnerFullScreen';
    document.body.appendChild(_i.cont);

    _i.wasShown = true;

    _i.contback = document.createElement('DIV');
    _i.contback.className = 'fullScreenOverlayBackground';
    _i.cont.appendChild(_i.contback);

    var posx = (window.innerWidth - WIDTH) / 2;
    var posy = (window.innerHeight - HEIGHT) / 2 + xScrollTop(document.body);

    var content = document.createElement('DIV');
    content.className = 'progressSpinnerContent';
    content.style.position = 'absolute';
    content.style.left = posx + 'px';
    content.style.top = posy + 'px';

    _i.createGraphic(hideGraphic, content);

    _message = document.createElement('DIV');
    _message.className = 'blockFloat fnt-r-14';
    _message.style.color = '#B2B2B2';
    _message.style.overflow = 'ellipsis';
    _message.style.lineHeight = '44px';
    _message.style.marginLeft = '10px';
    if (_messageText) {
      _message.innerText = _messageText;
    } else {
      _message.innerHTML = I18n.t('lbl_getting_information');
    }

    content.appendChild(_message);
    _i.cont.appendChild(content);

    document.body.style.overflow = 'hidden';
  };

  _i.constructor();
}
