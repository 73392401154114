function notificationPopup(title, prompt, timeout, opts) {
  if (!(this instanceof notificationPopup)) {
    return new notificationPopup(title, prompt, timeout, opts);
  }

  var WIDTH = 394;
  var HEIGHT = 160;
  var MESSAGE_AREA_PADDING = 5;
  var MESSAGE_AREA_HEIGHT = 60;

  var _i = this;
  _i.title = title;
  _i.prompt = prompt;
  _i.timeout = timeout;
  _i.popupContainer;
  _i.opts = opts || {};

  function confirmedCbInternal() {
    _i.popupContainer.hide();
  }

  _i.close = function () {
    _i.cancelTimeout();
    _i.popupContainer.hide();
    _i.closeCallback && _i.closeCallback();
  };

  _i.cancelTimeout = function () {
    clearTimeout(_i.msgTimeout);
  };

  _i.dismiss = function () {
    _i.popupContainer.hide();
  };

  _i.centerShow = function () {
    var d = document;
    var rootElm =
      d.documentElement && d.compatMode == 'CSS1Compat'
        ? d.documentElement
        : d.body;
    var vpw = self.innerWidth ? self.innerWidth : rootElm.clientWidth; // viewport width
    var vph = self.innerHeight ? self.innerHeight : rootElm.clientHeight; // viewport height
    _i.popupContainer.pointAt(
      vpw / 2,
      rootElm.scrollTop + vph / 2 - HEIGHT,
      0,
      0
    );
    _i.msgTimeout = setTimeout(_i.dismiss, _i.timeout);
  };

  function init() {
    var spec = compSpritesButtonsIcons.errorDialogSpecs;
    var viewport = document.getElementById('mainCon');

    var content = document.createElement('DIV');
    content.style.width = WIDTH + 'px';
    content.style.height = HEIGHT + 'px';
    content.id = 'notification-popup';
    content.tabIndex = '0';

    var buttons = document.createElement('DIV');
    buttons.className = 'blockFloatNot';
    buttons.style.width = 'auto';
    buttons.style.paddingBottom = MESSAGE_AREA_PADDING + 'px';
    buttons.style.float = 'right';

    content.appendChild(buttons);

    var cancelButton = new spritesButton(
      'cancelButtonNotification',
      null,
      compSpritesButtonsIcons.closeDialogButtonRest,
      compSpritesButtonsIcons.closeDialogButtonHover,
      compSpritesButtonsIcons.closeDialogButtonDown
    );
    cancelButton.setCallback(_i.close);
    cancelButton.setButtonText('');
    buttons.appendChild(cancelButton.container);

    var titlediv = document.createElement('DIV');
    titlediv.className = 'blockFloatNot fnt-r-14';
    titlediv.style.width = WIDTH - MESSAGE_AREA_PADDING * 2 + 'px';
    titlediv.style.textAlign = 'center';
    titlediv.style.marginTop = '20px';
    titlediv.style.marginLeft = '10px';
    titlediv.style.marginRight = '10px';
    titlediv.style.marginBottom = '5px';
    titlediv.innerText = title;
    content.appendChild(titlediv);

    var message = document.createElement('DIV');
    message.className = 'blockFloatNot fnt-r-14';
    message.style.width = WIDTH - MESSAGE_AREA_PADDING * 2 + 'px';
    message.style.color = '#898989';
    message.style.textAlign = 'center';
    message.style.marginLeft = '10px';
    message.style.marginRight = '10px';
    message.innerHTML = _i.opts.escaped ? prompt : _.escape(prompt);

    content.appendChild(message);

    _i.popupContainer = new popupContainer(
      spec,
      WIDTH,
      HEIGHT,
      3, // innerMargin
      false, // autorotate
      'nc',
      viewport,
      ['nc'],
      null, // blurCallback
      null,
      true
    );

    _i.popupContainer.attachContent(content);
    _i.centerShow();
  }

  init();
  focusElementAfterDelay('#notification-popup');
}
