function SettingsUser(attach, user, parent) {
  var _i = this;
  if (!(this instanceof SettingsUser)) {
    return new SettingsUser(attach, user, parent);
  }

  _i.attach = attach;
  _i.user = user;
  _i.parent = parent;
  _i.mode = 'read';

  _i.userChanged = function () {
    _i.parent.refreshList();
  };

  _i.nameClick = function () {
    window.location.href =
      getBaseURL() + '?edituser=true&user_id=' + _i.user.guid;
  };

  _i.invitationSentAfterUnarchive = function () {
    notificationPopup(
      I18n.t('lbl_invitation_sent'),
      I18n.t('msg_invitation_sent_confirm'),
      5000
    );
    _i.user.invitation_pending = true;
    _i.parent.refreshList();
  };

  _i.inviteUserAfterUnarchive = function () {
    if (_i.user.email == null) {
      window.location.href =
        getBaseURL() + 'sendinvite?ret=set&user_id=' + _i.user.id;
    } else {
      gService.resendInvite(_i.user.id, _i.invitationSentAfterUnarchive);
    }
  };

  _i.undeleteEdit = function () {
    var confirmDelete = threeButtonPopup(
      I18n.t('lbl_unarchive') +
        ' ' +
        _i.user.firstName +
        ' ' +
        _i.user.lastName +
        '? ' +
        _i.user.firstName +
        ' ' +
        _i.user.lastName +
        ' ' +
        I18n.t('msg_unarchive_description_lc'),
      I18n.t('lbl_cancel'),
      I18n.t('lbl_unarchive'),
      I18n.t('lbl_unarchive_invite'),
      null,
      function () {
        gService.undeleteUser(_i.user, _i.userChanged);
      },
      function () {
        gService.undeleteUser(_i.user, _i.inviteUserAfterUnarchive);
      },
      _i.parent.container,
      null,
      150
    );
    confirmDelete.show(_i.deleteButton.container);
  };

  _i.deleteEdit = function () {
    if (_i.user.account_owner) {
      new notificationPopup(
        I18n.t('msg_cannot_archive_account_owner'),
        I18n.t('msg_change_account_owner'),
        15000
      );
    } else {
      var confirmDelete = confirmationPopup(
        I18n.t('lbl_archive') +
          ' ' +
          _i.user.firstName +
          ' ' +
          _i.user.lastName +
          '? ',
        I18n.t('lbl_archive'),
        function () {
          gService.deleteUser(_i.user, _i.userChanged);
        },
        null,
        _i.parent.container
      );
      confirmDelete.show(_i.deleteButton.container);
    }
  };

  _i.cancelEdit = function () {
    if (_i.mode == 'edit') {
      _i.listMode();
    }
    _i.usercontainer.className = 'blockFloatNot settingsUserContainer';
  };

  _i.saveEdit = function () {
    if (_i.usertypeid && _i.usertypeid != null) {
      var data = {};
      data.id = _i.user.id;
      data.firstName = _i.user.firstName;
      data.lastName = _i.user.lastName;
      data.mobile_phone = _i.user.mobile_phone;
      data.office_phone = _i.user.office_phone;
      data.employee_number = _i.user.employee_number;
      data.user_type_id = _i.usertypeid;
      gService.updateUser(data, _i.userChanged);
    }
  };

  _i.containerMouseover = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.usercontainer.className = 'blockFloatNot settingsUserContainerHover';
    }
  };

  _i.containerMouseout = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.usercontainer.className = 'blockFloatNot settingsUserContainer';
    }
  };

  _i.containerClick = function (e) {
    if (_i.mode != 'edit') {
      if (window.event) e = window.event;
      var srcEl = e.srcElement ? e.srcElement : e.target;
      if (srcEl && srcEl.id == 'settingsUserAction') {
        cancelEvent(e);
        _i.resetPassword();
      } else {
        _i.parent.editModeCallback();
        _i.editMode();
        _i.usercontainer.className = 'blockFloatNot settingsUserContainerEdit';
      }
    }
  };

  _i.onUserTypeChanged = function (obj) {
    if (obj[0].id != null) {
      _i.usertypeid = obj[0].id;
      _i.usertype.setValue(obj[0].value);
    }
  };

  _i.popupDataFromUserTypes = function () {
    var ret = [];
    var types = gData.userTypes;
    var me = gData.getMe();
    var offset = 0;
    for (var i = 0; i < types.length; i++) {
      //NB this only works because we are excluding admin which is greater than all the
      // other types
      if (types[i].id < me.user_type_id) {
        continue;
      }
      obj = {};
      obj.type = 'item';
      obj.id = types[i].id;
      obj.value = offset;
      obj.selected = false;
      obj.label = types[i].value;
      if (obj.id == _i.user.user_type_id) {
        obj.selected = true;
        _i.usertypeid = obj.id;
        _i.usertypeinitialvalue = offset;
      } else {
        obj.selected = false;
      }
      if ((me && me.id != _i.user.id) || obj.selected) {
        ret.push(obj);
      }
      offset++;
    }
    return ret;
  };

  _i.userUndeleted = function () {
    _i.parent.refreshList();
  };

  _i.passwordReset = function (obj) {
    if (obj && !obj.error && !_i.user.invitation_pending && _i.user.login) {
      notificationPopup(
        I18n.t('lbl_password_reset'),
        I18n.t('msg_password_reset_confirm'),
        5000
      );
    } else if (!_i.user.login) {
      notificationPopup(
        I18n.t('lbl_invitation_sent'),
        I18n.t('msg_invitation_sent_confirm'),
        5000
      );
      _i.user.invitation_pending = true;
      _i.parent.refreshList();
    } else if (_i.user.invitation_pending) {
      notificationPopup(
        I18n.t('lbl_invitation_sent'),
        I18n.t('msg_invite_resent'),
        5000
      );
    }
  };

  _i.passwordResetError = function (err) {
    var message;
    try {
      message = JSON.parse(err.responseText).message;
    } catch (ex) {
      message =
        'Error accepting password reset request. Please contact support';
    }
    notificationPopup(I18n.t('lbl_reset_password'), message, 5000);
  };

  _i.unarchiveUser = function () {
    gService.undeleteUser(_i.user, _i.userUndeleted);
  };

  _i.resetPassword = function () {
    if (user.deleted) {
      var confirmDelete = threeButtonPopup(
        I18n.t('lbl_unarchive') +
          ' ' +
          _i.user.firstName +
          ' ' +
          _i.user.lastName +
          '? ' +
          _i.user.firstName +
          ' ' +
          _i.user.lastName +
          ' ' +
          I18n.t('msg_unarchive_description_lc'),
        I18n.t('lbl_cancel'),
        I18n.t('lbl_unarchive'),
        I18n.t('lbl_unarchive_invite'),
        null,
        function () {
          gService.undeleteUser(_i.user, _i.userChanged);
        },
        function () {
          gService.undeleteUser(_i.user, _i.inviteUserAfterUnarchive);
        },
        _i.parent.container,
        null,
        150
      );
      confirmDelete.show(_i.action);
    } else if (_i.user.invitation_pending && _i.user.email != null) {
      var confirm = confirmationPopup(
        I18n.t('lbl_resend_invitation_to') + ' ' + _i.user.email + '?',
        I18n.t('lbl_resend'),
        function () {
          gService.resendInvite(_i.user.id, _i.passwordReset);
        },
        null,
        _i.parent.container
      );
      confirm.show(_i.action);
    } else if (_i.user.email == null) {
      window.location.href =
        getBaseURL() + 'sendinvite?ret=set&user_id=' + _i.user.id;
    } else if (!_i.user.login) {
      var confirm = confirmationPopup(
        I18n.t('lbl_send_invitation_to') + ' ' + _i.user.email + '?',
        I18n.t('lbl_send'),
        function () {
          gService.updatewithinvite(
            { id: _i.user.id, email: _i.user.email },
            _i.passwordReset
          );
        },
        null,
        _i.parent.container
      );
      confirm.show(_i.action);
    } else {
      var confirm = confirmationPopup(
        I18n.t('lbl_reset_password_for') +
          ' ' +
          _i.user.firstName +
          ' ' +
          _i.user.lastName +
          '?',
        I18n.t('lbl_reset'),
        function () {
          var passwordreset = {};
          passwordreset.email = _i.user.email;
          gService.passwordreset(
            passwordreset,
            _i.passwordReset,
            _i.passwordResetError
          );
        },
        null,
        _i.parent.container
      );
      confirm.show(_i.action);
    }
  };

  _i.editMode = function () {
    removeAllChildren(_i.usercontainer);
    var name = document.createElement('DIV');
    name.className = 'blockFloat fnt-r-14 settingsUserNameEdit';
    _i.usercontainer.appendChild(name);
    name.innerText = user.firstName + ' ' + user.lastName;
    xAddEventListener(name, platform.click, _i.nameClick, true);

    _i.usertypeData = _i.popupDataFromUserTypes();
    var height = 32 * 7;
    if (_i.usertypeData.length < 7) {
      height = 32 * _i.usertypeData.length;
    }
    _i.usertypePopup = new selectionPopup(
      200,
      height,
      'mainCon',
      _i.usertypeData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onUserTypeChanged,
      false,
      null /*blurCallback*/,
      'nw',
      null /*prefferedDirections*/,
      true /*noautorotate*/
    );
    _i.usertype = new PopUpInput(
      _i.usercontainer.id,
      null,
      _i.usertypeinitialvalue,
      'blockFloat settingsPageEditInputContainerSmallerCategory',
      'blockFloatNot fnt-r-14 settingsInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsTextInputSmall',
      _i.usertypeData,
      _i.usertypePopup
    );
    var me = gData.getMe();
    if (me && me.id != _i.user.id) {
      _i.deleteButton = new secondaryButton('settingsSecondaryButtonCategory');
      if (user.deleted) {
        _i.deleteButton.setButtonText(I18n.t('lbl_unarchive'));
        _i.deleteButton.setCallback(_i.undeleteEdit);
      } else {
        _i.deleteButton.setButtonText(I18n.t('lbl_archive'));
        _i.deleteButton.setCallback(_i.deleteEdit);
      }
      _i.usercontainer.appendChild(_i.deleteButton.container);
      _i.deleteButton.container.tabIndex = 0;
    }
    _i.saveButton = new secondaryButton('settingsSecondaryButtonCategory');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveEdit);
    _i.usercontainer.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;

    _i.cancelButton = new spritesButton(
      'settingsCancelButtonCategory',
      null,
      compSpritesButtonsIcons.closeButtonRest,
      compSpritesButtonsIcons.closeButtonHover,
      compSpritesButtonsIcons.closeButtonDown
    );
    _i.cancelButton.setCallback(_i.cancelEdit);
    _i.cancelButton.setButtonText('');
    _i.usercontainer.appendChild(_i.cancelButton.container);

    _i.mode = 'edit';
  };

  _i.listMode = function () {
    removeAllChildren(_i.usercontainer);
    var name = document.createElement('DIV');
    name.className = 'blockFloat fnt-r-14 settingsUserName';
    _i.usercontainer.appendChild(name);
    if (user.firstName) {
      name.innerText = _i.user.firstName + ' ' + _i.user.lastName;
    } else {
      name.innerText = _i.user.email;
    }

    var type = document.createElement('DIV');
    type.className = 'blockFloat fnt-r-14 settingsUserType';
    _i.usercontainer.appendChild(type);

    if (user.deleted) {
      type.innerText = I18n.t('lbl_archived');
    } else {
      for (var j = 0; gData.userTypes && j < gData.userTypes.length; j++) {
        if (gData.userTypes[j].id == user.user_type_id) {
          type.innerText = gData.userTypes[j].value;
          break;
        }
      }
    }

    var status = document.createElement('DIV');
    status.className = 'blockFloat fnt-r-14 settingsUserStatus';
    _i.usercontainer.appendChild(status);
    if (user.deleted) {
      status.innerText = '';
    } else if (_i.user.invitation_pending) {
      status.innerText = I18n.t('lbl_invited_lc');
    } else if (_i.user.email == null) {
      status.innerText = ' ';
    } else {
      status.innerText = ' ';
    }

    if (user.deleted || gData.accountSettings.moduleEnabled('sso') == false) {
      // The action button is overloaded for for a variety of actions. If the user is archived, we
      // use it for unarchving. Otherwise, we hide it for sso enabled accounts.
      _i.action = document.createElement('DIV');
      _i.action.className = 'blockFloat fnt-r-14 settingsUserAction';
      _i.action.id = 'settingsUserAction';
      _i.usercontainer.appendChild(_i.action);
      if (user.deleted) {
        _i.action.innerText = I18n.t('lbl_unarchive');
      } else if (_i.user.invitation_pending && _i.user.email != null) {
        _i.action.innerText = I18n.t('lbl_resend_invitation');
      } else if (_i.user.email == null || !_i.user.login) {
        _i.action.innerText = I18n.t('lbl_send_invitation');
      } else {
        _i.action.innerText = I18n.t('lbl_reset_password');
      }
    }

    if (
      gData.accountSettings.moduleEnabled('sso') &&
      user.login_type == 'default'
    ) {
      var d = document.createElement('DIV');
      d.className = 'settings_div__non-sso-user-warning fnt-r-14';
      d.innerText = I18n.t('lbl_not_using_sso');
      d.title = I18n.t('msg_signin_rm_instead_of_SSO');
      _i.usercontainer.appendChild(d);
    }

    _i.mode = 'list';
  };

  _i.constructor = function () {
    _i.usercontainer = document.createElement('DIV');
    _i.usercontainer.className = 'blockFloatNot settingsUserContainer';
    _i.usercontainer.id = 'settingsUserContainer' + getGuid();
    _i.attach.appendChild(_i.usercontainer);
    xAddEventListener(
      _i.usercontainer,
      'mouseover',
      _i.containerMouseover,
      true
    );
    xAddEventListener(_i.usercontainer, 'mouseout', _i.containerMouseout, true);
    xAddEventListener(
      _i.usercontainer,
      platform.click,
      _i.containerClick,
      true
    );
    _i.listMode();
  };
  _i.constructor();
}
