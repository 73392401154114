var MAX_LENGTH = 255;

function ProjectBudgetItem(
  attachTo,
  parent,
  item,
  defaultView,
  item_type,
  currency,
  scale,
  textId,
  numericId
) {
  var _i = this;
  if (!(this instanceof ProjectBudgetItem)) {
    return new ProjectBudgetItem(
      attachTo,
      parent,
      item,
      defaultView,
      item_type,
      currency,
      scale,
      textId,
      numericId
    );
  }

  // params
  _i.attachTo = attachTo;
  _i.parent = parent;
  _i.item = item;
  _i.defaultView = defaultView;
  _i.item_type = item_type;
  _i.currency = currency;
  _i.scale = scale;

  // ids
  _i.textID = textId;
  _i.numericID = numericId;

  _i.isNumeric = function (input) {
    return !isNaN(parseFloat(input)) && isFinite(input);
  };

  _i.formatNumber = function (val) {
    if (!_i.isNumeric(val)) {
      return val;
    }
    if (Number(val.toFixed(0)) == Number(val.toFixed(2))) {
      return val.toFixed(0);
    } else if (Number(val.toFixed(1)) == Number(val.toFixed(2))) {
      return val.toFixed(1);
    } else {
      return val.toFixed(2);
    }
  };

  _i.formatCurrency = function (num) {
    if (_i.currency) return formatCurrency(num);
    else return _i.formatNumber(num);
  };

  _i.valueFromInput = function (inputtext) {
    var val = inputtext.value;
    if (val == '') return null;
    if (!val) return 0;
    if (_i.currency) val = convertCurrencyToNumber(val);
    val = parseFloat(val);

    return isNumber(val) ? val : 0;
  };

  _i.getTotal = function () {
    return _i.valueFromInput(_i.inputText) * _i.scale;
  };

  _i.hasValue = function () {
    if (_i.inputName.value != null && _i.inputName.value != '') return true;
    if (
      _i.valueFromInput(_i.inputText) != null &&
      _i.valueFromInput(_i.inputText) != 0
    )
      return true;
    return false;
  };

  _i.getValue = function () {
    // if the item does not have an assignable id, then it is a predefined category.
    // treat is as a new category that has been added.
    var valueObj = {
      id: item && item.assignable_id ? item.id : null,
      category: _i.inputName.value,
      amount: _i.valueFromInput(_i.inputText) * _i.scale,
      assignable_id: _i.parent.project.id,
      item_type: _i.item_type,
      assignable_parent_id: _i.parent.project.parent_id,
      peritem_amount: _i.valueFromInput(_i.peritemAmountText),
    };
    // NOTE if parent `ProjectBudgetGroup` has a `project` property with a `guid`, likely a
    // temporary phase, mark with guid for match up later
    if (_i.parent.project.guid !== undefined) {
      valueObj.guid = _i.parent.project.guid;
    }
    return valueObj;
  };

  _i.hasNewInput = function () {
    if (_i.cancelInputCallback) return false;
    return (
      _i.inputName.value != '' ||
      _i.inputText.value != '' ||
      _i.peritemAmountText.value != '' ||
      _i.peritemTotal.value != '' ||
      _i.peritemUnitsText.value != ''
    );
  };

  _i.blurPerItemAmountTextInputHandler = function () {
    if (_i.item == null && _i.hasNewInput()) {
      _i.parent.inputStateChanged();
      _i.cancelInputCallback = true;
    }

    var peritem = _i.valueFromInput(_i.peritemAmountText);
    var units = _i.valueFromInput(_i.peritemUnitsText);
    if (peritem == null || units == null) return;
    _i.peritemAmountText.value =
      peritem > 0 ? _i.formatCurrency(peritem ? peritem : 0) : '';
    _i.peritemUnitsText.value = units > 0 ? units : '';
    val = peritem * units;
    _i.inputText.value = val ? _i.formatCurrency(val) : '';
    _i.peritemTotal.value = _i.inputText.value;
    _i.parent.budgetModified();
  };

  _i.blurTextInputHandler = function () {
    if (_i.item == null && _i.hasNewInput()) {
      _i.parent.inputStateChanged();
      _i.cancelInputCallback = true;
    }
    var val = _i.showingDetailed
      ? _i.valueFromInput(_i.peritemTotal)
      : _i.valueFromInput(_i.inputText);
    var peritem = _i.valueFromInput(_i.peritemAmountText);
    _i.inputText.value = val ? _i.formatCurrency(val) : '';
    _i.peritemTotal.value = _i.inputText.value;
    _i.peritemUnitsText.value = peritem > 0 && val > 0 ? val / peritem : '';
    _i.parent.budgetModified();
  };

  _i.calculatorPressed = function () {
    report_page_view(window.location.pathname + '/budget/budget_calc_used');
    if (_i.showingDetailed) {
      _i.inputText.style.display = 'block';
      _i.peritemAmountText.style.display = 'none';
      _i.peritemX.style.display = 'none';
      _i.peritemUnitsText.style.display = 'none';
      _i.peritemEq.style.display = 'none';
      _i.peritemTotal.style.display = 'none';
      _i.showingDetailed = false;
      _i.calculator.container.title = 'Show fields to calculate this item';
    } else {
      _i.inputText.style.display = 'none';
      _i.peritemAmountText.style.display = 'block';
      _i.peritemX.style.display = 'block';
      _i.peritemEq.style.display = 'block';
      _i.peritemUnitsText.style.display = 'block';
      _i.peritemTotal.style.display = 'block';
      _i.showingDetailed = true;
      _i.calculator.container.title = 'Hide fields to calculate this item';
    }
  };

  _i.listMode = function () {
    _i.inputName = document.createElement('input');
    _i.inputName.maxLength = MAX_LENGTH;
    _i.inputName.setAttribute('type', 'text');
    _i.inputName.className = 'blockFloat fnt-r-14 editProjectTextInputLarge';
    _i.inputName.setAttribute('aria-labelledby', _i.textID);
    _i.inputName.value = _i.item ? _i.item.category : '';
    xAddEventListener(_i.inputName, 'blur', _i.blurTextInputHandler, true);
    _i.container.appendChild(_i.inputName);

    _i.calculator = new spritesButton(
      'blockFloat fnt-r-14 editProjectCalcButton',
      '',
      compSpritesButtonsIcons.calculatorButtonRest,
      compSpritesButtonsIcons.calculatorButtonHover,
      compSpritesButtonsIcons.calculatorButtonDown
    );
    _i.calculator.setCallback(_i.calculatorPressed);
    _i.calculator.setButtonText('');
    _i.container.appendChild(_i.calculator.container);
    _i.calculator.container.title = 'Show fields to calculate this item';
    _i.inputText = document.createElement('input');
    _i.inputText.setAttribute('type', 'text');
    _i.inputText.className =
      'blockFloat fnt-r-14 editProjectExpenseBudgetAmount';
    _i.inputText.value = _i.item
      ? _i.formatCurrency(_i.item.amount / _i.scale)
      : '';
    _i.inputText.setAttribute('aria-labelledby', _i.numericID);
    xAddEventListener(_i.inputText, 'blur', _i.blurTextInputHandler, true);
    _i.container.appendChild(_i.inputText);

    _i.peritemAmountText = document.createElement('input');
    _i.peritemAmountText.setAttribute('type', 'text');
    _i.peritemAmountText.className =
      'blockFloat fnt-r-14 editProjectExpenseBudgetPerItemAmount';
    _i.peritemAmountText.value =
      _i.item && _i.item.peritem_amount > 0
        ? _i.formatCurrency(_i.item.peritem_amount)
        : '';
    xAddEventListener(
      _i.peritemAmountText,
      'blur',
      _i.blurPerItemAmountTextInputHandler,
      true
    );
    _i.container.appendChild(_i.peritemAmountText);
    _i.peritemAmountText.style.display = 'none';

    _i.peritemX = document.createElement('DIV');
    _i.peritemX.className = 'blockFloat fnt-r-14 editProjectPerItemLabel';
    _i.peritemX.innerHTML = I18n.t('lbl_symbol_multiply');
    _i.container.appendChild(_i.peritemX);
    _i.peritemX.style.display = 'none';

    _i.peritemUnitsText = document.createElement('input');
    _i.peritemUnitsText.setAttribute('type', 'text');
    _i.peritemUnitsText.className =
      'blockFloat fnt-r-14 editProjectExpenseBudgetPerItemUnits';
    _i.peritemUnitsText.value =
      _i.item && _i.item.peritem_amount > 0 && _i.item.amount > 0
        ? _i.item.amount / _i.scale / _i.item.peritem_amount
        : '';
    _i.peritemUnitsText.placeholder = _i.item ? _i.item.peritem_label : '';
    _i.peritemUnitsText.title = _i.item
      ? _i.item.peritem_label
      : I18n.t('lbl_items_lc');
    xAddEventListener(
      _i.peritemUnitsText,
      'blur',
      _i.blurPerItemAmountTextInputHandler,
      true
    );
    _i.container.appendChild(_i.peritemUnitsText);
    _i.peritemUnitsText.style.display = 'none';

    _i.peritemEq = document.createElement('DIV');
    _i.peritemEq.className = 'blockFloat fnt-r-14 editProjectPerItemLabel';
    _i.peritemEq.innerHTML = I18n.t('lbl_symbol_equal');
    _i.container.appendChild(_i.peritemEq);
    _i.peritemEq.style.display = 'none';

    _i.peritemTotal = document.createElement('input');
    _i.peritemTotal.setAttribute('type', 'text');
    _i.peritemTotal.className =
      'blockFloat fnt-r-14 editProjectExpenseBudgetPerItemTotal';
    _i.peritemTotal.value = _i.item
      ? _i.formatCurrency(_i.item.amount / _i.scale)
      : '';
    xAddEventListener(_i.peritemTotal, 'blur', _i.blurTextInputHandler, true);
    _i.container.appendChild(_i.peritemTotal);
    _i.peritemTotal.style.display = 'none';

    _i.showingDetailed = false;
    if (_i.defaultView) {
      if (_i.peritemAmountText.value != '' || _i.peritemUnitsText.value != '') {
        _i.calculatorPressed();
      }
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot settingsBillRateUserContainer';
    _i.container.id = 'settingsPhaseBudget' + getGuid();
    _i.attachTo.appendChild(_i.container);
    _i.listMode();
  };
  _i.constructor();
}

function ProjectBudgetGroup(
  attachTo,
  parent,
  project,
  showheader,
  item_type,
  currency,
  scale
) {
  var _i = this;
  if (!(this instanceof ProjectBudgetGroup)) {
    return new ProjectBudgetGroup(
      attachTo,
      parent,
      project,
      showheader,
      item_type,
      currency,
      scale
    );
  }

  // params
  _i.attachTo = attachTo;
  _i.parent = parent;
  _i.project = project;
  _i.showheader = showheader;
  _i.item_type = item_type;
  _i.currency = currency;
  _i.scale = scale;

  _i.budgetItems = [];
  _i.categories = _i.parent.categories;

  // ids
  _i.textID = 'label' + getGuid();
  _i.numericID = 'num-label' + getGuid();

  _i.buildCategoriesData = function () {
    var listData = [];
    if (_i.categories == null) _i.categories = [];
    listData = [];

    listData.push({
      type: 'item',
      id: -1,
      label: I18n.t('lbl_add_all_categories'),
      icon: null,
    });

    listData.push({
      type: 'label',
      label: I18n.t('lbl_categories'),
    });

    _i.categories.sort(function (a, b) {
      var sa = a.category.toLowerCase();
      var sb = b.category.toLowerCase();
      if (sa == sb) return 0;
      return sa > sb ? 1 : -1;
    });

    for (var i = 0; i < _i.categories.length; i++) {
      listData.push({
        type: 'item',
        value: _i.categories[i].id,
        id: i,
        label: _i.categories[i].category,
        icon: null,
      });
    }

    return listData;
  };

  _i.addCategory = function (id) {
    if (id < 0 || id >= _i.categories.length) return;
    _i.budgetItems.push(
      ProjectBudgetItem(
        _i.budgetitemscontainer,
        _i,
        _i.categories[id],
        true,
        _i.item_type,
        _i.currency,
        _i.scale,
        _i.textID,
        _i.numericID
      )
    );
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.budgetitemscontainer.appendChild(spacer);
  };

  _i.onCategorySelected = function (obj) {
    if (obj[0].id == -1) {
      // add all categories
      for (var i = 0; i < _i.categories.length; i++) {
        _i.addCategory(i);
      }
    } else {
      _i.addCategory(obj[0].id);
      _i.budgetItems[_i.budgetItems.length - 1].inputText.focus();
      _i.budgetItems[_i.budgetItems.length - 1].inputText.select();
    }
  };

  _i.showCategoriesSelectionPopup = function () {
    if (!_i.filterPopup) {
      _i.categoriespopupdata = _i.buildCategoriesData();
      var height = _i.categoriespopupdata.length * 30;
      if (height > 500) height = 500;
      if (_i.categoriespopupdata.length < 10) {
        _i.filterPopup = new selectionPopup(
          320,
          height,
          'mainCon',
          _i.categoriespopupdata,
          compSpritesButtonsIcons.wideDialogSpecs,
          _i.onCategorySelected,
          false,
          null /*blurCallback*/,
          'nw',
          null /*prefferedDirections*/,
          true /*noautorotate*/,
          false /* doNotDismissOnSelection */,
          null // parent
        );
      } else {
        _i.filterPopup = new filterPopup(
          320,
          height,
          'mainCon',
          _i.categoriespopupdata,
          compSpritesButtonsIcons.blackBarDialog,
          _i.onCategorySelected,
          false,
          null /*blurCallback*/,
          'nw',
          null /*prefferedDirections*/,
          true /*noautorotate*/,
          false /* doNotDismissOnSelection */,
          null // parent
        );
      }
      _i.filterPopup.popupContainer.autoOrient = false;
      _i.filterPopup.popupContainer.setDirection('nw');
      _i.filterPopup.selectionList.ignoreEnter = true;
      _i.filterPopup.isShown = false;
    }
    _i.filterPopup.showAtPoint(xPageX(_i.icon) + 30, xPageY(_i.icon) + 9, 0, 0);
    return false;
  };

  _i.refresh = function () {
    if (_i.label)
      _i.label.innerText = _i.project.phase_name
        ? _i.project.phase_name
        : I18n.t('lbl_non_phase_specific_bracket');
  };

  _i.getTotal = function () {
    var totalVal = 0;
    _i.budgetItems.forEach(function (item) {
      totalVal += item.getTotal();
    });
    return totalVal;
  };

  _i.getValues = function () {
    var item_array = [];
    _i.budgetItems.forEach(function (item) {
      if (item.hasValue()) item_array.push(item.getValue());
    });
    if (_i.additem.hasNewInput()) {
      if (_i.additem.hasValue()) item_array.push(_i.additem.getValue());
    }
    return item_array;
  };

  _i.containerMouseover = function () {
    _i.nestedDiv.style.display = 'block';
  };

  _i.containerMouseout = function () {
    _i.nestedDiv.style.display = 'none';
  };

  _i.budgetModified = function () {
    _i.parent.retotal();
  };

  _i.inputStateChanged = function () {
    if (_i.additem.hasNewInput()) {
      _i.budgetItems.push(_i.additem);
      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot settingsSpacerImage3';
      spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
      _i.container.appendChild(spacer);
      _i.additem = ProjectBudgetItem(
        _i.container,
        _i,
        null,
        true,
        _i.item_type,
        _i.currency,
        _i.scale,
        _i.textID,
        _i.numericID
      );
    }
  };

  _i.addItemClicked = function () {
    _i.showCategoriesSelectionPopup();
  };

  _i.renderHeader = function () {
    _i.label = document.createElement('H3');
    _i.label.className =
      'blockFloatNot fnt-b-14 expensesControlHeaderContainer editProjectHeaders';
    if (_i.showheader) {
      _i.label.innerText = _i.project.phase_name
        ? _i.project.phase_name
        : I18n.t('lbl_non_phase_specific_bracket');
    } else {
      _i.label.innerText = I18n.t('lbl_overall_project');
    }
    _i.container.appendChild(_i.label);

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    _i.container.appendChild(_i.nestedDiv);

    _i.icon = document.createElement('DIV');
    _i.icon.className = 'blockFloat expenseAddIconGroup';
    getSprite(compSpritesButtonsIcons.smallAddIcon, _i.icon);
    _i.nestedDiv.appendChild(_i.icon);
    xAddEventListener(_i.icon, platform.click, _i.addItemClicked, false);
    var label = document.createElement('LABEL');
    label.className = 'blockFloat fnt-r-12 taskAddTask expenseAddGroupHeader';
    label.innerHTML = I18n.t('lbl_add_predefined_category');
    xAddEventListener(label, platform.click, _i.addItemClicked, false);
    _i.nestedDiv.appendChild(label);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);
    var label = document.createElement('LABEL');
    label.className =
      'blockFloatNot fnt-r-13 expensesControlHeaderContainerLight';
    label.id = _i.textID;
    label.innerHTML = I18n.t('lbl_category_optional');
    _i.container.appendChild(label);
    var nestedDiv = document.createElement('DIV');
    nestedDiv.className = 'floatRightDiv';
    _i.container.appendChild(nestedDiv);
    label = document.createElement('LABEL');
    label.className =
      'blockFloatNot fnt-r-13 expensesControlHeaderContainerLight';
    label.id = _i.numericID;
    label.innerText = _i.currency
      ? I18n.t('lbl_amount')
      : _i.scale == 1
      ? I18n.t('lbl_hours')
      : I18n.t('lbl_days');
    nestedDiv.appendChild(label);
  };

  _i.renderItems = function () {
    var budgetitems = _i.project.budgetitems
      ? _i.project.budgetitems[item_type]
      : null;

    if (budgetitems == null || budgetitems.length == 0) {
      return;
    }
    budgetitems.sort(function (a, b) {
      if (a.category && b.category) {
        var sa = a.category.toLowerCase();
        var sb = b.category.toLowerCase();
        return sa < sb ? -1 : 1;
      } else if (b.category) return 1;
      else return -1;
    });

    _i.budgetItems = [];
    budgetitems.forEach(function (item) {
      _i.budgetItems.push(
        ProjectBudgetItem(
          _i.budgetitemscontainer,
          _i,
          item,
          true,
          _i.item_type,
          _i.currency,
          _i.scale,
          _i.textID,
          _i.numericID
        )
      );
      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot settingsSpacerImage3';
      spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
      _i.budgetitemscontainer.appendChild(spacer);
    });
  };

  _i.render = function () {
    _i.renderHeader();

    _i.budgetitemscontainer = document.createElement('DIV');
    _i.budgetitemscontainer.className = 'blockFloatNot';
    _i.container.appendChild(_i.budgetitemscontainer);
    _i.renderItems();
    _i.additem = ProjectBudgetItem(
      _i.container,
      _i,
      null,
      true,
      _i.item_type,
      _i.currency,
      _i.scale,
      _i.textID,
      _i.numericID
    );
  };

  var ctor = (function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot expensesControlContentContainer';
    _i.container.id =
      'expensesControlContentContainer' + _i.project.guid + getGuid();
    _i.attachTo.appendChild(_i.container);
    _i.render();
  })();
}

function ProjectBudget(
  attachTo,
  parent,
  projectId,
  budget,
  categories,
  item_type,
  currency,
  budgetstring,
  scale,
  ignorescale,
  scalestr1,
  scalestr2
) {
  var _i = this;
  if (!(this instanceof ProjectBudget)) {
    return new ProjectBudget(
      attachTo,
      parent,
      projectId,
      budget,
      categories,
      item_type,
      currency,
      budgetstring,
      scale,
      ignorescale,
      scalestr1,
      scalestr2
    );
  }

  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.parent = parent;
  _i.projectId = projectId;
  _i.budget = budget;
  _i.categories = categories;
  _i.item_type = item_type;
  _i.currency = currency;
  _i.budgetstring = budgetstring;
  _i.ignorescale = ignorescale;
  _i.scalestr1 = scalestr1;
  _i.scalestr2 = scalestr2;
  if (ignorescale) _i.scale = 1;
  else _i.scale = scale;

  _i.saveScale = scale;
  _i.budgetsgroups = [];

  _i.isNumeric = function (input) {
    return !isNaN(parseFloat(input)) && isFinite(input);
  };

  _i.formatNumber = function (val) {
    if (!_i.isNumeric(val)) {
      return val;
    }
    if (Number(val.toFixed(0)) == Number(val.toFixed(2))) {
      return val.toFixed(0);
    } else if (Number(val.toFixed(1)) == Number(val.toFixed(2))) {
      return val.toFixed(1);
    } else {
      return val.toFixed(2);
    }
  };

  _i.formatCurrency = function (num) {
    if (_i.currency) return formatCurrency(num);
    else return _i.formatNumber(num);
  };

  _i.buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-14 editprojectSpan';
    ret.innerText = str;
    return ret;
  };

  _i.reset = function () {
    removeAllChildren(_i.budgetscontainer);
    _i.buildData();
    _i.buildBody();
    _i.buildFooter();
  };

  _i.getTotal = function () {
    var total = 0;
    if (_i.selectionProjectBudget) {
      total = _i.budgetsgroups[0].getTotal();
    } else {
      for (var i = 0; i < _i.budgetsgroups.length; i++) {
        total += _i.budgetsgroups[i].getTotal();
      }
    }
    return total;
  };

  _i.retotal = function () {
    var total = _i.getTotal() / _i.scale;
    _i.budgetTotal.innerText = isBelowMaxSafeInteger(total)
      ? _i.formatCurrency(total)
      : '-';
  };

  _i.refresh = function () {
    _i.budgetsgroups.forEach(function (group) {
      group.refresh();
    });
  };

  _i.cancelButtonClicked = function () {
    _i.reset();
  };

  _i.valueFromInput = function (rowindex) {
    var val = _i.budgetsinput[rowindex];
    if (val) val = val.value;
    if (val == '') return 'empty';
    if (!val) return 0;
    val = convertCurrencyToNumber(val);
    val = parseFloat(val);

    return isNumber(val) ? val : 0;
  };

  _i.toggleCallbackProjectHasBudget = function () {
    if (_i.hasProjectBudget) {
      report_page_view(
        window.location.pathname + '/budget/nobudgetset_' + item_type
      );
      _i.hasProjectBudget = false;
      _i.selectionbudgetexists.setEnabled(true);
      _i.selectionbudgetproject.setEnabled(false);
      _i.selectionbudgetphases.setEnabled(false);
      if (_i.saveScale != 1) {
        _i.scaleSelect.style.display = 'none';
      }
      _i.budgetscontainer.style.display = 'none';
      _i.reset();
    }
  };

  _i.toggleCallbackProjectBudget = function () {
    if (!_i.hasProjectBudget || !_i.selectionProjectBudget) {
      report_page_view(
        window.location.pathname + '/budget/projectbudgetset_' + item_type
      );
      _i.hasProjectBudget = true;
      _i.selectionProjectBudget = true;
      _i.selectionbudgetexists.setEnabled(false);
      _i.selectionbudgetproject.setEnabled(true);
      _i.selectionbudgetphases.setEnabled(false);
      if (_i.saveScale != 1) {
        _i.scaleSelect.style.display = 'block';
      }
      _i.budgetscontainer.style.display = 'block';
      _i.reset();
    }
  };

  _i.toggleCallbackPhasesBudget = function () {
    if (!_i.hasProjectBudget || _i.selectionProjectBudget) {
      report_page_view(
        window.location.pathname + '/budget/phasesbudgetset_' + item_type
      );
      _i.hasProjectBudget = true;
      _i.selectionProjectBudget = false;
      _i.selectionbudgetexists.setEnabled(false);
      _i.selectionbudgetproject.setEnabled(false);
      _i.selectionbudgetphases.setEnabled(true);
      if (_i.saveScale != 1) {
        _i.scaleSelect.style.display = 'block';
      }
      _i.budgetscontainer.style.display = 'block';
      _i.reset();
    }
  };

  _i.toggleCallbackScale = function () {
    if (_i.hasProjectBudget && _i.ignorescale) {
      _i.ignorescale = false;
      _i.selectionnoscale.setEnabled(false);
      _i.selectionscale.setEnabled(true);
      _i.scale = _i.saveScale;
      _i.reset();
    }
  };

  _i.toggleCallbackNoScale = function () {
    if (_i.hasProjectBudget && !_i.ignorescale) {
      _i.ignorescale = true;
      _i.selectionnoscale.setEnabled(true);
      _i.selectionscale.setEnabled(false);
      _i.scale = 1;
      _i.reset();
    }
  };

  _i.buildHeader = function () {
    _i.selectionbudgetexists = new SelectionToggle(
      _i.headercontainer,
      _i.buildPlainTextSpan(I18n.t('lbl_no_' + _i.budgetstring)),
      !_i.hasProjectBudget,
      'blockFloatNot editprojectSelectorItemSmallBudget',
      'fnt-r-15 editprojectSelectorText2 ',
      'editprojectSelectorIcon'
    );
    _i.selectionbudgetexists.setCallback(_i.toggleCallbackProjectHasBudget);

    _i.selectionbudgetproject = new SelectionToggle(
      _i.headercontainer,
      _i.buildPlainTextSpan(I18n.t('lbl_one_' + _i.budgetstring)),
      _i.hasProjectBudget && _i.selectionProjectBudget,
      'blockFloatNot editprojectSelectorItemSmallNoMarginBudget',
      'fnt-r-15 editprojectSelectorText2 ',
      'editprojectSelectorIcon'
    );
    _i.selectionbudgetproject.setCallback(_i.toggleCallbackProjectBudget);

    _i.selectionbudgetphases = new SelectionToggle(
      _i.headercontainer,
      _i.buildPlainTextSpan(I18n.t('lbl_full_' + _i.budgetstring)),
      _i.hasProjectBudget && !_i.selectionProjectBudget,
      'blockFloatNot editprojectSelectorItemSmallNoMargin',
      'fnt-r-15 editprojectSelectorText2 ',
      'editprojectSelectorIcon'
    );
    _i.selectionbudgetphases.setCallback(_i.toggleCallbackPhasesBudget);

    _i.scaleSelect = document.createElement('DIV');
    _i.scaleSelect.className = 'blockFloatNot';
    _i.scaleSelect.style.marginTop = '10px';
    _i.headercontainer.appendChild(_i.scaleSelect);

    if (_i.saveScale > 1) {
      _i.selectionscale = new SelectionToggle(
        _i.scaleSelect,
        _i.buildPlainTextSpan(I18n.t('msg_specificy_budget_in') + _i.scalestr1),
        !_i.ignorescale,
        'blockFloatNot editprojectSelectorItemSmallMarginBudget',
        'fnt-r-15 editprojectSelectorText2 ',
        'editprojectSelectorIcon'
      );
      _i.selectionscale.setCallback(_i.toggleCallbackScale);
      _i.selectionnoscale = new SelectionToggle(
        _i.scaleSelect,
        _i.buildPlainTextSpan(I18n.t('msg_specificy_budget_in') + _i.scalestr2),
        _i.ignorescale,
        'blockFloatNot editprojectSelectorItemSmallMarginBudget',
        'fnt-r-15 editprojectSelectorText2 ',
        'editprojectSelectorIcon'
      );
      _i.selectionnoscale.setCallback(_i.toggleCallbackNoScale);
    }
    if (!_i.hasProjectBudget) _i.budgetscontainer.style.display = 'none';
    if (_i.saveScale == 1 || !_i.hasProjectBudget) {
      _i.scaleSelect.style.display = 'none';
    }
  };

  _i.getZeroBudgetValue = function () {
    return {
      id: null,
      amount: 0,
      assignable_id: _i.project.id,
      item_type: _i.item_type,
    };
  };

  _i.saveCallback = function () {
    if (_i.parent) _i.parent.budgetsSaved(item_type);
  };

  _i.getValues = function () {
    var budgetItems;

    if (!_i.hasProjectBudget) {
      return [];
    }

    if (_i.selectionProjectBudget) {
      budgetItems = _i.budgetsgroups[0].getValues();
      if (budgetItems.length == 0) {
        budgetItems.push(_i.getZeroBudgetValue());
      }
      return budgetItems;
    } else {
      budgetItems = _i.budgetsgroups[0].getValues();
      if (budgetItems.length == 0) {
        budgetItems.push(_i.getZeroBudgetValue());
      }
      for (var i = 1; i < _i.budgetsgroups.length; i++) {
        budgetItems = budgetItems.concat(_i.budgetsgroups[i].getValues());
      }
      return budgetItems;
    }
  };

  _i.buildBody = function () {
    if (_i.project == null) return;
    _i.budgetsgroups = [];
    _i.budgetsgroups.push(
      ProjectBudgetGroup(
        _i.budgetscontainer,
        _i,
        _i.project,
        !_i.selectionProjectBudget,
        _i.item_type,
        _i.currency,
        _i.scale
      )
    );
    if (!_i.selectionProjectBudget) {
      for (var i = 0; i < _i.project.children.length; i++) {
        _i.budgetsgroups.push(
          ProjectBudgetGroup(
            _i.budgetscontainer,
            _i,
            _i.project.children[i],
            !_i.selectionProjectBudget,
            _i.item_type,
            _i.currency,
            _i.scale
          )
        );
      }
    }
  };

  _i.initBudget = function (proj) {
    if (proj.budgetitems == null) proj.budgetitems = [];
    if (proj.budgetitems[_i.item_type] == null)
      proj.budgetitems[_i.item_type] = [];

    _i.budget.forEach(function (item) {
      if (
        item.assignable_id == proj.id &&
        item.item_type == _i.item_type &&
        proj.id != null
      ) {
        if (item.category != null || item.amount != 0)
          proj.budgetitems[item_type].push(item);
      }
    });
    if (proj.children)
      proj.children.forEach(function (child) {
        _i.initBudget(child);
      });
  };

  _i.buildData = function () {
    _i.project = _i.parent.projectData;

    if (
      _i.project.budgetitems == null ||
      _i.project.budgetitems[_i.item_type] == null
    ) {
      _i.initBudget(_i.project);
    }
  };

  _i.buildFooter = function () {
    var total = _i.getTotal() / _i.scale;

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage4';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.budgetscontainer.appendChild(spacer);

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.budgetscontainer.appendChild(spacer);

    var totalval = document.createElement('DIV');
    totalval.className = 'blockFloatNot settingsBillRateUserContainer';
    _i.budgetscontainer.appendChild(totalval);

    var name = document.createElement('DIV');
    name.className = 'blockFloat fnt-r-14 expenseTotalLabel';
    totalval.appendChild(name);
    name.innerHTML = I18n.t('lbl_total_' + _i.budgetstring);
    _i.budgetTotal = document.createElement('H3');
    _i.budgetTotal.className =
      'blockFloat fnt-b-14 editprojectPhaseTotal editProjectHeaders';
    _i.budgetTotal.innerText = _i.formatCurrency(total);
    totalval.appendChild(_i.budgetTotal);
  };

  _i.buildBudget = function () {
    _i.buildData();
  };

  _i.setBudgetStatus = function () {
    _i.hasProjectBudget = false;
    _i.selectionProjectBudget = true;
    if (
      _i.project.budgetitems &&
      _i.project.budgetitems[_i.item_type] &&
      _i.project.budgetitems[_i.item_type].length > 0
    ) {
      _i.budgetscontainer.style.display = 'block';
      _i.hasProjectBudget = true;
    }
    for (var i = 0; i < _i.project.children.length; i++) {
      if (_i.project.children[i].budgetitems[_i.item_type].length > 0) {
        _i.budgetscontainer.style.display = 'block';
        _i.hasProjectBudget = true;
        _i.selectionProjectBudget = false;
      }
    }
    _i.buildHeader();
    _i.reset();
  };

  var ctor = (function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot expensesControlContentContainer';
    _i.container.setAttribute(
      'data-client-id',
      _i.item_type + 'BudgetControlContainer'
    );
    _i.attachTo.appendChild(_i.container);

    var label = document.createElement('H3');
    label.className =
      'blockFloatNot fnt-b-14 expensesControlHeaderContainer editProjectHeaders';
    label.innerHTML = I18n.t('lbl_' + _i.budgetstring);
    _i.container.appendChild(label);

    var label = document.createElement('LABEL');
    label.className = 'blockFloatNot fnt-r-14 expenseHeaderLabel';
    label.innerHTML = I18n.t('lbl_' + _i.budgetstring + '_desc');
    _i.container.appendChild(label);

    _i.headercontainer = document.createElement('DIV');
    _i.headercontainer.className = 'blockFloatNot';
    _i.container.appendChild(_i.headercontainer);

    _i.budgetscontainer = document.createElement('DIV');
    _i.budgetscontainer.className =
      'blockFloatNot expensesControlItemContainer';
    _i.container.appendChild(_i.budgetscontainer);
    _i.buildBudget();
    _i.setBudgetStatus();
  })();
}
