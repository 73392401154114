function alertPopup(prompt, parentPopupContainer) {
  if (!(this instanceof alertPopup)) {
    return new alertPopup(prompt, parentPopupContainer);
  }

  var WIDTH = 260;
  var HEIGHT = 100;
  var MESSAGE_AREA_PADDING = 10;
  var MESSAGE_AREA_HEIGHT = 60;

  var _i = this;

  _i.popupContainer;

  function confirmedCbInternal() {
    _i.popupContainer.hide();
  }

  _i.show = function (attachToElement) {
    _i.popupContainer.pointAtElement(attachToElement);
  };

  _i.showAtPoint = function (x, y) {
    _i.popupContainer.pointAt(x, y, 0, 0);
  };

  function init() {
    var spec = parentPopupContainer
      ? compSpritesButtonsIcons.wideDialogSpecsGray
      : compSpritesButtonsIcons.wideDialogSpecs;

    var viewport = document.getElementById('mainCon');

    var content = document.createElement('DIV');
    content.style.width = WIDTH + 'px';
    content.style.height = HEIGHT + 'px';

    var message = document.createElement('DIV');
    message.className = 'blockFloatNot fnt-r-14';
    message.style.width = WIDTH - MESSAGE_AREA_PADDING * 2 + 'px';
    message.style.height =
      MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 2 + 'px';
    message.style.padding = MESSAGE_AREA_PADDING + 'px';
    message.innerText = prompt;

    content.appendChild(message);

    var buttons = document.createElement('DIV');
    buttons.className = 'blockFloatNot';
    buttons.style.width = 'auto';
    buttons.style.height =
      HEIGHT - MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 3 + 'px';
    buttons.style.paddingLeft =
      buttons.style.paddingRight =
      buttons.style.paddingBottom =
      buttons.style.paddingTop =
        MESSAGE_AREA_PADDING + 'px';
    buttons.style.float = 'right';

    content.appendChild(buttons);

    var confirmButton = new primaryButton('confirmationPopupYesButton');
    confirmButton.setButtonText(I18n.t('lbl_ok_up'));
    confirmButton.setCallback(confirmedCbInternal);

    buttons.appendChild(confirmButton.container);

    _i.popupContainer = new popupContainer(
      spec,
      WIDTH,
      HEIGHT,
      3, // innerMargin
      true, // autorotate
      'wc',
      viewport,
      ['wc', 'ec'],
      null, // blurCallback
      parentPopupContainer
    );

    _i.popupContainer.attachContent(content);
  }

  init();
}
