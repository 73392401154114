function SettingsCategoryValue(attach, tag, parent) {
  var _i = this;
  if (!(this instanceof SettingsCategoryValue)) {
    return new SettingsCategoryValue(attach, tag, parent);
  }

  _i.attach = attach;
  _i.tag = tag;
  _i.parent = parent;
  _i.mode = 'read';

  _i.tagChanged = function () {
    _i.parent.refreshList();
  };

  _i.deleteEdit = function () {
    var confirmDelete = confirmationPopup(
      I18n.t('lbl_delete') + ' ' + _i.tag.value + '?',
      I18n.t('lbl_delete'),
      function () {
        gService.removeTagFromNamespace(
          _i.tag,
          _i.tagChanged,
          _i.errorPopup,
          _i.parent.namespaceval
        );
      },
      null,
      _i.parent.container
    );
    confirmDelete.show(_i.deleteButton.container);
  };

  _i.errorPopup = function (errorText) {
    new notificationPopup(I18n.t('lbl_cannot_delete'), errorText, 8000);
  };

  _i.cancelEdit = function () {
    if (_i.mode == 'edit') {
      _i.listMode();
    }
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
  };

  _i.numDuplicates = function (proposedValue) {
    var arr = gData.tagsByNameSpace[_i.tag.namespace];

    if (!arr) {
      return 0;
    }

    var dupes = 0;

    for (var i = 0; i < arr.length; i++) {
      if (
        arr[i].value.toLowerCase().trim() == proposedValue.toLowerCase().trim()
      ) {
        dupes++;
      }
    }

    return dupes++;
  };

  _i.saveEdit = function () {
    var proposedValue = singleSpace(trim(_i.tagvalue.getValue()));

    if (proposedValue == '') {
      return;
    }

    if (
      _i.numDuplicates(proposedValue) > 0 &&
      _i.tagvalue.getValue() != _i.tagvalue.startingValue
    ) {
      _i.tagvalue.setAlert(I18n.t('msg_duplicate_value'));
      return;
    }

    var data = {};
    data.id = _i.tag.id;
    data.namespace = _i.tag.namespace;
    data.name = proposedValue;
    data.value = proposedValue;
    gService.updateCategoryTag(data, _i.tagChanged);
  };

  _i.containerMouseover = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainerHover';
    }
  };

  _i.containerMouseout = function (evt) {
    if (_i.mode != 'edit') {
      cancelEvent(evt);
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    }
  };

  _i.containerClick = function (evt) {
    if (_i.mode != 'edit') {
      _i.parent.editModeCallback();
      _i.editMode();
      _i.valuecontainer.className = 'blockFloatNot settingsUserContainerEdit';
    }
  };

  _i.editMode = function () {
    removeAllChildren(_i.valuecontainer);
    _i.tagvalue = new TextInput(
      _i.valuecontainer.id,
      null,
      _i.tag.value != '' ? _i.tag.value : '- empty -',
      'blockFloat',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall'
    );

    _i.tagvalue.startingValue = _i.tagvalue.getValue();

    _i.deleteButton = new destructiveButton('settingsSecondaryButtonCategory');
    _i.deleteButton.setButtonText(I18n.t('lbl_delete'));
    _i.deleteButton.setCallback(_i.deleteEdit);
    _i.valuecontainer.appendChild(_i.deleteButton.container);
    _i.deleteButton.container.tabIndex = 0;

    _i.saveButton = new primaryButton('settingsSecondaryButtonCategory');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveEdit);
    _i.valuecontainer.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;

    _i.cancelButton = new spritesButton(
      'settingsCancelButtonCategory',
      null,
      compSpritesButtonsIcons.closeButtonRest,
      compSpritesButtonsIcons.closeButtonHover,
      compSpritesButtonsIcons.closeButtonDown
    );
    _i.cancelButton.setButtonText('');
    _i.cancelButton.setCallback(_i.cancelEdit);
    _i.valuecontainer.appendChild(_i.cancelButton.container);

    _i.mode = 'edit';
  };

  _i.listMode = function () {
    removeAllChildren(_i.valuecontainer);
    var name = document.createElement('DIV');
    name.className = 'blockFloat fnt-r-14 ellipsis settingsCategoryName';
    _i.valuecontainer.appendChild(name);
    name.innerText = _i.tag.value != '' ? _i.tag.value : '- empty -';

    _i.mode = 'list';
  };

  _i.constructor = function () {
    _i.valuecontainer = document.createElement('DIV');
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    _i.valuecontainer.id = 'settingsUserContainer' + getGuid();
    _i.attach.appendChild(_i.valuecontainer);
    xAddEventListener(
      _i.valuecontainer,
      'mouseover',
      _i.containerMouseover,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      'mouseout',
      _i.containerMouseout,
      true
    );
    xAddEventListener(
      _i.valuecontainer,
      platform.click,
      _i.containerClick,
      true
    );
    _i.listMode();
  };
  _i.constructor();
}
