function editField(parentObj, attachTo, ind, name, value, type) {
  var _i = this;

  // params
  _i.parentObj = parentObj;
  _i.attachTo = xGetElementById(attachTo);
  _i.ind = ind;
  _i.name = name;
  _i.value = value;
  _i.type = type;

  _i.constructor = function () {
    _i.cont = document.createElement('DIV');
    _i.cont.className = 'blockFloatNot';
    _i.cont.style.width = '100%';
    _i.cont.style.height = 'auto';
    _i.cont.style.marginTop = '4px';
    _i.cont.style.marginBottom = '4px';

    // TODO:
    _i.label = document.createElement('DIV');
    _i.label.className = 'blockFloatNot ffHelveticaNeueLTPro55Roman';
    _i.label.style.color = '#000';
    _i.label.style.width = '200px';
    _i.label.innerHTML = name;

    _i.inputCont = document.createElement('DIV');
    _i.inputCont.className = 'blockFloatNot';
    _i.inputCont.style.width = '300px';

    _i.attachTo.appendChild(_i.cont);
    _i.cont.appendChild(_i.label);
    _i.cont.appendChild(_i.inputCont);

    if (type == 'date') {
      _i.input = document.createElement('DIV');
      _i.input.id = getGuid();
      _i.input.className = 'ffHelveticaNeueLTPro55Roman';
      _i.input.style.width = '150px';
      _i.input.style.height = '22px';
      _i.input.style.border = '1px solid #777777';
      _i.input.style.lineHeight = '22px';
      _i.input.style.fontSize = '14px';
      _i.input.style.backgroundColor = '#ffffff';
      _i.initializing = true;
      _i.dateEntry = new popupCal(_i.input.id, function () {
        if (!_i.initializing) {
          _i.parentObj.reportFieldChanged(_i.ind, _i.input.innerHTML, _i.type);
        }
      });
      _i.inputCont.appendChild(_i.input);
      if (value) {
        _i.dateEntry.setDate(value, true);
      }
      _i.initializing = false;

      xAddEventListener(
        _i.input,
        platform.mouseDown,
        function (evt) {
          _i.dateEntry.show();
          cancelEvent(evt);
        },
        true
      );
    } else {
      _i.input = document.createElement('INPUT');
      if (type == 'password') {
        _i.input.type = 'password';
      }
      _i.input.value = value;
      _i.input.style.width = '300px';
      _i.inputCont.appendChild(_i.input);

      xAddEventListener(
        _i.input,
        'keyup',
        function () {
          _i.parentObj.reportFieldChanged(_i.ind, _i.input.value, _i.type);
        },
        true
      );
    }
  };

  _i.constructor();
}
