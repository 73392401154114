function SelectionCheckbox(
  attach,
  labelSpan,
  initial_value,
  container_style,
  label_style,
  icon_style,
  checkbox_enabled
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  if (_i.attachTo == null) _i.attachTo = attach;
  _i.labelSpan = labelSpan;
  _i.label_style = label_style;
  _i.initial_value = initial_value;
  _i.icon_style = icon_style;
  _i.container_style = container_style;
  _i.checkbox_enabled = checkbox_enabled;
  _i.callback = null;
  _i.guid = Awe.getGuid();
  _i.checked = 'unchecked';
  _i.alertData = [
    {
      type: 'label',
      value: null,
      label: 'label',
    },
  ];

  // ids
  _i.spanID = 'label-span' + _i.guid;

  _i.setEnabled = function (val) {
    while (_i.icon.childNodes.length >= 1) {
      _i.icon.removeChild(_i.icon.firstChild);
    }
    if (val) {
      _i.icon.appendChild(getSprite(compSpritesButtonsIcons.selectedCheckbox));
    } else {
      _i.icon.appendChild(
        getSprite(compSpritesButtonsIcons.unselectedCheckbox)
      );
    }
  };

  _i.setEnabledWithOpacity = function (enabled, setOpacity) {
    if (!enabled && setOpacity) {
      _i.icon.style.opacity = '30%';
      _i.labelSpan.style.opacity = '30%';
    } else {
      _i.icon.style.opacity = '100%';
      _i.labelSpan.style.opacity = '100%';
    }
    _i.setEnabled(enabled);
  };

  _i.onClick = function () {
    if (_i.callback) {
      _i.callback();
    }
    if (_i.checked === 'unchecked') {
      _i.icon.setAttribute('aria-checked', true);
      _i.checked = 'checked';
    } else {
      _i.icon.setAttribute('aria-checked', false);
      _i.checked = 'unchecked';
    }
  };

  _i.onKeypress = function (e) {
    if (typeof e == 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key == 'Enter' || e.key == ' ') {
      _i.onClick();
      return false;
    }
    return true;
  };

  _i.showAlert = function () {
    if (_i.alertMessage == null || _i.alertMessage == '') return;

    _i.alertPopup.show(_i.guid + 'alertmsgshow');
  };

  _i.buildAlertMessage = function () {
    _i.alertData[0].label = _i.alertMessage;
  };

  _i.setAlert = function (alertmsg) {
    _i.alertMessage = alertmsg;
    _i.buildAlertMessage();
    _i.alertPopup = new selectionPopup(
      250,
      40,
      'mainCon',
      _i.alertData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onAlertClick,
      false,
      null /*blurCallback*/,
      'nw',
      null,
      true
    );
    _i.showAlert();
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.getValue = function () {
    return _i.inputText.value;
  };

  _i.constructor = function () {
    _i.inputContainer = document.createElement('DIV');
    _i.inputContainer.className = _i.container_style;
    _i.attachTo.appendChild(_i.inputContainer);
    _i.icon = document.createElement('DIV');
    _i.icon.tabIndex = 0;
    _i.icon.className = 'blockFloat ' + _i.icon_style;
    if (_i.checkbox_enabled === false) {
      _i.icon.style.opacity = '30%';
      _i.labelSpan.style.opacity = '30%';
    }
    if (_i.initial_value) {
      _i.icon.appendChild(getSprite(compSpritesButtonsIcons.selectedCheckbox));
    } else {
      _i.icon.appendChild(
        getSprite(compSpritesButtonsIcons.unselectedCheckbox)
      );
    }

    _i.icon.id = _i.guid + 'alertmsgshow';

    _i.icon.setAttribute('aria-labelledby', _i.spanID);
    _i.icon.setAttribute('role', 'checkbox');
    _i.icon.setAttribute('aria-checked', false);
    xAddEventListener(_i.icon, platform.click, _i.onClick, false);
    xAddEventListener(_i.icon, 'keypress', _i.onKeypress, false);

    _i.inputContainer.appendChild(_i.icon);

    _i.labelText = document.createElement('LABEL');
    _i.labelText.id = 'checkbox-label' + _i.guid;
    _i.labelText.className = 'blockFloat ' + _i.label_style;
    _i.labelText.appendChild(_i.labelSpan);
    _i.labelSpan.id = _i.spanID;
    _i.inputContainer.appendChild(_i.labelText);
  };
  _i.constructor();
}
