function SettingsEditSsoSettings(attach, setting, parent) {
  var _i = this;
  if (!(this instanceof SettingsEditSsoSettings)) {
    return new SettingsEditSsoSettings(attach, setting, parent);
  }

  _i.attach = attach;
  _i.setting = setting;
  _i.parent = parent;
  _i.mode = 'none';
  _i.autoProvision = setting ? setting.autoProvision : false;
  _i.selectionAutomaticMode = null;
  _i.selectionManualMode = null;
  _i.setupDisplayMode = 'automatic';

  _i.buildPlainTextSpan = function (str, isRecommended) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-14 settingsSpan';
    ret.innerText = str;

    if (isRecommended) {
      var extText = document.createElement('SPAN');
      extText.className = 'fnt-r-14 settingsSpan ssoRecommendedModeText';
      extText.innerText = ' (Recommended)';
      ret.appendChild(extText);
    }

    return ret;
  };

  _i.cancelEdit = function () {
    $('.settingsHeader').text('SSO');
    _i.parent.refresh();
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
  };

  _i.autoconfigure = function () {
    var missingData = _i.metadataMissing();
    if (missingData) {
      _i.onError(`${I18n.t('err_missing_data')}: ` + missingData);
      return;
    }
    var data = {};
    data.id = _i.setting.id;
    data.idp_metadata_url = singleSpace(trim(_i.metadataUrlInput.getValue()));
    data.auto_configure = true;
    data.auto_provision = _i.autoProvision;

    if (data.id) {
      gService.updateSsoSettings(data, _i.settingsSaved, _i.onSaveError);
    } else {
      gService.createSsoSettings(data, _i.settingsSaved, _i.onSaveError);
    }
  };

  _i.settingsSaved = function () {
    $('.settingsHeader').text('SSO');
    _i.parent.refresh();
  };

  _i.saveEdit = function () {
    var missingData =
      _i.setupDisplayMode == 'manual' ? _i.dataMissing() : _i.metadataMissing();

    if (missingData) {
      _i.onError(`${I18n.t('err_missing_data')}: ` + missingData);
      return;
    }

    var data = {};
    data.id = _i.setting.id;

    if (_i.setupDisplayMode == 'manual') {
      data.idp_sso_target_url = singleSpace(
        trim(_i.ssoTargetUrlInput.getValue())
      );
      data.idp_slo_target_url = singleSpace(
        trim(_i.sloTargetUrlInput.getValue())
      );

      if (_i.base64EncodedCertificate) {
        data.base_64_idp_cert = _i.base64EncodedCertificate;
      }
    } else {
      data.use_metadata_url = true;
      data.idp_metadata_url = singleSpace(trim(_i.metadataUrlInput.getValue()));
    }

    data.auto_provision = _i.autoProvision;

    if (data.id) {
      gService.updateSsoSettings(data, _i.settingsSaved, _i.onSaveError);
    } else {
      gService.createSsoSettings(
        data,
        function () {
          window.clientEventLogger &&
            window.clientEventLogger.push({
              eventTimestamp: Date.now(),
              eventData: {
                eventType: 'Clicked',
                featureArea: 'RM Authentication',
                description:
                  'A user has clicked to confirm enabling SSO configuration for their organization',
                objectName: 'Save',
                objectType: 'Button',
                viewName: 'Account Settings',
              },
            });
          _i.settingsSaved();
        },
        _i.onSaveError
      );
    }
  };

  _i.isNewSetting = function () {
    // Determines if a new setting is being created as opposed to editing existing settings.
    return _i.setting.id == null || typeof _i.setting.id === undefined;
  };

  _i.metadataMissing = function () {
    // metadata url always required.
    if (!_i.metadataUrlInput.getValue()) {
      return 'metadata URL';
    }
  };

  _i.dataMissing = function () {
    // Sign-in, sign-out URLs always required.
    if (!_i.ssoTargetUrlInput.getValue()) {
      return 'sign-in URL';
    }
    if (!_i.sloTargetUrlInput.getValue()) {
      return 'sign-out URL';
    }

    // Cert required on create, on update, existing cert is not shown but will be reused. So it's OK if it's not there.
    if (_i.isNewSetting() && !_i.base64EncodedCertificate) {
      return 'certificate';
    }
    return null;
  };

  _i.onSaveError = function (jqXHR, textStatus, errorThrown) {
    var popupMessage = 'Save failed: ' + jqXHR.responseText;
    _i.onError(popupMessage);
  };

  _i.onError = function (message) {
    new notificationPopup(I18n.t('lbl_error'), message, 6000);
  };

  _i.onCertificateUploaded = function (status, file) {
    if (status) {
      _i.base64EncodedCertificate = file;
      new notificationPopup(
        I18n.t('msg_upload_succeeded'),
        I18n.t('msg_certificate_upload_complete'),
        4000
      );
    } else {
      new notificationPopup(
        I18n.t('lbl_error'),
        I18n.t('msg_certificate_upload_failed'),
        6000
      );
    }
  };

  _i.initDisplayMode = function () {
    _i.setupDisplayMode =
      _i.setting.id && _i.setting.idp_metadata_url ? 'automatic' : 'manual';
    if (_i.setupDisplayMode == 'manual' && !_i.setting.idp_slo_target_url)
      _i.setupDisplayMode = 'automatic';
  };

  _i.editMode = function () {
    var MARGIN = '10px',
      FULL_WIDTH = '560px';
    _i.mode = 'edit';

    _i.initDisplayMode();

    $('.settingsHeader').text('Setup SSO');
    removeAllChildren(_i.valuecontainer);

    _i.valuecontainer.className = 'blockFloatNot customFieldsEditContainer';
    _i.valuecontainer.id = 'ssoConfigurationControl';
    _i.label = document.createElement('H3');
    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';

    _i.label.innerHTML = I18n.t('msg_autoprovision_description');
    _i.label.innerHTML +=
      ' <a target="_blank" href="https://help.smartsheet.com/articles/2480846">Learn more</a>';

    _i.label.style.marginTop = MARGIN;
    _i.label.style.marginBottom = '30px';
    _i.valuecontainer.appendChild(_i.label);

    _i.ssoSetupAutomaticContainer = document.createElement('DIV');
    _i.ssoSetupAutomaticContainer.className =
      'blockFloatNot ssoSetupAutomaticContainer';
    _i.ssoSetupAutomaticContainer.id = 'ssoSetupAutomaticContainer';

    _i.ssoSetupManualContainer = document.createElement('DIV');
    _i.ssoSetupManualContainer.className =
      'blockFloatNot ssoSetupManualContainer';
    _i.ssoSetupManualContainer.id = 'ssoSetupManualContainer';

    _i.selectionAutomaticMode = new SelectionToggle(
      _i.valuecontainer,
      _i.buildPlainTextSpan(I18n.t('lbl_automatic_configuration'), true),
      _i.setupDisplayMode == 'automatic',
      'blockFloatNot settingsSelectorItem ssoSetupModeSelection',
      'fnt-r-15 settingsSelectorText ',
      'settingsSelectorIcon'
    );

    _i.selectionAutomaticMode.setCallback(
      _i.displayAutomaticMode.bind(this, true)
    );

    _i.valuecontainer.appendChild(_i.ssoSetupAutomaticContainer);

    _i.selectionManualMode = new SelectionToggle(
      _i.valuecontainer,
      _i.buildPlainTextSpan(I18n.t('lbl_manual_configuration')),
      _i.setupDisplayMode == 'manual',
      'blockFloatNot settingsSelectorItem ssoSetupModeSelection',
      'fnt-r-15 settingsSelectorText ',
      'settingsSelectorIcon'
    );

    _i.selectionManualMode.setCallback(_i.displayManualMode.bind(this, true));

    _i.valuecontainer.appendChild(_i.ssoSetupManualContainer);

    _i.displayAutoProvisionCheckbox();

    var spacer = document.createElement('DIV');
    spacer.style.marginTop = MARGIN;
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.valuecontainer.appendChild(spacer);

    var buttonsContainer = document.createElement('DIV');
    buttonsContainer.style.marginTop = MARGIN;
    buttonsContainer.style.width = FULL_WIDTH;
    buttonsContainer.style.float = 'left';

    _i.cancelButton = new secondaryButton('settingsSecondaryButtonCategory');
    _i.cancelButton.setButtonText(I18n.t('lbl_cancel'));
    _i.cancelButton.setCallback(_i.cancelEdit);
    _i.cancelButton.container.style.float = 'right';
    _i.cancelButton.container.style.margin = 0;
    _i.cancelButton.container.tabIndex = 0;

    _i.saveButton = new primaryButton('settingsPrimaryButtonCategory');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveEdit);
    _i.saveButton.container.style.float = 'right';
    _i.saveButton.container.style.margin = '0 0 0 ' + MARGIN;
    _i.saveButton.container.tabIndex = 0;

    buttonsContainer.appendChild(_i.saveButton.container);
    buttonsContainer.appendChild(_i.cancelButton.container);
    _i.valuecontainer.appendChild(buttonsContainer);

    _i.displayManualMode(false);
    _i.displayAutomaticMode(false);
    _i.enableDisableModes();
  };

  // Only shows values, no edit support.
  _i.displayMode = function () {
    _i.mode = 'display';

    $('.settingsHeader').text('SSO');
    removeAllChildren(_i.valuecontainer);

    _i.valuecontainer.className = 'blockFloatNot customFieldsEditContainer';

    _i.initDisplayMode();

    if (_i.setupDisplayMode == 'automatic')
      _i.displaySettingLabelAndValue(
        I18n.t('lbl_idp_metadata_url'),
        _i.setting.idp_metadata_url || ''
      );
    else {
      _i.displaySettingLabelAndValue(
        I18n.t('lbl_sso_target_signin_url'),
        _i.setting.idp_sso_target_url || ''
      );
      _i.displaySettingLabelAndValue(
        I18n.t('lbl_sso_target_signout_url'),
        _i.setting.idp_slo_target_url || ''
      );
      _i.displayCertificateDetails();
    }
    var autoProvisionText = _i.setting.auto_provision
      ? I18n.t('lbl_activated_with_email')
      : I18n.t('lbl_deactivated');
    _i.displaySettingLabelAndValue(
      I18n.t('lbl_user_auto_provision'),
      autoProvisionText
    );
  };

  _i.displaySettingLabelAndValue = function (labelText, valueText) {
    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-b-14 settingsSsoSettingLabel';
    label.innerText = labelText;
    _i.valuecontainer.appendChild(label);

    var text = document.createElement('DIV');
    text.className = 'blockFloatNot fnt-r-14 settingsSsoSettingValue';
    text.innerText = valueText;
    _i.valuecontainer.appendChild(text);
  };

  _i.enableDisableModes = function () {
    if (_i.setupDisplayMode == 'automatic') {
      _i.selectionManualMode.setEnabled(false);
      _i.selectionAutomaticMode.setEnabled(true);
      _i.ssoTargetUrlInput.inputText.disabled = true;
      _i.sloTargetUrlInput.inputText.disabled = true;
      _i.certificateUpload.editCertificateButton.setDisabled();
      _i.metadataUrlInput.inputText.disabled = false;
    } else {
      _i.selectionManualMode.setEnabled(true);
      _i.selectionAutomaticMode.setEnabled(false);

      _i.ssoTargetUrlInput.inputText.disabled = false;
      _i.sloTargetUrlInput.inputText.disabled = false;
      _i.certificateUpload.editCertificateButton.setEnabled(
        compSpritesButtonsIcons.secondaryButtonRest
      );
      _i.metadataUrlInput.inputText.disabled = true;
    }
  };

  _i.displayAutomaticMode = function (setDisplayMode) {
    removeAllChildren(_i.ssoSetupAutomaticContainer);

    var MARGIN = '10px',
      FULL_WIDTH = '560px';

    _i.metadataUrlInput = new TextInput(
      'ssoSetupAutomaticContainer',
      I18n.t('lbl_idp_metadata_url'),
      _i.setting.idp_metadata_url || '',
      ' ',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputLarge'
    );

    _i.metadataUrlInput.labelText.style.marginTop = MARGIN;
    _i.metadataUrlInput.labelText.style.marginBottom = MARGIN;
    _i.metadataUrlInput.startingValue = _i.metadataUrlInput.getValue();

    if (setDisplayMode) {
      _i.setupDisplayMode = 'automatic';
      _i.enableDisableModes();
    }
  };

  _i.displayManualMode = function (setDisplayMode) {
    removeAllChildren(_i.ssoSetupManualContainer);

    var MARGIN = '10px',
      FULL_WIDTH = '560px';

    _i.ssoTargetUrlInput = new TextInput(
      'ssoSetupManualContainer',
      I18n.t('lbl_sso_target_signin_url'),
      _i.setting.idp_sso_target_url || '',
      ' ',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputLarge'
    );

    _i.ssoTargetUrlInput.labelText.style.marginTop = MARGIN;
    _i.ssoTargetUrlInput.startingValue = _i.ssoTargetUrlInput.getValue();

    _i.sloTargetUrlInput = new TextInput(
      'ssoSetupManualContainer',
      I18n.t('lbl_sso_target_signout_url'),
      _i.setting.idp_slo_target_url || '',
      ' ',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputLarge'
    );

    _i.sloTargetUrlInput.labelText.style.marginTop = MARGIN;
    _i.sloTargetUrlInput.startingValue = _i.sloTargetUrlInput.getValue();

    var fileUploadContainer = document.createElement('DIV');
    fileUploadContainer.style.marginBottom = MARGIN;
    fileUploadContainer.style.width = FULL_WIDTH;
    fileUploadContainer.style.float = 'left';
    _i.ssoSetupManualContainer.appendChild(fileUploadContainer);
    _i.certificateUpload = new certificateUpload(
      fileUploadContainer,
      _i.onCertificateUploaded,
      !_i.isNewSetting()
    );

    if (setDisplayMode) {
      _i.setupDisplayMode = 'manual';
      _i.enableDisableModes();
    }
  };

  _i.displayAutoProvisionCheckbox = function () {
    _i.autoProvision = _i.setting.id ? _i.setting.auto_provision : false;

    var labelSpan = document.createElement('span');
    labelSpan.innerHTML = I18n.t('lbl_auto_provision_with_email');
    _i.autoProvisionCheckbox = new SelectionCheckbox(
      _i.valuecontainer.id,
      labelSpan,
      _i.autoProvision,
      'blockFloatNot',
      'fnt-r-14 settingsPrefTag',
      'fnt-r-14 settingsPrefCheckbox'
    );

    _i.autoProvisionCheckbox.setCallback(function () {
      _i.autoProvision = !_i.autoProvision;
      _i.autoProvisionCheckbox.setEnabled(_i.autoProvision);
    });
  };

  _i.displayCertificateDetails = function () {
    _i.certDetailsHeader = document.createElement('DIV');
    _i.certDetailsHeader.className =
      'blockFloatNot fnt-r-14 settingsSsoHeaderLabel';
    _i.certDetailsHeader.innerHTML = I18n.t('lbl_certificate_details_html');
    _i.valuecontainer.appendChild(_i.certDetailsHeader);

    _i.displaySettingLabelAndValue(
      I18n.t('lbl_subject'),
      _i.setting.x509_cert.subject
    );
    _i.displaySettingLabelAndValue(
      I18n.t('lbl_issuer'),
      _i.setting.x509_cert.issuer
    );
    _i.displaySettingLabelAndValue(
      I18n.t('lbl_not_before'),
      _i.setting.x509_cert.not_before
    );
    _i.displaySettingLabelAndValue(
      I18n.t('lbl_not_after'),
      _i.setting.x509_cert.not_after
    );
  };

  _i.constructor = function () {
    _i.valuecontainer = document.createElement('DIV');
    _i.valuecontainer.className = 'blockFloatNot settingsUserContainer';
    _i.valuecontainer.id = 'settingsUserContainer' + getGuid();
    _i.attach.appendChild(_i.valuecontainer);
  };

  _i.constructor();
}
