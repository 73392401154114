// Creates and returns a DIV with the specified sprite
// centered vertically and horizontally
// Example: var myCenteredSprite = centeredSprite( mySpriteDefinitions.someSpriteDef )

function centeredSprite(spriteDef) {
  var outer = document.createElement('DIV');
  var inner = document.createElement('DIV');
  var center = document.createElement('DIV');

  outer.style.position = 'absolute';
  outer.style.display = 'block';
  outer.style.width = '100%';
  outer.style.height = '100%';
  outer.style.left = '0px';
  outer.style.top = '0px';

  center.style.position = 'absolute';
  center.style.display = 'block';
  center.style.width = '1px';
  center.style.height = '1px';
  center.style.left = '50%';
  center.style.top = '50%';

  inner.style.position = 'absolute';
  inner.style.display = 'block';
  inner.style.left = -Math.round(spriteDef.width / 2) + 'px';
  inner.style.top = -Math.round(spriteDef.height / 2) + 'px';
  inner.style.width = spriteDef.width + 'px';
  inner.style.height = spriteDef.height + 'px';
  inner.style.backgroundImage = 'url(' + spriteDef.backgroundImage + ')';
  inner.style.backgroundPosition =
    spriteDef.xOffset + 'px ' + spriteDef.yOffset + 'px';

  if (spriteDef.backgroundRepeat) {
    inner.style.backgroundRepeat = spriteDef.backgroundRepeat;
  } else {
    inner.style.backgroundRepeat = 'no-repeat';
  }

  outer.appendChild(center);
  center.appendChild(inner);

  return outer;
}

// Creates and returns a DIV with the specified sprite
// If optionalContainer is passed, the sprite is applied to the passed DIV
// instead of creating a new one.
// Example:  var myDiv = getSprite( componentSprites.unselectedCheckbox

function getSprite(spriteDef, optionalContainer) {
  var dv = optionalContainer || document.createElement('DIV');

  dv.style.width = spriteDef.width + 'px';
  dv.style.height = spriteDef.height + 'px';
  dv.style.backgroundImage = 'url(' + spriteDef.backgroundImage + ')';
  dv.style.backgroundPosition =
    spriteDef.xOffset + 'px ' + spriteDef.yOffset + 'px';

  if (spriteDef.backgroundRepeat) {
    dv.style.backgroundRepeat = spriteDef.backgroundRepeat;
  } else {
    dv.style.backgroundRepeat = 'no-repeat';
  }

  return dv;
}

function getSpriteHTMLStyleAttributes(spriteDef, containerType) {
  return (
    'width: ' +
    spriteDef.width +
    'px; height: ' +
    spriteDef.height +
    'px; background-image: url(' +
    spriteDef.backgroundImage +
    '); background-position: ' +
    spriteDef.xOffset +
    'px ' +
    spriteDef.yOffset +
    'px; background-repeat: ' +
    (spriteDef.backgroundRepeat || 'no-repeat') +
    ';'
  );
}

// apply sprite to an existing sprite
// for use in mouseover states, for example
function applySprite(container, spriteDef) {
  container.style.backgroundPosition =
    spriteDef.xOffset + 'px ' + spriteDef.yOffset + 'px';
}
