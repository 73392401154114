var sampleStaticListDataObject = {
  label: 'View',
  items: [
    {
      label: 'Grid View',
      value: 'Grid View',
      spriteName: 'thumbView',
      selected: true,
    },
    {
      label: 'List View',
      value: 'List View',
      spriteName: 'listView',
      selected: false,
    },
  ],
};

function staticListItem(parentObject, parentNode, index, dat, callback) {
  var _i = this;

  // params
  _i.parentObject = parentObject;
  _i.parentNode = parentNode;
  _i.index = index;
  _i.dat = dat;
  _i.callback = callback;

  // locals
  _i.container = document.createElement('DIV');
  _i.icon = getSprite(compSpritesButtonsIcons[_i.dat.spriteName]);
  _i.label = document.createElement('DIV');

  _i.update = function () {
    if (dat.selected == true) {
      removeClass(_i.container, 'fnt-r-14');
      addClass(_i.container, 'fnt-b-14');
    } else {
      removeClass(_i.container, 'fnt-b-14');
      addClass(_i.container, 'fnt-r-14');
    }
  };

  _i.containerClick = function () {
    _i.callback(_i.index);
  };

  _i.init = (function () {
    _i.container.className = 'blockFloatNot fnt-r-14 staticListItem';
    _i.container.style.width = '100%';
    _i.container.style.height = '30px';
    _i.container.style.marginLeft = '-10px';
    _i.container.style.cursor = 'pointer';

    _i.icon.className = 'blockFloat';
    _i.icon.style.marginLeft = '10px';
    _i.icon.style.marginTop = '6px';

    _i.label.className = 'blockFloat';
    _i.label.style.marginLeft = '6px';
    _i.label.style.marginTop = '6px';
    _i.label.style.lineHeight = '16px';
    _i.label.innerText = dat.label;

    _i.parentNode.appendChild(_i.container);
    _i.container.appendChild(_i.icon);
    _i.container.appendChild(_i.label);

    xAddEventListener(_i.container, 'click', _i.containerClick, false);
  })();
}

function staticList(parentObject, parentNode, dataObject, callback) {
  var _i = this;

  // params
  _i.parentObject = parentObject;
  _i.parentNode = parentNode;
  _i.dataObject = dataObject;
  _i.callback = callback;

  // locals
  _i.container = document.createElement('DIV');
  _i.label = document.createElement('DIV');
  _i.items = [];

  _i.update = function () {
    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].update();
    }
  };

  _i.itemClick = function (index) {
    // already selected
    if (_i.items[index].dat.selected) {
      return;
    }

    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].dat.selected = false;
    }

    _i.items[index].dat.selected = true;

    _i.update();

    _i.callback(index);
  };

  _i.init = (function () {
    _i.container.className = 'blockFloatNot fnt-r-14';
    _i.container.style.width = '200px';
    _i.container.style.height = 'auto';
    _i.container.style.marginBottom = '6px';

    _i.label.className = 'blockFloatNot';
    _i.label.style.color = '#878787';
    _i.label.style.width = '100%';
    _i.label.style.height = '18px';
    _i.label.style.marginLeft = '0px';
    _i.label.innerText = _i.dataObject.label;

    _i.parentNode.appendChild(_i.container);
    _i.container.appendChild(_i.label);

    for (var z = 0; z < _i.dataObject.items.length; z++) {
      _i.items[_i.items.length] = new staticListItem(
        _i,
        _i.container,
        _i.items.length,
        _i.dataObject.items[z],
        _i.itemClick
      );
    }

    _i.update();
  })();
}
