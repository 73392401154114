// This method mixes in the new activity feed interfaces to render the new feed.
function ActivityFeedMixin(_i) {
  _i.followingProject = false;
  _i.projectNotificationsEnabled = false;

  var events = {
    $followSwitchToggled: function () {
      var toggled = $(this).is(':checked');
      if (toggled) {
        updateProjectFollowPopupText(true);
        gService.followProjectById(
          _i.project.id,
          function () {},
          function (e) {
            new notificationPopup(
              'Sorry, something unexpected happened. If this continues to be a problem, please create a support ticket at ' +
                window.SMARTSHEET_SUPPORT_URL +
                '. Thank you.',
              'Failed to follow project',
              10000
            );
          }
        );
      } else {
        updateProjectFollowPopupText(false);
        gService.unfollowProjectById(_i.project.id, function () {});
      }
    },
  };

  var _buildDisplay = _i.buildDisplay;
  var _constructor = _i.constructor;

  var handleFollowingResponse = function (data) {
    try {
      _i.followingProject = data && !$.isArray(data) && data.follower_id;
    } catch (e) {
      _i.followingProject = false;
    }
  };
  var handleSettingsResponse = function (data) {
    try {
      _i.projectNotificationsEnabled =
        data && !$.isArray(data) && data.notifications_enabled === true;
    } catch (e) {
      _i.projectNotificationsEnabled = false;
    }
  };
  var updateProjectFollowPopupText = function (isEnabled) {
    var enabledText = isEnabled ? 'enabled' : 'disabled';
    $('#following-popup-info').html(
      I18n.t('msg_follow_this_project_' + enabledText + '_html')
    );
    $('#following-popup-label').html(
      I18n.t('lbl_follow_this_project_' + enabledText)
    );
  };
  var buildProjectFollowControl = function () {
    var projectFollowControl = document.createElement('DIV');
    projectFollowControl.className = 'projectFollowControl';
    projectFollowControl.id = 'project-follow-control';
    projectFollowControl.innerHTML = I18n.t('lbl_follow');

    var projectFollowSwitch = buildProjectFollowSwitch();
    projectFollowControl.appendChild(projectFollowSwitch);

    var projectFollowPopup = buildProjectFollowPopup();
    projectFollowControl.appendChild(projectFollowPopup);

    return projectFollowControl;
  };
  var buildProjectMuteStatusControl = function () {
    var statusText = _i.projectNotificationsEnabled ? 'enabled' : 'disabled';
    var projectMuteStatusControl = document.createElement('DIV');
    projectMuteStatusControl.className = 'projectMuteStatusControl';
    projectMuteStatusControl.id = 'project-mute-status-control';

    var projectMuteStatusIcon = document.createElement('DIV');
    projectMuteStatusIcon.className = 'bell-icon-' + statusText;
    projectMuteStatusControl.appendChild(projectMuteStatusIcon);

    var projectMuteStatusPopup = buildProjectMuteStatusPopup();
    projectMuteStatusControl.appendChild(projectMuteStatusPopup);

    return projectMuteStatusControl;
  };
  var buildProjectFollowSwitch = function () {
    var checkedText = _i.followingProject ? 'checked' : '';
    var projectFollowSwitch = document.createElement('DIV');
    projectFollowSwitch.className = 'onoffswitch';
    projectFollowSwitch.innerHTML =
      '<input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="onoffswitch1" ' +
      checkedText +
      '><label class="onoffswitch-label" for="onoffswitch1"><span class="onoffswitch-inner"></span><span class="onoffswitch-switch"></span></label>';
    return projectFollowSwitch;
  };
  var buildProjectFollowPopup = function () {
    var statusText = _i.followingProject ? 'enabled' : 'disabled';
    var projectFollowPopup = document.createElement('DIV');
    projectFollowPopup.id = 'project-follow-popup';
    projectFollowPopup.className = 'projectFollowPopup';

    var popupLabel = document.createElement('SPAN');
    popupLabel.className = 'switch-info-container';
    popupLabel.innerHTML =
      "<h4 id='following-popup-label' class='popup-label'>" +
      I18n.t('activityview.projectfollow.' + statusText + '.label') +
      '</h4>';
    projectFollowPopup.appendChild(popupLabel);

    var popupContainer = document.createElement('DIV');
    popupContainer.className = 'switch-info-container';
    popupContainer.innerHTML =
      "<span id='following-popup-info' class='switch-info-text'></span>";
    projectFollowPopup.appendChild(popupContainer);

    return projectFollowPopup;
  };
  var buildProjectMuteStatusPopup = function () {
    var projectMuteStatusPopup = document.createElement('DIV');
    projectMuteStatusPopup.id = 'project-mute-status-popup';
    projectMuteStatusPopup.className = 'projectMuteStatusPopup';

    var popupLabel = document.createElement('SPAN');
    popupLabel.className = 'switch-info-container';
    popupLabel.innerHTML =
      "<h4 id='mute-status-popup-label' class='popup-label'>" +
      I18n.t('lbl_notifications_muted') +
      '</h4>';
    projectMuteStatusPopup.appendChild(popupLabel);

    var popupContainer = document.createElement('DIV');
    popupContainer.className = 'switch-info-container';
    popupContainer.innerHTML =
      "<span id='mute-status-popup-info' class='switch-info-text'></span>";
    projectMuteStatusPopup.appendChild(popupContainer);

    return projectMuteStatusPopup;
  };
  var loadNewActivityFeed = function () {
    if (_i.user) {
      var key = 'user:' + _i.user.id;
      _i.feed = new FeedController(key);
    } else {
      var key = 'project:' + _i.project.id;
      _i.feed = new FeedController(key);
    }
    _i.feed.polling(_i.loadFeedItems);
  };
  var renderNewActivityFeed = function () {
    var newFeedItems;
    if ($('#new-activity-feed').length > 0) {
      newFeedItems = $('#new-activity-feed')[0];
      // set the html of the feed's container element
      newFeedItems.innerHTML = _i.feed.getHTML();
    } else {
      newFeedItems = document.createElement('div');
      newFeedItems.id = 'new-activity-feed';
      // set the html of the feed's container element
      newFeedItems.innerHTML = _i.feed.getHTML();

      // append items to feed items container
      _i.feeditemscontainer.appendChild(newFeedItems);
    }
    // execute the <script> sections before rendering the feed
    exec_body_scripts(newFeedItems);
    // Appends the old feed below the new feed.
    _i.setOldFeed();
  };

  // Overrides _i.setOldFeed method.
  _i.setOldFeed = function (hasEmptyText) {
    if (hasEmptyText) return;
    // Contains the old feed dom elements
    var oldFeedContainer = document.createElement('div');
    oldFeedContainer.id = 'old-feed-container';
    oldFeedContainer.className = 'feed__old-feed-container';

    // Add separator + dialog
    var dialogContainer = document.createElement('div');
    dialogContainer.className = 'feed__old-feed-dialog-container';
    dialogContainer.id = 'old-feed-dialog-container';
    if (_i.showOldFeed == true) dialogContainer.className += ' hide-it';

    var details = document.createElement('div');
    details.className = 'feed__old-feed-details';
    details.innerHTML = I18n.t('msg_upgraded_notification_system');

    var btn = document.createElement('div');
    btn.id = 'old-feed-link';
    btn.className = 'feed__old-feed-link';
    btn.innerHTML = I18n.t('msg_show_previous_activity');
    btn.onclick = function (event) {
      $('#old-feed-content-container').show();
      $('#old-feed-dialog-container').hide();
      _i.showOldFeed = true;
    };
    dialogContainer.appendChild(details);
    dialogContainer.appendChild(btn);

    // Add old feed contents
    var contentContainer = document.createElement('div');
    contentContainer.className = 'feed__old-feed-content-container';
    contentContainer.id = 'old-feed-content-container';

    var hideDialog = document.createElement('div');
    hideDialog.className = 'feed__old-feed-hide-dialog';
    hideDialog.innerHTML = I18n.t('msg_hide_prvious_activity');
    hideDialog.onclick = function (event) {
      $('#old-feed-content-container').hide();
      $('#old-feed-dialog-container').show();
      _i.showOldFeed = false;
    };

    contentContainer.appendChild(hideDialog);

    if (!_i.showOldFeed)
      contentContainer.className = 'feed__old-feed-content-container hide-it';
    else contentContainer.className = 'feed__old-feed-content-container';

    if (_i.user) {
      contentContainer.appendChild(_i.nestedDiv);
    }
    _i.appendOldFeed(contentContainer);

    oldFeedContainer.appendChild(dialogContainer);
    oldFeedContainer.appendChild(contentContainer);
  };

  // Overrides _i.buildDisplay method.
  _i.buildDisplay = function () {
    _buildDisplay.apply(this, arguments);
    renderNewActivityFeed();
  };

  // Overrides _i.constructor method.
  _i.constructor = function () {
    _constructor.apply(this, arguments);
    loadNewActivityFeed();
  };

  var constructor = function () {
    if (_i.user) {
      var key = 'user:' + _i.user.id;
      _i.feed = new FeedController(key);
    } else {
      var key = 'project:' + _i.project.id;
      _i.feed = new FeedController(key);
      async.parallel(
        [
          function (cb) {
            gService.isFollowingProjectById(_i.project.id, cb);
          },
          function (cb) {
            gService.getProjectNotificationSettings(
              _i.project.id,
              function (settings) {
                cb(null, settings);
              }
            );
          },
        ],
        function (error, results) {
          results = results || [];
          handleFollowingResponse(results[0]);
          handleSettingsResponse(results[1]);

          // Project notification Mute status icon only appears when project has
          // disabled (aka muted) notifications for this project.
          if (!_i.projectNotificationsEnabled) {
            var projectMuteStatusControl = buildProjectMuteStatusControl();
            _i.header.appendChild(projectMuteStatusControl);
          }

          var projectFollowControl = buildProjectFollowControl();
          _i.header.appendChild(projectFollowControl);

          updateProjectFollowPopupText(_i.followingProject);
          $('#mute-status-popup-info').html(
            I18n.t('msg_notifications_muted_html')
          );
          // Update anchor's link to redirect to project's edit page.
          $('#project-settings-anchor').attr(
            'href',
            `${window.APP_ENDPOINT}/editproject?id=${_i.project.id}`
          );
          $('#onoffswitch1').change(events.$followSwitchToggled);
        }
      );
    }
  };

  constructor();
}
