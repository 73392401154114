function ActiveProjectsView_Data(dollars, projects, remainingProjects) {
  var _i = this,
    projectsNotAdded = 0,
    projectsAddedById = {},
    today = new Date().toStartOfDay();

  _i.projects = projects;
  _i.hiddenProjects = remainingProjects;

  _i.dollars = dollars;
  _i.projectDataViewArray = [];

  _i.max_totalbudgeted = 0;
  _i.max_totalcompleted = 0;
  _i.max_totalassigned = 0;

  _i.updateActiveProjects = function () {
    var projectsToAdd = [],
      project,
      budgetdollars = gData.getBudgetByItemType('TimeFees'),
      budgetdays = gData.getBudgetByItemType('TimeFeesDays'),
      addCurrentProject,
      viewData;

    /* This method may be invoked up to twice during the lifetime of this object. First, while we are
     * only displaying limited data, and again, when we have set it to display everything. Thus, the
     * current implementation logic progressively fills up the data in _i.projectDataViewArray
     * Additional invocations should have no effect because by then all projects would have already
     * been added to the projectsAddedById hash, and so they will be skipped.
     */

    for (var i = 0; i < _i.projects.length; i++) {
      viewData = {};
      viewData.project = _i.projects[i];

      var endsAt = new Date(_i.projects[i].ends_at);
      var startsAt = new Date(_i.projects[i].starts_at);
      viewData.percentagecalremain = Math.floor(
        (100 * (endsAt.getTime() - today.getTime())) /
          (endsAt.getTime() - startsAt.getTime())
      );
      if (viewData.percentagecalremain < 0) viewData.percentagecalremain = 0;
      if (viewData.percentagecalremain > 100.0)
        viewData.percentagecalremain = 100.0;

      viewData.percentagecalcomplete = 100 - viewData.percentagecalremain;

      _i.projectDataViewArray.push(viewData);
    }
  };
}
