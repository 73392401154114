function numberDay(parentObj, attachTo, gridSize, dayNum) {
  var _i = this;

  // params
  _i.parentObj = parentObj;
  _i.attachTo = xGetElementById(attachTo);
  _i.gridSize = gridSize;
  _i.dayNum = dayNum;

  // locals
  _i.cont = null;
  _i.selected = null;

  _i.select = function () {
    _i.selected = true;
    _i.cont.style.backgroundColor = '#808080';
    _i.cont.style.color = '#FFFFFF';
  };

  _i.unselect = function () {
    _i.selected = false;
    _i.cont.style.backgroundColor = '#FFFFFF';
    _i.cont.style.color = '#333333';
  };

  _i.contMouseover = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.selected == true) {
      return;
    }

    _i.cont.style.backgroundColor = '#DEE2E3';
  };

  _i.contMouseout = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.selected == true) {
      return;
    }

    _i.cont.style.backgroundColor = '#FFFFFF';
  };

  _i.contClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    _i.parentObj.reportDayClick(_i.dayNum);
  };

  _i.constructor = function () {
    _i.selected = false;

    _i.cont = document.createElement('DIV');
    _i.cont.className = 'blockFloat';
    _i.cont.style.width = _i.gridSize + 'px';
    _i.cont.style.height = _i.gridSize + 'px';
    _i.cont.style.backgroundColor = '#FFFFFF';
    _i.cont.style.textAlign = 'center';
    _i.cont.style.fontFamily = 'Arial';
    _i.cont.style.fontSize = '14px';
    _i.cont.style.fontWeight = 'bold';
    _i.cont.style.color = '#333333';
    _i.cont.style.cursor = 'pointer';
    _i.cont.style.lineHeight = _i.gridSize + 'px';
    _i.cont.innerText = _i.dayNum;
    _i.cont.onmouseover = _i.contMouseover;
    _i.cont.onmouseout = _i.contMouseout;

    xAddEventListener(_i.cont, platform.mouseDown, _i.contClick, false);

    _i.attachTo.appendChild(_i.cont);
  };

  _i.constructor();
}

function popupCal(container, onentryCallback) {
  var _i = this;

  // params
  _i.container = xGetElementById(container);
  _i.onentryCallback = onentryCallback;

  // locals
  _i.cont = null;
  _i.startDate = null;
  _i.items = [];

  // const
  _i.gridSize = 30;

  _i.dayNames = [
    I18n.t('lbl_sunday_abbr_uc'),
    I18n.t('lbl_monday_abbr_uc'),
    I18n.t('lbl_tuesday_abbr_uc'),
    I18n.t('lbl_wednesday_abbr_uc'),
    I18n.t('lbl_thursday_abbr_uc'),
    I18n.t('lbl_friday_abbr_uc'),
    I18n.t('lbl_saturday_abbr_uc'),
  ];

  _i.monthNames = [
    I18n.t('lbl_month_january'),
    I18n.t('lbl_month_february'),
    I18n.t('lbl_month_march'),
    I18n.t('lbl_month_april'),
    I18n.t('lbl_month_may'),
    I18n.t('lbl_month_june'),
    I18n.t('lbl_month_july'),
    I18n.t('lbl_month_august'),
    I18n.t('lbl_month_september'),
    I18n.t('lbl_month_october'),
    I18n.t('lbl_month_november'),
    I18n.t('lbl_month_december'),
  ];

  _i.clear = function () {
    _i.startDate = null;
    //_i.setDate( new Date() );
  };

  _i.reportDayClick = function (dayNum) {
    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].unselect();
    }

    for (var z = 0; z < _i.items.length; z++) {
      if (_i.items[z].dayNum == dayNum) {
        _i.items[z].select();
      }
    }

    _i.startDate.setDate(dayNum);
    _i.container = _i.container || xGetElementById(container);
    _i.container.innerText =
      _i.startDate.getMonth() +
      1 +
      '/' +
      _i.startDate.getDate() +
      '/' +
      _i.startDate.getFullYear();

    _i.hide();

    if (_i.onentryCallback) {
      _i.onentryCallback();
    }
  };

  _i.incYear = function () {
    var yr = _i.startDate.getFullYear() + 1;
    _i.startDate.setYear(yr);
  };

  _i.decYear = function () {
    var yr = _i.startDate.getFullYear() - 1;
    _i.startDate.setYear(yr);
  };

  _i.incMonth = function () {
    var mo = _i.startDate.getMonth() + 1;
    if (mo > 11) {
      mo = 0;
      _i.incYear();
    }

    _i.startDate.setMonth(mo);
    _i.setMonth();
  };

  _i.decMonth = function () {
    var mo = _i.startDate.getMonth() - 1;

    if (mo < 0) {
      mo = 11;
      _i.decYear();
    }
    _i.startDate.setMonth(mo);
    _i.setMonth();
  };

  _i.setDate = function (dat) {
    _i.startDate = dat;
    _i.setMonth();
    _i.reportDayClick(_i.startDate.getDate());
  };

  _i.setMonth = function (dat) {
    if (dat == null) {
      dat = _i.startDate;
    }

    var mo = _i.monthNames[dat.getMonth()];
    var yr = dat.getFullYear();

    _i.header.innerText = mo + ' ' + yr;
    _i.addDays();
  };

  _i.leftMonthClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);
    _i.decMonth();
  };

  _i.leftYearClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);
    _i.decYear();
    _i.setMonth();
  };

  _i.rightMonthClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);
    _i.incMonth();
  };

  _i.rightYearClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);
    _i.incYear();
    _i.setMonth();
  };

  _i.toggle = function () {
    if (_i.cont.style.display == 'block') {
      _i.hide();
    } else {
      _i.show();
    }
  };

  _i.isVisible = function () {
    return _i.cont.style.display == 'block';
  };

  _i.show = function () {
    if (_i.startDate == null) {
      _i.startDate = new Date();
      _i.setMonth();
    }
    _i.container = _i.container || xGetElementById(container);
    _i.cont.style.left = xPageX(_i.container) + 'px';
    _i.cont.style.top = xPageY(_i.container) + xHeight(_i.container) + 'px';
    _i.cont.style.display = 'block';
    xAddEventListener(document, platform.mouseDown, _i.hide, false);
    //    xAddEventListener( document, "keypress", _i.hide, false );
  };

  _i.hide = function () {
    _i.cont.style.display = 'none';
    xRemoveEventListener(document, platform.mouseDown, _i.hide, false);
    //    xRemoveEventListener( document, "keypress", _i.hide, false );
  };

  _i.blankDay = function () {
    var dv = document.createElement('DIV');
    dv.className = 'blockFloat';
    dv.style.width = _i.gridSize + 'px';
    dv.style.height = _i.gridSize + 'px';
    dv.style.backgroundColor = '#FFFFFF';
    _i.dayHolder.appendChild(dv);
  };

  _i.removeDays = function () {
    while (_i.dayHolder.childNodes.length > 0) {
      _i.dayHolder.removeChild(_i.dayHolder.childNodes[0]);
    }

    _i.items = [];
  };

  _i.addBlanks = function (dat) {
    var numBlank = dat.getDay();

    for (var z = 0; z < numBlank; z++) {
      _i.blankDay();
    }
  };

  _i.addDays = function () {
    _i.removeDays();

    var dy = 1;
    var tmpDate = new Date(_i.startDate);
    tmpDate.setDate(dy);
    var mo = tmpDate.getMonth();

    _i.addBlanks(tmpDate);

    while (tmpDate.getMonth() == mo) {
      _i.items[_i.items.length] = new numberDay(
        _i,
        _i.dayHolder,
        _i.gridSize,
        dy
      );
      dy++;
      tmpDate.setDate(dy);
    }
  };

  _i.makeDayNames = function () {
    _i.dayNameCont = document.createElement('DIV');
    _i.dayNameCont.className = 'blockFloatNot';
    _i.dayNameCont.style.fontFamily = 'Arial';
    _i.dayNameCont.style.fontSize = '14px';
    _i.dayNameCont.style.fontWeight = 'bold';
    _i.dayNameCont.style.color = '#333333';
    _i.dayNameCont.style.backgroundColor = '#DEE2E3';
    _i.dayNameCont.style.cursor = 'default';
    _i.cont.appendChild(_i.dayNameCont);

    for (var z = 0; z < _i.dayNames.length; z++) {
      var dv = document.createElement('DIV');
      dv.className = 'blockFloat';
      dv.style.width = _i.gridSize + 'px';
      dv.style.height = _i.gridSize + 'px';
      dv.style.textAlign = 'center';
      dv.style.lineHeight = _i.gridSize + 'px';
      dv.innerText = _i.dayNames[z];
      _i.dayNameCont.appendChild(dv);
    }
  };

  _i.constructor = function () {
    _i.cont = document.createElement('DIV');
    _i.cont.className = 'blockFloatNot';
    _i.cont.style.display = 'none';
    _i.cont.style.left = '180px';
    _i.cont.style.top = '50px';
    _i.cont.style.width = 7 * _i.gridSize + 'px';
    _i.cont.style.minHeight = '50px';
    _i.cont.style.backgroundColor = '#FFFFFF';
    _i.cont.style.border = '2px solid #A0A0A0';
    _i.cont.style.zIndex = Z_POPUP;

    _i.header = document.createElement('DIV');
    _i.header.className = 'blockFloatNot';
    _i.header.style.width = '100%';
    _i.header.style.height = '22px';
    _i.header.style.textAlign = 'center';
    _i.header.style.lineHeight = _i.header.style.height;
    _i.header.style.cursor = 'default';
    _i.header.style.fontSize = '14px';
    _i.header.style.color = '#333333';
    _i.header.style.fontFamily = 'Arial';
    _i.header.style.fontWeight = 'bold';

    _i.leftMonth = document.createElement('DIV');
    _i.leftMonth.style.position = 'absolute';
    _i.leftMonth.style.left = '4px';
    _i.leftMonth.style.top = '0px';
    _i.leftMonth.style.width = '20px';
    _i.leftMonth.style.height = _i.gridSize + 'px';
    _i.leftMonth.style.lineHeight = _i.header.style.lineHeight;
    _i.leftMonth.style.color = '#A0A0A0';
    _i.leftMonth.style.fontFamily = 'Arial';
    _i.leftMonth.style.fontSize = '14px';
    _i.leftMonth.style.fontWeight = 'bold';
    _i.leftMonth.innerHTML = '&lt;';
    _i.leftMonth.style.cursor = 'pointer';
    xAddEventListener(
      _i.leftMonth,
      platform.mouseDown,
      _i.leftMonthClick,
      false
    );

    _i.leftYear = document.createElement('DIV');
    _i.leftYear.style.position = 'absolute';
    _i.leftYear.style.left = '26px';
    _i.leftYear.style.top = '0px';
    _i.leftYear.style.width = '20px';
    _i.leftYear.style.height = _i.gridSize + 'px';
    _i.leftYear.style.lineHeight = _i.header.style.lineHeight;
    _i.leftYear.style.color = '#A0A0A0';
    _i.leftYear.style.fontFamily = 'Arial';
    _i.leftYear.style.fontSize = '14px';
    _i.leftYear.style.fontWeight = 'bold';
    _i.leftYear.innerHTML = '&lt;&lt;';
    _i.leftYear.style.cursor = 'pointer';
    xAddEventListener(_i.leftYear, platform.mouseDown, _i.leftYearClick, false);

    _i.rightMonth = document.createElement('DIV');
    _i.rightMonth.style.position = 'absolute';
    _i.rightMonth.style.right = '2px';
    _i.rightMonth.style.top = '0px';
    _i.rightMonth.style.width = '10px';
    _i.rightMonth.style.height = _i.gridSize + 'px';
    _i.rightMonth.style.lineHeight = _i.header.style.lineHeight;
    _i.rightMonth.style.color = '#A0A0A0';
    _i.rightMonth.style.fontFamily = 'Arial';
    _i.rightMonth.style.fontSize = '14px';
    _i.rightMonth.style.fontWeight = 'bold';
    _i.rightMonth.innerHTML = '&gt;';
    _i.rightMonth.style.cursor = 'pointer';
    xAddEventListener(
      _i.rightMonth,
      platform.mouseDown,
      _i.rightMonthClick,
      false
    );

    _i.rightYear = document.createElement('DIV');
    _i.rightYear.style.position = 'absolute';
    _i.rightYear.style.right = '24px';
    _i.rightYear.style.top = '0px';
    _i.rightYear.style.width = '20px';
    _i.rightYear.style.height = _i.gridSize + 'px';
    _i.rightYear.style.lineHeight = _i.header.style.lineHeight;
    _i.rightYear.style.color = '#A0A0A0';
    _i.rightYear.style.fontFamily = 'Arial';
    _i.rightYear.style.fontSize = '14px';
    _i.rightYear.style.fontWeight = 'bold';
    _i.rightYear.innerHTML = '&gt;&gt;';
    _i.rightYear.style.cursor = 'pointer';
    xAddEventListener(
      _i.rightYear,
      platform.mouseDown,
      _i.rightYearClick,
      false
    );

    _i.dayHolder = document.createElement('DIV');
    _i.dayHolder.className = 'blockFloatNot';
    _i.dayHolder.style.width = '100%';

    document.body.appendChild(_i.cont);
    _i.cont.appendChild(_i.header);
    _i.cont.appendChild(_i.leftMonth);
    _i.cont.appendChild(_i.leftYear);
    _i.cont.appendChild(_i.rightMonth);
    _i.cont.appendChild(_i.rightYear);
    _i.makeDayNames();
    _i.cont.appendChild(_i.dayHolder);
  };

  _i.constructor();
}
