function SettingsCustomFields(attachTo, namespacevalue, label, label2) {
  if (!gData.accountSettings.moduleEnabled('custom fields')) {
    return new SettingsCustomFieldsUpsell({
      attachTo: attachTo,
      namespacevalue: namespacevalue,
      label: label,
      label2: label2,
    });
  }
  var _i = this;
  if (!(this instanceof SettingsCustomFields)) {
    return new SettingsCustomFields(attachTo, namespacevalue, label, label2);
  }

  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.namespaceval = namespacevalue;
  _i.labelval = label;
  _i.labelval2 = label2;
  _i.fieldControllers = [];

  _i.categoryCreated = function () {
    _i.buildFieldsArray();
    _i.refreshList();
  };

  _i.editModeCallback = function (controller) {
    _i.hideElementsAboveTheList();
    _i.displaySingleField(null, controller);
  };

  _i.refreshList = function () {
    _i.showElementsAboveTheList();
    removeAllChildren(_i.fieldsCollection);
    _i.buildFieldsArray();
    _i.displayFieldsList();
  };

  _i.spacerLineObject = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.fieldsCollection.appendChild(spacer);
  };

  var changeDisplayOfElementsAboveTheList = function (displayValue) {
    _.each(_i.elementsAboveTheList, function (element) {
      element.style.display = displayValue;
    });
  };

  _i.showElementsAboveTheList = function () {
    changeDisplayOfElementsAboveTheList('block');
  };

  _i.hideElementsAboveTheList = function () {
    changeDisplayOfElementsAboveTheList('none');
  };

  _i.displayFieldsList = function () {
    _i.fieldControllers = (_i.customFields || []).map(function (field) {
      var controller = SettingsCustomField(_i.fieldsCollection, field, _i);
      _i.spacerLineObject();
      return controller;
    });
  };

  _i.displaySingleField = function (field, controller) {
    removeAllChildren(_i.fieldsCollection);
    if (!controller) {
      controller = SettingsCustomField(_i.fieldsCollection, field, _i);
    }
    _i.fieldControllers = [controller];
    _i.fieldsCollection.appendChild(controller.valuecontainer);
    controller.editMode();
  };

  _i.buildFieldsArray = function () {
    _i.customFields = gData.getCustomFields(_i.namespaceval);
    if (_i.customFields) {
      _i.customFields.sort(function (a, b) {
        var sa = a.name.toLowerCase();
        var sb = b.name.toLowerCase();
        if (sa == sb) return 0;
        return sa > sb ? 1 : -1;
      });
    } else {
      gService.getCustomFields(_i.namespaceval, _i.refreshList);
    }
  };

  _i.newFieldView = function () {
    _i.hideElementsAboveTheList();
    removeAllChildren(_i.fieldsCollection);
    _i.displaySingleField({ namespace: namespacevalue, name: '' });
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);
    _i.label = document.createElement('DIV');

    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.label.innerHTML = I18n.t(
      'lbl_' + (_i.labelval2 ? _i.labelval2 : _i.labelval)
    );
    _i.container.appendChild(_i.label);

    _i.addButton = new primaryButton('settingsPrimaryButton');
    _i.addButton.setButtonText(I18n.t('lbl_add'));
    _i.addButton.setCallback(_i.newFieldView);
    _i.container.appendChild(_i.addButton.container);
    _i.addButton.container.tabIndex = 0;

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);

    _i.elementsAboveTheList = _.toArray(_i.container.children);

    _i.fieldsCollection = document.createElement('DIV');
    _i.fieldsCollection.classname = 'blockFloatNot';
    _i.container.appendChild(_i.fieldsCollection);

    _i.buildFieldsArray();
    _i.displayFieldsList();
  };

  _i.constructor();
}
