function SettingsBranding(attachTo, label, description) {
  const _i = this;

  _i.attachTo = xGetElementById(attachTo);
  _i.labelText = label;
  _i.descriptionText = description;
  _i.originalLogo = _i.logoUrl = gData.accountSettings
    .enterprise_header_logo_url
    ? gData.accountSettings.enterprise_header_logo_url
    : DEFAULT_LOGO_URL;
  _i.originalBgColor = gData.accountSettings.enterprise_header_bgcolor
    ? gData.accountSettings.enterprise_header_bgcolor
    : '#003059';
  _i.originalHeaderColor = gData.accountSettings.enterprise_header_color
    ? gData.accountSettings.enterprise_header_color
    : '#ffffff';
  _i.originalHeaderShadowEnabled =
    gData.accountSettings.enterprise_header_shadow_enabled != null
      ? gData.accountSettings.enterprise_header_shadow_enabled
      : true;
  _i.orgImagePixelWidth = 40;
  _i.orgImagePixelHeight = 40;
  _i.isIE = !!window.MSInputMethodContext && !!document.documentMode;
  // _i.scaleFactor = 4;

  _i.addThumbnailSelectionElements = function (contentContainer) {
    var previewHeaderDiv = document.createElement('DIV');
    previewHeaderDiv.className = 'blockFloatNot settingsBrandingPickerTextLeft';
    previewHeaderDiv.style.margin = '16px 0 0 0';
    previewHeaderDiv.innerText = I18n.t('lbl_preview_custom_branding');
    contentContainer.appendChild(previewHeaderDiv);

    var brandPreviewWrapperDiv = document.createElement('DIV');
    brandPreviewWrapperDiv.className = 'blockFloatNot';
    brandPreviewWrapperDiv.id = 'brandPreviewWrapperDiv';
    contentContainer.appendChild(brandPreviewWrapperDiv);

    _i.brandPreview = new OrgBrandPreview(
      'brandPreviewWrapperDiv',
      _i.logoUrl,
      _i.originalBgColor,
      _i.originalHeaderColor,
      _i.originalHeaderShadowEnabled
    );

    var thumbnailSelectionText = document.createElement('DIV');
    thumbnailSelectionText.className =
      'blockFloat fnt-r-15 editBrandEditThumbnailText';
    thumbnailSelectionText.id = 'thumbnailSelectionText';
    brandPreviewWrapperDiv.appendChild(thumbnailSelectionText);

    var textHeaderDiv = document.createElement('DIV');
    textHeaderDiv.className = 'blockFloatNot settingsBrandingPickerTextLeft';
    textHeaderDiv.style.margin = 0;
    textHeaderDiv.innerText = I18n.t('lbl_logo');
    thumbnailSelectionText.appendChild(textHeaderDiv);

    var textDiv = document.createElement('DIV');
    textDiv.className = 'blockFloatNot editBrandDarkText';
    textDiv.innerText = I18n.t('msg_select_image');
    thumbnailSelectionText.appendChild(textDiv);

    _i.awsUpload = new AWSCropprImageUpload(
      document.getElementById('thumbnailSelectionText'),
      gService.getS3CredentialsLogoImage,
      undefined,
      'public-read',
      _i.brandPreview,
      _i.orgImagePixelWidth,
      _i.orgImagePixelHeight,
      'branding'
    );

    _i.thumbnailSelectionText = thumbnailSelectionText;
  };

  _i.addColorPickerElements = function (contentContainer) {
    // background color
    var bgSelectionDiv = document.createElement('DIV');
    bgSelectionDiv.style.width = '560px';
    bgSelectionDiv.style.float = 'left';

    var bgColorText = document.createElement('DIV');
    bgColorText.innerText = I18n.t('lbl_background_color');
    bgColorText.className = 'settingsBrandingPickerTextLeft';
    bgSelectionDiv.appendChild(bgColorText);
    contentContainer.appendChild(bgSelectionDiv);

    _i.bgColorPickerInput = document.createElement('input');
    if (_i.isIE) {
      _i.bgColorPickerInput.type = 'text';
      _i.bgColorPickerInput.className = 'settingsBrandingPickerTextInput';
    } else {
      _i.bgColorPickerInput.type = 'color';
      _i.bgColorPickerInput.className = 'settingsBrandingPickerInput';
    }
    _i.bgColorPickerInput.id = 'bgColorPicker';
    _i.bgColorPickerInput.value = _i.originalBgColor;
    _i.bgColorPickerInput.onchange = _i.handleBgColorChange;
    bgSelectionDiv.appendChild(_i.bgColorPickerInput);

    // header text color
    var headerSelectionDiv = document.createElement('DIV');
    headerSelectionDiv.style.width = '560px';
    headerSelectionDiv.style.float = 'left';

    var headerColorText = document.createElement('DIV');
    headerColorText.innerText = I18n.t('lbl_header_color');
    headerColorText.className = 'settingsBrandingPickerTextLeft';
    headerSelectionDiv.appendChild(headerColorText);
    contentContainer.appendChild(headerSelectionDiv);

    _i.headerColorPickerInput = document.createElement('input');
    if (_i.isIE) {
      _i.headerColorPickerInput.type = 'text';
      _i.headerColorPickerInput.className = 'settingsBrandingPickerTextInput';
    } else {
      _i.headerColorPickerInput.type = 'color';
      _i.headerColorPickerInput.className = 'settingsBrandingPickerInput';
    }
    _i.headerColorPickerInput.id = 'headerColorPicker';
    _i.headerColorPickerInput.value = _i.originalHeaderColor;
    _i.headerColorPickerInput.onchange = _i.handleHeaderColorChange;
    headerSelectionDiv.appendChild(_i.headerColorPickerInput);

    // header text shadow
    var headerTextShadowDiv = document.createElement('DIV');
    headerTextShadowDiv.className = 'fnt-r-15';
    headerTextShadowDiv.style.width = '560px';
    headerTextShadowDiv.style.float = 'left';
    headerTextShadowDiv.style.marginTop = '16px';

    _i.headerTextShadowCheck = document.createElement('input');
    _i.headerTextShadowCheck.type = 'checkbox';
    _i.headerTextShadowCheck.id = 'headerShadowCheck';
    _i.headerTextShadowCheck.checked = _i.originalHeaderShadowEnabled;
    _i.headerTextShadowCheck.onchange = _i.handleHeaderTextShadowChange;
    headerTextShadowDiv.appendChild(_i.headerTextShadowCheck);

    var headerTextShadowLabel = document.createElement('label');
    headerTextShadowLabel.className = 'editBrandDarkText';
    headerTextShadowLabel.style.marginLeft = '4px';
    headerTextShadowLabel.innerText = I18n.t('lbl_enable_drop_shadow');
    headerTextShadowDiv.appendChild(headerTextShadowLabel);
    contentContainer.appendChild(headerTextShadowDiv);
  };

  _i.addResetSaveButtons = function (contentContainer) {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsAddPlaceholderLinespacer';
    spacer.style.marginTop = '32px';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.grayLine));
    contentContainer.appendChild(spacer);

    var buttonsContainer = document.createElement('DIV');
    buttonsContainer.style.marginTop = '10px';
    buttonsContainer.style.width = '560px';
    buttonsContainer.style.float = 'left';

    // save button
    _i.saveButton = new primaryButton(
      'settingsSecondaryButtonCategory settingsBrandingPickerTextRight'
    );
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.addBrandingButtonCallback);
    buttonsContainer.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;

    // reset button
    _i.resetButton = new secondaryButton(
      'settingsSecondaryButtonCategory settingsBrandingPickerTextRight'
    );
    _i.resetButton.setButtonText(I18n.t('lbl_remove_custom_branding'));
    _i.resetButton.setCallback(_i.resetBranding);
    buttonsContainer.appendChild(_i.resetButton.container);
    _i.resetButton.container.tabIndex = 0;

    contentContainer.appendChild(buttonsContainer);

    _i.setResetIsDisabled();
  };

  _i.handleBgColorChange = function () {
    _i.brandPreview.updateBackgroundColor(_i.bgColorPickerInput.value);
  };

  _i.handleHeaderColorChange = function () {
    _i.brandPreview.updateHeaderColor(_i.headerColorPickerInput.value);
  };

  _i.handleHeaderTextShadowChange = function () {
    _i.brandPreview.updateShadowEnabled(_i.headerTextShadowCheck.checked);
  };

  _i.addBrandingButtonCallback = function () {
    if (_i.isInAsynCall) {
      return;
    }
    if (_i.awsUpload.hasThumbnailChanged) {
      _i.sendToS3Action();
    } else {
      _i.saveBrand();
    }
  };

  _i.setResetIsDisabled = function () {
    // check if shadow enabled has explicitly been turned off.
    // Otherwise the interaction can be a bit confusing, it can feel like nothing has been done
    if (
      gData.accountSettings.enterprise_header_logo_url ||
      (gData.accountSettings.enterprise_header_bgcolor &&
        gData.accountSettings.enterprise_header_bgcolor !== '#003059') ||
      (gData.accountSettings.enterprise_header_color &&
        gData.accountSettings.enterprise_header_color !== '#ffffff') ||
      gData.accountSettings.enterprise_header_shadow_enabled === false
    ) {
      _i.resetButton.setEnabled(compSpritesButtonsIcons.secondaryButtonRest);
    } else {
      _i.resetButton.setDisabled();
    }
  };

  _i.resetBranding = function () {
    var confirmReset = confirmationPopup(
      I18n.t('msg_remove_custom_branding'),
      I18n.t('lbl_remove_confirmation'),
      function () {
        gService.resetBrand(_i.brandReset, _i.brandError);
      },
      null,
      null,
      null,
      175
    );
    confirmReset.show(_i.resetButton.container);
  };

  _i.brandReset = function () {
    _i.logoUrl = DEFAULT_LOGO_URL;
    _i.originalBgColor = '#003059';
    _i.originalHeaderColor = '#ffffff';
    _i.originalHeaderShadowEnabled = true;

    var image = new Image();
    image.src = _i.logoUrl;

    image.onload = function () {
      _i.awsUpload.file.value = '';
      _i.awsUpload.thumbnailchange(image);
      _i.awsUpload.hasThumbnailChanged = false;
    };
    _i.bgColorPickerInput.value = _i.originalBgColor;
    _i.brandPreview.updateBackgroundColor(_i.originalBgColor);
    _i.headerColorPickerInput.value = _i.originalHeaderColor;
    _i.brandPreview.updateHeaderColor(_i.originalHeaderColor);
    _i.headerTextShadowCheck.checked = _i.originalHeaderShadowEnabled;
    _i.brandPreview.updateShadowEnabled(_i.originalHeaderShadowEnabled);

    _i.setResetIsDisabled();
  };

  _i.sendToS3Callback = function () {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    _i.saveBrand();
  };

  _i.sendToS3FailureCallback = function () {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    new notificationPopup(
      I18n.t('err_unexpected_issue_html', {
        supportUrl: window.SMARTSHEET_SUPPORT_URL,
      }),
      I18n.t('err_image_upload_failed'),
      10000
    );
  };

  _i.sendToS3Action = function () {
    if (_i.isInAsynCall) return;
    _i.isInAsynCall = new pageSpinner();
    _i.awsUpload.processAndSendImage(
      _i.brandPreview.thumbnail.height,
      _i.brandPreview.thumbnail.width,
      _i.brandPreview.thumbnail,
      _i.sendToS3Callback,
      _i.sendToS3FailureCallback
    );
  };

  _i.saveBrand = function () {
    const brandData = {};

    if (_i.awsUpload.hasThumbnailChanged) {
      brandData.logoUrl = _i.awsUpload.getUrl();
      _i.awsUpload.hasThumbnailChanged = false;
    }
    if (_i.originalBgColor !== _i.bgColorPickerInput.value) {
      brandData.bgColor = _i.bgColorPickerInput.value;
      _i.originalBgColor = brandData.bgColor;
    }
    if (_i.originalHeaderColor !== _i.headerColorPickerInput.value) {
      brandData.headerColor = _i.headerColorPickerInput.value;
      _i.originalHeaderColor = brandData.headerColor;
    }
    if (_i.originalHeaderShadowEnabled !== _i.headerTextShadowCheck.checked) {
      brandData.shadowEnabled = _i.headerTextShadowCheck.checked;
      _i.originalHeaderShadowEnabled = brandData.shadowEnabled;
    }

    if (_.isEmpty(brandData)) {
      new notificationPopup(I18n.t('msg_no_brand_changes'), '', 5000);
    } else {
      _i.isInAsynCall = new pageSpinner();
      gService.updateBrand(brandData, _i.brandSaved, _i.brandError);
    }
  };

  _i.brandSaved = function (obj) {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    _i.setResetIsDisabled();
  };

  _i.brandError = function (response) {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    new notificationPopup(
      I18n.t('err_unexpected_issue_html', {
        supportUrl: window.SMARTSHEET_SUPPORT_URL,
      }),
      response.error,
      10000
    );
  };

  _i.constructor = function () {
    _i.brandContainer = document.createElement('DIV');
    _i.brandContainer.className = 'blockFloatNot settingsUserContainer';
    _i.brandContainer.id = 'brandContainer' + getGuid();
    _i.attachTo.appendChild(_i.brandContainer);
    _i.addThumbnailSelectionElements(_i.brandContainer);
    _i.addColorPickerElements(_i.brandContainer);
    _i.addResetSaveButtons(_i.brandContainer);
  };

  _i.constructor();
}
