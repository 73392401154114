function addSpacer(container, classnameStr, sprite, text) {
  var spacer = document.createElement('DIV');
  spacer.className = classnameStr;
  if (sprite) spacer.appendChild(sprite);
  if (text) spacer.innerText = text;
  container.appendChild(spacer);
  return spacer;
}

function AvailabilityItem(attachTo, parent, item, availability_block_id, opts) {
  var _i = this;
  if (!(this instanceof AvailabilityItem)) {
    return new AvailabilityItem(
      attachTo,
      parent,
      item,
      availability_block_id,
      opts
    );
  }

  // params
  _i.attachTo = attachTo;
  _i.parent = parent;
  _i.item = item;
  _i.availability_block_id = availability_block_id;
  _i.isNew = opts && opts.isNew;
  _i.createLROnChangeListener = opts && opts.createLROnChangeListener;

  // ids
  _i.startDateDropdownID = 'startdate-dropdown';
  _i.startDateBtnID = 'startdate-button';
  _i.endDateDropdownID = 'enddate-dropdown';
  _i.endDateBtnID = 'enddate-button';

  // Limited Resource (LR) OnChangeListeners
  _i.lRChangeListenerInitialValues = {
    startDate: _i.item.starts_at ? parseRubyDate(_i.item.starts_at) : null,
    endDate: _i.item.ends_at ? parseRubyDate(_i.item.ends_at) : null,
    days: _i.item.days.map((val) => String(val)), // Cast initial value to a string because all updates will result in strings
    preexisting: !_i.isNew,
  };
  _i.startDateLRChangeListener = _i.createLROnChangeListener
    ? _i.createLROnChangeListener(
        `availability${availability_block_id}_start`,
        _i.lRChangeListenerInitialValues.startDate
      )
    : () => {};
  _i.endDateLRChangeListener = _i.createLROnChangeListener
    ? _i.createLROnChangeListener(
        `availability${availability_block_id}_end`,
        _i.lRChangeListenerInitialValues.endDate
      )
    : () => {};
  _i.hoursListeners = [];
  if (_i.createLROnChangeListener) {
    for (let i = 0; i < 7; i++) {
      _i.hoursListeners.push(
        _i.createLROnChangeListener(
          `availability${availability_block_id}_hours${i}`,
          _i.lRChangeListenerInitialValues.days[i]
        )
      );
    }
  }
  _i.existenceLRChangeListener = _i.createLROnChangeListener
    ? _i.createLROnChangeListener(
        `availability${availability_block_id}_existence`,
        _i.lRChangeListenerInitialValues.preexisting
      )
    : () => {};

  _i.changed = false;

  _i.errorSaving = function () {
    _i.parent.errorSaving();
  };

  _i.saveCallback = function () {
    _i.parent.savedCallback();
  };

  _i.save = function (user_id, availability_id, opts) {
    var s = _i.startdate.selectedDate
        ? _i.startdate.selectedDate.toRubyDate()
        : null,
      e = _i.enddate.selectedDate ? _i.enddate.selectedDate.toRubyDate() : null,
      data = {
        starts_at: s,
        ends_at: e,
        day0: _i.days[0].getValueAsNumber(),
        day1: _i.days[1].getValueAsNumber(),
        day2: _i.days[2].getValueAsNumber(),
        day3: _i.days[3].getValueAsNumber(),
        day4: _i.days[4].getValueAsNumber(),
        day5: _i.days[5].getValueAsNumber(),
        day6: _i.days[6].getValueAsNumber(),
      };
    if (opts && opts.isNew && opts.isDeleted) {
      // it was never created server-side, so no service call is needed
      return _i.saveCallback();
    } else if (opts && opts.isNew) {
      return gService.createAvailability(
        data,
        user_id,
        _i.saveCallback,
        _i.errorSaving
      );
    } else if (opts && opts.isDeleted) {
      return gService.deleteAvailability(
        user_id,
        availability_id,
        _i.saveCallback,
        _i.errorSaving
      );
    }
    return gService.updateAvailability(
      data,
      user_id,
      availability_id,
      _i.saveCallback,
      _i.errorSaving
    );
  };

  _i.endDateSelected = function (date) {
    item.ends_at = new Date(date);
    _i.endDateLRChangeListener(date);
    _i.enddate.setDate(date);
    _i.changed = true;
  };

  _i.startDateSelected = function (date) {
    item.starts_at = new Date(date);
    _i.startDateLRChangeListener(date);
    _i.startdate.setDate(date);
    _i.changed = true;
  };

  _i.deletePressed = function (e) {
    if (e.key === 'Enter' || e.key === ' ') {
      _i.deleteClicked(e);
    }
  };

  _i.deleteClicked = function (e) {
    var adjustWidthForLanguage = true;
    var adjustWidthForLanguageAvailabilityPopup = true;
    var confirmDelete = confirmationPopup(
      I18n.t('msg_delete_availability_confirm'),
      I18n.t('lbl_delete'),
      function () {
        _i.parent.deleteCallback(e, _i.availability_block_id);
        _i.startDateLRChangeListener(
          _i.lRChangeListenerInitialValues.startDate
        );
        _i.endDateLRChangeListener(_i.lRChangeListenerInitialValues.endDate);
        _i.existenceLRChangeListener(false); // If it was preexisting, this will cause the banner to show, or if not, this won't
        for (let i = 0; i < _i.hoursListeners.length; i++) {
          _i.hoursListeners[i](_i.lRChangeListenerInitialValues.days[i]);
        }
      },
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      adjustWidthForLanguage,
      adjustWidthForLanguageAvailabilityPopup
    );
    confirmDelete.show(_i.deleteButton);
    focusElementAfterDelay('#float-alert-message');
  };

  _i.labelFromDay = function (day) {
    if (day === 0) return I18n.t('lbl_day_sunday_abbr');
    if (day === 1) return I18n.t('lbl_day_monday_abbr');
    if (day === 2) return I18n.t('lbl_day_tuesday_abbr');
    if (day === 3) return I18n.t('lbl_day_wednesday_abbr');
    if (day === 4) return I18n.t('lbl_day_thursday_abbr');
    if (day === 5) return I18n.t('lbl_day_friday_abbr');
    if (day === 6) return I18n.t('lbl_day_saturday_abbr');
  };

  _i.inputChange = function (value, id) {
    item.days[id] = value;
    _i.hoursListeners[id]?.(item.days[id]);
    _i.changed = true;
  };

  _i.buildDayInput = function (day, val) {
    var dalabel = _i.labelFromDay(day);
    var dayText = new TextInput(
      _i.container.id,
      dalabel,
      val,
      'blockFloat availabilityEditInputContainerDay',
      'blockFloatNotChromeFix fnt-r-13 availabilityTextInputLabel',
      'blockFloatNotChromeFix fnt-r-15 availabilityInputDay',
      null,
      TextInputFilter.Numeric,
      null,
      null,
      _i.inputChange,
      day
    );
    return dayText;
  };

  _i.getValue = function () {
    return {
      category: _i.inputName.value,
      amount: _i.valueFromInput(_i.inputText) * _i.scale,
      assignable_id: _i.parent.project.id,
      item_type: _i.item_type,
      assignable_parent_id: _i.parent.project.parent_id,
      peritem_amount: _i.valueFromInput(_i.peritemAmountText),
    };
  };

  _i.buildBody = function () {
    var start = _i.item.starts_at
      ? parseRubyDate(_i.item.starts_at)
      : new Date();
    _i.startPopup = new calendarPopup(
      230,
      210,
      'mainCon',
      start,
      compSpritesButtonsIcons.wideDialogSpecsGray,
      _i.startDateSelected,
      false,
      false,
      'ne',
      true,
      null,
      null,
      null,
      null,
      defaultCalendarShortcutNone,
      _i.startDateDropdownID
    );

    _i.startdate = new PopUpCalendar(
      _i.container.id,
      I18n.t('lbl_start_date'),
      'blockFloatNotChromeFix availabilityEditInputContainerSmall',
      'blockFloatNotChromeFix fnt-r-14 availabilityInputContainer',
      'blockFloatNotChromeFix fnt-r-13 availabilityTextInputLabel',
      'blockFloatNotChromeFix fnt-r-15 availabilityInputDateText',
      parseRubyDate(_i.item.starts_at),
      _i.startPopup,
      true,
      _i.startDateDropdownID,
      _i.startDateBtnID
    );
    addSpacer(_i.container, 'blockFloat availabilitySpacer1');
    _i.days = [];
    for (var i = 0; i < 7; i++) {
      _i.days.push(_i.buildDayInput(i, _i.item.days[i]));
      addSpacer(_i.container, 'blockFloat availabilitySpacer1');
    }

    var end = _i.item.ends_at ? parseRubyDate(_i.item.ends_at) : new Date();
    _i.endPopup = new calendarPopup(
      230,
      210,
      'mainCon',
      end,
      compSpritesButtonsIcons.wideDialogSpecsGray,
      _i.endDateSelected,
      false,
      false,
      'ne',
      true,
      null,
      null,
      null,
      null,
      defaultCalendarShortcutNone,
      _i.endDateDropdownID
    );

    _i.enddate = new PopUpCalendar(
      _i.container.id,
      I18n.t('lbl_end_date'),
      'blockFloat availabilityEditInputContainerSmall',
      'blockFloatNotChromeFix fnt-r-14 availabilityInputContainer',
      'blockFloatNotChromeFix fnt-r-13 availabilityTextInputLabel',
      'blockFloatNotChromeFix fnt-r-15 availabilityInputDateText',
      parseRubyDate(_i.item.ends_at),
      _i.endPopup,
      true,
      _i.endDateDropdownID,
      _i.endDateBtnID
    );

    addSpacer(_i.container, 'blockFloat availabilitySpacer1');

    _i.deleteButton = $(
      '<div class="blockFloat removeIcon" tabindex=0 role="button" style="cursor:pointer;margin-top:34px;"></div>'
    )[0];
    _i.deleteButton.setAttribute(
      'aria-label',
      'Remove availability ' + _i.container.getAttribute('data-suid')
    );
    _i.deleteButton.addEventListener('click', _i.deleteClicked);
    _i.deleteButton.addEventListener('keypress', _i.deletePressed);
    _i.container.appendChild(_i.deleteButton);
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot availabilityControlContentContainer';
    _i.container.setAttribute('data-suid', _i.availability_block_id + 1);
    _i.container.id =
      'availabilityView_suid_' + _i.container.getAttribute('data-suid');
    _i.attachTo.appendChild(_i.container);
    _i.buildBody();
  };
  _i.constructor();
}

function AvailabilityView(attachTo, user, createLROnChangeListener) {
  var _i = this;
  if (!(this instanceof AvailabilityView)) {
    return new AvailabilityView(attachTo, user, createLROnChangeListener);
  }
  _i.attachTo = xGetElementById(attachTo);
  _i.user = user || { availabilities: [] };
  _i.createLROnChangeListener = createLROnChangeListener;
  _i.changed = false;

  _i.availabilities = [];
  _i.availabilityLRChangeListeners = [];

  _i.buildHeader = function () {
    var d = document.createElement('div');
    d.className = 'blockFloatNot';

    _i.container.id = 'peopleEditPartTimeAvailibilityId';
    _i.addButton = $.parseHTML(
      `<input type="button" role="button" class="fnt-r-14-ls formContentButton" value="${I18n.t(
        'lbl_add_part_time_availability'
      )}" style="margin-top: 15px;" />`
    )[0];
    _i.addButton.addEventListener('click', _i.addCallback);
    d.appendChild(_i.addButton);

    _i.container.appendChild(d);
  };

  _i.savedCallback = function () {
    _i.expectedCallbacks = _i.expectedCallbacks - 1;
    if (_i.expectedCallbacks == 0) {
      if (_i.callback) _i.callback();
    }
  };

  _i.saveAllAvailabilities = function () {
    var changedAvailabilities = _.filter(_i.availabilities, { changed: true });
    _i.expectedCallbacks = changedAvailabilities.length;
    if (_i.expectedCallbacks > 0) {
      _.each(changedAvailabilities, function (availability) {
        availability.save(_i.user.id, availability.item.id, {
          isNew: availability.isNew,
          isDeleted: availability.isDeleted,
        });
      });
    } else {
      _i.callback && _i.callback();
    }
    return;
  };

  _i.errorSaving = function () {
    alert(
      'Error saving availabilities for this user. Make sure they have valid start and end dates if all days are zero'
    );
    if (_i.callback) _i.callback();
  };

  _i.saveEverything = function (callback, user_id) {
    _i.callback = callback;
    if (user_id) {
      _i.user.id = user_id;
    }

    // if nothing has changed, return immediately
    for (var i = 0; i < _i.availabilities.length; i++) {
      if (_i.availabilities[i].changed) {
        _i.changed = true;
        break;
      }
    }
    if (!_i.changed) {
      if (callback) callback();
      return;
    }
    return _i.saveAllAvailabilities();
  };

  _i.deleteCallback = function (e, availability_block_id) {
    _i.user.availabilities.splice(availability_block_id, 1);
    _i.availabilities[availability_block_id].isDeleted = true;
    _i.availabilities[availability_block_id].changed = true;
    $(e.target).closest('.availabilityControlContentContainer').remove();
    _i.changed = true;
  };

  _i.addDefaultAvailability = function () {
    var days = [];
    var mask = 0x01;
    var workweek = Number(gData.accountSettings.working_days);
    for (var i = 0; i < 7; i++) {
      if ((workweek & mask) != 0)
        days[i] = gData.accountSettings.hours_in_workday;
      else days[i] = 0;
      mask = mask << 1;
    }
    // build a new availability
    var newavail = { starts_at: null, ends_at: null, days: days };
    _i.user.availabilities.push(newavail);
  };

  _i.addCallback = function () {
    _i.addDefaultAvailability();
    _i.buildBody();
    _i.changed = true;
  };

  _i.buildBody = function (opts) {
    if (opts && opts.firstTime) {
      for (var i = 0; i < _i.user.availabilities.length; i++) {
        var item = _i.user.availabilities[i];
        _i.availabilities.push(
          new AvailabilityItem(_i.availabilitiescontainer, _i, item, i, {
            createLROnChangeListener: _i.createLROnChangeListener,
          })
        );
      }
    } else {
      var index = _i.user.availabilities.length - 1;
      var item = _i.user.availabilities[index];
      _i.availabilities.push(
        new AvailabilityItem(_i.availabilitiescontainer, _i, item, index, {
          isNew: true,
          createLROnChangeListener: _i.createLROnChangeListener,
        })
      );
    }
  };

  _i.buildData = function () {
    if (_i.user == null) {
      _i.user = {};
      _i.user.availabilities = [];
      _i.addDefaultAvailability();
      return;
    }
    _i.user.availabilities.sort(function (a, b) {
      if (a.starts_at == null) {
        return 1;
      } else if (b.starts_at == null) {
        return -1;
      } else {
        return parseRubyDate(a.starts_at).getTime() <
          parseRubyDate(b.starts_at).getTime()
          ? -1
          : 1;
      }
    });
  };

  var ctor = (function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot expensesControlContentContainer';
    _i.attachTo.appendChild(_i.container);
    _i.buildData();
    _i.buildHeader();
    _i.availabilitiescontainer = document.createElement('DIV');
    _i.availabilitiescontainer.className =
      'blockFloatNot expensesControlItemContainer';
    _i.container.appendChild(_i.availabilitiescontainer);
    _i.buildBody({ firstTime: true });
  })();
}
