function SettingsUsers(attachTo) {
  var _i = this,
    _INDEX_ACTIVE = 0,
    _INDEX_INVITED = _INDEX_ACTIVE + 1,
    _INDEX_RESOURCE_ONLY = _INDEX_INVITED + 1,
    _INDEX_ARCHIVED = _INDEX_RESOURCE_ONLY + 1,
    titlesByGroup = [
      { header: 'Active' },
      { header: 'Invited' },
      {
        header: 'Schedule Only',
        subheading:
          'These people can be scheduled on projects, but do not have login or timesheet access. To gain access, invite them to join your organization’s account. Additional charges may apply for added Schedule-Only People.',
      },
      { header: 'Archived' },
    ];

  if (!(this instanceof SettingsUsers)) {
    return new SettingsUsers(attachTo);
  }

  _i.attachTo = xGetElementById(attachTo);
  _i.sendInvite = false;
  _i.userControllers = [];

  _i.userCreated = function () {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    _i.buildUserArrays();
    _i.refreshList();
    _i.firstName.inputText.value = '';
    _i.lastName.inputText.value = '';
    _i.email.inputText.value = '';
  };

  _i.editModeCallback = function () {
    for (var i = 0; i < _i.userControllers.length; i++) {
      _i.userControllers[i].cancelEdit();
    }
  };

  _i.verifyString = function (str) {
    return str != null && str != '';
  };

  _i.toggleCallbackNoInvite = function () {
    if (_i.sendInvite) {
      _i.sendInvite = false;
      _i.invitationForm.style.display = 'none';
      _i.selctionAddWithInvite.setEnabled(false);
      _i.selctionAddNoInvite.setEnabled(true);
    }
  };

  _i.toggleCallbackSendInvite = function () {
    if (!_i.sendInvite) {
      _i.sendInvite = true;
      _i.invitationForm.style.display = 'block';
      _i.selctionAddWithInvite.setEnabled(true);
      _i.selctionAddNoInvite.setEnabled(false);
    }
  };

  _i.verifyForm = function () {
    // verify all fields, create organization with user, and login
    if (!_i.verifyString(_i.firstName.getValue())) {
      _i.firstName.setAlert(I18n.t('err_valid_first_name'));
      return false;
    }
    if (!_i.verifyString(_i.lastName.getValue())) {
      _i.lastName.setAlert(I18n.t('err_valid_last_name'));
      return false;
    }
    if (_i.sendInvite) {
      if (!verifyEmail(_i.email.getValue(), true)) {
        _i.email.setAlert(I18n.t('err_valid_email'));
        return false;
      }
    }
    return true;
  };

  _i.addUserButtonCallback = function () {
    window.location.href = getBaseURL() + 'adduser?refer=settings';
  };

  _i.addMultipleUserButtonCallback = function () {
    window.location.href = getBaseURL() + 'addmultipleuser';
  };

  _i.onUserTypeChanged = function (obj) {
    if (obj[0].id != null) {
      _i.usertypeid = obj[0].id;
      _i.usertype.setValue(obj[0].value);
    }
  };

  _i.popupDataFromUserTypes = function () {
    var ret = [];
    var types = gData.userTypes;
    for (var i = 0; i < types.length; i++) {
      obj = {};
      obj.type = 'item';
      obj.id = types[i].id;
      obj.value = i;
      obj.selected = false;
      obj.label = types[i].value;
      ret.push(obj);
    }
    return ret;
  };

  _i.refreshList = function () {
    removeAllChildren(_i.usersCollection);
    _i.buildUserArrays();
    _i.displayUsers();
    _i.label.innerHTML = _i.getUserCountsText();
  };

  _i.spacerLineUser = function () {
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage2';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.usersCollection.appendChild(spacer);
  };

  _i.displayGroupHeader = function (s) {
    var d = document.createElement('h2');
    d.className = 'blockFloatNot settingsProgressLabel fnt-b-14';
    d.innerText = s.header;
    if (s.subheading) {
      var p = document.createElement('p');
      p.className = 'fnt-r-12 settingsSubheading';
      p.innerText = s.subheading;
      d.appendChild(p);
    }
    _i.usersCollection.appendChild(d);
  };

  _i.displayNone = function () {
    var d = document.createElement('DIV');
    d.className = 'blockFloatNot fnt-r-14';
    d.innerHTML = 'None';
    _i.usersCollection.appendChild(d);
  };

  _i.displayUsers = function () {
    _i.userControllers = [];

    _i.usersByGroup.forEach(function (a, i) {
      var groupHasUsers = a.length > 0;
      _i.displayGroupHeader(titlesByGroup[i]);
      groupHasUsers && _i.spacerLineUser();
      if (!groupHasUsers) {
        _i.displayNone();
      } else {
        a.forEach(function (u) {
          _i.userControllers.push(SettingsUser(_i.usersCollection, u, _i));
          _i.spacerLineUser();
        });
      }
    });
  };

  _i.buildUserArrays = function () {
    var user;

    _i.users = gData.getUsers();
    _i.usersByGroup = [];
    _i.usersByGroup[_INDEX_ACTIVE] = [];
    _i.usersByGroup[_INDEX_INVITED] = [];
    _i.usersByGroup[_INDEX_RESOURCE_ONLY] = [];
    _i.usersByGroup[_INDEX_ARCHIVED] = [];

    for (var i = 0; i < _i.users.length; i++) {
      user = _i.users[i];
      if (user.deleted) {
        _i.usersByGroup[_INDEX_ARCHIVED].push(user);
      } else if (user.invitation_pending) {
        _i.usersByGroup[_INDEX_INVITED].push(user);
      } else if (!user.login) {
        _i.usersByGroup[_INDEX_RESOURCE_ONLY].push(user);
      } else {
        _i.usersByGroup[_INDEX_ACTIVE].push(user);
      }
    }
  };

  _i.buildLinkTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-b-13 adduserLink';
    ret.innerText = str;
    return ret;
  };

  _i.buildLightTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-13 adduserLightSpan';
    ret.innerText = str;
    return ret;
  };

  _i.buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-13 adduserSpan';
    ret.innerText = str;
    return ret;
  };

  _i.initAddUserForm = function () {
    var container = document.createElement('DIV');
    container.className = 'blockFloatNot addUserPageForm';
    container.id = 'addUserPageForm';
    _i.container.appendChild(container);

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    _i.container.appendChild(spacer);

    _i.createUser = new primaryButton('adduserAddButton');
    _i.createUser.setButtonText(I18n.t('lbl_add_person'));
    _i.createUser.setCallback(_i.addUserButtonCallback);
    _i.container.appendChild(_i.createUser.container);
    _i.createUser.container.tabIndex = 0;

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.createMultiple = new secondaryButton('adduserAddButton');
    _i.createMultiple.setButtonText(I18n.t('lbl_add_multiple_people'));
    _i.createMultiple.setCallback(_i.addMultipleUserButtonCallback);
    _i.container.appendChild(_i.createMultiple.container);
    _i.createMultiple.container.tabIndex = 0;
  };

  _i.getUserCountsText = function () {
    var activeUsers = 0,
      invitedUsers = 0,
      resourceUsers = 0,
      archivedUsers = 0;

    _i.users = gData.getUsers();

    _i.users.forEach(function (u) {
      if (u.login && !u.deleted) {
        activeUsers += 1;
      } else if (!u.login && u.invitation_pending) {
        invitedUsers += 1;
      } else if (!u.login && !u.invitation_pending && !u.deleted) {
        resourceUsers += 1;
      } else if (u.deleted) {
        archivedUsers += 1;
      }
    });

    html = I18n.t('msg_add_new_html', {
      activeUsers: I18n.t('lbl_subtext', {
        count: activeUsers,
        type: I18n.t('lbl_active_lc'),
        users:
          activeUsers != 1 ? I18n.t('lbl_users_lc') : I18n.t('lbl_user_lc'),
      }),
      archivedUsers: I18n.t('lbl_subtext', {
        count: archivedUsers,
        type: I18n.t('lbl_archived_lc'),
        users:
          archivedUsers != 1 ? I18n.t('lbl_users_lc') : I18n.t('lbl_user_lc'),
      }),
      resourceUsers: I18n.t('lbl_subtext', {
        count: resourceUsers,
        type: I18n.t('lbl_schedule_only'),
        users:
          resourceUsers != 1 ? I18n.t('lbl_users_lc') : I18n.t('lbl_user_lc'),
      }),
      invitedUsers: I18n.t('lbl_subtext', {
        count: invitedUsers,
        type: I18n.t('lbl_invited_lc'),
        users:
          invitedUsers != 1 ? I18n.t('lbl_users_lc') : I18n.t('lbl_user_lc'),
      }),
    });

    return html;
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);

    _i.label = document.createElement('H3');
    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.label.innerHTML = _i.getUserCountsText();
    _i.container.appendChild(_i.label);

    _i.initAddUserForm();

    _i.usersCollection = document.createElement('DIV');
    _i.usersCollection.className = 'blockFloatNot';
    _i.container.appendChild(_i.usersCollection);

    _i.buildUserArrays();
    _i.displayUsers();
  };

  _i.constructor();
}
