/*
  CLASS: filterBar( attachTo ) : string or DOM element to attach HTML structure to
    .getSelection() : returns array of array of obj representing the selection state of the collection of dropdowns
    .setCallback( callback ) : callback gets getSelection() on filter change
    .addFilterDropdown( label, dropdownValues, multiSelect ) : label for dropdown, JSON list of values, boolean for multiselect
       the possible values going into the dropdown values can be seen in sample data and can be a label, a divider, or an item
       returns the dropdown object
    .removeFilterDropdown( dropdown ) : removes a filter dropdown that was returned by .addFilterDropdown
    .addControl( control ) : adds a standard control such as a button, checkbox, or other
    .removeControl( control ) : removes a control that was previously added with .addControl

*/

function filterBar(attachTo) {
  var _i = this;

  // params
  _i.attachTo = xGetElementById(attachTo);

  // locals
  _i.container = null;
  _i.labelCont = null;
  _i.labelText = null;
  _i.showAllBtn = null;
  _i.controls = [];
  _i.callback = null;

  _i.getSelection = function () {
    return _i.ddGroup.getSelection();
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.reIndex = function () {
    for (var z = 0; z < _i.controls.length; z++) {
      _i.controls[z].controlIndex = z;
    }
  };

  _i.removeControl = function (control) {
    _i.controls.splice(control.controlIndex, 1);
    _i.reIndex();

    control.destroy();
    control = null;
  };

  _i.addControl = function (control) {
    control.setAttachment(_i.container);
    control.controlIndex = _i.controls.length;
    _i.controls[_i.controls.length] = control;

    return control;
  };

  _i.filterChange = function () {
    if (_i.callback) {
      _i.callback(_i.getSelection());
    }
  };

  _i.showAllBtnClick = function () {
    _i.ddGroup.clearSelection();
    _i.filterChange();
  };

  _i.removeFilterDropdown = function (dd) {
    _i.ddGroup.removeFilterDropdown(dd);
  };

  _i.addFilterDropdown = function (label, dropdownValues, multiSelect) {
    return _i.ddGroup.addFilterDropdown(label, dropdownValues, multiSelect);
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'filterBar blockFloatNot';
    _i.attachTo.appendChild(_i.container);

    // container for big Filter label and showAll button
    _i.labelCont = document.createElement('DIV');
    _i.labelCont.className = 'blockFloat';
    _i.labelCont.style.width = 'auto';
    _i.labelCont.style.height = '100%';
    _i.labelCont.style.marginRight = '50px';
    _i.labelCont.style.marginLeft = '50px';
    _i.container.appendChild(_i.labelCont);

    _i.labelText = new textBlock('Filter', 'filterLabel');
    _i.labelText.setAttachment(_i.labelCont);
    setFloat(_i.labelText.container, 'blockFloatNot');
    setMargin(_i.labelText.container, 14, 0, 0, 0);

    _i.showAllBtn = new textBlock(
      'clear all filters',
      'showAllBtn',
      _i.labelCont.container
    );
    _i.showAllBtn.setAttachment(_i.labelCont);
    setFloat(_i.showAllBtn.container, 'blockFloatNot');
    _i.showAllBtn.setCallback(_i.showAllBtnClick);

    _i.ddGroup = new filterDropdownGroup(_i.container);
    _i.ddGroup.setOnChangeCallback(_i.filterChange);
  };

  _i.constructor();
}
