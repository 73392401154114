settingsYesNo = [
  {
    type: 'item',
    value: '0',
    label: 'No',
  },
  {
    type: 'item',
    value: '1',
    label: 'Yes',
  },
];

settingsFilter = [
  {
    type: 'item',
    value: '0',
    label: I18n.t('lbl_my_projects'),
  },
  {
    type: 'item',
    value: '1',
    label: I18n.t('lbl_all_projects'),
  },
];
