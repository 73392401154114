// implement the billing setup wizard
function billingWizard(wizardView, billingVM) {
  var _i = this;

  _i.wizardView = wizardView;

  _i.billingVM = billingVM;

  _i.useCardOnFile = false;

  var buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-18';
    ret.innerText = str;
    return ret;
  };

  var createSolidLine = function () {
    var l = document.createElement('DIV');
    l.className = 'blockFloat wizardSolidLine';
    return l;
  };

  var createDottedLine = function () {
    var l = document.createElement('DIV');
    l.className = 'blockFloat wizardDottedLine';
    return l;
  };

  _i.getFirstPage = function () {
    _i.createBillingFormPage();
    return _i.billingForm;
  };

  _i.cancelButtonCallback = function () {
    report_page_view('/cancel_billing_form');
    _i.wizardView.hide();
  };

  _i.doneButtonCallback = function () {
    RemovePageSpinner();
    _i.wizardView.hide();
  };

  _i.ccFormSubscribeButtonCallback = function () {
    report_page_view('/submit_billing_form');
    _i.billingVM.processBillingForm(_i.useCardOnFile);
  };

  _i.selectPlan = function (planId) {
    for (var id in _i.choosePlanOptions) {
      _i.choosePlanOptions[id].setEnabled(id == planId);
      if (id == planId) {
        _i.billingVM.setCurrentPlan(planId);
      }
    }

    _i.yearlyAmountSummary.innerHTML = I18n.t('msg_billing', {
      amount: _i.billingVM.currentPlan.price_yearly,
      saving: _i.billingVM.currentPlan.yearly_saving,
    });

    _i.updatePlanSummaryPanel(_i.planSummaryPanel);
  };

  _i.createBillingFormPage = function () {
    var d, spacer, leftCol, rightCol, planName;

    _i.billingForm = document.createElement('DIV');

    // CHOOSE A PLAN

    _i.choosePlan = document.createElement('DIV');

    _i.choosePlanTitle = document.createElement('DIV');
    _i.choosePlanTitle.className = 'blockFloat fnt-b-32 wizardTitle';
    _i.choosePlanTitle.innerHTML = I18n.t('lbl_choose_plan');

    _i.choosePlanDescription = document.createElement('DIV');
    _i.choosePlanDescription.className =
      'blockFloat fnt-r-14 wizardDescription';
    _i.choosePlanDescription.innerHTML = I18n.t('msg_active_user_count', {
      activeUserCount: _i.billingVM.currentUserCount,
    });

    _i.choosePlan.appendChild(_i.choosePlanTitle);
    _i.choosePlan.appendChild(_i.choosePlanDescription);

    _i.choosePlanOptions = {};

    _i.billingVM.plans.forEach(function (plan) {
      if (
        plan.hidden &&
        _i.billingVM.currentBillingInfo.show_hidden_plans == false
      ) {
        return;
      }
      d = document.createElement('DIV');
      d.className = 'blockFloat wizardSelectionRow';
      planName =
        _i.billingVM.currentPlan.id == plan.id && plan.grandfathered_in
          ? plan.display_name +
            "<span style='color:#3c3'> (grandfathered in)</span>"
          : plan.display_name;
      _i.choosePlanOptions[plan.id] = new SelectionToggle(
        d,
        buildPlainTextSpan(planName),
        _i.billingVM.currentPlan.id == plan.id,
        'blockFloat',
        'fnt-r-18 wizardSelectionRowText',
        'settingsSelectorIcon'
      );

      if (plan.max_users < _i.billingVM.currentUserCount) {
        d.style.opacity = '0.25';
        _i.choosePlanOptions[plan.id].inputContainer.style.cursor = 'default';
      } else {
        _i.choosePlanOptions[plan.id].setCallback(function () {
          _i.selectPlan(plan.id);
        });
      }

      var price = document.createElement('DIV');
      price.className = 'floatRightDiv fnt-r-18';
      price.innerHTML = I18n.t('lbl_value_per_month', {
        value: plan.price_monthly,
      });
      d.appendChild(price);

      _i.choosePlan.appendChild(d);
    });

    _i.choosePlan.appendChild(createDottedLine());

    d = document.createElement('DIV');
    d.className = 'blockFloat fnt-r-14 wizardLargePlan';
    d.innerHTML = I18n.t('msg_large_plan');
    _i.choosePlan.appendChild(d);

    _i.billingForm.appendChild(_i.choosePlan);

    // PAYMENT OCCURANCE

    _i.yearlyOrMonthly = document.createElement('DIV');

    _i.yearlyOrMonthlyTitle = document.createElement('DIV');
    _i.yearlyOrMonthlyTitle.className = 'blockFloatNot fnt-b-20 wizardSubTitle';
    _i.yearlyOrMonthlyTitle.innerHTML = I18n.t('lbl_payment_occurence');

    _i.yearlyOrMonthly.appendChild(_i.yearlyOrMonthlyTitle);

    d = document.createElement('DIV');
    d.className = 'blockFloatNot wizardSelectionRow';
    _i.selectMonthly = new SelectionToggle(
      d,
      buildPlainTextSpan(I18n.t('lbl_pay_monthly')),
      _i.billingVM.currentBillingInfo.payment_cycle == 'monthly',
      'blockFloat',
      'fnt-r-18 wizardSelectionRowText',
      'settingsSelectorIcon'
    );

    _i.selectMonthly.setCallback(function () {
      _i.selectYearly.setEnabled(false);
      _i.selectMonthly.setEnabled(true);
      _i.billingVM.setMonthlyBilling();
      _i.updatePlanSummaryPanel(_i.planSummaryPanel);
    });
    _i.yearlyOrMonthly.appendChild(d);

    d = document.createElement('DIV');
    d.className = 'blockFloat wizardSelectionRow';
    _i.selectYearly = new SelectionToggle(
      d,
      buildPlainTextSpan(I18n.t('lbl_pay_yearly')),
      _i.billingVM.currentBillingInfo.status == 'trial' ||
        _i.billingVM.currentBillingInfo.payment_cycle == 'yearly',
      'blockFloat',
      'fnt-r-18',
      'settingsSelectorIcon'
    );

    _i.selectYearly.setCallback(function () {
      _i.selectMonthly.setEnabled(false);
      _i.selectYearly.setEnabled(true);
      _i.billingVM.setYearlyBilling();
      _i.updatePlanSummaryPanel(_i.planSummaryPanel);
    });
    _i.yearlyOrMonthly.appendChild(d);

    _i.yearlyAmountSummary = document.createElement('DIV');
    _i.yearlyAmountSummary.className =
      'blockFloatNot fnt-r-15 wizardYearlySavingsExplanation';
    _i.yearlyAmountSummary.innerHTML = I18n.t('msg_billing', {
      amount: _i.billingVM.currentPlan.price_yearly,
      saving: _i.billingVM.currentPlan.yearly_saving,
    });
    _i.yearlyOrMonthly.appendChild(_i.yearlyAmountSummary);

    _i.yearlyOrMonthly.appendChild(createDottedLine());

    _i.billingForm.appendChild(_i.yearlyOrMonthly);

    // CC INFO

    _i.ccDetails = document.createElement('DIV');

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot wizardVspacer15px';
    _i.ccDetails.appendChild(spacer);

    _i.ccDetailsTitle = document.createElement('DIV');
    _i.ccDetailsTitle.className = 'blockFloatNot fnt-b-20 wizardSubTitle';
    _i.ccDetailsTitle.innerHTML = I18n.t('lbl_billing_info');

    _i.ccDetails.appendChild(_i.ccDetailsTitle);

    // CURRENT CARD OR NEW TOGGLE

    if (
      _i.billingVM.currentBillingInfo &&
      _i.billingVM.currentBillingInfo.last4
    ) {
      _i.useCardOnFile = true;

      d = document.createElement('DIV');
      d.className = 'blockFloatNot wizardSelectionRow';
      _i.useCurrentCard = new SelectionToggle(
        d,
        buildPlainTextSpan(
          I18n.t('lbl_last4', {
            last4: _i.billingVM.currentBillingInfo.last4,
            expiryMonth: _i.billingVM.currentBillingInfo.expiry_month,
            expiryYear: _i.billingVM.currentBillingInfo.expiry_year,
          })
        ),
        true,
        'blockFloat',
        'fnt-r-18 wizardSelectionRowText',
        'settingsSelectorIcon'
      );

      _i.useCurrentCard.setCallback(function () {
        _i.enterNewCard.setEnabled(false);
        _i.useCurrentCard.setEnabled(true);
        _i.useCardOnFile = true;
      });
      _i.ccDetails.appendChild(d);

      d = document.createElement('DIV');
      d.className = 'blockFloat wizardSelectionRow';
      _i.enterNewCard = new SelectionToggle(
        d,
        buildPlainTextSpan(I18n.t('lbl_new_card')),
        false,
        'blockFloat',
        'fnt-r-18',
        'settingsSelectorIcon'
      );

      _i.enterNewCard.setCallback(function () {
        _i.useCurrentCard.setEnabled(false);
        _i.enterNewCard.setEnabled(true);
        _i.useCardOnFile = false;
      });
      _i.ccDetails.appendChild(d);

      spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot wizardVspacer15px';
      _i.ccDetails.appendChild(spacer);
    }

    // NEW CC FORM

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot wizardVspacer15px';
    _i.ccDetails.appendChild(spacer);

    _i.errorInfo = document.createElement('DIV');
    _i.errorInfo.className = 'blockFloatNot fnt-r-14 wizardErrorBox';
    _i.errorInfo.innerHTML =
      'Sorry, something unexpected happened. If this continues to be a problem, please contact support with details at ' +
      window.SMARTSHEET_SUPPORT_URL;
    _i.ccDetails.appendChild(_i.errorInfo);

    leftCol = document.createElement('DIV');
    leftCol.className = 'blockFloatNot wizardLeftColumn';

    _i.ccDetails.appendChild(leftCol);

    d = document.createElement('DIV');
    d.className = 'blockFloat fnt-r-14 wizardWeAccept';
    d.innerHTML = I18n.t('lbl_we_accept');
    leftCol.appendChild(d);

    d = document.createElement('DIV');
    d.className = 'blockFloat wizardCcBanner';
    getSprite(compSpritesButtonsIcons.creditcardImages, d);
    leftCol.appendChild(d);

    _i.ccNumber = new TextInput(
      leftCol,
      I18n.t('lbl_card_number'),
      '',
      null,
      null,
      null,
      300
    );

    var expiryContainer = document.createElement('DIV');
    expiryContainer.className = 'blockFloatNot wizardExpiryContainer';
    leftCol.appendChild(expiryContainer);

    d = document.createElement('DIV');
    d.className = 'blockFloatNot fnt-r-13 defaultTextInputLabel';
    d.innerHTML = I18n.t('lbl_expiration_format');
    expiryContainer.appendChild(d);

    d = document.createElement('DIV');
    d.className = 'blockFloatNot';
    _i.ccExpiryMonth = new TextInput(d, '', '', null, null, null, 50);
    expiryContainer.appendChild(d);

    d = document.createElement('DIV');
    d.className = 'blockFloat';
    _i.ccExpiryYear = new TextInput(d, '', '', null, null, null, 70);
    expiryContainer.appendChild(d);

    var cvvContainer = document.createElement('DIV');
    cvvContainer.className = 'blockFloat wizardCvvContainer';
    leftCol.appendChild(cvvContainer);

    d = document.createElement('DIV');
    d.className = 'blockFloatNot fnt-r-13 defaultTextInputLabel';
    d.innerHTML = I18n.t('lbl_security_code');
    cvvContainer.appendChild(d);

    d = document.createElement('DIV');
    d.className = 'blockFloat';
    _i.ccCvv = new TextInput(d, '', '', null, null, null, 70);
    cvvContainer.appendChild(d);

    rightCol = document.createElement('DIV');
    rightCol.className = 'blockFloat wizardRightColumn';

    _i.yearlyOrMonthly.appendChild(rightCol);
    _i.updatePlanSummaryPanel(rightCol);
    _i.planSummaryPanel = rightCol;

    d = document.createElement('DIV');
    d.className = 'blockFloatNot fnt-r-13 defaultTextInputLabel';
    d.innerHTML = I18n.t('lbl_billing_zipcode');
    leftCol.appendChild(d);

    d = document.createElement('DIV');
    d.className = 'blockFloatNot wizardBillingZipContainer';
    _i.billingZip = new TextInput(d, '', '', null, null, null, 100);
    leftCol.appendChild(d);

    _i.ccDetails.appendChild(rightCol);

    _i.billingForm.appendChild(_i.ccDetails);

    // COUPON

    _i.couponDetails = document.createElement('DIV');

    _i.couponTextInput = new TextInput(
      _i.couponDetails,
      I18n.t('lbl_coupon_optional'),
      '',
      null,
      null,
      null,
      300
    );

    _i.ccDetails.appendChild(_i.couponDetails);

    d = document.createElement('DIV');
    d.className =
      'blockFloatNot fnt-r-13 defaultTextInputLabel wizardCouponHelpText';
    d.innerHTML = I18n.t('msg_coupon_help');
    _i.ccDetails.appendChild(d);

    // BUTTONS

    var buttonsOuter = document.createElement('DIV');
    buttonsOuter.className = 'blockFloatNot wizardTitle';

    var buttons = document.createElement('DIV');
    buttons.className = 'floatRightDiv wizardButtons';

    buttonsOuter.appendChild(buttons);

    _i.nextButton = new primaryButton('blockFloat wizardButton');
    _i.nextButton.setButtonText(I18n.t('lbl_subscribe'));
    _i.nextButton.setCallback(_i.ccFormSubscribeButtonCallback);
    _i.nextButton.container.tabIndex = 0;
    buttons.appendChild(_i.nextButton.container);

    _i.cancelButton = new secondaryButton('blockFloat wizardButton');
    _i.cancelButton.setButtonText(I18n.t('lbl_cancel'));
    _i.cancelButton.setCallback(_i.cancelButtonCallback);
    _i.cancelButton.container.tabIndex = 0;
    buttons.appendChild(_i.cancelButton.container);

    _i.billingForm.appendChild(buttonsOuter);

    if (_i.billingVM.currentBillingInfo.status != 'trial') {
      _i.selectPlan(_i.billingVM.currentPlan.id);
      _i.updatePlanSummaryPanel(_i.planSummaryPanel);
    }
  };

  _i.createThankYouPage = function () {
    var d, spacer, leftCol, rightCol;

    _i.thankYouPage = document.createElement('DIV');

    _i.thankYouMessage = document.createElement('DIV');

    _i.thankYouTitle = document.createElement('DIV');
    _i.thankYouTitle.className = 'blockFloat fnt-b-32 wizardTitle';
    _i.thankYouTitle.innerHTML = I18n.t('lbl_thank_you');

    _i.thankYouMessage.appendChild(_i.thankYouTitle);

    _i.thankYouPage.appendChild(_i.thankYouMessage);

    // BUTTONS

    var buttonsOuter = document.createElement('DIV');
    buttonsOuter.className = 'blockFloatNot wizardTitle';

    var buttons = document.createElement('DIV');
    buttons.className = 'floatRightDiv wizardButtons';

    buttonsOuter.appendChild(buttons);

    _i.doneButton = new secondaryButton('blockFloat wizardButton');
    _i.doneButton.setButtonText(I18n.t('lbl_done'));
    _i.doneButton.setCallback(_i.doneButtonCallback);
    _i.doneButton.container.tabIndex = 0;
    buttons.appendChild(_i.doneButton.container);

    _i.thankYouPage.appendChild(buttonsOuter);

    return _i.thankYouPage;
  };

  _i.getBillingInformationFromForm = function () {
    var cardData = {};
    cardData.number = _i.ccNumber.getValue();
    cardData.cvc = _i.ccCvv.getValue();
    cardData.expiryMonth = _i.ccExpiryMonth.getValue();
    cardData.expiryYear = _i.ccExpiryYear.getValue();
    cardData.zipOrPostalCode = _i.billingZip.getValue();
    cardData.coupon = _i.couponTextInput.getValue();
    return cardData;
  };

  _i.updatePlanSummaryPanel = function (ps) {
    removeAllChildren(ps);

    var d = document.createElement('DIV');
    d.className = 'blockFloatNot fnt-r-14 wizardPlanSummaryText';
    d.innerHTML = I18n.t('lbl_recurring_subscription');
    ps.appendChild(d);

    ps.appendChild(createSolidLine());

    d = document.createElement('DIV');
    d.className = 'blockFloatNot fnt-r-14 wizardPlanSummaryText';
    d.innerText = _i.billingVM.currentPlan.display_name;
    ps.appendChild(d);

    ps.appendChild(createSolidLine());

    d = document.createElement('DIV');
    d.className = 'blockFloatNot fnt-r-14 wizardPlanSummaryText';
    if (_i.billingVM.isYearlyBilling()) {
      d.innerHTML = I18n.t('lbl_bill_yearly');
    } else {
      d.innerHTML = I18n.t('lbl_bill_monthly');
    }
    ps.appendChild(d);

    ps.appendChild(createSolidLine());

    d = document.createElement('DIV');
    d.className = 'blockFloat fnt-r-14 wizardPlanSummaryText';
    if (_i.billingVM.isYearlyBilling()) {
      d.innerHTML = I18n.t('lbl_total_per_year');
    } else {
      d.innerHTML = I18n.t('lbl_total_per_month');
    }
    ps.appendChild(d);

    d = document.createElement('DIV');
    d.className = 'floatRightDiv fnt-r-14 wizardPlanSummaryText';
    if (_i.billingVM.isYearlyBilling()) {
      d.innerHTML = I18n.t('lbl_dollars_per_year', {
        value: _i.billingVM.currentPlan.price_yearly,
      });
    } else {
      d.innerHTML = I18n.t('lbl_dollars_per_year', {
        value: _i.billingVM.currentPlan.price_monthly,
      });
    }
    ps.appendChild(d);
  };

  _i.clearError = function () {
    _i.errorInfo.innerHTML = '';
    _i.errorInfo.style.display = 'none';
  };

  _i.onError = function (message) {
    if (_i.pspinner) {
      _i.pspinner.remove();
      _i.pspinner = null;
    }
    _i.errorInfo.innerText = message;
    _i.errorInfo.style.display = 'block';
    report_page_view('/error_in_billing_form');
  };

  _i.onDataValidated = function () {
    _i.clearError();
    _i.pspinner = new pageSpinner();
  };

  _i.onTokenRecieved = function () {
    _TR('token received');
  };

  _i.onSubscriptionSaved = function () {
    if (_i.pspinner) {
      _i.pspinner.remove();
      _i.pspinner = null;
    }
    _i.wizardView.setPage(_i.createThankYouPage());
    refreshAllCookies();
    report_page_view('/subscription_completed');
    trackGAWConversion(GOOGLE_CONVERSION_TRACKING_LABELS.BILLING_THANKYOU_PAGE);
  };

  _i.onCouponValidated = function () {
    _TR('coupon validate');
  };

  _i.constructor = function () {
    _i.billingVM.initializeBillingForm(
      _i.getBillingInformationFromForm,
      _i.onDataValidated,
      _i.onTokenRecieved,
      _i.onSubscriptionSaved,
      _i.onError
    );
  };

  _i.constructor();
}
