/*
 * grid time units and column width calculations.
 *
 * IMPORTANT: Note that this object neither references nor directly
 * manipulates anything in the DOM.
 */

function gridTimeUnit(pixelsPerDay) {
  var _i = this,
    mode,
    GRID_PIXELS_PER_DAY_DEFAULT_IN_WEEK_VIEW = 32;

  var _daysInMonth = [
    31, -1 /* february must be checked for leap years */, 31, 30, 31, 30, 31,
    31, 30, 31, 30, 31,
  ];

  function getDaysInMonth(d) {
    var days = _daysInMonth[d.getMonth()];
    if (days == -1) {
      // http://bit.ly/QKTl7X
      var y = d.getFullYear();
      days = y % 400 == 0 || (y % 100 > 0 && y % 4 == 0) ? 29 : 28;
    }
    return days;
  }

  function getColumnStartDateForWeek(d) {
    return Date.addDays(
      d,
      -d.getDay() + Number(gData.accountSettings.first_working_day)
    );
  }

  function getColumnLabelInDayView(d) {
    if (_i.pxPerDay < 115) {
      return d.toLocaleDateString(window.I18n.locale, {
        day: 'numeric',
        month: 'numeric',
      });
    } else {
      return d.toLocaleDateString(window.I18n.locale, {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
      });
    }
  }

  function getColumnLabelInWeekView(d) {
    var csd = getColumnStartDateForWeek(d),
      ced = Date.addDays(csd, 6);
    var ret = csd.toLocaleDateString(window.I18n.locale, {
      day: 'numeric',
      month: 'short',
    });
    if (!(_i.pxPerDay < 20)) {
      var monthNameComesSecond = !isNaN(parseInt(ret, 10)),
        weekDoesNotEndInNextMonth = csd.getMonth() == ced.getMonth();

      if (monthNameComesSecond && weekDoesNotEndInNextMonth) {
        ret =
          csd.getDate() +
          ' \u2014 ' +
          ced.toLocaleDateString(window.I18n.locale, {
            day: 'numeric',
            month: 'short',
          });
      } else if (weekDoesNotEndInNextMonth) {
        ret += ' \u2014 ' + ced.getDate();
      } else {
        ret +=
          ' \u2014 ' +
          ced.toLocaleDateString(window.I18n.locale, {
            day: 'numeric',
            month: 'short',
          });
      }

      var firstFullWeekOfJanuary =
        d.getMonth() == 0 &&
        d.getDate() <= 7 &&
        d.getDay() <= 6 - d.getDay() - csd.getDay();

      if (firstFullWeekOfJanuary) {
        ret += ', ' + csd.getFullYear();
      }
    }

    var weekNumber = csd.getWeek(
      Number(gData.accountSettings.first_working_day)
    );
    var weekLabel =
      window.I18n.locale === 'ja' || window.I18n.locale === 'ja-JP'
        ? weekNumber + '週目'
        : I18n.t('lbl_week') + ' ' + weekNumber;

    ret += "<br/><span class='fnt-r-10'>" + weekLabel + '</span>';
    return ret;
  }

  function getColumnLabelInMonthView(d) {
    var month = d.getMonth(),
      january = month === 0,
      tinyColumn = _i.pxPerDay < 2,
      smallColumn = _i.pxPerDay < 4;

    if (january) {
      return smallColumn
        ? "'" + ('' + d.getFullYear()).substring(2, 4)
        : d.toLocaleDateString(window.I18n.locale, { month: 'long' }) +
            ', ' +
            d.getFullYear();
    }
    if (tinyColumn) {
      if (window.I18n.locale === 'ja' || window.I18n.locale === 'ja-JP') {
        return month + 1;
      }
      return d
        .toLocaleDateString(window.I18n.locale, { month: 'short' })
        .substring(0, 1);
    }
    if (smallColumn) {
      return d.toLocaleDateString(window.I18n.locale, { month: 'short' });
    }
    return d.toLocaleDateString(window.I18n.locale, { month: 'long' });
  }

  function reinitializeMode() {
    if (_i.pxPerDay < 20) {
      mode = 'month';
      _i.name = 'month';
      _i.displayValue = 'M';
    } else if (_i.pxPerDay < 70) {
      mode = 'week';
      _i.name = 'week';
      _i.displayValue = 'W';
    } else {
      mode = 'day';
      _i.name = 'day';
      _i.displayValue = 'D';
    }
  }

  _i.pxPerDay = pixelsPerDay
    ? pixelsPerDay
    : GRID_PIXELS_PER_DAY_DEFAULT_IN_WEEK_VIEW /*default*/;
  _i.name;
  _i.displayValue;

  _i.setPxPerDay = function (px) {
    _i.pxPerDay = px || GRID_PIXELS_PER_DAY_DEFAULT_IN_WEEK_VIEW;
    reinitializeMode();
  };

  _i.zoom = function (direction) {
    var direction = direction && direction < 0 ? -1 : 1,
      STEP = 4,
      MIN = 4;
    _i.pxPerDay = _i.pxPerDay + direction * STEP;
    if (_i.pxPerDay < MIN) {
      _i.pxPerDay = MIN;
    }
    reinitializeMode();
  };

  _i.getColumnStartDate = function (d) {
    switch (mode) {
      case 'month':
        return Date.addDays(d, -d.getDate() + 1);
      case 'week':
        return getColumnStartDateForWeek(d);
      default:
        /*day*/ return d;
    }
  };

  _i.getColumnWidthPx = function (d) {
    switch (mode) {
      case 'month':
        return getDaysInMonth(d) * _i.pxPerDay;
      case 'week':
        return _i.pxPerDay * 7 /*days per week*/;
      default:
        /*day*/ return _i.pxPerDay;
    }
  };

  _i.getColumnWidthDays = function (d) {
    switch (mode) {
      case 'month':
        return getDaysInMonth(d);
      case 'week':
        return 7;
      default:
        /*day*/ return 1;
    }
  };

  _i.getColumnLabel = function (d) {
    switch (mode) {
      case 'month':
        return getColumnLabelInMonthView(d);
      case 'week':
        return getColumnLabelInWeekView(d);
      default:
        /*day*/ return getColumnLabelInDayView(d);
    }
  };

  _i.getNextRulerButtonDate = function (d, direction) {
    direction = direction && direction < 0 ? -1 : 1;
    switch (mode) {
      case 'month':
        return new Date(d.getFullYear(), d.getMonth() + direction, 1);
      case 'week':
        return Date.addDays(d, 7 * direction);
      default:
        /*day*/ return Date.addDays(d, 7 * direction);
    }
  };

  _i.getRulerButtonWidthPx = function () {
    return 70;
  };

  _i.getRulerButtonLabel = function (d) {
    switch (mode) {
      case 'month':
        if (d.getMonth() == 0) {
          return d.getFullYear();
        } else {
          return d.toLocaleDateString(window.I18n.locale, {
            month: 'short',
          });
        }
      case 'week':
      default:
        // day
        return d.toLocaleDateString(window.I18n.locale, {
          day: 'numeric',
          month: 'numeric',
        });
    }
  };

  var _ctor = (function () {
    reinitializeMode();
  })();
}
