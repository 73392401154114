// This is essentially the same as selectionlistItem, except no event listeners are added.
// This allows us to mimic the look of a selectionlist while actually using jsvlv.
function selectionlistItemBasic(rowHeight, index, dat, multiselect) {
  var _i = this;

  __A(
    !dat.isexclusive || (dat.isexclusive && multiselect),
    'exclusive should only be used in multiselect lists.'
  );

  // params
  _i.rowHeight = rowHeight;
  _i.index = index;
  _i.dat = dat;
  _i.multiselect = multiselect;

  // locals
  _i.container = null;
  _i.inner = null;
  _i.bground = null;
  _i.checkbox = null;

  _i.hide = function () {
    _i.container.style.display = 'none';
  };

  _i.show = function () {
    _i.container.style.display = 'block';
  };

  _i.formatLabel = function () {
    _i.container.style.cursor = 'default';
    _i.container.style.color = '#808080';

    if (_i.dat.label && _i.dat.label.nodeName) {
      _i.text.appendChild(_i.dat.label);
    } else {
      _i.text.innerText = _i.dat.label;
    }
  };

  _i.formatDivider = function () {
    _i.container.style.cursor = 'default';
    _i.container.style.height = '8px';
  };

  _i.updateItem = function (dat) {
    if (_i.dat.label.nodeName) {
      removeAllChildren(_i.text);
      _i.text.appendChild(dat.label);
    } else {
      _i.text.innerHTML = dat.escaped ? dat.label : _.escape(dat.label);
    }

    if (_i.icon && dat.icon) {
      newIcon = getSprite(compSpritesButtonsIcons[dat.icon]);
      newIcon.className = 'blockFloat';
      newIcon.style.marginTop =
        (parseInt(_i.container.style.height) -
          compSpritesButtonsIcons[dat.icon].height) /
          2 +
        'px';
      _i.inner.replaceChild(newIcon, _i.icon);

      _i.icon = newIcon;
    } else if (dat.iconURL) {
      newIcon = document.createElement('IMG');
      newIcon.src = _i.dat.iconURL;
      newIcon.style.width = '23px';
      newIcon.style.maxWidth = '23px';
      _i.iconCont.replaceChild(newIcon, _i.icon);

      _i.icon = newIcon;
    }
  };
  _i.formatItem = function () {
    _i.container.className += ' greyHover';
    _i.container.style.cursor = 'pointer';
    if (_i.dat.label && _i.dat.label.nodeName) {
      _i.text.appendChild(_i.dat.label);
    } else if (_i.dat.label) {
      _i.text.innerHTML = dat.escaped ? dat.label : _.escape(dat.label);
    } else {
      _i.container.style.float = 'center';
      _i.text.style.display = 'none';
    }

    _i.text.style.marginLeft = '6px'; // for IE9
    _i.text.style.marginTop = '1px';

    if (_i.multiselect) {
      if (_i.dat.isexclusive) {
        if (_i.dat.icon) {
          _i.icon = getSprite(compSpritesButtonsIcons[_i.dat.icon]);
          _i.icon.className = 'blockFloat multiSelectPopupIcon';
          _i.icon.style.marginTop =
            (_i.dat.iconMarginTop ||
              (parseInt(_i.container.style.height) -
                compSpritesButtonsIcons[_i.dat.icon].height) /
                2) + 'px';
          _i.inner.appendChild(_i.icon);
        }
      } else {
        _i.text.style.marginTop = null;
        _i.text.style.marginLeft = null;
        _i.checkboxContainer = document.createElement('DIV');
        _i.checkboxContainer.className =
          'blockFloat multiSelectPopupIconContainer';
        _i.inner.appendChild(_i.checkboxContainer);
        _i.checkbox = document.createElement('DIV');
        _i.checkbox.className = 'blockFloat multiSelectPopupIcon';
        _i.checkboxContainer.appendChild(_i.checkbox);
        getSprite(compSpritesButtonsIcons.unselectedCheckbox, _i.checkbox);
        _i.separator = document.createElement('DIV');
        _i.separator.className = 'blockFloat multiSelectPopupSeperator';
        _i.inner.appendChild(_i.separator);
        if (_i.dat.icon) {
          _i.icon = getSprite(compSpritesButtonsIcons[_i.dat.icon]);
          _i.icon.className = 'blockFloat multiSelectPopupIcon';
          _i.icon.style.marginTop =
            (_i.dat.iconMarginTop ||
              (parseInt(_i.container.style.height) -
                compSpritesButtonsIcons[_i.dat.icon].height) /
                2) + 'px';
          _i.inner.appendChild(_i.icon);
        }
      }
    } else if (_i.dat.icon) {
      _i.icon = getSprite(compSpritesButtonsIcons[_i.dat.icon]);
      _i.icon.className = 'blockFloat';
      _i.icon.style.marginTop =
        (_i.dat.iconMarginTop ||
          (parseInt(_i.container.style.height) -
            compSpritesButtonsIcons[_i.dat.icon].height) /
            2) + 'px';
      _i.inner.appendChild(_i.icon);
    } else if (_i.dat.iconURL) {
      _i.iconCont = document.createElement('DIV');
      _i.iconCont.className = 'blockFloat';
      _i.iconCont.style.marginTop = '4px';
      _i.iconCont.style.width = '23px';
      _i.iconCont.style.height = '23px';
      _i.iconCont.style.overflow = 'hidden';
      _i.iconCont.style.borderRadius = '2px';
      _i.iconCont.style.webkitBorderRadius = '2px';
      _i.iconCont.style.mozBorderRadius = '2px';
      _i.icon = document.createElement('IMG');
      _i.icon.src = _i.dat.iconURL;
      _i.icon.style.width = '23px';
      _i.icon.style.maxWidth = '23px';
      _i.iconCont.appendChild(_i.icon);
      _i.inner.appendChild(_i.iconCont);
    }

    _i.dat.container = _i.container;
  };

  _i.sizeLabel = function () {
    // size item labels only
    if (_i.dat.type != 'item') {
      return;
    }

    var w = xWidth(_i.inner) - xOffset(_i.text, _i.inner).x - 25;
    if (_i.multiselect) w += 25;
    xWidth(_i.text, w);
    var total = $(_i.text).outerWidth(true);
    if (_i.iconCont) total += $(_i.iconCont).outerWidth(true);
    else if (_i.icon) total += $(_i.icon).outerWidth(true);
    if (_i.checkboxContainer) total += $(_i.checkboxContainer).outerWidth(true);
    if (_i.separator) total += $(_i.separator).outerWidth(true);
    _i.inner.style.minWidth = total + 'px';
  };

  _i.buildLabelDiv = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot unselectable';
    _i.container.style.margin = '0px';
    _i.container.style.marginBottom = '1px';
    _i.container.style.width = '100%';

    _i.inner = document.createElement('DIV');
    _i.inner.className = 'blockFloat';
    _i.inner.style.width = '100%';
    _i.inner.style.height = '100%';
    _i.inner.style.overflow = 'hidden';
    _i.container.style.whiteSpace = 'nowrap';

    _i.text = document.createElement('DIV');

    _i.formatLabel();

    _i.container.appendChild(_i.inner);
    _i.inner.appendChild(_i.text);
  };

  _i.constructor = function () {
    if (_i.dat.type == 'labeldiv') {
      _i.buildLabelDiv();
      return;
    }

    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot unselectable';
    _i.container.style.margin = '0px';
    _i.container.style.marginBottom = _i.multiselect ? '' : '1px';
    _i.container.style.color = '#1A1A1A';
    _i.container.style.whiteSpace = 'nowrap';
    _i.container.style.width = '100%';
    _i.container.style.height = _i.rowHeight + 'px';
    _i.container.style.lineHeight = _i.container.style.height;
    _i.container.style.overflow = 'hidden';

    _i.container.title = _i.dat.label.replace(/\<[^\>]+\>/g, ''); // remove html tags

    _i.bground = new backgroundBar(compSpritesButtonsIcons.menuSelect);
    _i.bground.hide();

    _i.hover = new backgroundBar(compSpritesButtonsIcons.menuHover);
    _i.hover.hide();

    _i.inner = document.createElement('DIV');
    _i.inner.className = 'blockFloat';
    _i.inner.style.width = '100%';
    _i.inner.style.height = '100%';
    _i.inner.style.overflow = 'hidden';

    _i.text = document.createElement('DIV');
    _i.text.style.maxWidth = '386px';
    if (_i.multiselect && _i.dat.type == 'item') {
      _i.text.className = 'blockFloat fnt-r-14 multiSelectPopupLabel ellipsis';
    } else {
      _i.inner.style.marginLeft = '10px';
      _i.text.className = 'blockFloat fnt-r-14 ellipsis';
    }
    if (_i.dat.type == 'label') {
      _i.formatLabel();
    }
    if (_i.dat.type == 'divider') {
      _i.formatDivider();
    }
    if (_i.dat.type == 'item') {
      _i.formatItem();
    }

    _i.container.appendChild(_i.bground.container);
    _i.container.appendChild(_i.hover.container);
    _i.container.appendChild(_i.inner);
    _i.inner.appendChild(_i.text);
  };

  _i.constructor();
}
