// billing, via stripe

function billingVM() {
  var _i = this;

  _i.getBillingInfoFromFormCallback = null;
  _i.onTokenReceivedCallback = null;
  _i.onErrorCallback = null;
  _i.billingInfo = null; // user supplied, via form
  _i.currentBillingInfo = null; // in server database

  _i.plans = [];

  function getCurrentUserCount() {
    var users = gData.getUsers(true);
    var count = 0;
    var i, u;
    for (i = 0; i < users.length; i++) {
      u = users[i];
      if (u.license_type === 'licensed') {
        count++;
      }
    }
    return count;
  }

  _i.currentUserCount = getCurrentUserCount();

  function getResourceUserCount() {
    var users = gData.getUsers(true);
    var count = 0;
    var i, u;
    for (i = 0; i < users.length; i++) {
      u = users[i];
      if (u.license_type === 'managed_resource') {
        count++;
      }
    }
    return count;
  }

  _i.resourceUserCount = getResourceUserCount();

  _i.loadPlans = function (callback) {
    new injectJS(
      SERVICE_BASE_URL + '/plans',
      function (data) {
        _i.plans = data;
        _i.currentPlan = _i.plans[0];
        for (var i = 0; i < _i.plans.length; i++) {
          if (_i.plans[i].max_users < _i.currentUserCount) {
            _i.currentPlan = _i.plans[i + 1];
          } else {
            break;
          }
        }
        callback && callback(data);
      },
      onerror
    );
  };

  _i.currentPaymentCycle = 'yearly'; // default

  _i.initializeBillingForm = function (
    getBillingInfoFromFormCallback,
    onDataValidatedCallback,
    onTokenReceivedCallback,
    onSubscriptionSavedCallback,
    onErrorCallback
  ) {
    // Stripe.setPublishableKey('');

    _i.getBillingInfoFromFormCallback = getBillingInfoFromFormCallback;
    _i.onDataValidatedCallback = onDataValidatedCallback;
    _i.onTokenReceivedCallback = onTokenReceivedCallback;
    _i.onSubscriptionSavedCallback = onSubscriptionSavedCallback;
    _i.onErrorCallback = onErrorCallback;
  };

  _i.setCurrentPlan = function (planId) {
    for (var i = 0; i < _i.plans.length; i++) {
      if (_i.plans[i].id == planId) {
        _i.currentPlan = _i.plans[i];
        return;
      }
    }
  };

  _i.setMonthlyBilling = function () {
    _i.currentPaymentCycle = 'monthly';
  };

  _i.setYearlyBilling = function () {
    _i.currentPaymentCycle = 'yearly';
  };

  _i.isYearlyBilling = function () {
    return _i.currentPaymentCycle == 'yearly';
  };

  _i.getPlan = function (planId) {
    for (var i = 0; i < _i.plans.length; i++) {
      if (_i.plans[i].id == planId) {
        return _i.plans[i];
      }
    }
  };

  _i.getStripePlanId = function () {
    if (_i.currentPaymentCycle == 'monthly') {
      return _i.currentPlan.stripe_id_monthly;
    } else {
      return _i.currentPlan.stripe_id_yearly;
    }
  };

  _i.processBillingForm = function (useCardOnFile) {
    _i.billingInfo = _i.getBillingInfoFromFormCallback();

    if (useCardOnFile) {
      _i.createOrUpdateBillingInfo(
        _i.currentPlan.id,
        _i.getStripePlanId(),
        _i.currentPaymentCycle,
        null,
        null,
        null,
        null,
        null,
        null,
        _i.onSubscriptionSavedCallback,
        _i.onErrorCallback
      );
    } else {
      var validationError = false;

      if (!Stripe.validateCardNumber(_i.billingInfo.number)) {
        _i.onErrorCallback(I18n.t('err_invalid_card_num'));
        validationError = true;
      } else if (
        !Stripe.validateExpiry(
          _i.billingInfo.expiryMonth,
          _i.billingInfo.expiryYear
        )
      ) {
        _i.onErrorCallback(I18n.t('err_invalid_expiry'));
        validationError = true;
      } else if (!Stripe.validateCVC(_i.billingInfo.cvc)) {
        _i.onErrorCallback(I18n.t('err_invalid_cvc'));
        validationError = true;
      } else if (!_i.billingInfo.zipOrPostalCode) {
        _i.onErrorCallback(I18n.t('err_invaid_zipcode'));
        validationError = true;
      }

      if (!validationError) {
        _i.onDataValidatedCallback();
        Stripe.createToken(
          {
            number: _i.billingInfo.number,
            cvc: _i.billingInfo.cvc,
            exp_month: _i.billingInfo.expiryMonth,
            exp_year: _i.billingInfo.expiryYear,
            address_zip: _i.billingInfo.zipOrPostalCode,
          },
          _i.stripeCreateTokenResponseHandler
        );
      }
    }
  };

  _i.stripeCreateTokenResponseHandler = function (status, response) {
    if (response.error) {
      _i.onErrorCallback(response.error.message);
    } else {
      if (_i.onTokenReceivedCallback) {
        _i.onTokenReceivedCallback();
      }
      var token = response['id'];
      var last4 = response['card']['last4'];
      var exp_month = response['card']['exp_month'];
      var exp_year = response['card']['exp_year'];

      _i.createOrUpdateBillingInfo(
        _i.currentPlan.id,
        _i.getStripePlanId(),
        _i.currentPaymentCycle,
        token,
        last4,
        exp_month,
        exp_year,
        _i.billingInfo.zipOrPostalCode,
        _i.billingInfo.coupon,
        _i.onSubscriptionSavedCallback,
        _i.onErrorCallback
      );
    }
  };

  _i.createOrUpdateBillingInfo = function (
    plan,
    stripe_plan,
    payment_cycle,
    token,
    last4,
    expiryMonth,
    expiryYear,
    zipOrPostalCode,
    coupon,
    callback,
    onerror
  ) {
    var url = SERVICE_BASE_URL + '/organizations/subscription/update';
    var payload = {};
    payload['plan'] = plan;
    payload['stripe_plan_id'] = stripe_plan;
    payload['payment_cycle'] = payment_cycle;

    if (token) {
      payload['stripe_token'] = token;
      payload['last4'] = last4;
      payload['exp_month'] = expiryMonth;
      payload['exp_year'] = expiryYear;
      payload['zopc'] = zipOrPostalCode;
      payload['coupon'] = coupon;
    }

    putJSV1(
      url,
      payload,
      function (data) {
        _i._onCurrentBillingInfoLoaded(data);
        callback && callback(data);
      },
      onerror
    );
  };

  // returns an array of { date:d,value:v,plan:p }
  _i.getInvoices = function (startDate, endDate) {
    // TODO implement
    return null;
  };

  _i.getCurrentBillingInfo = function (callback) {
    if (_i.currentBillingInfo) {
      callback(_i.currentBillingInfo);
    } else {
      _i._loadCurrentBillingInfo(callback);
    }
  };

  _i._loadCurrentBillingInfo = function (callback) {
    new injectJS(
      SERVICE_BASE_URL + '/organizations/subscription',
      function (data) {
        _i._onCurrentBillingInfoLoaded(data);
        callback && callback(data);
      },
      onerror
    );
  };

  _i._onCurrentBillingInfoLoaded = function (data) {
    _i.currentBillingInfo = data;
    if (data.plan) {
      _i.currentPaymentCycle = data.payment_cycle;
    }
    _i.currentBillingInfo.trial_ends_at = parseRubyDate(
      _i.currentBillingInfo.trial_ends_at
    );
  };
}
