function AddProjectDataControl(
  allowblank,
  showCurrentProjects,
  container,
  onProfilePage
) {
  var _i = this;

  _i.allowblank = allowblank;
  _i.isLoading;

  _i.itemFromProject = function (proj) {
    var item = {};
    item.type = 'item';
    item.value = proj;
    item.label = (proj.client ? proj.client : '') + ' ' + proj.name;

    var icon = 'externalMark';
    if (proj.type != 'Project') {
      icon = 'leaveMark';
    } else if (proj.project_state) {
      if (proj.project_state == 'Internal') {
        icon = 'internalMark';
      } else if (proj.project_state == 'Tentative') {
        icon = 'grayMark';
      }
    }
    item.icon = icon;

    return item;
  };

  _i.buildBlank = function () {
    if (_i.allowblank) {
      _i.projectNames.push({
        type: 'item',
        value: null,
        label: '- leave blank -',
      });
    }
  };

  _i.buildCurrentProjects = function () {
    if (showCurrentProjects) {
      var current = gData.getMe().getCurrentAssignables();

      if (current.length > 0) {
        _i.projectNames.push({
          type: 'label',
          label: 'Current Projects',
        });

        var current = gData.getMe().getCurrentAssignables();
        current.sort(gData.assignableComparer);
        for (var i = 0; i < current.length; i++) {
          _i.projectNames.push(_i.itemFromProject(current[i]));
        }

        _i.projectNames.push({
          type: 'label',
          label: 'All Projects',
        });
      }
    }
  };

  _i.buildAllProjects = function (assignables) {
    assignables.sort(function (a, b) {
      if (a.client && b.client) {
        var sa = a.client.toLowerCase();
        var sb = b.client.toLowerCase();
        return sa < sb ? -1 : 1;
      } else if (b.client) return 1;
      else return -1;
    });
    for (var i = 0; i < assignables.length; i++) {
      _i.projectNames.push(_i.itemFromProject(assignables[i]));
    }
  };

  _i.buildBlankAndProjects = function (assignables) {
    _i.buildBlank();
    _i.buildCurrentProjects();
    _i.buildAllProjects(assignables);
  };

  _i.buildProjectNames = function () {
    _i.projectNames = [];

    if (onProfilePage) {
      if (gData.allAssignablesLoaded()) {
        var assignables = gData.getAssignables(true);
        _i.buildBlankAndProjects(assignables);
      } else {
        gService.getAssignablesForStatusView(cb, false);
        function cb(assignables) {
          _i.buildBlankAndProjects(assignables);
          container.removeSpinner();
        }
      }
    } else {
      _i.buildBlank();
      _i.buildCurrentProjects();
      // get all assignables
      var assignables = gData.getAssignables(true);
      _i.buildAllProjects(assignables);
    }
  };

  _i.constructor = function () {
    _i.buildProjectNames();
  };

  _i.constructor();
}
