// API Response Cache (ARC)
function arc(whitelist, shouldPurgeStaleData, disable) {
  var cacheableUrlWhiteList = whitelist,
    VERSION = 2,
    META_SUFFIX = '_meta',
    __CACHE_DISABLED = disable;

  // given an API url and a unique session id, typically a user-id,
  // geenrate a cache key for the data to be cached.
  function getKey(url, uid) {
    return hashCode(url + uid);
  }

  // create meta-data about the api response to be cached
  function createMetaData(url, uid, xhr) {
    var e = xhr.getResponseHeader('ETag'),
      lm = xhr.getResponseHeader('Last-Modified');
    return {
      v: VERSION,
      et: e,
      lm: lm,
      u: url,
    };
  }

  function trackEvent(url, label) {
    return;
  }

  function isMetaDataKey(key) {
    return key.substr(-META_SUFFIX.length) === META_SUFFIX;
  }

  function getMetaData(key) {
    var meta;
    try {
      meta = JSON.parse(sessionStorage[key]);
    } catch (e) {
      meta &&
        _TR(
          'warning: cannot parse cache meta-data with key ' +
            key +
            '. error = ' +
            e
        );
      // treat the meta value as a string (unparsable JSON string])
      meta = sessionStorage[key];
    }
    return meta;
  }

  function removeMatchingEntries(matchDelegate) {
    var i,
      key,
      meta,
      keysToDelete = [];

    if (typeof matchDelegate != 'function') {
      throw 'invalid matchDelegate';
    }

    for (i = 0; i < sessionStorage.length; i++) {
      key = sessionStorage.key(i);
      if (isMetaDataKey(key)) {
        meta = getMetaData(key);
        if (meta && matchDelegate(meta)) {
          keysToDelete.push(key);
          keysToDelete.push(key.substr(0, key.length - META_SUFFIX.length));
        }
      }
    }
    keysToDelete.forEach(function (k) {
      sessionStorage.removeItem(k);
    });
  }

  function purgeStaleData() {
    // purge if the version does not match or __CACHE_DISABLED
    function stale(meta) {
      return meta.v != VERSION || __CACHE_DISABLED;
    }
    removeMatchingEntries(stale);
  }

  this.isCacheable = function (url) {
    return (
      _.findIndex(cacheableUrlWhiteList, function (s) {
        return url.includes(s);
      }) >= 0
    );
  };

  this.write = function (url, uid, xhr, response) {
    var key = getKey(url, uid),
      key_meta = key + META_SUFFIX;

    if (__CACHE_DISABLED) {
      return;
    }

    try {
      sessionStorage[key] = LZString.compressToUTF16(response);
      sessionStorage[key_meta] = JSON.stringify(createMetaData(url, uid, xhr));
    } catch (e) {
      if (e.name === 'QUOTA_EXCEEDED_ERR') {
        // continue without caching
        sessionStorage[key] = null;
        sessionStorage[key_meta] = null;
        trackEvent(url, 'overflow');
      }
    }
  };

  this.read = function (url, uid) {
    var key = getKey(url, uid),
      key_meta = key + META_SUFFIX,
      meta,
      response;

    if (__CACHE_DISABLED) {
      return null;
    }

    meta = getMetaData(key_meta);
    response = sessionStorage[key];

    if (response) {
      trackEvent(url, 'hit');
    } else {
      trackEvent(url, 'miss');
    }

    if (meta && meta.v > 1) {
      // compression added in version 2
      response = LZString.decompressFromUTF16(response);
    }

    return response;
  };

  this.expire = function (urlMatcher) {
    function shouldExpire(meta) {
      return meta.v == VERSION && meta.u.indexOf(urlMatcher) >= 0;
    }
    removeMatchingEntries(shouldExpire);
  };

  shouldPurgeStaleData && setTimeout(purgeStaleData, 1000);

  return this;
}
