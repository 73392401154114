function FeedController(feedName) {
  if (!(this instanceof FeedController)) return new FeedController(feedName);
  var options = {};
  options.pollingEnabled = FEED_POLLING_ENABLED || false;
  options.pollingInterval = FEED_POLLING_INTERVAL || 60000; // one minute

  var self = this;
  self.options = options;

  self._feedName = feedName;
  var parts = feedName.split(':');
  var feedTypeExt = parts[0] == 'user' ? '_notification/' : '_aggregated/';

  self.url = ENS_FEED_URL + '/v1/' + parts[0] + feedTypeExt + parts[1];
  self._html = '';
}

FeedController.prototype.clear = function () {
  this._html = '';
};

FeedController.prototype.polling = function (loadFn) {
  var self = this;

  function polling(rate) {
    setTimeout(function () {
      var mydata = {};
      async.series(
        [
          function (cb) {
            self.fetch(null, function (data) {
              mydata = data;
              cb();
            });
          },
          function (cb) {
            loadFn(mydata);
            cb();
          },
        ],
        function () {
          // prevent polling if not enabled
          if (self.options.pollingEnabled == true) polling();
        }
      );
    }, rate || self.options.pollingInterval);
  }
  // override timeout for the first fetch for activity from ens
  polling(1);
};

FeedController.prototype.fetch = function (options, callback) {
  var self = this;
  var url = self.url;

  options = options || {};
  // TODO add paging mechanism to provide better feed experience
  if (!options.limit) options.limit = 250;
  if (!options.offset) options.offset = 0;
  if (!options.application) options.application = 'plans';

  url += '?' + $.param(options);

  $.ajax({
    url: url,
    xhrFields: {
      withCredentials: true,
    },
  })
    .done(function (html) {
      self._html = html;
      return callback();
    })
    .fail(function (jqxhr, textStatus, error) {
      var err = textStatus + ', ' + error;
      console.log('Request to ENS Failed: ' + err);
      callback('request-failed');
    });
};

FeedController.prototype.getHTML = function () {
  return this._html;
};
