function FeedItemView(attach, obj) {
  if (!(this instanceof FeedItemView)) {
    return new FeedItemView(attach, obj);
  }

  var _i = this;
  _i.feeditem = obj;
  _i.attachTo = xGetElementById(attach);
  _i.guid = Awe.getGuid();

  _i.handleUserIconClicked = function () {
    if (_i.feeditem.user)
      window.location = getBaseURL() + '?user_id=' + _i.feeditem.user.guid;
  };

  _i.buildBoldTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-b-13';
    ret.innerText = str;
    return ret;
  };

  _i.buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-13';
    ret.innerText = str;
    return ret;
  };

  _i.buildProjectClickableSpan = function (proj) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-b-13';
    ret.style.cursor = 'pointer';
    var url = getBaseURL() + 'viewproject' + '?id=' + proj.id;
    ret.onclick = function () {
      window.location = url;
    };
    ret.innerText = proj.phase_name
      ? proj.name + ': ' + proj.phase_name
      : proj.name;
    return ret;
  };

  _i.buildUserClickableSpan = function (user) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-b-13';
    if (user) {
      ret.style.cursor = 'pointer';
      var url = getBaseURL() + '?user_id=' + user.guid;
      ret.onclick = function () {
        window.location = url;
      };
      ret.innerText = user.firstName + ' ' + user.lastName + ' ';
    }
    return ret;
  };

  _i.testEndDateChange = function (history_log) {
    var retstr = null;
    if (
      history_log.log_data.ends_at &&
      history_log.log_data.ends_at.length == 2 &&
      history_log.log_data.ends_at[0] != history_log.log_data.ends_at[1]
    ) {
      retstr =
        I18n.t('msg_end_date_changed') +
        parseRubyDate(
          history_log.log_data.ends_at[1],
          true
        ).toDateFormattedStringShort() +
        ' ' +
        I18n.t('lbl_for_lc');
    }
    return retstr;
  };

  _i.testStartDateChange = function (history_log) {
    var retstr = null;
    if (
      history_log.log_data.starts_at &&
      history_log.log_data.starts_at.length == 2 &&
      history_log.log_data.starts_at[0] != history_log.log_data.starts_at[1]
    ) {
      retstr =
        I18n.t('msg_start_date_changed') +
        parseRubyDate(
          history_log.log_data.starts_at[1],
          true
        ).toDateFormattedStringShort() +
        ' ' +
        I18n.t('lbl_for_lc');
    }
    return retstr;
  };

  _i.testPercentChange = function (history_log) {
    var retstr = null;
    if (
      history_log.log_data.percent &&
      history_log.log_data.percent.length == 2 &&
      history_log.log_data.percent[0] != history_log.log_data.percent[1]
    ) {
      var a = history_log.log_data.percent[0] * 100;
      var b = history_log.log_data.percent[1] * 100;
      retstr =
        I18n.t('msg_assignment_changed') +
        a.toFixed() +
        '%' +
        I18n.t('lbl_to_lc') +
        b.toFixed() +
        '% ' +
        I18n.t('lbl_for_lc');
    }
    return retstr;
  };

  _i.parseHistoryLogForFeedItem = function () {
    var startDateString = '';
    var endDateString = '';
    var iconType = null;
    var proj = null;

    var caption = document.createElement('DIV');
    if (
      _i.feeditem.history_log.log_user &&
      _i.feeditem.history_log.log_user.displayName
    ) {
      caption.appendChild(
        _i.buildUserClickableSpan(_i.feeditem.history_log.log_user)
      );
    }
    if (_i.feeditem.history_log.log_action == 'create') {
      if (_i.feeditem.history_log.log_objectOfAction == 'project') {
        proj = JSON.parse(_i.feeditem.history_log.data).project;
        if (proj && proj.parent_id) {
          caption.appendChild(
            _i.buildPlainTextSpan(I18n.t('msg_created_new_phase') + ' ')
          );
        } else {
          caption.appendChild(
            _i.buildPlainTextSpan(I18n.t('msg_project_created') + ' ')
          );
        }
        caption.appendChild(
          _i.buildProjectClickableSpan(_i.feeditem.history_log.log_data.project)
        );
      } else if (_i.feeditem.history_log.log_objectOfAction == 'assignment') {
        var localuser = gData.getUser(
          _i.feeditem.history_log.log_data.assignment.user_id
        );
        var startTime = parseRubyDate(
          _i.feeditem.history_log.log_data.assignment.starts_at,
          true
        ).toDateFormattedString();
        var endTime = parseRubyDate(
          _i.feeditem.history_log.log_data.assignment.ends_at,
          true
        ).toDateFormattedString();
        var proj = null;
        if (_i.feeditem.history_log.log_assignment) {
          proj = null;
          for (i = 0; i < gData.assignments.length; i++) {
            if (
              gData.assignments[i].id ==
              _i.feeditem.history_log.log_objectOfActionId
            ) {
              assignment = gData.assignments[i];
              break;
            }
          }
          if (assignment) {
            proj = gData.getAssignableOrChild(assignment.assignable_id, false);
          }
        }
        caption.appendChild(_i.buildPlainTextSpan(' added '));
        caption.appendChild(_i.buildUserClickableSpan(localuser));
        caption.appendChild(_i.buildPlainTextSpan(' to '));
        if (proj) {
          caption.appendChild(_i.buildProjectClickableSpan(proj));
        }
        caption.appendChild(
          _i.buildPlainTextSpan(' from ' + startTime + ' to ' + endTime)
        );
      } else if (_i.feeditem.history_log.log_objectOfAction == 'user') {
        caption.appendChild(
          _i.buildPlainTextSpan(I18n.t('msg_project_created') + ' ')
        );
        caption.appendChild(
          _i.buildProjectClickableSpan(_i.feeditem.history_log.log_data.project)
        );
      } else if (_i.feeditem.history_log.log_objectOfAction == 'status') {
        _i.feeditem.status = _i.feeditem.history_log.log_data.status;
        _i.parseStatusForFeedItem();
        _i.feeditem.status = null;
        return;
      }
    } else if (_i.feeditem.history_log.log_action == 'destroy') {
      if (_i.feeditem.history_log.log_objectOfAction == 'project') {
        var proj = gData.getAssignableOrChild(
          _i.feeditem.history_log.log_objectOfActionId,
          false
        );
        if (proj && proj.parent_id)
          caption.appendChild(_i.buildPlainTextSpan('removed phase '));
        else caption.appendChild(_i.buildPlainTextSpan('removed project '));
        if (proj) {
          caption.appendChild(_i.buildProjectClickableSpan(proj));
        }
      } else if (_i.feeditem.history_log.log_objectOfAction == 'assignment') {
        var assignment = null;
        var localuser = gData.getUser(
          _i.feeditem.history_log.log_data.assignment.user_id
        );
        var proj = _i.feeditem.history_log.log_assignment;
        caption.appendChild(
          _i.buildPlainTextSpan('removed an assignment for ')
        );
        caption.appendChild(_i.buildUserClickableSpan(localuser));
        if (proj && proj.parent_id)
          caption.appendChild(_i.buildPlainTextSpan('on phase '));
        else caption.appendChild(_i.buildPlainTextSpan('on project '));
        if (proj) caption.appendChild(_i.buildProjectClickableSpan(proj));
      }
    } else if (_i.feeditem.history_log.log_action == 'update') {
      if (_i.feeditem.history_log.log_objectOfAction == 'project') {
        var proj = gData.getAssignableOrChild(
          _i.feeditem.history_log.log_objectOfActionId,
          false
        );
        if (proj && proj.parent_id)
          caption.appendChild(_i.buildPlainTextSpan('changed phase '));
        else caption.appendChild(_i.buildPlainTextSpan('changed project '));
        if (proj) {
          caption.appendChild(_i.buildProjectClickableSpan(proj));
        }
      } else if (_i.feeditem.history_log.log_objectOfAction == 'assignment') {
        var assignment = null;
        var localuser = null;
        var proj = null;
        for (i = 0; i < gData.assignments.length; i++) {
          if (
            gData.assignments[i].id ==
            _i.feeditem.history_log.log_objectOfActionId
          ) {
            assignment = gData.assignments[i];
            break;
          }
        }
        if (assignment) {
          proj = gData.getAssignableOrChild(assignment.assignable_id, false);
          localuser = gData.getUser(assignment.user_id);
          var startDateString = _i.testStartDateChange(_i.feeditem.history_log);
          var endDateString = _i.testEndDateChange(_i.feeditem.history_log);
          var percentString = _i.testPercentChange(_i.feeditem.history_log);
          if (startDateString != null && endDateString == null) {
            caption.appendChild(_i.buildPlainTextSpan(startDateString));
          } else if (startDateString == null && endDateString != null) {
            caption.appendChild(_i.buildPlainTextSpan(endDateString));
          } else if (percentString != null) {
            caption.appendChild(_i.buildPlainTextSpan(percentString));
            iconType = compSpritesButtonsIcons.allocationIconL;
          } else {
            caption.appendChild(
              _i.buildPlainTextSpan('changed the assignment for ')
            );
          }
          caption.appendChild(_i.buildUserClickableSpan(localuser));
          if (proj && proj.parent_id)
            caption.appendChild(_i.buildPlainTextSpan('on phase '));
          else caption.appendChild(_i.buildPlainTextSpan('on project '));
          if (proj) {
            caption.appendChild(_i.buildProjectClickableSpan(proj));
          }
        }
      }
    }

    _i.feeditem.captionText = caption;
    _i.feeditem.captionTime = new Date(
      _i.feeditem.date
    ).toFeedStyleFormattedString();
    if (_i.feeditem.history_log.log_action == 'create') {
      _i.feeditem.captionIcon = compSpritesButtonsIcons.addIconL;
    }

    if (iconType) {
      _i.feeditem.captionIcon = iconType;
    } else if (startDateString != null && endDateString == null) {
      _i.feeditem.captionIcon = compSpritesButtonsIcons.startDateIconL;
    } else if (startDateString == null && endDateString != null) {
      _i.feeditem.captionIcon = compSpritesButtonsIcons.endDateIconL;
    } else if (_i.feeditem.history_log.log_action == 'update') {
      _i.feeditem.captionIcon = compSpritesButtonsIcons.changeIconL;
    } else if (_i.feeditem.history_log.log_action == 'destroy') {
      _i.feeditem.captionIcon = compSpritesButtonsIcons.removeIconL;
    }

    if (_i.feeditem.history_log.log_user) {
      _i.feeditem.userThumbnail = _i.feeditem.history_log.log_user.thumbnail;
    }
    _i.feeditem.user = _i.feeditem.history_log.log_user;
  };

  _i.parseStatusForFeedItem = function () {
    var localuser = gData.getUser(_i.feeditem.status.user_id);
    if (!localuser) return;
    _i.feeditem.user = localuser;
    _i.feeditem.captionText = document.createElement('DIV');
    _i.feeditem.captionText.appendChild(_i.buildUserClickableSpan(localuser));
    _i.feeditem.captionTime = new Date(
      _i.feeditem.date
    ).toFeedStyleFormattedString();

    if (_i.feeditem.status.status == 'ITO' || _i.feeditem.status.status == '') {
      _i.feeditem.captionText.appendChild(
        _i.buildPlainTextSpan('is in the office')
      );
      _i.feeditem.captionIcon = compSpritesButtonsIcons.inIconL;
    } else if (_i.feeditem.status.status == 'WFH') {
      _i.feeditem.captionText.appendChild(_i.buildPlainTextSpan('is at home'));
      _i.feeditem.captionIcon = compSpritesButtonsIcons.homeIconL;
    } else if (_i.feeditem.status.status == 'OOO') {
      _i.feeditem.captionText.appendChild(
        _i.buildPlainTextSpan('is on the road')
      );
      _i.feeditem.captionIcon = compSpritesButtonsIcons.roadIconL;
    } else if (_i.feeditem.status.status == 'SIC') {
      _i.feeditem.captionText.appendChild(_i.buildPlainTextSpan('is Out Sick'));
      _i.feeditem.captionIcon = compSpritesButtonsIcons.sickIconL;
    } else if (_i.feeditem.status.status == 'VAC') {
      _i.feeditem.captionText.appendChild(
        _i.buildPlainTextSpan('is On Vacation')
      );
      _i.feeditem.captionIcon = compSpritesButtonsIcons.vacIconL;
    } else if (_i.feeditem.status.status == 'OOF') {
      _i.feeditem.captionText.appendChild(
        _i.buildPlainTextSpan('is Out of the Office')
      );
      _i.feeditem.captionIcon = compSpritesButtonsIcons.oofIconL;
    }
    if (_i.feeditem.status.assignable_id != null) {
      var proj = gData.getAssignable(_i.feeditem.status.assignable_id, false);
      if (proj != null) {
        _i.feeditem.captionText.appendChild(
          _i.buildPlainTextSpan(' working on ')
        );
        _i.feeditem.captionText.appendChild(_i.buildProjectClickableSpan(proj));
        _i.feeditem.captionText.appendChild(_i.buildPlainTextSpan('.  '));
      }
    } else {
      _i.feeditem.captionText.appendChild(_i.buildPlainTextSpan('.  '));
    }

    _i.feeditem.captionText.appendChild(_i.buildPlainTextSpan('<br>'));
    if (_i.feeditem.status.message != null) {
      _i.feeditem.captionText.appendChild(
        _i.buildPlainTextSpan(_i.feeditem.status.message)
      );
    }
    _i.feeditem.userThumbnail = localuser.thumbnail;
  };

  _i.parseFeedItem = function () {
    if (!_i.feeditem) return;
    if (_i.feeditem.status != null) {
      _i.parseStatusForFeedItem();
    } else if (_i.feeditem.history_log != null) {
      _i.parseHistoryLogForFeedItem();
    }
  };

  _i.constructor = function () {
    _i.parseFeedItem();
    _i.feedItemView = document.createElement('DIV');
    _i.feedItemView.className = 'blockFloatNot feedbubble';

    _i.attachTo.appendChild(_i.feedItemView);

    _i.thumbnailContainer = document.createElement('DIV');
    _i.thumbnailContainer.className = 'blockFloat feedItemThumbnailContainer';
    _i.thumbnailContainer.id = _i.guid + 'feedbubble';
    if (gData.accountSettings.canUserReadModel(gData.getMe(), 'User', null))
      xAddEventListener(
        _i.thumbnailContainer,
        platform.click,
        _i.handleUserIconClicked,
        false
      );
    else _i.thumbnailContainer.style.cursor = 'default';
    _i.userThumbnail = document.createElement('IMG');
    _i.userThumbnail.className = 'feedItemThumbnail';

    _i.thumbnailContainer.appendChild(_i.userThumbnail);

    _i.captionContainerWrapper = document.createElement('DIV');
    _i.captionContainerWrapper.className =
      'blockFloat feedItemCaptionContainerWrapper triangle-border-left';

    _i.captionContainer = document.createElement('DIV');
    _i.captionContainer.className = 'feedItemCaptionContainer';

    _i.captionContainerWrapper.appendChild(_i.captionContainer);

    _i.captionIcon = document.createElement('DIV');
    _i.captionIcon.className = 'blockFloat feedItemCaptionIcon';

    _i.captionTextContainer = document.createElement('DIV');
    _i.captionTextContainer.className =
      'blockFloat feedItemCaptionTextContainer';

    _i.captionText = document.createElement('DIV');
    _i.captionText.className = 'fontRegular fnt-r-13 feedItemCaptionText';
    _i.captionText.innerHTML = '';

    _i.captionTime = document.createElement('DIV');
    _i.captionTime.className =
      'blockFloatNot fontRegular fnt-r-12 feedItemCaptionTime';
    _i.captionTime.innerHTML = '';

    _i.captionContainer.appendChild(_i.captionIcon);
    _i.captionContainer.appendChild(_i.captionTextContainer);
    _i.captionTextContainer.appendChild(_i.captionText);
    _i.captionTextContainer.appendChild(_i.captionTime);

    _i.userThumbnail.src = _i.feeditem.userThumbnail;

    if (_i.feeditem.captionIcon)
      _i.captionIcon.appendChild(getSprite(_i.feeditem.captionIcon));
    if (_i.feeditem.captionText)
      _i.captionText.appendChild(_i.feeditem.captionText);
    _i.captionTime.innerText = _i.feeditem.captionTime;

    _i.feedItemView.appendChild(_i.thumbnailContainer);
    _i.feedItemView.appendChild(_i.captionContainerWrapper);
  };

  _i.constructor();
}
