function SettingsProjects(attachTo) {
  var DUPLICATE_ERROR_INDEX = 7;

  var _i = this;

  if (!(this instanceof SettingsProjects)) {
    return new SettingsProjects(attachTo);
  }
  // params
  _i.attachTo = xGetElementById(attachTo);
  _i.inputstr = '';
  _i.columns = [];
  _i.create = null;

  _i.projectFields = [
    'name',
    'client',
    'project_state',
    'starts_at',
    'ends_at',
    'daybudget',
    'dollarbudget',
    'project_code',
  ];

  _i.errorHeaderText = {
    0: { text: 'err_invalid_project_name' },
    1: { text: '' }, // a bit hacky, but necessary for current implementation
    2: { text: 'err_invalid_project_type' },
    3: { text: 'err_invalid_start_end_date' },
    4: { text: 'err_invalid_start_end_date' },
    5: { text: 'err_invalid_budget' },
    6: { text: 'err_invalid_budget' },
    7: { text: 'err_duplicate_projects' },
  };

  debugger;

  _i.errorMessage = {
    0: 'editproject.projectnameerror',
    2: 'editproject.projecttypeerror',
    3: 'editproject.projectdateerror',
    4: 'editproject.projectdateerror',
    5: 'editproject.projectbudgeterror',
    6: 'editproject.projectbudgeterror',
    7: 'err_duplicate_projects_description',
  };

  _i.verifyString = function (str) {
    return !_.isEmpty(str);
  };

  _i.addState = function (project, state) {
    if (!state) {
      project.project_state = '';
      return;
    }

    var validStates = ['Internal', 'Confirmed', 'Tentative'];
    if (_.indexOf(validStates, state) < 0) {
      project.project_state = state;
      return;
    }

    var tags = gData.tagsByNameSpace['project state'];
    var confirmedTagIndex = 0;
    for (var index = 0; index < tags.length; index++) {
      if (tags[index].value.toLowerCase() === state.toLowerCase()) {
        project.project_state = tags[index].value;
        project.state_tag = tags[index];
        return;
      }
      if (tags[index].name == 'Confirmed') confirmedTagIndex = index;
    }
    // if state was not a project state tag, use the default
    if (!project.project_state) {
      project.project_state = tags[confirmedTagIndex].value;
      project.state_tag = tags[confirmedTagIndex].id;
    }
  };

  _i.buildProject = function (str) {
    if (str === '') return;
    var fields = str.split('\t');
    if (fields.length == 1) fields = str.split(',');
    var project = {};
    for (var i = 0; i < _i.projectFields.length; i++) {
      if (i === 2) {
        _i.addState(project, fields[2]);
      } else {
        project[_i.projectFields[i]] = fields[i];
      }
    }
    if (
      project.name == 'Project Name (Required)' &&
      project.client == 'Client'
    ) {
      // assume they copied the header from the csv and ignore this project.
    } else {
      project.projectKey =
        project.name +
        project.client +
        project.project_state +
        project.starts_at +
        project.ends_at +
        project.dollarbudget +
        project.daybudget +
        project.project_code;
      // Check for duplicates
      if (_i.projects[project.projectKey]) {
        _i.projectsWithErrors.push(project);
        _i.projectsWithErrors.push(_i.projects[project.projectKey]);
        project.duplicate = true;
        _i.projects[project.projectKey].duplicate = true;
        _i.errorTypes.push(DUPLICATE_ERROR_INDEX);
      } else {
        _i.projects[project.projectKey] = project;
      }
    }
  };

  _i.buildLinkText = function (str, link, newWindow) {
    var ret = document.createElement('a');
    ret.href = link;
    if (newWindow) {
      ret.target = '_blank';
    }
    ret.className = 'fnt-r-14 addprojectLink';
    ret.innerHTML = str;
    return ret;
  };

  _i.buildLightTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-13 adduserLightSpan';
    ret.innerText = str;
    return ret;
  };

  _i.buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-14 addprojectSpan';
    ret.innerHTML = str;
    return ret;
  };

  _i.textareaCallback = function () {
    if (this.value === '') {
      _i.create.setDisabled();
    } else {
      _i.create.setEnabled();
    }
  };

  _i.nextButtonCallback = function () {
    ShowPageSpinner(0, true);
    UpdatePageSpinner(I18n.t('lbl_validating_data'));
    setTimeout(function () {
      _i.projects = {}; // all projects
      _i.projectsWithErrors = []; // projects containing errors
      _i.projectErrors = {}; // hash table of project keys -> project errors
      _i.errorTypes = []; // error types to display in page header (stores index of error)
      _i.inputstr = _i.inputlist.getValue();
      lines = _i.inputstr.split(/\r\n|\r|\n/);

      for (var i = 0; i < lines.length; i++) {
        _i.buildProject(lines[i]);
      }

      var validator = new csvValidator();
      var k = _.keys(_i.projects);
      for (var i = 0; i < k.length; i++) {
        var p = _i.projects[k[i]];
        var errors = validator.validate(p);
        if (errors.length > 0) {
          _i.projectErrors[_i.projects[k[i]].projectKey] = errors; // store errors in hash table
          _i.projectsWithErrors.push(_i.projects[k[i]]);
        }
      }
      var contains_duplicates = _.contains(_i.errorTypes, DUPLICATE_ERROR_INDEX)
        ? true
        : false;
      _i.errorTypes = validator.getErrorTypes();
      if (contains_duplicates) {
        _i.errorTypes[DUPLICATE_ERROR_INDEX] = true;
      }
      _i.showValidationResults();
      RemovePageSpinner();
    }, 100);
  };

  _i.cancelButtonCallback = function () {
    window.location.href = getBaseURL() + 'settings?page=project';
  };

  _i.backButtonCallback = function () {
    window.location.href = getBaseURL() + 'settings?page=project';
  };

  _i.addProjectsButtonCallback = function () {
    ShowPageSpinner(0, true);
    UpdatePageSpinner(I18n.t('lbl_uploading_data'));
    setTimeout(function () {
      _i.projectsToCreate = [];
      var k = _.keys(_i.projects);
      for (var i = 0; i < k.length; i++) {
        var proj = _i.projects[k[i]];

        proj.budget_items = [];
        proj.budget_items.push({
          item_type: 'TimeFees',
          amount: proj.dollarbudget,
        });
        proj.budget_items.push({
          item_type: 'TimeFeesDays',
          amount:
            Number(proj.daybudget) *
            Number(gData.accountSettings.hours_in_workday),
        });
        proj.starts_at = new Date(proj.starts_at);
        proj.ends_at = new Date(proj.ends_at);

        delete proj.dollarbudget;
        delete proj.daybudget;
        delete proj.projectKey;
        _i.projectsToCreate.push(proj);
      }
      gService.bulkCreateProjects(_i.projectsToCreate);
      RemovePageSpinner();

      if (_i.bodycontainer) removeAllChildren(_i.bodycontainer);

      var container = document.createElement('DIV');
      container.className = 'addprojectPageFloat';
      _i.bodycontainer.appendChild(container);

      _i.mainContent = document.createElement('DIV');
      _i.mainContent.className = 'fnt-b-41 addprojectPageBodyContent';
      _i.mainContent.id = 'addprojectPageBodyContent';
      container.appendChild(_i.mainContent);

      var projectsUploaded = document.createElement('DIV');
      projectsUploaded.className = 'blockFloatNot fnt-b-32 addprojectHeader';
      projectsUploaded.innerHTML += I18n.t('lbl_project_data_uploaded');
      _i.mainContent.appendChild(projectsUploaded);

      var label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-r-14 addUseLabelInstructions';
      label.innerHTML = I18n.t('msg_project_upload_received');
      _i.mainContent.appendChild(label);

      var label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-r-14 addUseLabelInstructions';
      label.innerHTML = I18n.t('msg_project_upload_progress');
      _i.mainContent.appendChild(label);

      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot';
      _i.mainContent.appendChild(spacer);

      _i.nesteddiv = document.createElement('DIV');
      _i.mainContent.appendChild(_i.nesteddiv);

      _i.cancel = new secondaryButton('addprojectCancelButton');
      _i.cancel.setButtonText(I18n.t('lbl_back_to_settings'));
      _i.cancel.setCallback(_i.backToSettingsCallback);
      _i.nesteddiv.appendChild(_i.cancel.container);
      _i.cancel.container.tabIndex = 0;

      _i.createProj = new primaryButton('addprojectAddButton');
      _i.createProj.setButtonText(I18n.t('lbl_continue_to_projects'));
      _i.createProj.setCallback(_i.goToProjectsCallback);
      _i.nesteddiv.appendChild(_i.createProj.container);
      _i.createProj.container.tabIndex = 0;
    }, 100);
  };

  _i.backToSettingsCallback = function () {
    window.location = '/settings';
  };

  _i.goToProjectsCallback = function () {
    window.location.href = getBaseURL() + 'projects';
  };

  _i.addProjectListHeader = function () {
    _i.projectlistContainer = document.createElement('table');
    _i.projectlistContainer.id = 'import-preview';
    _i.projectlistContainer.className =
      'blockFloatNot addprojectMultiProjectInspectHeader';
    _i.mainContent.appendChild(_i.projectlistContainer);
    var columnLabel = [
      I18n.t('lbl_project_name'),
      I18n.t('lbl_client'),
      I18n.t('lbl_project_type'),
      I18n.t('lbl_start_date'),
      I18n.t('lbl_end_date'),
      I18n.t('lbl_time_budget_in_days'),
      I18n.t('lbl_fee_budget_in_amounts'),
      I18n.t('lbl_project_code'),
    ];

    _i.tableHeader = document.createElement('tr');
    for (var i = 0; i < columnLabel.length; i++) {
      var label = document.createElement('th');
      label.className = 'fnt-r-14 addprojectListHeaderLabel';
      label.innerText = columnLabel[i];
      _i.projectlistContainer.appendChild(label);
    }
  };

  _i.addError = function (index) {
    var header = document.createElement('DIV');
    header.className = 'blockFloatNot fnt-r-14 projectErrorHeader';
    header.innerHTML = I18n.t(_i.errorHeaderText[index].text);
    _i.mainContent.appendChild(header);

    var message = document.createElement('DIV');
    message.className = 'blockFloatNot fnt-r-14 lineSpacing';
    message.innerHTML = I18n.t(_i.errorMessage[index]);
    _i.mainContent.appendChild(message);
  };

  _i.addErrorsAndMessages = function () {
    for (var i = 0; i < _.keys(_i.errorHeaderText).length; i++) {
      _i.errorHeaderText[i].showing = false;
    }
    for (var i = 0; i < _i.errorTypes.length; i++) {
      // Check if we need to display an error message, and ensure the message is not already displayed
      if (
        _i.errorTypes[i] &&
        (i > 3
          ? !_i.errorHeaderText[i - 1].showing
          : !_i.errorHeaderText[i].showing)
      ) {
        _i.addError(i);
        _i.errorHeaderText[i].showing = true;
      }
    }
  };

  _i.addSuccessPageHeader = function () {
    var addprojectHeader = document.createElement('DIV');
    addprojectHeader.className = 'blockFloatNot fnt-b-32 addprojectHeader';
    _i.mainContent.appendChild(addprojectHeader);

    // https://blog.tompawlak.org/number-currency-formatting-javascript
    var numWithCommas = _.keys(_i.projects)
      .length.toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    addprojectHeader.innerHTML = numWithCommas;
    addprojectHeader.innerHTML += I18n.t('editproject.project', {
      count: _.keys(_i.projects).length,
    });
    addprojectHeader.innerHTML += I18n.t('lbl_ready_to_import');

    var label = document.createElement('DIV');
    label.className =
      'blockFloatNot fnt-r-14 addUseLabelInstructions spacerMargin';
    label.innerHTML = I18n.t('msg_project_import_valid_data');
    _i.mainContent.appendChild(label);

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot';
    _i.mainContent.appendChild(spacer);

    _i.nesteddiv = document.createElement('DIV');
    _i.mainContent.appendChild(_i.nesteddiv);

    _i.cancel = new secondaryButton('addprojectCancelButton');
    _i.cancel.setButtonText(I18n.t('lbl_go_back'));
    _i.cancel.setCallback(_i.backButtonCallback);
    _i.nesteddiv.appendChild(_i.cancel.container);
    _i.cancel.container.tabIndex = 0;

    _i.createProj = new primaryButton('addprojectAddButton');
    _i.createProj.setButtonText(I18n.t('lbl_import_projects'));
    _i.createProj.setCallback(_i.addProjectsButtonCallback);
    _i.nesteddiv.appendChild(_i.createProj.container);
    _i.createProj.container.tabIndex = 0;
  };

  _i.addErrorPageHeader = function () {
    var addprojectHeader = document.createElement('DIV');
    addprojectHeader.className = 'blockFloatNot fnt-b-32 addprojectHeader';
    _i.mainContent.appendChild(addprojectHeader);
    addprojectHeader.innerHTML = I18n.t('lbl_errors_found');

    var label = document.createElement('DIV');
    label.className =
      'blockFloatNot fnt-r-14 addUseLabelInstructions lineSpacing';

    // https://blog.tompawlak.org/number-currency-formatting-javascript
    var numWithCommas = _.keys(_i.projectsWithErrors)
      .length.toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    label.innerHTML = numWithCommas;
    debugger;
    label.innerHTML += I18n.t('editproject.project', {
      count: _i.projectsWithErrors.length,
    });
    label.innerHTML += I18n.t('err_project_create_html');
    _i.mainContent.appendChild(label);

    var link = document.createElement('DIV');
    link.className = 'blockFloatNot fnt-r-14';
    link_text = _i.buildLinkText(
      I18n.t('lbl_learn_more_import_projects'),
      'https://help.smartsheet.com/articles/2481126',
      true
    );
    link.appendChild(link_text);
    _i.mainContent.appendChild(link);

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot addprojectSpacerImage1';
    _i.mainContent.appendChild(spacer);

    _i.nesteddiv = document.createElement('DIV');
    _i.nesteddiv.className = 'blockFloatNot';
    _i.mainContent.appendChild(_i.nesteddiv);

    _i.createProj = new primaryButton('addprojectAddButton');
    _i.createProj.setButtonText(I18n.t('lbl_return_to_import_projects'));
    _i.createProj.setCallback(_i.backButtonCallback);
    _i.nesteddiv.appendChild(_i.createProj.container);
    _i.createProj.container.tabIndex = 0;

    _i.addErrorsAndMessages();
  };

  _i.addProjectList = function (projects) {
    var k = _.keys(projects);
    for (var i = 0; i < k.length; i++) {
      var project = projects[k[i]];
      var projectErrors = _i.projectErrors[project.projectKey];

      var columnLabel = [
        project.name,
        project.client,
        project.project_state,
        project.starts_at,
        project.ends_at,
        project.daybudget,
        project.dollarbudget,
        project.project_code,
      ];
      var tableRow = document.createElement('tr');
      for (var j = 0; j < columnLabel.length; j++) {
        var label = document.createElement('td');
        label.className = 'fnt-r-14 addprojectListLabel';
        if (_.contains(projectErrors, j) || project.duplicate) {
          label.className += ' error';
        }
        if ((j === 0 || (j >= 2 && j <= 4)) && columnLabel[j] === '') {
          label.innerHTML = '- no value -';
          label.className += ' blank';
        } else {
          label.innerText = columnLabel[j];
        }
        tableRow.appendChild(label);
      }
      _i.projectlistContainer.appendChild(tableRow);
    }
  };

  _i.initProjectInspectPage = function () {
    if (_.isEmpty(_i.projectsWithErrors)) {
      _i.addSuccessPageHeader();
      _i.addProjectListHeader();
      _i.addProjectList(_i.projects);
    } else {
      _i.addErrorPageHeader();
      _i.addProjectListHeader();
      _i.addProjectList(_i.projectsWithErrors);
    }
  };

  _i.showValidationResults = function () {
    removeAllChildren(xGetElementById('settingsPageContentContainer'));
    var parent = xGetElementById('mainCon');
    parent.removeChild(xGetElementById('settingsPageContentContainer'));

    var mainCon = xGetElementById('mainCon');

    _i.bodycontainer = document.createElement('DIV');
    _i.bodycontainer.className = 'addprojectPageBody';
    mainCon.appendChild(_i.bodycontainer);

    var container = document.createElement('DIV');
    container.className = 'addprojectPageFloat';
    _i.bodycontainer.appendChild(container);

    _i.mainContent = document.createElement('DIV');
    _i.mainContent.className = 'fnt-b-41 addprojectPageBodyContent';
    _i.mainContent.id = 'addprojectPageBodyContent';
    container.appendChild(_i.mainContent);

    _i.initProjectInspectPage();
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);

    _i.label = document.createElement('H3');
    _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
    _i.label.innerHTML = I18n.t('lbl_import_existing_project');
    _i.container.appendChild(_i.label);

    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 addUseLabelInstructions';
    _i.container.appendChild(label);

    var span = _i.buildPlainTextSpan(I18n.t('lbl_download'));
    label.appendChild(span);
    link_text = _i.buildLinkText(
      I18n.t('lbl_this_spreadsheet'),
      getBaseURL() + 'project_import.xls',
      false
    );
    label.appendChild(link_text);

    label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 addUseLabelInstructions';
    label.innerHTML = I18n.t('msg_fillout_spreadsheet_html');
    _i.container.appendChild(label);

    label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 addUseLabelInstructions3';
    label.innerHTML = I18n.t('msg_start_end_date_format_html');
    _i.container.appendChild(label);

    label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 addUseLabelInstructions3';
    link_text = _i.buildLinkText(
      I18n.t('lbl_learn_more_import_projects'),
      'https://help.smartsheet.com/articles/2481126',
      true
    );
    label.appendChild(link_text);
    _i.container.appendChild(label);

    label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 addUseLabelInstructions';
    label.innerHTML = I18n.t('msg_select_and_copy_cell_field_html');
    _i.container.appendChild(label);

    _i.inputlist = new TextArea(
      _i.container,
      '',
      _i.inputstr,
      'blockFloat addprojectMultipleInputContainer',
      'blockFloatNot fnt-r-13 addprojectTextInputLabel',
      'blockFloatNot fnt-r-15 addprojectMultipleInput',
      _i.textareaCallback
    );

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.container.appendChild(spacer);

    _i.nesteddiv = document.createElement('DIV');
    _i.nesteddiv.className = 'floatRightDiv';
    _i.container.appendChild(_i.nesteddiv);

    _i.create = new primaryButton('addprojectAddButton');
    _i.create.setButtonText(I18n.t('lbl_next'));
    _i.create.setCallback(_i.nextButtonCallback);
    if (_.isEmpty(_i.create) || _i.create.value === undefined) {
      _i.create.setDisabled();
    } else {
      _i.create.setEnabled();
    }
    _i.nesteddiv.appendChild(_i.create.container);
    _i.create.container.tabIndex = 0;

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot addprojectSpacerImage1';
    _i.container.appendChild(spacer);
  };

  _i.constructor();
}
