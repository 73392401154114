/*
 * A popup that provides a prompt and standard yes/no options. The popup will
 * dismiss itself when either the yes or no option is chosen by the user, and corresponding
 * callbacks will be invoked.
 *
 * If the user dismisses the popup using standard popup dismiss action (e.g. hitting
 * the ESC key), the cancelledCallback will not be invoked.
 *
 * prompt - A text message, usually in the form of a yes/no question
 * yesButtonText - String value to use for the yes button. Pass null to use default
 * confirmationCallback - function to invoke when the user selects the yes option.
 * cancelledCallback - optional function to invoke when the user select the no option.
 */
function textinputPopup(
  prompt,
  yesButtonText,
  confirmedCallback,
  cancelledCallback,
  parentPopupContainer,
  blurCallback,
  options
) {
  if (!(this instanceof textinputPopup)) {
    return new textinputPopup(
      prompt,
      yesButtonText,
      confirmedCallback,
      cancelledCallback,
      parentPopupContainer,
      blurCallback,
      options
    );
  }

  var WIDTH = 260;
  var HEIGHT = 100;
  var MESSAGE_AREA_PADDING = 10;
  var MESSAGE_AREA_HEIGHT = 60;

  var _i = this;

  _i.confirmedCallback = confirmedCallback;
  _i.cancelledCallback = cancelledCallback;
  _i.blurCallback = blurCallback;
  _i.options = options;
  _i.popupContainer;

  function confirmedCbInternal() {
    _i.popupContainer.hide();
    var ret = null;
    if (_i.inputText.value != I18n.t('lbl_enter_new_item'))
      ret = _i.inputText.value;
    if (_i.confirmedCallback) _i.confirmedCallback(ret);
  }

  function cancelledCbInternal() {
    _i.popupContainer.hide();
    if (_i.cancelledCallback) _i.cancelledCallback();
  }

  _i.show = function (attachToElement) {
    xAddEventListener(document, 'keydown', _i.containerKeypress, false);
    _i.popupContainer.pointAtElement(attachToElement);
    _i.inputText.focus();
    _i.inputText.select();
  };

  _i.showAtPoint = function (x, y) {
    xAddEventListener(document, 'keydown', _i.containerKeypress, false);
    _i.popupContainer.pointAt(x, y, 0, 0);
    _i.inputText.focus();
    _i.inputText.select();
  };

  _i.containerKeypress = function (evt) {
    // enter
    if (evt.keyCode == 13) {
      confirmedCbInternal();
      return;
    }
  };

  _i.blur = function () {
    xRemoveEventListener(document, 'keydown', _i.containerKeypress, false);
    _i.popupContainer.remove();
    if (_i.blurCallback) _i.blurCallback();
  };

  function init() {
    var spec = parentPopupContainer
      ? compSpritesButtonsIcons.wideDialogSpecsGray
      : compSpritesButtonsIcons.wideDialogSpecs;

    var viewport = document.getElementById('mainCon');

    var content = document.createElement('DIV');
    content.style.width = WIDTH + 'px';
    content.style.height = HEIGHT + 'px';

    _i.inputText = document.createElement('INPUT');
    _i.inputText.type = 'text';
    _i.inputText.className =
      'blockFloatNot fnt-r-14 defaultInputTextInputSmall';

    if (_i.options && _i.options.maxLength) {
      _i.inputText.maxLength = _i.options.maxLength;
    }

    _i.inputText.placeholder = I18n.t('lbl_enter_new_item');
    _i.inputText.focus();
    _i.inputText.select();
    content.appendChild(_i.inputText);

    var buttons = document.createElement('DIV');
    buttons.className = 'blockFloatNot';
    buttons.style.width = 'auto';
    buttons.style.height =
      HEIGHT - MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 3 + 'px';
    buttons.style.paddingLeft =
      buttons.style.paddingRight =
      buttons.style.paddingBottom =
        MESSAGE_AREA_PADDING + 'px';
    buttons.style.float = 'right';

    content.appendChild(buttons);

    var cancelButton = new secondaryButton('confirmationPopupCancelButton');
    cancelButton.setButtonText(I18n.t('lbl_cancel'));
    cancelButton.setCallback(cancelledCbInternal);

    buttons.appendChild(cancelButton.container);

    var confirmButton = new primaryButton('confirmationPopupYesButton');
    confirmButton.setButtonText(yesButtonText || I18n.t('lbl_yes'));
    confirmButton.setCallback(confirmedCbInternal);

    buttons.appendChild(confirmButton.container);

    _i.popupContainer = new popupContainer(
      spec,
      WIDTH,
      HEIGHT,
      3, // innerMargin
      true, // autorotate
      'se',
      viewport,
      ['wc'],
      _i.blur, // blurCallback
      parentPopupContainer
    );

    _i.popupContainer.attachContent(content);
  }

  init();
}
