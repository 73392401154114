function assignmentCountBarVM(startTime, endTime, assignmentCount) {
  var _i = this;
  _i.startTime = startTime;
  // timeline endDate is exclusive
  _i.endTime = new Date(endTime).addDays(-1).getTime();
  _i.assignmentCount = assignmentCount;

  _i.extend = function (newEndTime) {
    // timeline endDate is exclusive
    _i.endTime = new Date(newEndTime).addDays(-1).getTime();
  };
}
