var ColorPicker = function (
  width,
  height,
  initialColor,
  colorMap,
  callbacks,
  options
) {
  'use strict';
  var that = this,
    width = width,
    height = height,
    color = initialColor,
    callbacks = callbacks,
    container = null,
    popup = null,
    listJson = null,
    elId = 'colorContainer' + _.uniqueId(),
    t =
      '<div id="<%= id %>" class="blockFloat">' +
      '<div id="<%= id %>_label" class="fnt-r-13 resourceColorLabel"><%= title %></div>' +
      '<div id="<%= id %>_border" class="blockFloat resourceColorBorder">' +
      '<div id="<%= id %>_colorElement" class="resourceColorElement"></div>' +
      '</div>' +
      '</div>',
    $colorContainer = $(
      _.template(t, { id: elId, title: I18n.t('lbl_color') })
    ),
    colorContainer = $colorContainer[0],
    $colorBorder = $('#' + elId + '_border', $colorContainer),
    colorBorder = $colorBorder[0],
    $colorElement = $('#' + elId + '_colorElement', $colorContainer),
    colorElement = $colorElement[0],
    ColorMap = colorMap;

  options || (options = {});
  if (options.containerCSS) $colorContainer.css(options.containerCSS);
  if (options.removeLabel) $colorContainer.find('#' + elId + '_label').remove();
  if (options.removeBorder) $colorBorder.css('border', 'none');

  function hideInternal() {
    popup.hide();
  }

  function showInternal(c) {
    container = c;
    container.appendChild(colorContainer);
    applyColor();
  }

  function applyColor() {
    if (color && ColorMap[color]) {
      colorElement.style.backgroundColor = ColorMap[color].rgb;
    } else {
      colorElement.style.backgroundColor = 'transparent';
    }
  }

  function setColorInternal(newColor) {
    color = newColor;
    applyColor();
  }

  function onPopupBlur() {
    popup.remove();
  }

  function displayColorsList() {
    if (popup) {
      popup.hide();
    }

    var colorSelected = function (selectedValues) {
      color = selectedValues[0].value;
      applyColor();
      callbacks &&
        callbacks.colorSelectedCallback &&
        callbacks.colorSelectedCallback(color);
    };

    popup = new selectionPopup(
      width,
      height,
      options.viewport || container.id,
      listJson,
      compSpritesButtonsIcons.wideDialogSpecs,
      colorSelected,
      false /*multiselect*/,
      onPopupBlur,
      options.directions ? options.directions[0] : 'wc',
      options.directions || ['wc', 'wn', 'ws', 'ec', 'en', 'es'],
      false /*noautorotate*/,
      true /*doNotDismissOnSelection*/,
      null /*parentPopupContainer*/,
      null /*selectMultipleButtonClickCallback*/,
      null /*selectMultipleButtonLabel*/,
      true /*hideHighlight*/
    );

    popup.show(colorContainer, 20, 20);
  }

  function initialize() {
    listJson = [];

    for (var color in ColorMap) {
      listJson.push({
        type: 'item',
        label: '',
        value: color,
        icon: ColorMap[color].icon,
        iconColor: ColorMap[color].rgb,
      });
    }

    xAddEventListener(
      colorBorder,
      platform.click,
      that.onColorInputClick,
      false
    );
  }

  this.onColorInputClick = function () {
    displayColorsList();
  };

  this.getColor = function () {
    return color;
  };

  // Don't invoke the color changed callback when this is set. Currently the setter is also the user
  // of the callback and would always know that the color had changed.
  this.setColor = function (color) {
    setColorInternal(color);
  };

  this.show = function (container) {
    showInternal(container);
  };

  this.hide = function () {
    hideInternal();
  };

  initialize();
};
