function subMenuListItem(parentObject, parentNode, jsonData) {
  var _i = this;

  // params
  _i.parentObject = parentObject;
  _i.parentNode = parentNode;
  _i.jsonData = jsonData;

  // structures
  _i.container = document.createElement('DIV');
  _i.label = document.createElement('DIV');

  // objects
  _i.icon = null;

  _i.containerClick = function (evt) {
    cancelEvent(evt);
    _i.jsonData.callback && _i.jsonData.callback(_i.jsonData);
  };

  _i.init = (function () {
    _i.container.className = 'subMenuListItem blockFloatNot';

    if (_i.jsonData.icon) {
      _i.icon = getSprite(compSpritesButtonsIcons[_i.jsonData.icon]);
      _i.icon.className = 'blockFloat';
      _i.icon.style.marginTop = '3px';
      _i.icon.style.marginLeft = '7px';
      _i.icon.style.marginRight = '10px';
    }

    _i.label.className = 'ellipsis blockFloat fnt-r-14';
    _i.label.style.width = 'auto';
    _i.label.style.color = '#1A1A1A';
    _i.label.innerText = _i.jsonData.label;

    _i.parentNode.appendChild(_i.container);
    _i.icon && _i.container.appendChild(_i.icon);
    _i.container.appendChild(_i.label);

    xAddEventListener(_i.container, platform.click, _i.containerClick, true);
  })();
}
