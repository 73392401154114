/*
  CLASS: filterDropdownGroup( attachTo ) : used by filterBar and not
  currently exposed as an API.  Should be generalized for general
  use as a dropdown or group of dropdowns
*/

function filterDropdownGroup(attachTo) {
  var _i = this;

  // params
  _i.attachTo = xGetElementById(attachTo);

  // locals
  _i.container = null;
  _i.items = [];
  _i.onChangeCallback = null;

  _i.setOnChangeCallback = function (onChangeCallback) {
    _i.onChangeCallback = onChangeCallback;
  };

  _i.childFilterChange = function () {
    if (_i.onChangeCallback) {
      _i.onChangeCallback();
    }
  };

  _i.getSelection = function () {
    var retVal = [];

    for (var z = 0; z < _i.items.length; z++) {
      retVal[z] = _i.items[z].getSelection();
    }

    return retVal;
  };

  _i.blurAll = function () {
    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].blur();
    }
  };

  _i.reIndex = function () {
    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].controlIndex = z;
    }
  };

  _i.removeFilterDropdown = function (dd) {
    _i.items.splice(dd.controlIndex, 1);
    dd.destroy();
    dd = null;
    _i.reIndex;
  };

  _i.addFilterDropdown = function (
    labelTextValue,
    dropdownValues,
    multiSelect
  ) {
    var dd = new filterDropdown(
      _i.items.length,
      labelTextValue,
      dropdownValues,
      multiSelect
    );
    dd.setAttachment(_i.container);
    dd.setFocusCallback(_i.blurAll);
    dd.setOnChangeCallback(_i.childFilterChange);
    dd.controlIndex = _i.items.length;
    _i.items[_i.items.length] = dd;

    return dd;
  };

  _i.clearSelection = function () {
    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].clearSelection();
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloat';
    _i.attachTo.appendChild(_i.container);
  };

  _i.constructor();
}

function filterDropdown(index, labelTextValue, dropdownValues, multiSelect) {
  var _i = this;

  // params
  _i.index = index;
  _i.labelTextValue = labelTextValue;
  _i.dropdownValues = dropdownValues;
  _i.multiSelect = multiSelect;

  // locals
  _i.container = null;
  _i.labelCont = null;
  _i.labelText = null;
  _i.arrow = null;
  _i.attachTo = null;
  _i.displayValue = null;
  _i.popup = null;
  _i.focusCallback = null;
  _i.onChangeCallback = null;

  _i.destroy = function () {
    _i.attachTo.removeChild(_i.container);
  };

  _i.setOnChangeCallback = function (onChangeCallback) {
    _i.onChangeCallback = onChangeCallback;
  };

  _i.blur = function () {
    _i.popup.hide();
  };

  _i.getSelection = function () {
    var retVal = [];

    for (var z = 0; z < _i.dropdownValues.length; z++) {
      if (_i.dropdownValues[z].selected) {
        retVal[retVal.length] = _i.dropdownValues[z];
      }
    }

    return retVal;
  };

  _i.showSelection = function () {
    _i.displayValue.innerText = _i.curValueDisplayStr();

    // the popup has dropdownValues passed by reference
    // so any change in dropdownValues in this object is
    // reflected in popup as well
    _i.popup.showSelection();
  };

  _i.clearSelection = function () {
    _i.popup.hide();

    for (var z = 0; z < _i.dropdownValues.length; z++) {
      _i.dropdownValues[z].selected = false;
    }

    _i.dropdownValues[0].selected = true;
    _i.showSelection();
  };

  _i.setFocusCallback = function (focusCallback) {
    _i.focusCallback = focusCallback;
  };

  _i.labelContClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (!_i.popup.isVisible()) {
      _i.focusCallback();
    }

    _i.popup.toggle();
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);
    _i.attachTo.appendChild(_i.container);
  };

  _i.addIndexes = function () {
    for (var z = 0; z < _i.dropdownValues.length; z++) {
      _i.dropdownValues[z].index = z;
    }
  };

  _i.curValueDisplayStr = function () {
    var retVal = '';
    var curSelection = _i.getSelection();

    for (var z = 0; z < curSelection.length; z++) {
      if (z > 0) {
        retVal += ', ';
      }
      retVal += curSelection[z].value;
    }

    retVal = ellipsize(retVal, 15);

    return retVal;
  };

  _i.itemClick = function (index) {
    if (_i.multiSelect) {
      _i.dropdownValues[index].selected = !_i.dropdownValues[index].selected;
    } else {
      for (var z = 0; z < _i.dropdownValues.length; z++) {
        _i.dropdownValues[z].selected = false;
      }

      _i.dropdownValues[index].selected = true;
    }

    _i.showSelection();

    if (_i.onChangeCallback) {
      _i.onChangeCallback();
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloat';
    _i.container.style.height = '100%';
    _i.container.style.width = '112px';
    _i.container.style.marginRight = '18px';

    _i.labelCont = document.createElement('DIV');
    _i.labelCont.className = 'blockFloatNot';
    _i.labelCont.style.marginTop = '24px';

    _i.labelText = document.createElement('DIV');
    _i.labelText.className = 'blockFloat unselectable';
    _i.labelText.style.fontFamily = 'GibsonRegular';
    _i.labelText.style.fontSize = '14px';
    _i.labelText.style.color = '#808080';
    _i.labelText.innerText = _i.labelTextValue;
    _i.labelText.style.cursor = 'pointer';

    _i.arrow = document.createElement('DIV');
    _i.arrow.className = 'blockFloat';
    getSprite(compSpritesButtonsIcons.filterDropdownArrow, _i.arrow);
    _i.arrow.style.marginLeft = '2px';
    _i.arrow.style.marginTop = '8px';

    _i.container.appendChild(_i.labelCont);
    _i.labelCont.appendChild(_i.labelText);
    _i.labelCont.appendChild(_i.arrow);

    _i.displayValue = document.createElement('DIV');
    _i.displayValue.className = 'blockFloatNot unselectable';
    _i.displayValue.style.fontFamily = 'GibsonRegular';
    _i.displayValue.style.fontSize = '14px';
    _i.displayValue.style.color = '#000000';
    _i.displayValue.style.marginTop = '4px';
    _i.displayValue.style.cursor = 'default';
    _i.displayValue.style.whiteSpace = 'nowrap';
    _i.displayValue.style.cursor = 'pointer';

    _i.container.appendChild(_i.displayValue);

    _i.popup = new filterDropdownPopup(
      _i.dropdownValues,
      14,
      66,
      _i.multiSelect
    );
    _i.popup.setCallback(_i.itemClick);
    _i.popup.setAttachment(_i.container);

    xAddEventListener(_i.container, platform.click, _i.labelContClick, false);

    // add the ordinal index to the dropdownValues, so that when an array of
    // selected items is returned, we can know which index an item is
    _i.addIndexes();

    _i.showSelection();
  };

  _i.constructor();
}

function filterDropdownPopup(dropdownValues, xPos, yPos, multiSelect) {
  var _i = this;

  // params
  _i.dropdownValues = dropdownValues;
  _i.multiSelect = multiSelect;
  _i.xPos = xPos;
  _i.yPos = yPos;

  // locals
  _i.container = null;
  _i.attachTo = null;
  _i.callback = null;
  _i.items = [];
  _i.saveScrollTop = 0;

  // const
  _i.maxHeight = 300;

  _i.showSelection = function () {
    for (var z = 0; z < _i.dropdownValues.length; z++) {
      if (_i.dropdownValues[z].selected) {
        _i.items[z].select();
      } else {
        _i.items[z].unselect();
      }
    }

    if (!_i.multiSelect) {
      _i.hide();
    }
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.toggle = function () {
    if (_i.container.style.visibility == 'hidden') {
      _i.show();
      return;
    }

    _i.hide();
  };

  _i.isVisible = function () {
    return _i.container.style.visibility != 'hidden';
  };

  _i.show = function () {
    _i.container.style.visibility = 'visible';
    _i.container.style.zIndex = hiTagZ() + 1;
    _i.innerCont.scrollTop = _i.saveScrollTop;
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);
    _i.attachTo.appendChild(_i.container);
  };

  _i.hide = function () {
    _i.saveScrollTop = _i.innerCont.scrollTop;
    _i.container.style.display = 'none';
  };

  _i.itemClick = function (index) {
    if (!_i.multiSelect) {
      _i.callback(index);
      return;
    }

    // normally dropdown values gets set from the topmost object
    // and are sent back down through the children for display
    // if multiselect then preprocess the dropdownValues
    // changes will be seen at the top since dropdownValues is
    // a reference, that is, when you change a dropdownValue here
    // you are changing the dropdownValues in the parent object also

    if (index == 0) {
      for (var z = 0; z < _i.dropdownValues.length; z++) {
        _i.dropdownValues[z].selected = false;
      }

      // 0 is now unselected and will be
      // toggled to selected by the parent
      // and sent back down to be presented
      _i.callback(index);
    }

    if (index != 0) {
      _i.dropdownValues[0].selected = false;
      _i.callback(index);
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.style.position = 'absolute';
    _i.container.style.width = 'auto';
    _i.container.style.height = 'auto';
    _i.container.style.maxHeight = _i.maxHeight + 'px';
    _i.container.style.display = 'none';
    _i.container.style.top = _i.yPos + 'px';
    _i.container.style.left = _i.xPos + 'px';

    _i.nipple = document.createElement('DIV');
    _i.nipple.className = 'blockFloatNot';
    _i.nipple.style.width = '9px';
    _i.nipple.style.height = '9px';
    _i.nipple.style.left = '16px';
    _i.nipple.style.backgroundColor = '#FFFFFF';
    setRotation(_i.nipple, 45);

    _i.innerCont = document.createElement('DIV');
    _i.innerCont.className = 'blockFloatNot dialogShadowRightBottomLeft';
    _i.innerCont.style.width = 'auto';
    _i.innerCont.style.height = 'auto';
    _i.innerCont.style.maxHeight = _i.maxHeight + 'px';
    _i.innerCont.style.backgroundColor = '#FFFFFF';
    _i.innerCont.style.marginTop = '-4px';
    _i.innerCont.style.overflow = 'hidden';
    _i.innerCont.style.paddingTop = '4px';
    _i.innerCont.style.paddingBottom = '4px';

    _i.container.appendChild(_i.nipple);
    _i.container.appendChild(_i.innerCont);

    for (var z = 0; z < _i.dropdownValues.length; z++) {
      _i.items[z] = new filterDropdownPopupItem(
        z,
        _i.dropdownValues[z],
        _i.multiSelect
      );
      _i.items[z].setAttachment(_i.innerCont);
      _i.items[z].setCallback(_i.itemClick);
    }
  };

  _i.constructor();
}

function filterDropdownPopupItem(index, dat, multiSelect) {
  var _i = this;

  // params
  _i.index = index;
  _i.dat = dat;
  _i.multiSelect = multiSelect;

  // locals
  _i.container = null;
  _i.attachTo = null;
  _i.callback = null;
  _i.checkbox = null;

  // const
  _i.selectedBackgroundColor = '#00dab4';

  _i.select = function () {
    _i.dat.selected = true;

    if (!_i.checkbox) {
      _i.container.style.backgroundColor = _i.selectedBackgroundColor;
    } else {
      getSprite(compSpritesButtonsIcons.selectedCheckbox, _i.checkbox);
    }
  };

  _i.unselect = function () {
    _i.dat.selected = null;
    _i.container.style.backgroundColor = 'transparent';

    if (_i.checkbox) {
      getSprite(compSpritesButtonsIcons.unselectedCheckbox, _i.checkbox);
    }
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);
    _i.attachTo.appendChild(_i.container);
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.contClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.callback) {
      _i.callback(_i.index);
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFoatNot unselectable';
    _i.container.style.marginLeft = '10px';
    _i.container.style.marginRight = '10px';
    _i.container.style.marginBottom = '3px';
    _i.container.style.marginTop = '3px';
    _i.container.style.fontFamily = 'GibsonRegular';
    _i.container.style.fontSize = '13px';
    _i.container.style.color = '#000000';
    _i.container.style.whiteSpace = 'nowrap';
    _i.container.style.width = 'auto';
    _i.container.style.height = '25px';
    _i.container.style.lineHeight = '25px';
    _i.container.style.paddingLeft = '4px';

    _i.text = document.createElement('DIV');
    _i.text.className = 'blockFloat';
    _i.text.style.whiteSpace = 'nowrap';

    if (_i.dat.type == 'label') {
      _i.container.style.cursor = 'default';
      _i.container.style.color = '#808080';
      _i.text.innerText = _i.dat.label;
      xAddEventListener(_i.container, platform.click, cancelEvent, false);
    }

    if (_i.dat.type == 'item') {
      _i.container.style.cursor = 'pointer';
      _i.text.innerText = _i.dat.label;

      if (_i.index > 0 && _i.multiSelect) {
        _i.checkbox = document.createElement('DIV');
        _i.checkbox.className = 'blockFloat unselectable';
        _i.checkbox.style.width = '14px';
        _i.checkbox.style.height = '14px';
        _i.checkbox.style.backgroundColor = '#FFFFFF';
        _i.checkbox.style.marginTop = '4px';
        _i.checkbox.style.whiteSpace = 'nowrap';
        _i.container.appendChild(_i.checkbox);

        // have to add left margin to text
        // instead of right margin to checkbox
        // for IE9
        _i.text.style.marginLeft = '6px';
      }

      if (_i.dat.icon) {
        _i.icon = getSprite(compSpritesButtonsIcons[_i.dat.icon]);
        _i.icon.className = 'blockFloat';
        _i.icon.style.marginLeft = '16px';
        _i.icon.style.marginTop = '4px';
        _i.container.appendChild(_i.icon);
      }

      if (!_i.dat.unselectable) {
        xAddEventListener(_i.container, platform.click, _i.contClick, false);
      } else {
        // eat the event
        xAddEventListener(_i.container, platform.click, cancelEvent, false);
      }
    }

    if (_i.dat.type == 'divider') {
      _i.container.style.cursor = 'default';
      _i.container.style.height = '8px';
      xAddEventListener(_i.container, platform.click, cancelEvent, false);
    }

    _i.container.appendChild(_i.text);
  };

  _i.constructor();
}
