// messages are an array of objects, each in the form of
//
//    {
//      id:201402134,
//      message:"Resource Management by Smartsheet will be offline  for planned maintainance tomorrow at 3:00 PM PST",
//      url:"https://www.10000ft.com/blog?3",
//      expires: "2014-02-25"
//    }
//
function splash(container, messages, adjustHeightCallback) {
  var _i = this,
    _messages = messages,
    _currentMessage = null,
    _state = { dismissed: [] },
    _t =
      '<div class="splash fnt-r-14">' +
      '<div class="splashContentWrapper">' +
      '<div class="splashIcon"></div>' +
      '<div class="splashMessage" id="message"></div><div class="splashBar">|</div>' +
      '<div class="splashMoreLink"><a target="_blank" href="<%=url%>" id="readMore">Read more</a></div>' +
      '</div>' +
      '<div class="splashDismissWrapper" id="splashDismiss">' +
      '<div class="splashDismissLink">Dismiss</div>' +
      '<div class="splashDismissIcon"></div>' +
      '</div>' +
      '</div>',
    _elWrapper = container,
    _$el,
    _el,
    _elDimissLink,
    _elMoreLink,
    _elMessage,
    SPLASH_COOKIE_NAME = '__splash';

  function restoreState() {
    var s = getCookie(SPLASH_COOKIE_NAME);
    if (s) _state = JSON.parse(s);
  }

  function saveState() {
    setCookie(SPLASH_COOKIE_NAME, JSON.stringify(_state));
  }

  function wasDismissed(messageId) {
    var i;
    for (i = 0; i < _state.dismissed.length; i++) {
      if (_state.dismissed[i] == messageId) {
        return true;
      }
    }
    return false;
  }

  function getNextMessage() {
    var today = new Date(),
      m,
      i,
      condition;
    for (i = 0; i < _messages.length; i++) {
      m = _messages[i];
      condition = m.condition ? eval(m.condition) : true;
      if (
        parseRubyDate(m.expires) > today &&
        !wasDismissed(m.id) &&
        condition
      ) {
        return m;
      }
    }
    return null;
  }

  function showNext() {
    _currentMessage = getNextMessage();
    if (_currentMessage) {
      _elMessage.innerText = _currentMessage.message;
      _elMoreLink.href = _currentMessage.url;
      adjustHeightCallback &&
        adjustHeightCallback(parseInt(_$el.css('height')));
    } else {
      _el.style.display = 'none';
      adjustHeightCallback && adjustHeightCallback(0);
    }
  }

  _i.hide = function () {
    _elWrapper.removeChild(_el);
  };

  _i.initialize = function () {
    restoreState();
    _$el = $(_t);
    _el = _$el[0];
    _elDimissLink = $('#splashDismiss', _el)[0];
    _elMoreLink = $('#readMore', _el)[0];
    _elMessage = $('#message', _el)[0];
    _elDimissLink.addEventListener('click', function (evt) {
      _state.dismissed.push(_currentMessage.id);
      saveState();
      showNext();
      evt.preventDefault();
      evt.stopPropagation();
    });
    _elWrapper.appendChild(_el);
    showNext();
  };
}
