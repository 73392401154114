function selectionListEx(
  width,
  height,
  multiselect,
  onSelectCallback,
  onCheckboxCallback,
  keyForDataDelegate
) {
  var _i = this,
    _data = null,
    _unfilteredData = null,
    _selected = [],
    _vlv,
    _tIcon = '<div class="slist-icon <%=iconcss%>"></div>',
    _tIconMulti =
      '<div class="slist-icon slist-icon-multi <%=iconcss%>"></div>',
    _tHeader =
      '<div class="slist-header"><div class="slist-label" title="<%-label%>"><%-label%></div></div>',
    _tSingle =
      '<div class="slist-item"><%=icon%><div class="slist-label" title="<%-label%>"><%-label%></div></div>',
    _tCustom = '<div class="slist-item-custom"></div>',
    _tSeparator = '<div class="slist-horizontal-separator"></div>',
    _tMulti =
      '<div class="slist-item"><div class="slist-checkbox"><input type="checkbox" id="<%=checkboxId%>" /></div><div class="slist-separator">&nbsp;</div><%=icon%><div class="slist-label-multi" title="<%-label%>"><%-label%></div></div>',
    _tMultiSelected =
      '<div class="slist-item slist-item-selected"><div class="slist-checkbox"><input type="checkbox" id="<%=checkboxId%>" checked /></div><div class="slist-separator">&nbsp;</div><%=icon%><div class="slist-label-multi title="<%-label%>"><%-label%></div></div>',
    _vlvContentSource = {
      contentForRowAtIndex: getContentForItem,
      numberOfRows: function () {
        return _data.length;
      },
    },
    _vlvDelegate = {
      onSelectRow: onSelect,
      onFocusRow: onItemFocus,
      onBlurRow: onItemBlur,
    };

  if (multiselect && !onCheckboxCallback) {
    onCheckboxCallback = onSelectCallback;
  }

  if (!keyForDataDelegate) {
    // default
    keyForDataDelegate = function (d) {
      return d.label;
    };
  }

  function onSelect(index, element) {
    var d = _data[index];
    if (d.type == 'label' || d.type == 'custom') {
      return;
    }
    _selected = [];
    _selected[keyForDataDelegate(d)] = d;
    element.className = 'slist-item slist-item-selected';
    onSelectCallback && onSelectCallback(_i.getSelection(), element);
  }

  function onItemFocus(index, element) {
    var d = _data[index];
    if (d.type == 'label' || _selected[keyForDataDelegate(d)]) {
      return;
    }
    element.className = 'slist-item slist-item-focused';
  }

  function onItemBlur(index, element) {
    var d = _data[index];
    if (d.type == 'label' || _selected[keyForDataDelegate(d)]) {
      return;
    }
    element.className = 'slist-item';
  }

  function onCheckboxChange(index, checkbox) {
    var d = _data[index],
      containerItem = $(checkbox).parents('div.slist-item')[0];
    if (checkbox.checked) {
      containerItem.className = 'slist-item slist-item-selected';
      _selected[keyForDataDelegate(d)] = d;
    } else {
      containerItem.className = 'slist-item';
      _selected[keyForDataDelegate(d)] = null;
    }
    onCheckboxCallback && onCheckboxCallback(_i.getSelection());
  }

  function onCheckboxClicked(e) {
    e.stopPropagation();
  }

  function onCheckboxContainerClicked(index, e) {
    var checkbox = $(':checkbox', e.target)[0];
    checkbox.checked = !checkbox.checked;
    onCheckboxChange(index, checkbox);
    e.stopPropagation();
  }

  function getContentForItem(index) {
    var e,
      checkbox,
      checkboxId,
      checkboxContainer,
      checked,
      icon,
      d = _data[index];

    if (d.type == 'item') {
      if (multiselect) {
        icon = d.icon
          ? _.template(_tIconMulti, { iconcss: 'icon-' + d.icon })
          : '';
        checked = d.selected || _selected[keyForDataDelegate(d)];
        checkboxId = 'slcb' + ++selectionListEx.prototype.__checkboxguid;
        if (checked) {
          e = $(
            _.template(_tMultiSelected, {
              label: d.label,
              checkboxId: checkboxId,
              icon: icon,
            })
          )[0];
        } else {
          e = $(
            _.template(_tMulti, {
              label: d.label,
              checkboxId: checkboxId,
              icon: icon,
            })
          )[0];
        }
        checkbox = $(':checkbox', e)[0];
        checkbox.addEventListener('click', onCheckboxClicked, false);
        checkbox.addEventListener(
          'change',
          function (e) {
            onCheckboxChange(index, e.target);
          },
          false
        );
        checkboxContainer = $('.slist-checkbox', e)[0];
        checkboxContainer.addEventListener(
          'click',
          function (e) {
            onCheckboxContainerClicked(index, e);
          },
          false
        );
      } else {
        icon = d.icon ? _.template(_tIcon, { iconcss: 'icon-' + d.icon }) : '';
        e = $(_.template(_tSingle, { label: d.label, icon: icon }))[0];
      }
    } else if (d.type == 'custom' && d.getItemElement) {
      e = $(_tCustom)[0];
      e.appendChild(d.getItemElement(d.id));
    } else if (d.type == 'separator') {
      e = $(_tSeparator)[0];
    } else {
      e = $(_.template(_tHeader, { label: d.label }))[0];
    }
    return e;
  }

  /* data is an array of objects in the format
   * { type: "item", label: "AAMI Pitch", icon: "internalMark", value: Object }
   *
   * type should be 'item' or 'label'
   * value is any arbitrary state that can be attached to an item.
   */
  _i.setList = function (data) {
    _data = data;
    _unfilteredData = data;
    _selected = [];
    _data.forEach(function (d) {
      if (d.selected) {
        _selected[keyForDataDelegate(d)] = d;
      }
    });
  };

  // Refresh selection from existing data, e.g. on being shown after being hidden.
  _i.refreshSelection = function () {
    _selected = [];
    _data.forEach(function (d) {
      if (d.selected) {
        _selected[keyForDataDelegate(d)] = d;
      }
    });
  };

  _i.getSelection = function () {
    var a = [],
      value;
    for (var k in _selected) {
      value = _selected[k];
      if (value && typeof value != 'function') {
        a.push(_selected[k]);
      }
    }
    return a;
  };

  _i.filter = function (s) {
    s = (s || '').replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    var regex = new RegExp(s, 'gi'),
      z = 0,
      filteredData = [];
    for (; z < _unfilteredData.length; z++) {
      if (_unfilteredData[z].type == 'item') {
        if (_unfilteredData[z].label.match(regex)) {
          filteredData.push(_unfilteredData[z]);
        }
      }
    }
    _data = filteredData;
    _vlv.reload();
  };

  _i.removeFilter = function () {
    _data = _unfilteredData;
    _vlv.reload();
  };

  _i.show = function (container) {
    _vlv = new jsvlv(width, height, _vlvContentSource, _vlvDelegate);
    _vlv.show(container);
  };

  _i.hide = function () {
    _data = _unfilteredData;
    _selected = [];
    _vlv.hide();
  };
}

selectionListEx.prototype.__checkboxguid = 0;
