//This is deprecated. It calls the reports controller and applies no logic to the rows of data returned.

function csvDownloadsView(attachTo) {
  var _i = this;
  _i.attachTo = xGetElementById(attachTo);

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot exportToCsvHeaderContainer';

    _i.header = document.createElement('DIV');
    _i.header.className = 'blockFloat fnt-r-20';
    _i.header.innerHTML = I18n.t('lbl_export_to_csv');
    //_i.message = document.createElement("DIV");
    //_i.message.className = "blockFloatRight fnt-r-12";
    //_i.message.style.textAlign = "left";
    //_i.message.innerHTML = "We have changed how CSV data is downloaded. You can export CSV data in Reports.";

    var downloadButton = new secondaryButton('exportCsvButton');
    var today = new Date();
    downloadButton.setButtonText(I18n.t('lbl_download'));
    downloadButton.setCallback(function () {
      trackGAEvent('Analytics Page', 'click', 'CSV Downloads Button');
      window.location =
        APP_ENDPOINT + '/api/reports.csv?today=' + today.toRubyDate();
      return true;
    });

    _i.container.appendChild(_i.header);
    //_i.container.appendChild(_i.message);
    _i.container.appendChild(downloadButton.container);

    _i.attachTo.appendChild(_i.container);
  };

  _i.constructor();
}
