function ProjectStatisticView(attach, icon, value, label, tooltip, isdollar) {
  var _i = this;

  if (!(this instanceof ProjectStatisticView)) {
    return new ProjectStatisticView(
      attach,
      icon,
      value,
      label,
      tooltip,
      isdollar
    );
  }

  _i.attachTo = xGetElementById(attach);
  _i.icon = icon;
  _i.value = value;
  _i.label = label;
  _i.tooltip = tooltip;
  _i.isdollar = isdollar;

  _i.buildValueTextSpan = function () {
    var tspan = document.createElement('SPAN');
    tspan.className = 'fnt-b-18';
    var label = Number(_i.value.toFixed(2));
    if (_i.isdollar) label = formatCurrency(_i.value) + ' ';
    tspan.innerText = label + ' ';
    return tspan;
  };

  _i.buildLabelTextSpan = function () {
    var tspan = document.createElement('SPAN');
    tspan.className = 'fnt-r-12';
    tspan.innerText = _i.label;
    return tspan;
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot projectStatusStatisticContainer';
    _i.container.title = _i.tooltip ? _i.tooltip : '';
    _i.attachTo.appendChild(_i.container);

    var iconContainer = document.createElement('DIV');
    iconContainer.className = 'blockFloat projectStatusStatisticIcon';
    if (_i.icon) {
      getSprite(_i.icon, iconContainer);
    }
    _i.container.appendChild(iconContainer);

    var textdiv = document.createElement('DIV');
    textdiv.className = 'blockFloat projectStatusStatisticText';
    if (_i.value || _i.value == 0) textdiv.appendChild(_i.buildValueTextSpan());
    textdiv.appendChild(_i.buildLabelTextSpan());
    _i.container.appendChild(textdiv);
  };

  _i.constructor();
}

function ProjectPercentageStatisticView(
  attach,
  percentage,
  value,
  label,
  tooltip
) {
  var _i = this;

  if (!(this instanceof ProjectPercentageStatisticView)) {
    return new ProjectPercentageStatisticView(
      attach,
      percentage,
      value,
      label,
      tooltip
    );
  }

  _i.attachTo = xGetElementById(attach);
  _i.percentage = percentage;
  _i.value = value;
  _i.label = label;
  _i.tooltip = tooltip;

  _i.buildValueTextSpan = function () {
    var tspan = document.createElement('SPAN');
    tspan.className = 'fnt-b-18';
    tspan.innerText = _i.value + ' ';
    return tspan;
  };

  _i.buildLabelTextSpan = function () {
    var tspan = document.createElement('SPAN');
    tspan.className = 'fnt-r-12';
    tspan.innerText = _i.label;
    return tspan;
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot projectStatusStatisticContainer';
    _i.container.title = _i.tooltip ? _i.tooltip : '';
    _i.attachTo.appendChild(_i.container);

    var iconContainer = document.createElement('DIV');
    iconContainer.className = 'blockFloat projectStatusStatisticIcon';
    iconContainer.id = 'projectStatusStatisticIcon' + getGuid();
    _i.container.appendChild(iconContainer);
    if (percentage > 1) {
      PercentageArc(
        iconContainer.id,
        15,
        _i.percentage.toFixed(2),
        '#f4154a',
        '#f4154a'
      );
    } else {
      PercentageArc(
        iconContainer.id,
        15,
        _i.percentage.toFixed(2),
        '#1492D1',
        '#83CDE6'
      );
    }
    var textdiv = document.createElement('DIV');
    textdiv.className = 'blockFloat projectStatusStatisticText';
    textdiv.appendChild(_i.buildValueTextSpan());
    textdiv.appendChild(_i.buildLabelTextSpan());
    _i.container.appendChild(textdiv);
  };

  _i.constructor();
}
