// wizard container
function wizardView() {
  var _i = this;

  var WIDTH = 600;

  _i.cont = null;

  _i.onclosed = null;

  _i.show = function () {
    _i.cont.style.display = 'block';
  };

  _i.hide = function () {
    _i.cont.style.display = 'none';
    if (_i.onclosed) {
      _i.onclosed();
    }
  };

  _i.setPage = function (pageElement) {
    removeAllChildren(_i.wizardFrameCont);
    window.scrollTo(0, 0);
    _i.wizardFrameCont.appendChild(pageElement);
  };

  _i.constructor = function () {
    _i.cont = document.createElement('DIV');
    _i.cont.className = 'fullScreenOverlay';
    _i.cont.style.display = 'none';
    document.body.appendChild(_i.cont);

    _i.contback = document.createElement('DIV');
    _i.contback.className = 'fullScreenOverlayBackground wizardBackground';
    _i.cont.appendChild(_i.contback);

    _i.wizardFrameOuter = document.createElement('DIV');
    _i.wizardFrameOuter.className = 'wizardFrameOuter';

    _i.wizardFrameCont = document.createElement('DIV');
    _i.wizardFrameCont.className = 'wizardFrame';

    _i.wizardFrameOuter.appendChild(_i.wizardFrameCont);

    _i.cont.appendChild(_i.contback);
    _i.cont.appendChild(_i.wizardFrameOuter);
  };

  _i.constructor();
}
