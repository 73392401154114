function SavedReportsRow(
  attach,
  name,
  params,
  user_id,
  tag,
  callback,
  project_name,
  user_type_id,
  saved_report_id
) {
  var _i = this;

  _i.attachTo = attach;
  _i.name = name;
  _i.project_name = project_name;
  _i.params = params;
  _i.user_id = user_id;
  _i.tag = tag;
  _i.callback = callback;
  _i.user_type_id = user_type_id;

  function isSameElementOrChild(parent, e) {
    while (e && e.parentNode && e.parentNode != window) {
      if (e.parentNode == parent || e == parent) {
        return true;
      }
      e = e.parentNode;
    }
    return false;
  }

  _i.blur = function () {
    if (_i.user_id)
      _i.tagContainer.className = 'blockFloatNot savedReportContainer';
    else _i.tagContainer.className = 'blockFloatNot savedReportContainerWide';
  };

  // creates popover
  _i.deleteReport = function () {
    _i.tagContainer.className = _i.user_id
      ? 'blockFloatNot savedReportSelected'
      : 'blockFloatNot savedReportSelectedWide';
    xRemoveEventListener(
      _i.tagContainer,
      'mouseout',
      _i.containerMouseout,
      true
    );
    var confirmDelete = confirmationPopup(
      I18n.t('lbl_delete') + ' ' + name + '?',
      I18n.t('lbl_delete'),
      function () {
        _i.tagContainer.className = _i.user_id
          ? 'blockFloatNot savedReportContainer'
          : 'blockFloatNot savedReportContainerWide';
        xAddEventListener(
          _i.tagContainer,
          'mouseout',
          _i.containerMouseout,
          true
        );
        gService.removeReport(_i.user_id, _i.tag, _i.callback);
        focusElementAfterDelay('#' + _i.deleteb.container.id);
      },
      function () {
        _i.tagContainer.className = _i.user_id
          ? 'blockFloatNot savedReportContainer'
          : 'blockFloatNot savedReportContainerWide';
        xAddEventListener(
          _i.tagContainer,
          'mouseout',
          _i.containerMouseout,
          true
        );
        focusElementAfterDelay('#' + _i.deleteb.container.id);
      },
      null,
      _i.blur
    );
    confirmDelete.show(_i.buttoncontainer);

    document
      .getElementById('content')
      .setAttribute(
        'aria-label',
        "Confirm: Are you sure you'd like to delete " + name + '?'
      );
    document
      .getElementsByClassName('confirmationPopupCancelButton')[0]
      .setAttribute('aria-label', 'cancel, You are on a button.');
    document
      .getElementsByClassName('confirmationPopupYesButton')[0]
      .setAttribute('aria-label', 'delete, You are on a button.');
    focusElementAfterDelay('#content');
  };

  _i.deleteMouseClick = function (evt) {
    // Stop event from propagating to saved report container
    // And do nothing to allow _i.deleteb.setCallback( _i.deleteReport ) to run
    cancelEvent(evt);
  };

  _i.deleteButtonPress = function (evt) {
    if (evt.keyCode == 32 || evt.keyCode == 13) {
      _i.deleteReport();
    }
  };

  _i.containerMouseClick = function (evt) {
    window.clientEventLogger.push({
      eventTimestamp: Date.now(),
      eventData: {
        eventType: 'Clicked',
        featureArea: 'RM Analytics',
        objectName: 'Saved Report',
        objectType: 'Button',
        viewName: 'Analytics Page',
      },
    });
    if (_i.params) {
      trackGAEvent('Analytics Page', 'click', 'Saved Report Link');
      window.location =
        getBaseURL() +
        'report?' +
        _i.params +
        '&reportName=' +
        encodeURIComponent(_i.name) +
        (saved_report_id ? '&reportTagId=' + saved_report_id : '');
      return;
    }
  };

  _i.containerButtonPress = function (evt) {
    if (evt.keyCode == 32 || evt.keyCode == 13) {
      _i.containerMouseClick();
    }
  };

  _i.constructor = function () {
    // NOTE new container
    _i.rowWrap = document.createElement('DIV');
    _i.rowWrap.className = 'blockFloatNot savedReportWrap';
    _i.rowWrap.style.display = 'flex';
    _i.rowWrap.role = 'button';
    _i.attachTo.appendChild(_i.rowWrap);

    // NOTE existing container
    _i.tagContainer = document.createElement('DIV');
    _i.tagContainer.tabIndex = 0;
    //_i.tagContainer.role = 'button';
    _i.tagContainer.className = _i.user_id
      ? 'blockFloatNot savedReportContainer'
      : 'blockFloatNot savedReportContainerWide';

    _i.userCanDeleteReport = [1, 2].indexOf(_i.user_type_id) > -1;
    _i.rowWrap.appendChild(_i.tagContainer);

    // move event listener to row wrap
    // add an event listener onClick
    _i.tagContainer.style.cursor = 'pointer';
    xAddEventListener(
      _i.tagContainer,
      platform.click,
      _i.containerMouseClick,
      false
    );
    xAddEventListener(
      _i.tagContainer,
      'keydown',
      _i.containerButtonPress,
      false
    );

    _i.icon = document.createElement('DIV');
    _i.tagNameText = document.createElement('DIV');
    if (_i.user_id) {
      if (_i.user_id == -1)
        _i.icon.className = 'blockFloat savedReportIconNoLeftMargin';
      else _i.icon.className = 'blockFloat savedReportIcon';
      _i.icon.appendChild(getSprite(compSpritesButtonsIcons.reportIconSmall));
      _i.tagNameText.className = 'blockFloat fnt-r-12 savedReportText';
    } else {
      _i.icon.className = 'blockFloat savedReportIconWide';
      _i.icon.appendChild(getSprite(compSpritesButtonsIcons.reportIconLarge));
      _i.tagNameText.className = 'blockFloat fnt-r-14 savedReportTextWide';
    }
    _i.tagContainer.appendChild(_i.icon);
    _i.tagNameText.innerText = _i.name;
    _i.tagContainer.setAttribute('aria-role', 'link');
    _i.tagContainer.setAttribute(
      'aria-label',
      'link, ' + _i.name + ' You are on a link.'
    );
    _i.tagContainer.appendChild(_i.tagNameText);

    // NOTE the button gets attached to the new container
    _i.buttoncontainer = document.createElement('DIV');
    _i.buttoncontainer.className = 'blockFloat';
    _i.buttoncontainer.style.width = '32px';
    _i.buttoncontainer.style.height = '28px';
    _i.rowWrap.appendChild(_i.buttoncontainer);

    //Note: if the user is not admin or PM, do not display the trash can for remove saved report
    if (_i.user_id != -1 && _i.userCanDeleteReport) {
      _i.deleteb = new spritesButton(
        'blockFloat deleteButtonCategory deleteSavedReport',
        null,
        compSpritesButtonsIcons.deleteButtonRest,
        compSpritesButtonsIcons.deleteButtonHover,
        compSpritesButtonsIcons.deleteButtonDown
      );
      _i.deleteb.setCallback(_i.deleteReport);
      _i.deleteb.tabIndex = 0;
      _i.deleteb.setButtonText('');
      xAddEventListener(
        _i.deleteb.container,
        platform.click,
        _i.deleteMouseClick,
        false
      );
      xAddEventListener(
        _i.deleteb.container,
        'keydown',
        _i.deleteButtonPress,
        false
      );
      _i.buttoncontainer.appendChild(_i.deleteb.container);
      _i.deleteb.container.setAttribute(
        'aria-label',
        'delete, ' + _i.name + 'You are on a button.'
      );
    }
  };
  _i.constructor();
}

function SavedReportsView(attach, user, user_type_id) {
  var _i = this;
  _i.attachTo = xGetElementById(attach);
  _i.user = user;
  _i.user_type_id = user_type_id;
  _i.container = null;

  _i.rebuildDisplay = function () {
    if (_i.user) {
      _i.user = gData.getUserByGuid(_i.user.guid);
    }
    removeAllChildren(_i.container);
    _i.buildDisplay();
  };

  _i.buildDisplayForUserReports = function () {
    var headeradded = false;
    for (var i = 0; i < _i.user.tags.length; i++) {
      if (_i.user.tags[i].namespace == 'user reports') {
        if (!headeradded) {
          var header = document.createElement('DIV');
          header.className = 'fnt-r-20 headerText3';
          header.innerHTML = I18n.t('lbl_saved_reports');
          _i.container.appendChild(header);
          headeradded = true;

          var spacer = document.createElement('DIV');
          spacer.className = 'blockFloatNot lineSpacerSaveReport';
          _i.container.appendChild(spacer);
        }
        var a = new SavedReportsRow(
          _i.container,
          _i.user.tags[i].name,
          _i.user.tags[i].value,
          _i.user.id,
          _i.user.tags[i],
          _i.rebuildDisplay,
          null,
          _i.user_type_id
        );
        var spacer = document.createElement('DIV');
        spacer.className = 'blockFloatNot lineSpacerSaveReport';
        _i.container.appendChild(spacer);
      }
    }
  };

  _i.buildDisplayForOrganizationReports = function () {
    var reports = window.savedOrgReports;
    if (reports && reports.length > 0) {
      var header = document.createElement('H2');
      header.className = 'fnt-r-20 headerText3';
      header.innerHTML = I18n.t('lbl_saved_reports');
      _i.container.appendChild(header);
      headeradded = true;

      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot lineSpacerSaveReport';
      _i.container.appendChild(spacer);
    }
    for (var i = 0; reports && i < reports.length; i++) {
      var a = new SavedReportsRow(
        _i.container,
        reports[i].name,
        reports[i].value,
        null,
        reports[i],
        _i.rebuildDisplay,
        null,
        _i.user_type_id,
        reports[i].id
      );
      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot lineSpacerSaveReport';
      _i.container.appendChild(spacer);
    }
  };

  _i.buildDisplay = function () {
    if (_i.user) _i.buildDisplayForUserReports();
    else _i.buildDisplayForOrganizationReports();
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot savedReportsOuterDiv';
    _i.attachTo.appendChild(_i.container);
    _i.buildDisplay();
  };
  _i.constructor();
}
