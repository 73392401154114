function OrgBrandPreview(
  attach,
  logoUrl,
  backgroundColor,
  headerColor,
  shadowEnabled
) {
  var _i = this;
  if (!(this instanceof OrgBrandPreview)) {
    return new OrgBrandPreview(
      attach,
      logoUrl,
      backgroundColor,
      headerColor,
      shadowEnabled
    );
  }

  _i.attachTo = xGetElementById(attach);
  _i.logoUrl = logoUrl;
  _i.backgroundColor = backgroundColor;
  _i.headerColor = headerColor;
  _i.shadowEnabled = shadowEnabled;

  _i.addThumbnailView = function () {
    _i.orgThumbnailView = document.createElement('DIV');
    _i.orgThumbnailView.className = 'settingsBrandingPreview';

    _i.attachTo.appendChild(_i.orgThumbnailView);

    _i.thumbnailContainer = document.createElement('DIV');
    _i.thumbnailContainer.className =
      'blockFloatNot settingsBrandingPreviewInner';
    _i.thumbnailContainer.style.backgroundColor = _i.backgroundColor;
    _i.orgThumbnailView.appendChild(_i.thumbnailContainer);

    _i.thumbnailCropprContainer = document.createElement('DIV');

    if (_i.logoUrl.localeCompare(DEFAULT_LOGO_URL) !== 0) {
      _i.thumbnailCropprContainer.className = 'plansHeaderLogoCustom';
      _i.thumbnailCropprContainer.style.width = 'auto';
      _i.thumbnailCropprContainer.style.display = 'inline-block';
      _i.thumbnail = document.createElement('IMG');
      _i.thumbnail.className = 'headerLogoCustomImage';
      _i.thumbnail.src = _i.logoUrl;
    } else {
      _i.thumbnailCropprContainer.className = 'plansHeaderLogo';
      _i.thumbnail = document.createElement('IMG');
      _i.thumbnail.className = 'headerLogoCustomImage';
      _i.thumbnail.src = _i.logoUrl;
    }
    _i.thumbnailCropprContainer.appendChild(_i.thumbnail);

    _i.paddingDiv = document.createElement('DIV');
    _i.paddingDiv.style.margin = '0';
    _i.paddingDiv.appendChild(_i.thumbnailCropprContainer);
    _i.thumbnailContainer.appendChild(_i.paddingDiv);
  };

  _i.addExampleHeaders = function () {
    _i.headerPreview = document.createElement('DIV');
    _i.headerPreview.className = 'blockFloat menuButtonsLeft unselectable';
    _i.headerPreview.style.display = 'block';

    _i.thumbnailContainer.appendChild(_i.headerPreview);

    // add Org Name or
    _i.companyNameDiv = _i.createHeader(
      _i.headerPreview,
      'companyNamePreview',
      gPageHeader.companyname,
      'selected'
    );
    // add User Name or Profile
    _i.usernameDiv = _i.createHeader(
      _i.headerPreview,
      'userNamePreview',
      gPageHeader.username
    );
  };

  _i.createHeader = function (
    attachTo,
    elementId,
    labelText,
    additionalClasses
  ) {
    var container = document.createElement('DIV');
    container.style.width = 'auto';
    container.style.height = '100%';

    var containerClasses = 'fnt-r-14 menuButtonItem blockFloat';
    if (additionalClasses) {
      containerClasses = containerClasses + ' ' + additionalClasses;
    }
    if (_i.shadowEnabled === false) {
      container.style.textShadow = 'none';
    }
    container.className = containerClasses;
    container.id = elementId;

    var label = document.createElement('DIV');
    label.className = 'editBrandMenuButtonItemLabel floatLeft';
    label.innerText = labelText;
    label.style.color = _i.headerColor;

    container.appendChild(label);
    attachTo.appendChild(container);

    return container;
  };

  _i.updateBackgroundColor = function (backgroundColor) {
    _i.backgroundColor = backgroundColor;
    _i.thumbnailContainer.style.backgroundColor = _i.backgroundColor;
  };

  _i.updateHeaderColor = function (headerColor) {
    _i.headerColor = headerColor;
    _i.companyNameDiv.firstChild.style.color = _i.headerColor;
    _i.usernameDiv.firstChild.style.color = _i.headerColor;
  };

  _i.updateShadowEnabled = function (shadowEnabled) {
    _i.shadowEnabled = shadowEnabled;
    _i.companyNameDiv.style.textShadow =
      _i.shadowEnabled === false ? 'none' : '';
    _i.usernameDiv.style.textShadow = _i.shadowEnabled === false ? 'none' : '';
  };

  _i.constructor = function () {
    _i.addThumbnailView();
    _i.addExampleHeaders();
  };

  _i.constructor();
}
