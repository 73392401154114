function PopUpTextInput(
  attach,
  label,
  initial_value,
  container_style,
  button_style,
  label_style,
  input_style,
  input_data,
  popup,
  icon_style,
  options,
  dropdownID,
  btnID
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.label = label;
  _i.label_style = label_style;
  _i.initial_value = initial_value;
  _i.input_data = input_data;
  _i.input_style = input_style;
  _i.selected_value = initial_value;
  _i.container_style = container_style;
  _i.button_style = button_style;
  _i.popup = popup;
  _i.guid = Awe.getGuid();
  _i.editable = false;
  _i.icon_style = icon_style;
  _i.confirmedCallback = null;
  _i.options = options;

  // ids
  _i.dropdownID = dropdownID;
  _i.btnID = btnID;
  _i.currentSelectionID = 'current-selection' + Awe.getGuid();

  if (label) {
    _i.labelID = label.replace(/\s+/g, '-') + _i.guid;
  }

  _i.setValue = function (val) {
    _i.selected_value = val;
    _i.inputContainer.innerHTML = '';
    _i.createContent();
  };

  _i.setConfirmedCallback = function (callback) {
    _i.confirmedCallback = callback;
  };

  _i.inputKeydown = function (evt) {
    if (evt.keyCode == 13) {
      cancelEvent(evt);
      _i.inputText.blur();
      _i.editable = false;
    } else if (!_i.editable) {
      cancelEvent(evt);
    }
  };

  _i.displayedValue = function () {
    if (_i.inputText.innerHTML == I18n.t('lbl_enter_new_item')) return null;
    return _i.inputText.textContent;
  };

  _i.focusTextInput = function () {
    var confirm = textinputPopup(
      I18n.t('lbl_resend_invitation_to'),
      I18n.t('lbl_add'),
      function (val) {
        _i.confirmedCallbackInternal(val);
      },
      null,
      null,
      null,
      _i.options
    );
    confirm.show('elementDropDownSelect' + _i.guid);
  };

  _i.confirmedCallbackInternal = function (value) {
    if (value) {
      _i.inputText.innerText = value;
    }
    _i.confirmedCallback && _i.confirmedCallback(value);
  };

  _i.onButtonClick = function () {
    if (_i.input_data.length > 0) {
      // For a filterPopup, hide() will clear the selection, so restore it before calling show.
      // TODO: this is a workaround to allow this type to be used with filter popup for drop down
      // custom fields. Long term PopupTextInput should not be used with filter popup since it does
      // not correctly support multi selection.
      if (_i.popup instanceof filterPopup) {
        _i.popup.refreshSelection();
      }
      _i.popup.show('elementDropDownSelect' + _i.guid);
      _i.popup.isShown = true;
    }
    return false;
  };

  _i.onKeypress = function (e) {
    if (typeof e == 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key == 'Enter' || e.key == ' ') {
      _i.onButtonClick();
      focusFirstChildElementAfterDelay('#' + _i.dropdownID);
    }
    return true;
  };

  _i.blurTextInputHandler = function () {
    if (_i.popup.isShown) {
      _i.popup.hide();
      _i.popup.isShown = false;
    }
    xRemoveEventListener(
      _i.buttonContainer,
      platform.click,
      _i.onButtonClick,
      false
    );
    xAddEventListener(
      _i.elementDropDownSelect,
      platform.click,
      _i.onButtonClick,
      false
    );
    xRemoveEventListener(_i.buttonContainer, 'keypress', _i.onKeypress, false);
    xAddEventListener(
      _i.elementDropDownSelect,
      'keypress',
      _i.onKeypress,
      false
    );
  };

  _i.createBackground = function (sprite) {
    _i.bbar = new backgroundBar(sprite);
    _i.buttonContainer.appendChild(_i.bbar.container);
    if (_i.spriteHover) {
      _i.buttonContainer.backgroundHover = new backgroundBar(_i.spriteHover);
      _i.buttonContainer.appendChild(_i.container.backgroundHover.container);
      _i.buttonContainer.backgroundHover.container.style.display = 'none';
      _i.buttonContainer.backgroundHover.container.setAttribute(
        'aria-hidden',
        true
      );
    }
    if (_i.spriteDown) {
      _i.buttonContainer.backgroundDepress = new backgroundBar(_i.spriteDown);
      _i.buttonContainer.appendChild(_i.container.backgroundDepress.container);
      _i.buttonContainer.backgroundDepress.container.style.display = 'none';
      _i.buttonContainer.backgroundDepress.container.setAttribute(
        'aria-hidden',
        true
      );
    }
  };

  _i.createContent = function () {
    if (_i.label) {
      _i.labelText = document.createElement('LABEL');
      _i.labelText.className = label_style;
      _i.labelText.id = _i.labelID;
      _i.labelText.innerText = label;
      _i.inputContainer.appendChild(_i.labelText);
    }

    _i.buttonContainer = document.createElement('BUTTON');
    _i.buttonContainer.className = _i.button_style;
    _i.buttonContainer.id = _i.btnID;
    _i.buttonContainer.setAttribute('aria-describedby', _i.currentSelectionID);
    _i.inputContainer.appendChild(_i.buttonContainer);

    xAddEventListener(
      _i.buttonContainer,
      platform.click,
      _i.onButtonClick,
      false
    );
    xAddEventListener(_i.buttonContainer, 'keypress', _i.onKeypress, false);

    if (_i.label) {
      _i.buttonContainer.setAttribute('aria-labelledby', _i.labelID);
    }

    _i.createBackground(compSpritesButtonsIcons.secondaryButtonRest);

    if (
      _i.selected_value != null &&
      _i.selected_value < _i.input_data.length &&
      _i.input_data[_i.selected_value].icon
    ) {
      _i.iconImage = document.createElement('DIV');
      if (_i.icon_style) {
        _i.iconImage.className = 'blockFloat ' + _i.icon_style;
      } else {
        _i.iconImage.className = 'blockFloat iconImage';
      }
      if (_i.selected_value != null && _i.selected_value < _i.input_data.length)
        getSprite(
          compSpritesButtonsIcons[_i.input_data[_i.selected_value].icon],
          _i.iconImage
        );
      _i.buttonContainer.appendChild(_i.iconImage);
    }

    _i.inputText = document.createElement('DIV');
    _i.inputText.className = input_style;
    _i.inputText.id = _i.currentSelectionID;

    if (_i.selected_value != null && _i.selected_value < _i.input_data.length) {
      _i.inputText.innerText = _i.input_data[_i.selected_value].label;
      _i.inputText.title = _i.inputText.innerText;
    }
    _i.buttonContainer.appendChild(_i.inputText);
    xAddEventListener(_i.inputText, 'blur', _i.blurTextInputHandler, true);
    xAddEventListener(_i.inputText, 'keydown', _i.inputKeydown, true);

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    _i.buttonContainer.appendChild(_i.nestedDiv);

    _i.elementDropDownSelect = document.createElement('DIV');
    _i.elementDropDownSelect.className = 'blockFloat iconDropDownSelect';
    _i.elementDropDownSelect.appendChild(
      getSprite(compSpritesButtonsIcons.downArrowMenu)
    );
    _i.elementDropDownSelect.id = 'elementDropDownSelect' + _i.guid;
    _i.nestedDiv.appendChild(_i.elementDropDownSelect);
  };

  _i.constructor = function () {
    _i.inputContainer = document.createElement('DIV');
    _i.inputContainer.className = _i.container_style;
    _i.attachTo?.appendChild(_i.inputContainer);
    _i.createContent();
  };

  _i.constructor();
}
