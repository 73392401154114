// trace and debug pretty-print helpers
var __tr = true,
  __TR = function () {}; // no-op by default
(__lastTime = Date.now()),
  (ARC = new arc(
    [
      '/api/users',
      '/api/projects',
      '/api/leave_types',
      '/api/v1/users',
      '/api/v1/projects',
      '/api/v1/leave_types',
      '/api/v1/placeholder_resources',
      '/api/v1/custom_fields',
      '/api/v1/reports/meta/custom_field_values',
    ],
    true /* shouldPurgeStaleData */,
    DISABLE_SESSION_CACHING
  ));

function _pad2(n) {
  var s = '00' + n;
  return s.substring(s.length - 2);
}
function _pad3(n) {
  var s = '000' + n;
  return s.substring(s.length - 3);
}
function _time() {
  var d = new Date(),
    ms = d.getMilliseconds(),
    t = d.getTime();
  return I18n.l('date.formats.log', d) + '.' + _pad3(ms);
  __lastTime = t;
}
function _pad6(n) {
  var s = '000000' + n;
  return s.substring(s.length - 6);
}
function _msSinceLast() {
  var d = new Date(),
    t = d.getTime(),
    ms = t - __lastTime;
  __lastTime = t;
  return _pad6(ms);
}

if (__tr) {
  if (LOCAL_BUILD) {
    var _TR = function (s) {
      console.log('TRACE ' + _time() + ' (+' + _msSinceLast() + '): ' + s);
    };
  } else {
    var _TR = function (s) {
      console.log('TRACE: ' + s);
    };
  }
}

function _MTL(s) {
  console.timeStamp(s);
}
function _JS(o, pretty) {
  if (__tr && typeof o != 'string' && JSON && JSON.stringify) {
    return pretty ? JSON.stringify(o, null, 2) : JSON.stringify(o);
  } else {
    return o;
  }
}
function _dump(o, msg) {
  console.log('DUMP ' + (msg ? msg : '') + ':');
  console.log(o);
}
function _WARN(message) {
  console.warn(message);
}
function _ERROR(message) {
  if (LOCAL_BUILD) {
    _TR(message);
    debugger;
  } else {
    console.log('ERROR: ' + message);
  }
}

function __A(condition, description) {
  if (condition) {
    return;
  } else {
    _TR('Assertion failed: ' + description);
    if (LOCAL_BUILD) {
      debugger;
    }
  }
}

// http://stackoverflow.com/questions/979975/how-to-get-the-value-from-url-parameter
var QueryString = (function () {
  var query_string = {},
    query = window.location.search.substring(1),
    vars = query.split('&'),
    pair,
    key,
    qs_key,
    val;

  for (var i = 0, len = vars.length; i < len; i++) {
    pair = vars[i].split('=');
    key = pair[0];
    qs_val = query_string[key];
    val = pair[1];
    if (typeof qs_val === 'undefined') {
      query_string[key] = val;
    } else if (typeof qs_val === 'string') {
      var arr = [qs_val, val];
      query_string[key] = arr;
    } else {
      qs_val.push(val);
    }
  }
  return query_string;
})();

var VALID_EMAIL_REGEX = /^[A-Z0-9.'_%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// Production steps of ECMA-262, Edition 5, 15.4.4.18
// Reference: http://es5.github.com/#x15.4.4.18
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (callback, thisArg) {
    var T, k;
    if (this == null) {
      throw new TypeError(' this is null or not defined');
    }
    var O = Object(this);
    var len = O.length >>> 0; // Hack to convert O.length to a UInt32
    if ({}.toString.call(callback) != '[object Function]') {
      throw new TypeError(callback + ' is not a function');
    }
    if (thisArg) {
      T = thisArg;
    }
    k = 0;
    while (k < len) {
      var kValue;
      if (k in O) {
        kValue = O[k];
        callback.call(T, kValue, k, O);
      }
      k++;
    }
  };
}

var DayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

var DayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

var MonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

var MonthNamesLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// add n days to this date. alters the value of this date
Date.prototype.addDays = function (n) {
  this.setDate(this.getDate() + n);
  return this;
};

// get a new date that is d + n days. d is not altered
Date.addDays = function (d, n) {
  var newDate = new Date(d.getTime());
  newDate.addDays(n);
  return newDate;
};

Date.prototype.toDayOfWeekString = function (longName) {
  return this.toLocaleDateString(window.I18n.locale, {
    weekday: longName ? 'long' : 'short',
  });
};

Date.prototype.toInputStyleString = function () {
  return this.toLocaleDateString(window.I18n.locale, {
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};
Date.prototype.toInputStyleStringShort = function () {
  return this.toLocaleDateString(window.I18n.locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};
Date.prototype.toInputStyleStringLong = function () {
  return this.toLocaleDateString(window.I18n.locale, {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
};
Date.prototype.toInputStyleStringDayShort = function () {
  return this.toLocaleDateString(window.I18n.locale, {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

Date.prototype.toFeedStyleFormattedString = function () {
  var now = new Date();
  var one_day = 1000 * 60 * 60 * 24;
  var timeFromDate = this.getTime();
  var timeFromNow = now.getTime();

  if (timeFromDate >= timeFromNow - 1000 * 60) {
    return 'Now';
  }
  if (timeFromNow - timeFromDate < one_day) {
    var mins = timeFromNow - timeFromDate;
    mins = mins / (1000 * 60);
    if (mins < 60) {
      return (
        mins.toFixed(0) +
        (mins.toFixed(0) == '1' ? ' minute ago' : ' minutes ago')
      );
    } else {
      var hours = mins / 60;
      hours += 1;
      return (
        hours.toFixed(0) +
        (hours.toFixed(0) == '1' ? ' hour ago' : ' hours ago')
      );
    }
  }
  return this.toDateTimeFormattedString();
};

Date.prototype.daysInMonth = function () {
  return new Date(this.getYear(), this.getMonth() + 1, 0).getDate();
};

Date.prototype.toDateFormattedStringShort = function () {
  return I18n.l('date.formats.short', this);
};

Date.prototype.toDateFormattedStringShortWithYear = function () {
  if (window.I18n && window.I18n.locale)
    return this.toLocaleDateString(window.I18n.locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  return this.toDateFormattedStringShort() + ', ' + this.getFullYear();
};

Date.prototype.toDateFormattedString = function () {
  return I18n.l('date.formats.long', this);
};

Date.prototype.toDateTimeFormattedString = function () {
  return I18n.l('time.formats.long', this);
};

Date.prototype.toStartOfDay =
  Date.prototype.toStartOfDay ||
  function () {
    this.setHours(0, 0, 0, 0);
    return this;
  };

// added return value so function can be used
// on a new instance
Date.toStartOfDay =
  Date.toStartOfDay ||
  function (d) {
    var newDate = new Date(d.getTime());
    newDate.toStartOfDay();
    return newDate;
  };

Date.prototype.toStartOfWeek =
  Date.prototype.toStartOfWeek ||
  function (day) {
    this.toStartOfDay();
    day = day || 0;
    while (this.getDay() != day) {
      this.setDate(this.getDate() - 1);
    }
  };

Date.prototype.formatWeek = function () {
  //Currently the algorythm  we are using for weekno
  //sets the week to 00 when it belongs to the last week of the
  //previous year. We need to calculate which year it belongs to.

  weekno = this.getWeek();

  var addToYear = 0;
  if (weekno == 1 && this.getDayOfYear() >= 183) {
    addToYear = 1;
  } else if (weekno == 0 && this.getDayOfYear() < 183) {
    addToYear = -1;
    weekno = Date.addDays(this, -this.getDayOfYear()).getWeek();
  } else if (weekno >= 52 && this.getDayOfYear() < 182) {
    addToYear = -1;
  }

  return (
    this.getFullYear() +
    addToYear +
    ', week ' +
    (weekno >= 10 ? weekno : '0' + weekno)
  );
};

Date.prototype.formatMonth = function () {
  // Convert to the internationalized months
  var currentMonth = this.getMonth() + 1;
  var currentYear = this.getFullYear();
  var monthPadding = currentMonth < 10 ? '0' : '';

  return currentYear + '-' + monthPadding + currentMonth;
};

Date.toStartOfWeek =
  Date.toStartOfWeek ||
  function (d, day) {
    var newDate = new Date(d.getTime());
    newDate.toStartOfWeek(day);
    return newDate;
  };

Date.prototype.toEndOfWeek =
  Date.prototype.toEndOfWeek ||
  function (day) {
    this.toStartOfDay();
    day = day || 6;
    while (this.getDay() != day) {
      this.setDate(this.getDate() + 1);
    }
  };

Date.toEndOfWeek =
  Date.toStartOfWeek ||
  function (d, day) {
    var newDate = new Date(d.getTime());
    newDate.toEndOfWeek(day);
    return newDate;
  };

Date.prototype.toStartOfMonth =
  Date.prototype.toStartOfMonth ||
  function () {
    this.toStartOfDay();
    this.setDate(1);
  };

Date.toStartOfMonth =
  Date.toStartOfMonth ||
  function (d) {
    var newDate = new Date(d.getTime());
    newDate.toStartOfMonth();
    return newDate;
  };

Date.prototype.toEndOfMonth =
  Date.prototype.toEndOfMonth ||
  function () {
    this.toStartOfDay();
    this.setDate(1);
    this.setMonth(this.getMonth() + 1);
    this.setDate(0);
  };

Date.toEndOfMonth =
  Date.toEndOfMonth ||
  function (d) {
    var newDate = new Date(d.getTime());
    newDate.toEndOfMonth();
    return newDate;
  };

Date.prototype.toStartOfYear =
  Date.prototype.toStartOfYear ||
  function () {
    this.toStartOfDay();
    this.setDate(1);
    this.setMonth(0);
  };

Date.toStartOfYear =
  Date.toStartOfYear ||
  function (d) {
    var newDate = new Date(d.getTime());
    newDate.toStartOfYear();
    return newDate;
  };

Date.prototype.toEndOfYear =
  Date.prototype.toEndOfYear ||
  function () {
    this.toStartOfDay();
    this.setDate(1);
    this.setMonth(11);
    this.setDate(31);
  };

Date.prototype.equals =
  Date.prototype.equals ||
  function (anotherDate) {
    return this.getTime() == anotherDate.getTime();
  };

Date.prototype.toRubyDateUTC =
  Date.prototype.toRubyDate ||
  function () {
    return (
      this.getUTCFullYear() +
      '-' +
      _pad2(this.getUTCMonth() + 1) +
      '-' +
      _pad2(this.getUTCDay())
    );
  };

Date.prototype.toServerDate =
  Date.prototype.toRubyDate ||
  function () {
    return (
      this.getFullYear() +
      '-' +
      _pad2(this.getMonth() + 1) +
      '-' +
      _pad2(this.getDate())
    );
  };

// BUGBUG: Ruby date format is year-month-date, this is backwards
// See toServerDate above if you want YYYY-MM-DD
Date.prototype.toRubyDate =
  Date.prototype.toRubyDate ||
  function () {
    return (
      _pad2(this.getDate()) +
      '-' +
      _pad2(this.getMonth() + 1) +
      '-' +
      this.getFullYear()
    );
  };

// BUGBUG: Ruby date format is year-month-date, so this is the correct version for code that needs it
Date.prototype.toRubyDateYMD =
  Date.prototype.toRubyDateYMD ||
  function () {
    return (
      this.getFullYear() +
      '-' +
      _pad2(this.getMonth() + 1) +
      '-' +
      _pad2(this.getDate())
    );
  };

Date.prototype.getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.epoch-calendar.com */

  dowOffset = _.isNumber(dowOffset) ? dowOffset : 1; //default dowOffset to 1 aka Monday
  var newYear = new Date(this.getFullYear(), 0, 1);
  var day = newYear.getDay() - dowOffset; //the day of week the year begins on

  day = day >= 0 ? day : day + 7;

  var daynum =
    Math.floor(
      (this.getTime() -
        newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
        86400000
    ) + 1;

  var weeknum;
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    if (weeknum > 52) {
      var nYear = new Date(this.getFullYear() + 1, 0, 1);
      var nday = nYear.getDay() - dowOffset;
      nday = nday >= 0 ? nday : nday + 7;
      /* if the next year starts before the middle of
     the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53;
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7);
  }

  return weeknum;
};
// Joe Orost
// http://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366/26426761#26426761
Date.prototype.isLeapYear = function () {
  var year = this.getFullYear();
  if ((year & 3) != 0) return false;
  return year % 100 != 0 || year % 400 == 0;
};

Date.prototype.getDayOfYear = function () {
  var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  var mn = this.getMonth();
  var dn = this.getDate();
  var dayOfYear = dayCount[mn] + dn;
  if (mn > 1 && this.isLeapYear()) dayOfYear++;
  return dayOfYear;
};

Date.toEndOfYear =
  Date.toEndOfYear ||
  function (d) {
    var newDate = new Date(d.getTime());
    newDate.toEndOfYear();
    return newDate;
  };

Date.sameDay = function (a, b) {
  return (
    a.getDate() == b.getDate() &&
    a.getMonth() == b.getMonth() &&
    a.getFullYear() == b.getFullYear()
  );
};

Date.forDayOfWeek = function (d, day) {
  return Date.addDays(d, day - d.getDay());
};

Date.min = function (a, b) {
  return a.getTime() <= b.getTime() ? a : b;
};

Date.max = function (a, b) {
  return a.getTime() >= b.getTime() ? a : b;
};

Date.differenceMilliseconds = function (a, b) {
  return Math.abs(a.getTime() - b.getTime());
};

Date._MS_PER_DAY = 1000 * 60 * 60 * 24;

Date.calendarDayDiff = function (a, b) {
  // Discard the time and time-zone information.
  var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / Date._MS_PER_DAY);
};

String.prototype.ltrim = function () {
  return this.replace(/^\s+/, '');
};
String.prototype.rtrim = function () {
  return this.replace(/\s+$/, '');
};

Array.prototype.removeById = function (id, idPropertyName) {
  idPropertyName = idPropertyName || 'id';
  for (var i = 0; i < this.length; ++i) {
    if (this[i][idPropertyName] == id) {
      return this.splice(i, 1);
    }
  }
  return false;
};

Array.prototype.indexOfId = function (id, idPropertyName) {
  idPropertyName = idPropertyName || 'id';
  for (var i = 0, len = this.length; i < len; ++i) {
    if (this[i][idPropertyName] == id) {
      return i;
    }
  }
  return -1;
};

function formatNumber(val, precision) {
  if (!isNumber(val)) {
    return val;
  }
  if (precision != null && precision >= 0) return val.toFixed(precision);
  if (Number(val.toFixed(0)) == Number(val.toFixed(2))) {
    return val.toFixed(0);
  } else if (Number(val.toFixed(1)) == Number(val.toFixed(2))) {
    return val.toFixed(1);
  } else {
    return val.toFixed(2);
  }
}

function isWholeNumber(num) {
  return num % 1 > 0 ? false : true;
}

// This polyfill is for Number.MAX_SAFE_INTEGER
function isBelowMaxSafeInteger(value) {
  var MAX_SAFE_INTEGER = Math.pow(2, 53) - 1;
  return Math.abs(value) <= MAX_SAFE_INTEGER;
}

//taken from http://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript
// Utility methods
function formatCurrency(num, options) {
  if (typeof options === 'undefined') options = {};
  if (isNaN(parseFloat(num)) && isFinite(num)) {
    return I18n.toCurrency(0, options);
  } else {
    var precision = isWholeNumber(num) ? 0 : 2;
    return I18n.toCurrency(num, _.assign(options, { precision: precision }));
  }
}

function gotoMarketingSite() {
  document.location = 'https://www.10000ft.com';
}

function cancelEvent(e) {
  if (e == null) {
    e = window.event;
  }

  if (!e) {
    return;
  }

  if (!window.attachEvent) {
    e.stopPropagation && e.stopPropagation();
    e.preventDefault && e.preventDefault();
    return false;
  }

  e.cancelBubble = true;
  e.returnValue = false;

  return false;
}

// iterates through DOM and returns the highest Z index used
function hiTagZ() {
  var retVal = 0;

  var tagArray = ['DIV', 'IFRAME', 'IMG', 'A', 'UL', 'LI', 'OBJECT'];

  for (var y = 0, len = tagArray.length; y < len; y++) {
    var curTag = tagArray[y];
    var a = document.getElementsByTagName(curTag);
    for (var z = 0, a_len = a.length; z < a_len; z++) {
      var i = xGetComputedStyle(a[z], 'z-index', true);
      if (i) {
        if (i > retVal) {
          retVal = i;
        }
      }
    }
  }

  return retVal;
}

function round(num, decimals) {
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

function createUUID() {
  // http://www.ietf.org/rfc/rfc4122.txt
  // http://stackoverflow.com/a/8809472/850996
  var d = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

function stringComparer(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

function stringComparerCaseInsensitive(a, b) {
  a && (a = a.toLowerCase());
  b && (b = b.toLowerCase());
  return stringComparer(a, b);
}

function trim(str) {
  return str.replace(/^\s+|\s+$/, '');
}

function singleSpace(str) {
  return str.replace(/\s{2,}/g, ' ');
}

var guid = 0;

function csrf_param() {
  return $('meta[name="csrf-param"]').attr('content');
}

function csrf_token() {
  return $('meta[name="csrf-token"]').attr('content');
}

function getBaseURL() {
  return window.APP_ENDPOINT + '/';
}

// Based on http://bit.ly/P1oMcr
function hashCode(s) {
  var h = 5381;
  for (var i = 0, len = s.length; i < len; i++) {
    char = s.charCodeAt(i);
    h = (h << 5) + h + char; /* h * 33 + c */
  }
  return h;
}

function getJS(jsUrl, data, onSuccess, onError) {
  jqxhrWrapper('GET', jsUrl, data, onSuccess, onError);
}

function getJSON(jsUrl, onSuccess, onError) {
  jqxhrWrapper('GET', jsUrl, null, onSuccess, onError, 'application/json');
}

function postJS(jsUrl, data, onSuccess, onError) {
  jqxhrWrapper('POST', jsUrl, data, onSuccess, onError);
}

// TODO: Current post/put utils don't define content type JSON. V1 API requires it,
// so to get around this, we define a separate helper for V1. Would be preferable to
// unite these.
function postJSV1(jsUrl, data, onSuccess, onError) {
  jqxhrWrapper('POST', jsUrl, data, onSuccess, onError, 'application/JSON');
}

function putJS(jsUrl, data, onSuccess, onError) {
  jqxhrWrapper('PUT', jsUrl, data, onSuccess, onError);
}

function putJSV1(jsUrl, data, onSuccess, onError) {
  jqxhrWrapper('PUT', jsUrl, data, onSuccess, onError, 'application/JSON');
}

function deleteJS(jsUrl, onSuccess, onError) {
  jqxhrWrapper('DELETE', jsUrl, null /* data */, onSuccess, onError);
}

function deleteJSV1(jsUrl, onSuccess, onError) {
  jqxhrWrapper(
    'DELETE',
    jsUrl,
    null /* data */,
    onSuccess,
    onError,
    'application/JSON'
  );
}

function getUidh() {
  var s = window.whoami
    ? window.whoami.id + '-' + window.whoami.user_type_id
    : '';
  return hashCode(s);
}

// wrap $.ajax to perform additional logging and tracing
function jqxhrWrapper(type, jsUrl, data, onSuccess, onError, contentType) {
  var startTime,
    url = jsUrl,
    traceHeader = '[api-' + type.toLowerCase() + '] ',
    processData = true;

  if (url.indexOf('?') > 0) {
    url += '&';
  } else {
    url += '?';
  }

  // TODO: ___uidh is not required in all cases e.g. for PUT, POST, DELETE. Review use of ___uidh and use only when appropriate.
  url += '&___uidh=' + encodeURIComponent(getUidh());

  function beforeSend(jqXHR, settings) {
    jqXHR.setRequestHeader('X-CSRF-Token', csrf_token());
    if (
      (window.IS_EMBEDDED_SCHEDULE || window.isIframeView) &&
      window.clientState
    ) {
      jqXHR.setRequestHeader(
        'x-smar-xsrf',
        window.clientState.sessionKey || undefined
      );
    }
    startTime = new Date().getTime();
  }

  function complete(jqXHR, textStatus) {
    // TODO Report API request time for monitoring
    // var requestTime = new Date().getTime() - startTime;
  }

  function onsuccess(json, status, xhr) {
    // LOCAL_BUILD && console.log(json);
    if (json && json.error) {
      if (json.error == 'No auth') {
        deleteAllCookies();
        window.location = SIGN_IN_URL;
      }
      if (onError) {
        onError(json.error);
      } else {
        _ERROR(json.error);
        // todo - deprecate, and require onError
        new notificationPopup(
          'Sorry, something unexpected happened. If this continues to be a problem, please create a support ticket at ' +
            window.SMARTSHEET_SUPPORT_URL +
            '. Thank you.',
          json.error,
          6000
        );
      }
    } else {
      onSuccess && onSuccess(json);
    }
  }

  function onerror(jqXHR, textStatus, errorThrown) {
    if (onError) {
      onError(jqXHR, textStatus, errorThrown);
    } else {
      _ERROR(traceHeader + textStatus);
    }
  }
  $.support.cors = true;
  var processData = true;
  if (contentType == undefined) {
    contentType = 'application/x-www-form-urlencoded';
  } else if (contentType == 'application/JSON') {
    // If using content type application/JSON the data cannot be passed as-is,
    // since JSON parsing does not know how to handle it. Call stringify to get
    // JSON from the data.
    data = JSON.stringify(data);

    // Further, instruct JQuery to not process data into a query string since
    // we are processing it using stringify. This is needed for GET requests.
    processData = false;
  }

  // Try storing ajax locally to avoid requests being dropped.
  $.ajax({
    url: url,
    type: type,
    dataType: 'json',
    data: data,
    success: onsuccess,
    error: onerror,
    beforeSend: beforeSend,
    complete: complete,
    contentType: contentType,
    processData: processData,
    xhrFields: {
      withCredentials: true,
    },
  });
}

/*
 * jsUrl - the API url to GET
 * jOnload - invoked with successful json response
 * onError - invoked when the json response indicates and error
 * noauth - true, if auth tokens should not be attached to the API call
 * allowCache - allow browser to cache the server response
 * onChanged - invoked when data in cache is updated. ignored when allowCache is false.
 */
function injectJS(jsUrl, jsOnload, onError, noauth, allowCache, onChanged) {
  var url = jsUrl,
    uid = getUidh(),
    data = noauth ? null : { ___uidh: getUidh() },
    startTime,
    requestTime,
    cachedResponse,
    ajax;

  if (!ARC.isCacheable(jsUrl)) {
    __A(
      !allowCache,
      'Attempt to cache non-cacheable api response detected. ' + jsUrl
    );
    allowCache = false;
  }

  function onsuccess(json, status, xhr) {
    // TODO Report API request time for monitoring/analytics
    requestTime = new Date().getTime() - startTime;

    try {
      json = JSON.parse(json);
    } catch (err) {
      //bad json response
      json = JSON.parse(JSON.stringify(json));
    }
    if (json.error) {
      if (json.error == 'No auth') {
        deleteAllCookies();
        window.location = SIGN_IN_URL;
      }
      if (onError) {
        onError(json.error);
      } else {
        _ERROR(json.error);
        // todo - deprecate, and require onError
        new notificationPopup(
          'Sorry, something unexpected happened. If this continues to be a problem, please create a support ticket at ' +
            window.SMARTSHEET_SUPPORT_URL +
            '. Thank you.',
          json.error,
          6000
        );
      }
    } else {
      if (allowCache) {
        ARC.write(url, uid, xhr, JSON.stringify(json));
      }
      jsOnload && jsOnload(json);
    }
  }

  function onsuccessAsync(json, status, xhr) {
    if (!json.error && allowCache) {
      ARC.write(url, uid, xhr, json);
    }
  }

  function onerror(jqXHR, textStatus, errorThrown) {
    requestTime = new Date().getTime() - startTime;
    _TR(
      '[api-error] ' +
        this.url.replace('?', '.json?') +
        ' in ' +
        requestTime +
        ' ms'
    );
    if (onError) {
      onError(jqXHR, textStatus, errorThrown);
    } else {
      _ERROR('[api-error] ' + textStatus);
    }
  }

  function beforeSend(jqXHR, settings) {
    jqXHR.setRequestHeader('X-CSRF-Token', csrf_token());
    startTime = new Date().getTime();
  }

  function execAjax(successCallback) {
    $.support.cors = true;
    return (ajax = $.ajax({
      url: url,
      contentType: 'application/json; charset=utf-8',
      dataType: 'text',
      data: data,
      success: successCallback,
      error: onerror,
      beforeSend: beforeSend,
      cache: !DISABLE_XHR_CACHING && allowCache == true,
      xhrFields: {
        withCredentials: true,
      },
    }));
  }

  cachedResponse = ARC.read(url, uid);

  if (allowCache && cachedResponse) {
    setTimeout(function () {
      jsOnload && jsOnload(JSON.parse(cachedResponse));
    });
    ajax = setTimeout(function () {
      execAjax(onsuccessAsync);
    }, 5000);
  } else {
    ajax = execAjax(onsuccess);
  }
}

// Ensure DOM quota exceeded errors are averted
var safeSessionStorage = (function () {
  try {
    sessionStorage.setItem('test', 'test');
    sessionStorage.removeItem('test');
    return sessionStorage;
  } catch (e) {
    _TR('sessionStorage.setItem raise: ' + e);
    return {
      getItem: function (key) {
        return this[key];
      },
      setItem: function (key, val) {
        this[key] = '' + val;
      },
      removeItem: function (key) {
        delete this[key];
      },
      clear: function () {},
    };
  }
})();

function clearSessionStorage() {
  sessionStorage.clear();
  localStorage.clear();
}

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function getElementsByClassName(dcmnt, tag, clsName) {
  if (dcmnt == null) {
    dcmnt = document;
  }

  var arr = dcmnt.getElementsByTagName(tag);
  var outArr = [];

  for (var z = 0, len = arr.length; z < len; z++) {
    if (arr[z].className == clsName) {
      outArr[outArr.length] = arr[z];
    }
  }

  return outArr;
}

function parseForURLs(input) {
  if (input) {
    var regEx1 = /(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/;
    input = input.replace(regEx1, '<a href="mailto:$1">$1</a>');
    //  var regEx2 = /(^|[^'">])((\\\\)(\S+))(\b|$)/gi;
    //  input = input.replace(regEx2, "$1<a target='_blank' href='file:$2'>$2</a>");
    var myregex = /(^|[^'">])((ftp|http|https|file):\/\/(\S+))(\b|$)/gi;
    return input.replace(myregex, "$1<a target='_blank' href='$2'>$2</a>");
  } else {
    return null;
  }
}

function removeAllChildren(element) {
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
}

function justFilename(urlStr) {
  var retVal = urlStr;

  var ind = urlStr.lastIndexOf('/');

  if (ind > -1 && ind < urlStr.length - 1) {
    retVal = retVal.substr(ind + 1);
  }

  return retVal;
}

function synchronousPost(data, target) {
  syncxmlhttp = null;

  if (window.XMLHttpRequest) {
    syncxmlhttp = new XMLHttpRequest();
  } else {
    syncxmlhttp = new ActiveXObject('Microsoft.XMLHTTP');
  }

  syncxmlhttp.open('POST', target, false);
  syncxmlhttp.setRequestHeader(
    'Content-Type',
    'application/x-www-form-urlencoded'
  );
  syncxmlhttp.send(data);

  return syncxmlhttp.responseText;
}

function colorFromPercent(prcnt) {
  if (prcnt < 1) {
    return '#26A5DA';
  }
  if (prcnt > 1) {
    return '#EB008B';
  }
  return '#4BB649';
}

function redirect(url, params, replace) {
  var seperator = '?';

  params = params || {};

  for (v in params) {
    url += seperator + v + '=' + params[v];
    seperator = '&';
  }

  if (replace) {
    window.location.replace(url);
  } else {
    window.location.href = url;
  }
}

// Print debug messages on-screen when running locally
_enableDebugMessages = window.location.href.indexOf('localhost') >= 0;

function debugMessage(msg) {
  if (!_enableDebugMessages) {
    return;
  }
  _dwm = window._dwm || [];

  if (!window._dw) {
    // Create debug window
    _dw = document.createElement('DIV');
    var _dw_style = _dw.style;
    _dw_style.position = 'absolute';
    _dw_style.height = '300px';
    _dw_style.left = '1000px';
    _dw_style.top = '100px';
    document.getElementById('mainCon').appendChild(_dw);
  }

  _dwm.push(msg);
  _dw.innerHTML = '';

  for (var i = 0, _dwm_length = _dwm.length; i < _dwm_length; ++i) {
    var p = 300 - 2 - (i + 1) * 13;
    if (p < 0) {
      break;
    }
    var txt = document.createElement('DIV'),
      txt_style = txt.style;
    txt.className = 'fnt-b-13';
    txt_style.width = '500px';
    txt_style.height = '12px';
    txt_style.color = '#000';
    txt_style.fontSize = '10px';
    txt_style.position = 'absolute';
    txt_style.opacity = 1;
    txt_style.top = p + 'px';
    txt_style.left = '4px';
    txt.innerHTML = _dwm[_dwm_length - 1 - i];
    _dw.appendChild(txt);
  }
}

// From http://www.netlobo.com/url_query_string_javascript.html
function getParam(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regexS = '[\\?&]' + name + '=([^&#]*)';
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);
  if (results == null) return '';
  else return results[1];
}

// reports use this utility to see if a row is a
// null row (retitled) for pivoting
var commonLeaveType = I18n.t('lbl_leave_type_bracket_lc');
var commonNone = I18n.t('lbl_none_bracket_lc');
var commonProjectNone = I18n.t('lbl_project_bracket_lc');
reportRowisCatchAllRow = function (rowHeader) {
  return (
    rowHeader == commonLeaveType ||
    rowHeader == commonNone ||
    rowHeader == commonProjectNone
  );
};

// Create an object by copying an existing object or if a template. If from is null,
// this method returns a copy of the template, otherwise it returns a copy of from.
function copyOrCreate(from, template) {
  from = from || template;

  return JSON.parse(JSON.stringify(from));
  /*
  var object = {};

  for (var key in from)
  {
    //object[key] = from[key];
  }

  return object;
*/
}

// Quick method to compare objects. Assumes they have the same keys, intended for testing
// whether an object has changed after editing or matches the object it was cloned from.
// Do not use for comparing arbitrary objects for equality.
function objectsEqual(a, b) {
  if (a == null || b == null) {
    return a == b;
  }

  for (var key in a) {
    var a_val = a[key],
      b_val = b[key];
    if (a_val && b_val && typeof a_val === 'object') {
      if (a_val.toString() !== b_val.toString()) {
        return false;
      }
    } else if (a_val !== b_val) {
      return false;
    }
  }

  return true;
}

function convertCurrencyToNumber(str) {
  if (I18n.lookup('number.currency.format.separator') == '.')
    var number = parseFloat(str.replace(/[^0-9\.]+/g, ''));
  else if (I18n.lookup('number.currency.format.separator') == ',') {
    var str = str.replace(/[^0-9\,]+/g, '');
    var number = parseFloat(str.replace(/[\,]+/g, '.'));
  } // default
  else var number = parseFloat(str.replace(/[^0-9\.]+/g, ''));
  return number;
}

function getGuid() {
  window._nextGuid = window._nextGuid || 0;

  return '_guid_' + ++window._nextGuid;
}

function parseRubyDateFromISOWithoutTimezone(s, includeTime) {
  var tmpDate = s.replace(/Z*$/, '');
  var matches = _rubyDateRegex.exec(tmpDate);
  var d;
  if (matches && matches.length == 7) {
    d = new Date();
    d.setYear(matches[1]);
    d.setMonth(matches[2] - 1); // ? zero based?
    d.setDate(matches[3]);
    if (!includeTime) {
      d.setHours(matches[4]);
      d.setMinutes(matches[5]);
      d.setSeconds(matches[6]);
    }
  } else {
    d = new Date(s);
    if (!includeTime) {
      d.toStartOfDay();
    }
  }
  return d;
}

_rubyDateRegex = new RegExp(/(\d*)-(\d*)-(\d*)T(\d*):(\d*):(\d*)/);
function parseRubyDateTime(s, includeTime) {
  var matches = _rubyDateRegex.exec(s);
  var d;
  if (matches && matches.length == 7) {
    d = new Date(
      matches[2] +
        '/' +
        matches[3] +
        '/' +
        matches[1] +
        ' ' +
        matches[4] +
        ':' +
        matches[5] +
        ':' +
        matches[6] +
        ' GMT'
    );
  } else {
    d = new Date(s);
  }

  if (!includeTime) {
    d.toStartOfDay();
  }
  return d;
}

function parseRubyDate(s, skipValidation) {
  var nums, d;
  if (s) {
    nums = s.split('-');
    nums[1]--;
    d = new Date(nums[0], nums[1], nums[2]);
    if (!skipValidation && isNaN(d.getTime())) {
      d = parseRubyDateTime(s);
    }
    return d;
  }
}

//This implies that we are living in client time...
function parseRubyDateTimezoneAgnostic(s) {
  var nums, d;
  if (Object.prototype.toString.call(s) === '[object Date]') {
    d = new Date(s);
  } else {
    nums = s.split('-');
    nums[1]--;
    d = new Date(nums[0], nums[1], nums[2]);
  }
  return d;
}

function dateRangesOverlap(d1Start, d1End, d2Start, d2End, endDatesInclusive) {
  d1Start = d1Start.getTime();
  d1End = d1End.getTime();
  d2Start = d2Start.getTime();
  d2End = d2End.getTime();
  if (endDatesInclusive) {
    return d1Start <= d2End && d1End >= d2Start;
  }
  return d1Start < d2End && d1End > d2Start;
}

function mouseWheelHandler(obj, userHandler) {
  var _i = this;

  function wheelEvent(event) {
    var delta = 0;

    event = window.event || event;

    if (event.wheelDelta) {
      delta = event.wheelDelta / 120;
      if (window.opera) delta = -delta;
    }

    if (event.detail) {
      delta = -event.detail / 3;
    }

    if (delta) {
      userHandler(delta, event);
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    event.returnValue = false;
  }

  if (typeof obj == 'string') obj = document.getElementById(obj);

  if (window.addEventListener) {
    obj.addEventListener('DOMMouseScroll', wheelEvent, false);
  }
  obj.onmousewheel = wheelEvent;
}

function dist(x1, y1) {
  return Math.sqrt(Math.pow(x1, 2) + Math.pow(y1, 2));
}

function ang(x1, y1, x2, y2) {
  var rad = Math.atan2(y1 - y2, x1 - x2);
  var tmp = (-1 * rad * 180) / Math.PI;
  if (tmp < 0) {
    tmp += 360;
  }
  return tmp;
}

function direction(angl) {
  if (angl <= 45) {
    return 'E';
  }
  if (angl >= 315) {
    return 'E';
  }
  if (angl >= 45 && angl <= 135) {
    return 'N';
  }
  if (angl >= 135 && angl <= 215) {
    return 'W';
  }
  if (angl >= 215 && angl <= 315) {
    return 'S';
  }
}

function isToday(dt) {
  var today = new Date().toStartOfDay();
  if (dt.getTime() < today.getTime()) {
    return false;
  }
  today.setDate(today.getDate() + 1);
  return dt.getTime() < today.getTime();
}

function isGreaterThanToday(dt) {
  var td = new Date().toStartOfDay();
  td.setDate(td.getDate() + 1);
  return dt.getTime() >= td.getTime();
}

function colorObjFromAssignableByDate(assignable, dt) {
  var todate = new Date().toStartOfDay(),
    todate_time = todate.getTime(),
    dt_time = dt.getTime();

  if (dt_time < todate_time) {
    var uiState = 'enabled';
  }

  if (dt_time === todate_time) {
    var uiState = 'highlighted';
  }

  if (dt_time > todate_time) {
    var uiState = 'disabled';
  }

  return colorObjFromAssignable(assignable, uiState);
}

function colorObjFromAssignable(assignable, uiState) {
  var colors = {
    external: {
      enabled: {
        backgroundColor: '#00A69C',
        color: '#FFFFFF',
        border: '1px solid #49d6cf',
      },

      disabled: {
        backgroundColor: '#00A69C',
        color: '#80d3CE',
        border: '1px solid #49d6cf',
      },

      highlighted: {
        backgroundColor: '#2EB6AE',
        color: '#FFFFFF',
        border: '1px solid #49d6cf',
      },
    },

    internal: {
      enabled: {
        backgroundColor: '#8BC53F',
        color: '#FFFFFF',
        border: '1px solid #c0e780',
      },

      disabled: {
        backgroundColor: '#8BC53F',
        color: '#C5E29F',
        border: '1px solid #c0e780',
      },

      highlighted: {
        backgroundColor: '#A0CF62',
        color: '#FFFFFF',
        border: '1px solid #c0e780',
      },
    },

    leave: {
      enabled: {
        backgroundColor: '#D0D2D3',
        color: '#FFFFFF',
        border: '1px solid #F1F1F1',
      },

      disabled: {
        backgroundColor: '#D0D2D3',
        color: '#A0A0A0',
        border: '1px solid #F1F1F1',
      },

      highlighted: {
        backgroundColor: '#EFEFEF',
        color: '#FFFFFF',
        border: '1px solid #F1F1F1',
      },
    },
  };

  if (!assignable.project_state) {
    return colors.leave[uiState];
  }

  if (assignable.project_state && assignable.project_state == 'Internal') {
    return colors.internal[uiState];
  }

  return colors.external[uiState];
}

// converts a decimal to and hours:minutes string
// null argument is equivalent to 0
// returns null for a non parseable arg
// returns null for value less than 0
// used by quickbooks export
function decimalToHourMinuteString(decimalHoursStr) {
  decimalHoursStr = decimalHoursStr || '0';
  var decimalHours = parseFloat(decimalHoursStr);

  if (isNaN(decimalHours)) {
    return null;
  }
  if (decimalHours < 0) {
    return null;
  }

  var hours = Math.floor(decimalHours);
  var minutes = Math.round((decimalHours - hours) * 60);

  if (minutes == 60) {
    minutes = 0;
    hours += 1;
  }

  hoursStr = (hours < 10 ? '0' : '') + hours.toString();
  minutesStr = (minutes < 10 ? '0' : '') + minutes.toString();

  return hoursStr + ':' + minutesStr;
}

// dynamically post
// used by quickbooks export
function post_to_url(path, params, method) {
  method = method || 'post'; // Set method to post by default, if not specified.

  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  var form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  if (csrf_token()) {
    var hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', csrf_param());
    hiddenField.setAttribute('value', csrf_token());
    form.appendChild(hiddenField);
  }

  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

function createAndDownloadFile(fileObject, fallbackFn) {
  var fileName = fileObject.fileName || fileObject.filename,
    content = fileObject.content || fileObject.data,
    mimeType = fileObject.mimeType || fileObject.mimetype || 'text/plain';

  if (!fileName || content == null) throw 'incomplete file info';

  var URL = window.webkitURL || window.URL;
  if (!URL || BrowserDetect.browser === 'Safari') {
    if (fallbackFn) return fallbackFn(fileObject);
    throw 'URL not supported and no fallback provided';
  }

  var blob = new Blob([content], { type: mimeType });
  if (navigator.msSaveOrOpenBlob) {
    //IE 10+
    navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    var a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [mimeType, a.download, a.href].join(':');

    a.style.display = 'none';
    document.body.appendChild(a); // Firefox requires the element to be in the dom for the click to work
    a.click();
    document.body.removeChild(a);

    a.dataset.disabled = true;

    // Need a small delay for the revokeObjectURL to work properly.
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
    }, 1500);
  }
}

iconForTag = function (tag) {
  if (tag.namespace == 'project state' && tag.name == 'Tentative') {
    return 'grayMark';
  }
  if (tag.namespace == 'project state' && tag.name == 'Confirmed') {
    return 'blueMark';
  }
  if (tag.namespace == 'project state' && tag.name == 'Internal') {
    return 'purpleMark';
  }
};

var __escapeCsvQuoteRegEx = /\"/g,
  __escapeCsvQuote = '"',
  __escapeCsvEscapedQuote = '""';

escapeCsv = function (s) {
  var wrapInQuotes = false;
  if (s.indexOf(__escapeCsvQuote) >= 0) {
    wrapInQuotes = true;
    s = s.replace(__escapeCsvQuoteRegEx, __escapeCsvEscapedQuote);
  }
  wrapInQuotes = wrapInQuotes || s.indexOf(',') >= 0 || s.indexOf('\n') >= 0;
  if (wrapInQuotes) {
    s = __escapeCsvQuote + s + __escapeCsvQuote;
  }
  return s;
};

removeDuplicateProjects = function (arr) {
  var i;
  var len = arr.length;
  var out = [];
  var obj = {};

  for (i = 0; i < len; i++) {
    obj[arr[i].id] = arr[i];
  }
  for (i in obj) {
    out.push(obj[i]);
  }
  return out;
};

// This adds an angular widget to a container element.
// - tag is the tag name for the new element
// - attrs is a hash of attributes to add to the new element
function embedAngularWidget(container, tag, attrs) {
  var el = document.createElement(tag);
  var root = document.createElement('DIV');
  root.className = 'widget-wrapper';
  root.setAttribute('ng-controller', 'BridgeController');
  root.appendChild(el);

  //el.setAttribute("ng-controller", controller);
  if (attrs) {
    _.each(attrs, function (value, key) {
      el.setAttribute(key, value);
    });
  }

  container.appendChild(root);
  var START = Date.now();
  angular.bootstrap(root, ['app']);

  console.log('Angular embed took', (Date.now() - START) * 0.001, 'seconds');

  return root;
}

// Trigger a custom event on a DOM element. Any params after the domElement will be sent with the event
function triggerCustomEvent(name, domElement) {
  if (domElement) {
    var params = [];
    for (var i = 2; i < arguments.length; ++i) {
      params.push(arguments[i]);
    }
    var event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, false, params);
    domElement.dispatchEvent(event);
  }
}

function verifyEmail(str, requireEmail) {
  if (str == null || str == '') {
    if (requireEmail) {
      // treat null or empty strings as not valid
      return false;
    } else {
      return true;
    }
  } else {
    if (str.search(VALID_EMAIL_REGEX) == -1) return false;
  }
  return true;
}

function getErrorMessage(prefix) {
  var message;
  switch (prefix) {
    case 'INVALID_CUSTOM_FIELD_VALUE':
      message = I18n.t('err_invalid_value_dropdown');
      break;
    default:
      __A(false, 'unknown error');
      message = 'Unknown error!';
      break;
  }

  return message;
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.substring(1, str.length);
}

function getPageTitleFromPath(location) {
  var path = location.pathname.split('/')[1];
  var pathToPageName = {
    plan: 'Schedule',
    viewproject: 'Project',
  };
  var pageName = pathToPageName[path] || capitalize(path);
  return pageName + ' Page';
}

/**
 * Like _.pluck, except returns a unique array.
 * (Dependency: underscore.js)
 *
 * @function pluckUnique
 * @param {Array.Object} arrayOfObj - The collection from which to pluck the given property.
 * @param {String} key              - The object property to extract.
 * @return {Array.String}           - The unique array of property values.
 */
function pluckUnique(arrayOfObj, key) {
  var pluckedValues = {};
  arrayOfObj.forEach(function (obj) {
    var valAtKey = obj[key];
    if (pluckedValues[valAtKey]) {
      pluckedValues[valAtKey]++;
    } else {
      pluckedValues[valAtKey] = 1;
    }
  });
  return _.keys(pluckedValues);
}

function isWeekend(date) {
  if (!date instanceof Date)
    throw new TypeError(
      'cannot determine if ' + date + ' is a weekend, as it is not of type Date'
    );
  // JS implements getDay as 0-indexed, beginning with Sunday.
  var dayOfWeek = date.getDay(),
    saturday = 6,
    sunday = 0;
  return dayOfWeek === sunday || dayOfWeek === saturday;
}

function smartsheetBaseURL(hostname) {
  var domain = '.com';

  if (hostname.includes('.eu')) {
    domain = '.eu';
  }

  if (hostname.indexOf('.smart.ninja') !== -1) {
    return 'https://app.'.concat(hostname.split('.')[1], '.smart.ninja');
  }
  if (hostname.indexOf('staging.') !== -1) {
    return 'https://app.test.10000ft' + domain;
  }
  return 'https://app.smartsheet' + domain;
}

function buildViewInSheetItem(assignable, assignment) {
  var itemWrapClass = assignment.row_id
    ? 'gridMenu__row-id-option'
    : 'gridMenu__row-id-option--disabled';
  var iconHtmlString =
    '<div class="gridMenu__row-id-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><polygon points="17 17 7 17 7 7 9.5 7 9.5 8 8 8 8 16 16 16 16 14.5 17 14.5 17 17" /><polygon points="17 7 12 7 13.79 8.79 10.17 12.41 11.59 13.83 15.21 10.21 17 12 17 7" /></svg></div>';
  var labelHtmlString =
    '<span class="ellipsis">' + I18n.t('lbl_view_in_sheet') + '</span>';
  var title = assignment.row_id
    ? I18n.t('msg_assignment_linked_smartsheet')
    : I18n.t('msg_assignment_not_linked_smartsheet');
  var labelString =
    '<div class=' +
    itemWrapClass +
    '>' +
    iconHtmlString +
    labelHtmlString +
    '</div>';

  return {
    type: 'item',
    value: {
      callback: function (a, p, v, onSelectionHandledCallback, r, f) {
        var rowDirectURL = smartsheetBaseURL(window.location.hostname)
          .concat('/sheets/')
          .concat(assignable.mapped_sheet_id)
          .concat('?rowId=')
          .concat(assignment.row_id);
        window.open(rowDirectURL, '_blank');
        onSelectionHandledCallback && onSelectionHandledCallback();
      },
    },
    label: labelString,
    title: title,
    unselectable: !assignment.row_id,
    overrideParentEllipsis: true,
    escaped: true,
  };
}

function utilizationTimelineWebworkerScript() {
  var utilizationTimelineJS = `
  var accountSettingsHoursInWorkday,
    assignments,
    availabilities,
    availabilityCache = {},
    availabilitySince,
    hire_date,
    holidayDates, // list of date strings
    termination_date,
    workWeekHoursInWorkday,
    workingDays;

  self.onmessage = function (event) {
    var input = event.data;
    if (input instanceof Object && input.action == 'buildUtilizationTimeline') {
      accountSettingsHoursInWorkday = input.data.accountSettingsHoursInWorkday;
      assignments = input.data.assignments;
      availabilities = input.data.availabilities;
      availabilitySince = input.data.AVAILABILITY_SINCE;
      hire_date = input.data.hire_date;
      holidayDates = input.data.holidayDates;
      termination_date = input.data.termination_date;
      workWeekHoursInWorkday = input.data.workWeekHoursInWorkday;
      workingDays = input.data.workingDays;
      self.postMessage({
        timeline: buildUtilizationTimeline(input.data.options),
      });
      self.close();
    }
  };

  function buildUtilizationTimeline(options) {
    performance.mark('buildUtilizationTimeline Start');

    var i, j, k, o;

    // Final result goes here:
    var timeline = [],
      // Cached calculations go here:
      timelineEvents = [],
      timelineEventsByDate = {};

    // Build a map of times to arrays of events (start/end of projects)
    for (i = 0; i < self.assignments.length; ++i) {
      var a = self.assignments[i];
      var s = new Date(a.starts_at).getTime();

      // ends_at date is inclusive, so the next timeline event should start the day after.
      var e = Date.addDays(a.ends_at, 1).getTime();
      if (!timelineEventsByDate[s]) {
        timelineEventsByDate[s] = [];
        timelineEvents.push({
          time: s,
          events: timelineEventsByDate[s],
        });
      }
      o = {
        start: true,
        time: s,
        assignment: a,
      };
      timelineEventsByDate[s].push(o);
      if (!timelineEventsByDate[e]) {
        timelineEventsByDate[e] = [];
        timelineEvents.push({
          time: e,
          events: timelineEventsByDate[e],
        });
      }
      o = {
        start: false,
        time: e,
        assignment: a,
      };
      timelineEventsByDate[e].push(o);
    }

    // availability boundaries that don't fall on an existing timeline event date
    for (i = 0; self.availabilities && i < self.availabilities.length; i++) {
      var a = self.availabilities[i];
      (s = a.starts_at ? parseRubyDate(a.starts_at).getTime() : null),
        (e = a.ends_at ? parseRubyDate(a.ends_at).addDays(1).getTime() : null);

      if (s && !timelineEventsByDate[s]) {
        timelineEventsByDate[s] = [];
        timelineEvents.push({
          time: s,
          events: timelineEventsByDate[s],
        });
      }
      if (e && !timelineEventsByDate[e]) {
        timelineEventsByDate[e] = [];
        timelineEvents.push({
          time: e,
          events: timelineEventsByDate[e],
        });
      }
    }

    timelineEvents.sort(function (a, b) {
      return a.time - b.time;
    });

    // add start cap
    var firstTimelineEvent = timelineEvents[0];
    if (firstTimelineEvent && firstTimelineEvent.time > self.availabilitySince) {
      timeline.push({
        startTime: self.availabilitySince,
        endTime: firstTimelineEvent.time,
        assignments: [],
        utilization: 0.0,
        projectUtilizationScale: 1,
        isEndCap: false,
      });
    }

    var currentDate = null;
    var utilizationScale = 1;
    var assignments = [];

    for (i = 0; i < timelineEvents.length; ++i) {
      var event = timelineEvents[i];
      if (currentDate && event.time > self.availabilitySince) {
        // Build the current timeblock and store it in the timeline
        var utilization = 0;
        for (j = 0; j < assignments.length; ++j) {
          utilization += utilizationFromAssignment(assignments[j], options);
        }

        utilizationScale = getUtilizationScaleForDateRange(
          new Date(currentDate),
          new Date(event.time).addDays(1)
        );

        var block = {
          startTime:
            currentDate > self.availabilitySince
              ? currentDate
              : self.availabilitySince,
          endTime: event.time,
          assignments: [],
          utilization: utilization,
          projectUtilizationScale: utilizationScale,
        };

        for (k = 0; k < assignments.length; ++k) {
          block.assignments.push(assignments[k]);
        }
        timeline.push(block);
      }
      // Update the project list
      for (j = 0; j < event.events.length; ++j) {
        var e = event.events[j];
        if (e.start) {
          assignments.push(e.assignment);
        } else {
          for (k = 0; k < assignments.length; ++k) {
            if (assignments[k].id == e.assignment.id) {
              assignments.splice(k, 1);
              break;
            }
          }
        }
      }
      currentDate = event.time;
    }

    // add end-cap
    var lastEntry = timeline[timeline.length - 1];
    var today = new Date();
    today.toStartOfDay();

    if (lastEntry) {
      var endCap = {
        startTime: lastEntry.endTime,
        endTime: Date.addDays(today, 365).getTime(),
        assignments: [],
        utilization: 0.0,
        projectUtilizationScale: 1,
        isEndCap: true,
      };
      timeline.push(endCap);
    } else {
      // This user has an empty timeline
      var endCap = {
        startTime: self.availabilitySince,
        endTime: Date.addDays(today, 365).getTime(),
        assignments: [],
        utilization: 0.0,
        projectUtilizationScale: 1,
        isEndCap: true,
      };
      timeline.push(endCap);
    }

    performance.mark('buildUtilizationTimeline End');
    performance.measure(
      'buildUtilizationTimeline',
      'buildUtilizationTimeline Start',
      'buildUtilizationTimeline End'
    );

    return timeline;
  }

  // NOTE
  // The utility functions below are taken from util.js and userWrapper.js,
  // with minor adaptations in some cases.

  // add n days to this date. alters the value of this date
  Date.prototype.addDays = function (n) {
    this.setDate(this.getDate() + n);
    return this;
  };

  // get a new date that is d + n days. d is not altered
  Date.addDays = function (d, n) {
    var newDate = new Date(d);
    newDate.addDays(n);
    return newDate;
  };

  Date.calendarDayDiff = function (a, b) {
    // Discard the time and time-zone information.
    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / Date._MS_PER_DAY);
  };

  Date.prototype.toStartOfDay =
    Date.prototype.toStartOfDay ||
    function () {
      this.setHours(0, 0, 0, 0);
      return this;
    };

  Date._MS_PER_DAY = 1000 * 60 * 60 * 24;

  _rubyDateRegex = new RegExp(/(\d*)-(\d*)-(\d*)T(\d*):(\d*):(\d*)/);
  function parseRubyDateTime(s, includeTime) {
    var matches = _rubyDateRegex.exec(s);
    var d;
    if (matches && matches.length == 7) {
      d = new Date(
        matches[2] +
          '/' +
          matches[3] +
          '/' +
          matches[1] +
          ' ' +
          matches[4] +
          ':' +
          matches[5] +
          ':' +
          matches[6] +
          ' GMT'
      );
    } else {
      d = new Date(s);
    }

    if (!includeTime) {
      d.toStartOfDay();
    }
    return d;
  }

  function parseRubyDate(s, skipValidation) {
    var nums, d;
    if (s) {
      nums = s.split('-');
      nums[1]--;
      d = new Date(nums[0], nums[1], nums[2]);
      if (!skipValidation && isNaN(d.getTime())) {
        d = parseRubyDateTime(s);
      }
      return d;
    }
  }

  function getWorkdaysDuring(from, to) {
    var workDays = 0,
      endTime = to.getTime();
    while (from.getTime() < endTime) {
      if (getAvailabilityOn(from) > 0) {
        workDays += 1;
      }
      from = Date.addDays(from, 1);
    }
    return workDays;
  }

  function getAvailableHoursDuring(from, to) {
    var availableHours = 0,
      endTime = to.getTime();
    while (from.getTime() < endTime) {
      availableHours += getAvailabilityOn(from);
      from = Date.addDays(from, 1);
    }
    return availableHours;
  }

  function utilizationFromAssignment(a, options) {
    if (options && options.assignmentFilter && !options.assignmentFilter(a)) {
      return 0;
    }
    var available_hours, adjustedEndsAtDate, workDaysDuringAssignment;

    // NB: Assignment endDate is inclusive, but work day calculations use an exclusive end date.

    switch (a.allocation_mode) {
      case 'percent':
        return a.percent;
      case 'hours_per_day':
        adjustedEndsAtDate = Date.addDays(a.ends_at, 1);
        workDaysDuringAssignment = getWorkdaysDuring(
          a.starts_at,
          adjustedEndsAtDate
        );
        if (workDaysDuringAssignment == 0) {
          return a.hours_per_day / self.workWeekHoursInWorkday;
        } else {
          return (
            (a.hours_per_day * workDaysDuringAssignment) /
            getAvailableHoursDuring(a.starts_at, adjustedEndsAtDate)
          );
        }
      case 'fixed':
        adjustedEndsAtDate = Date.addDays(a.ends_at, 1);
        available_hours = getAvailableHoursDuring(
          a.starts_at,
          adjustedEndsAtDate
        );
        if (available_hours == 0) {
          // Use the organization-wide hours per work day.
          workDaysDuringAssignment = getWorkdaysDuring(
            a.starts_at,
            adjustedEndsAtDate
          );
          if (workDaysDuringAssignment == 0) {
            workDaysDuringAssignment = Date.calendarDayDiff(
              a.starts_at,
              adjustedEndsAtDate
            );
          }
          return (
            a.fixed_hours /
            (self.workWeekHoursInWorkday * workDaysDuringAssignment)
          );
        } else {
          return a.fixed_hours / available_hours;
        }
      default:
        throw 'unknown allocation mode';
    }
  }

  function isHoliday(d) {
    return (
      self.holidayDates.findIndex(function (date) {
        return date == d.toDateString();
      }) != -1
    );
  }

  var _isWorkdayCache_1 = {}; // treatCompanyHolidayAsWorkday
  var _isWorkdayCache_2 = {}; // not treatCompanyHolidayAsWorkday
  function isWorkday(d, treatCompanyHolidayAsWorkday) {
    var ci = d.getTime(); // cache index
    var result = treatCompanyHolidayAsWorkday
      ? self._isWorkdayCache_1[ci]
      : self._isWorkdayCache_2[ci];

    if (result != undefined) {
      return result;
    } else {
      result = false;

      if (self.workingDays[d.getDay()]) {
        if (treatCompanyHolidayAsWorkday || !isHoliday(d)) {
          result = true;
        }
      }

      if (treatCompanyHolidayAsWorkday) self._isWorkdayCache_1[ci] = result;
      else self._isWorkdayCache_2[ci] = result;

      return result;
    }
  }

  function getAvailabilityOn(date) {
    var key = new Date(date.getTime()).setHours(0, 0, 0, 0), // start of day, as ms
      value = availabilityCache[key],
      a;

    if (!(value === 0 || value)) {
      var notAvailable =
        (self.termination_date && date > self.termination_date) ||
        (self.hire_date && date < self.hire_date);
      if (notAvailable) {
        value = 0;
      } else {
        for (
          var i = 0;
          self.availabilities && i < self.availabilities.length;
          i++
        ) {
          a = self.availabilities[i];
          if (
            (a.starts_at == null ||
              Date.calendarDayDiff(parseRubyDate(a.starts_at), date) >= 0) &&
            (a.ends_at == null ||
              Date.calendarDayDiff(date, parseRubyDate(a.ends_at)) >= 0)
          ) {
            value = a.days[date.getDay()];
            break;
          }
        }
        if (!isWorkday(date)) value = 0;
        if (!(value === 0 || value))
          value = Number(self.accountSettingsHoursInWorkday);
      }
      availabilityCache[key] = value;
    }
    return value;
  }

  function getUtilizationScaleForDateRange(from, to) {
    var availableHours = 0,
      workDays = 0,
      h,
      endTime = to.getTime();
    while (from.getTime() < endTime) {
      h = getAvailabilityOn(from);
      if (h > 0) {
        availableHours += h;
        workDays += 1;
      }
      from = Date.addDays(from, 1);
    }
    return availableHours / (workDays || 1);
  }
  `;
  var blob = new Blob([utilizationTimelineJS], {
    type: 'application/javascript',
  });
  return (window.URL || window.webkitURL).createObjectURL(blob);
}

// NOTE Web worker scripts MUST be served from the same origin as the parent page.
// Since we store legacy assets in a different server (where the worker scripts are),
// we need another way to load them.
// References:
// https://stackoverflow.com/questions/5408406/web-workers-without-a-separate-javascript-file
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Using_web_workers#importing_scripts_and_libraries
// `workerScript` - filename of the script under the app/assets/javascripts/webworkers directory
function findWorkerURL(workerScript) {
  var assetsEndpoint = window.ASSETS_ENDPOINT + '/core-frontend';
  var uiBranchCookie = document.cookie
    .split(';')
    .map(function (c) {
      return c.trim();
    })
    .find(function (c) {
      return c.includes('ui-branch');
    });
  if (uiBranchCookie) {
    var uiBranch = uiBranchCookie.split('=')[1];
    if (uiBranch.toLowerCase() === 'local') {
      assetsEndpoint = 'http://localhost:3000';
    } else {
      assetsEndpoint = assetsEndpoint + '/' + uiBranch;
    }
  } else {
    assetsEndpoint = assetsEndpoint + '/mainline';
  }
  var assetUrl = assetsEndpoint + '/webworkers/' + workerScript;
  var blob = new Blob(['importScripts("' + assetUrl + '");'], {
    type: 'application/javascript',
  });
  return (window.URL || window.webkitURL).createObjectURL(blob);
}

// Focus Handling
function focusElementAfterDelay(selector) {
  var focusInterval = setInterval(function () {
    var element = document.querySelectorAll(selector);

    if (element.length > 0) {
      var focusElem = element[0];
      focusElem.focus();
    }
    clearInterval(focusInterval);
  }, 100);
  setTimeout(function () {
    clearInterval(focusInterval);
  }, 1000);
}

function focusFirstChildElementAfterDelay(limitor, index) {
  var focusInterval = setInterval(function () {
    var focusable = document.querySelectorAll(
      limitor +
        ' button, ' +
        limitor +
        ' [href], ' +
        limitor +
        ' input, ' +
        limitor +
        ' select, ' +
        limitor +
        ' textarea, ' +
        limitor +
        " [tabindex]:not([tabindex='-1'])"
    );
    var focusIndex = 0;

    // to focus a child other than the first
    if (index) {
      focusIndex = index;
    }

    var childFocusable = focusable[focusIndex];
    if (childFocusable) {
      childFocusable.focus();
    }

    clearInterval(focusInterval);
  }, 100);
  setTimeout(function () {
    clearInterval(focusInterval);
  }, 5000);
}
