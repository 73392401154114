function repeatAssignmentPopup(callback, parentPopupContainer, blurCallback) {
  if (!(this instanceof repeatAssignmentPopup)) {
    return new repeatAssignmentPopup(
      callback,
      parentPopupContainer,
      blurCallback
    );
  }

  var MIN_WIDTH = 240;
  var MAX_WIDTH = 340;
  var WIDTH = Math.min(
    MIN_WIDTH * window.rm.l10n.getWidthMultiplier(),
    MAX_WIDTH
  );
  var HEIGHT = 120;
  var _i = this;

  _i.callback = callback;
  _i.blurCallback = blurCallback;
  _i.popupContainer;

  function updateCbInternal(confirmButton) {
    var undoPopup = confirmationPopupPrimaryButton(
      I18n.t('msg_once_applied_cannot_undo'),
      I18n.t('lbl_confirm'),
      function () {
        _i.popupContainer.hide();
        var r = _i.repeatEvery.getValue();
        var c = _i.endAfter.getValue();

        r = r && r.trim() != '' ? parseInt(r) : 0;
        c = c && c.trim() != '' ? parseInt(c) : 0;
        if (c > 52) {
          _i.popupContainer.setDimensions(WIDTH, HEIGHT + 30);
          _i.labelForCountTooLargeError.style.display = 'block';
        } else {
          _i.popupContainer.remove();
          _i.callback(r, c);
        }
      },
      null,
      _i.popupContainer,
      null,
      null,
      null,
      null,
      null,
      true
    );

    undoPopup.show(confirmButton.container);
  }

  function blurCbInternal() {
    _i.popupContainer.remove();
    _i.blurCallback && _i.blurCallback();
  }

  _i.show = function (attachToElement) {
    _i.popupContainer.pointAtElement(attachToElement);
  };

  _i.showAtPoint = function (x, y) {
    _i.popupContainer.pointAt(x, y, 0, 0);
  };

  _i.blur = function () {
    blurCbInternal();
  };

  function init() {
    var spec = parentPopupContainer
      ? compSpritesButtonsIcons.wideDialogSpecsGray
      : compSpritesButtonsIcons.wideDialogSpecs;

    var viewport = document.getElementById('mainCon');

    var content = document.createElement('DIV');
    content.style.width = WIDTH + 'px';
    content.style.height = HEIGHT + 'px';
    content.style.overflow = 'hidden';

    var line, label;

    line = document.createElement('DIV');
    line.className = 'blockFloatNot';

    label = document.createElement('DIV');
    label.className = 'blockFloat fnt-r-14';
    label.style.width = window.rm.l10n.getWidthMultiplier() * 90 + 'px';
    label.style.marginTop = '7px';
    label.innerHTML = I18n.t('lbl_repeat_every');
    line.appendChild(label);

    _i.repeatEvery = new TextInput(
      line /*attachTo*/,
      null /*label*/,
      '1',
      null,
      null,
      null,
      30,
      TextInputFilter.Integer,
      null,
      updateCbInternal /* enterCb */,
      null /* changedCb */,
      null /* change_id */,
      null /* focusCb */
    );

    _i.repeatEvery.inputContainer.style.marginTop = '0px';
    _i.repeatEvery.inputContainer.style.marginLeft = '10px';
    _i.repeatEvery.inputText.style.width = '30px';
    _i.repeatEvery.inputText.style.backgroundColor = '#FFFFFF';

    label = document.createElement('DIV');
    label.className = 'blockFloat fnt-r-14';
    label.style.marginTop = '7px';
    label.style.marginLeft = '20px';
    label.innerHTML = I18n.t('lbl_week_parenthesis_s');

    line.appendChild(label);

    content.appendChild(line);

    line = document.createElement('DIV');
    line.className = 'blockFloatNot';
    line.style.marginTop = '7px';

    label = document.createElement('DIV');
    label.className = 'blockFloat fnt-r-14';
    label.style.width = window.rm.l10n.getWidthMultiplier() * 90 + 'px';
    label.style.marginTop = '7px';
    label.innerHTML = I18n.t('lbl_end_after');
    line.appendChild(label);

    _i.endAfter = new TextInput(
      line /*attachTo*/,
      null /*label*/,
      '1',
      null,
      null,
      null,
      30,
      TextInputFilter.Integer,
      null,
      updateCbInternal /* enterCb */,
      null /* changedCb */,
      null /* change_id */,
      null /* focusCb */
    );

    _i.endAfter.inputContainer.style.marginTop = '0px';
    _i.endAfter.inputContainer.style.marginLeft = '10px';
    _i.endAfter.inputText.style.width = '30px';
    _i.endAfter.inputText.style.backgroundColor = '#FFFFFF';

    label = document.createElement('DIV');
    label.className = 'blockFloat fnt-r-14';
    label.style.marginTop = '7px';
    label.style.marginLeft = '20px';
    label.innerHTML = I18n.t('lbl_times');

    line.appendChild(label);
    content.appendChild(line);

    label = document.createElement('DIV');
    label.className = 'blockFloat fnt-r-11';
    label.style.marginTop = '10px';
    label.style.whiteSpace = 'nowrap';
    label.innerHTML = I18n.t('msg_you_can_only_repeat_52_times_or_less');

    content.appendChild(label);

    _i.labelForCountTooLargeError = label;

    var buttons = document.createElement('DIV');
    buttons.className = 'buttonContainerCenterWide';
    buttons.style.marginTop = '12px';

    var confirmButton = new primaryButton(
      'buttonContainerCenterWide',
      I18n.t('msg_confirm_assignment_repeat')
    );
    confirmButton.setButtonText(I18n.t('lbl_update'));
    confirmButton.setCallback(updateCbInternal);

    buttons.appendChild(confirmButton.container);

    content.appendChild(buttons);

    _i.popupContainer = new popupContainer(
      spec,
      WIDTH,
      HEIGHT,
      8, // innerMargin
      true, // autorotate
      'wc',
      viewport,
      ['wc'],
      _i.blur, // blurCallback
      parentPopupContainer
    );

    _i.popupContainer.attachContent(content);
  }

  init();
}
