function InfoIconSVG(attach, circleColor = '#057ED1', iColor = '#CAE8FC') {
  var _i = this;
  _i.attachTo = xGetElementById(attach);

  _i.constructor = () => {
    const infoIcon = document.createElement('DIV');
    infoIcon.style = `
      padding-right: 8px;
      height: 24px;
      width: 24px;
      margin: 0 0 0 0;
    `;
    _i.attachTo.appendChild(infoIcon);

    const svgxmlns = 'http://www.w3.org/2000/svg';
    const svg = document.createElementNS(svgxmlns, 'svg');
    svg.setAttribute('xmlns', svgxmlns);
    svg.setAttribute('width', 24);
    svg.setAttribute('height', 24);
    svg.setAttribute('viewBox', '-2 -2 24 24');
    svg.setAttribute('className', 'infoIcon');
    svg.style.overflow = 'visible';
    infoIcon.appendChild(svg);

    // These must be added via editing the innerHTML because using document.createElement creates <tag></tag> style elements, while svg paths MUST be <tag /> style elements
    svg.innerHTML = `
      <path fillrule="evenodd" cliprule="evenodd" d="M20 10.0077C20 15.5305 15.5228 20.0077 10 20.0077C4.47715 20.0077 0 15.5305 0 10.0077C0 4.48484 4.47715 0.00769043 10 0.00769043C15.5228 0.00769043 20 4.48484 20 10.0077ZM18.5 10.0077C18.5 14.7021 14.6944 18.5077 10 18.5077C5.30558 18.5077 1.5 14.7021 1.5 10.0077C1.5 5.31327 5.30558 1.50769 10 1.50769C14.6944 1.50769 18.5 5.31327 18.5 10.0077Z" fill="${circleColor}" />
      <path fillrule="evenodd" cliprule="evenodd" d="M9.20001 6.80427C9.20174 6.35218 9.55565 6.00652 9.99921 6.00769C10.4741 6.00892 10.8004 6.39043 10.8 6.80812C10.7995 7.24946 10.4396 7.60833 9.99779 7.60768C9.57633 7.60683 9.19825 7.27365 9.20001 6.80427Z" fill="${iColor}" />
      <path fillrule="evenodd" cliprule="evenodd" d="M10.7492 14.3129C10.7486 14.6383 10.5116 14.9118 10.17 14.9883C9.71221 15.0909 9.39083 14.7714 9.37432 14.3347C9.3734 14.3105 9.37345 14.2862 9.3735 14.262L9.37353 14.2453L9.37348 10.4908C9.37348 10.4046 9.37341 10.4042 9.28031 10.4046C9.1837 10.405 9.08723 10.4038 8.99167 10.3884C8.66394 10.3356 8.35853 10.0513 8.38137 9.65302C8.39902 9.34503 8.68258 9.05745 9.03017 9.01581C9.08634 9.0091 9.14347 9.00689 9.20019 9.0067C9.37958 9.00616 9.50847 9.00665 9.64978 9.00719C9.73532 9.00752 9.8254 9.00786 9.93399 9.008C10.1116 9.00823 10.2813 9.03729 10.4283 9.13712C10.6395 9.28063 10.7492 9.47389 10.7494 9.71702C10.75 10.2828 10.7499 10.8485 10.7498 11.4142L10.7498 12.6865C10.75 13.2286 10.7502 13.7707 10.7492 14.3129Z" fill="${iColor}" />
    `;
  };

  _i.constructor();
}
